import { useState } from "react"
import { useParams } from "react-router-dom"
import { useQuery } from "@apollo/client"
import { Col, Row, Space } from "antd"
import { graphql } from "src/api/graphql/gql.ts"
import { useRequireRole } from "src/global/hooks/useRequireRole.ts"
import { getDataAssetBreadcrumbs } from "src/pages/DataAssets/views/AssetDetails/DataAssetBreadcrumbs.tsx"
import { DataAssetTabs } from "src/pages/DataAssets/views/AssetDetails/DataAssetTabs.tsx"
import { MetricsTable } from "src/pages/DataAssets/views/AssetDetails/MetricsTable.tsx"
import { SimpleNewExpectationDrawer } from "src/pages/DataAssets/views/Expectations/SimpleExpectationDrawer/SimpleNewExpectationDrawer.tsx"
import { MainContent, MainContentBlock } from "src/global/layout/MainContent.tsx"
import { HeaderTypes } from "src/global/ui/PageHeader/types.ts"
import { PageHeader } from "src/global/ui/PageHeader/PageHeader.tsx"
import { useIsDemoData } from "src/global/hooks/useIsDemoData.ts"
import { DataAssetOverviewAlert } from "src/global/components/alerts/DemoData/DataAssetOverviewAlert.tsx"
import { useDemoDataAssetName } from "src/global/hooks/useDemoDataAssetName.ts"
import { dataAssetsNavigateToVar } from "src/pages/DataAssets/components/DataAssetsTable"

export const MetricsTab_DataAssetDocument = graphql(`
  query MetricsTab_DataAsset($id: UUID!) {
    dataAsset(id: $id) {
      id
      name
      datasourceV2 {
        id
        ...DataAssetBreadcrumbs_DataSource
      }
      ...UseIsDemoData_DataAsset
      ...UseDemoDataAssetName_DataAsset
    }
  }
`)

export function MetricsTab() {
  const { assetId } = useParams<Record<"assetId", string>>()
  const dataAssetId = decodeURIComponent(assetId ?? "")

  const { data } = useQuery(MetricsTab_DataAssetDocument, {
    variables: { id: dataAssetId },
    skip: !dataAssetId,
  })

  const isEditor = useRequireRole("EDITOR")
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  const isDemoData = useIsDemoData(data?.dataAsset)
  const assetName = useDemoDataAssetName(data?.dataAsset)

  const headerContent: HeaderTypes = {
    title: data?.dataAsset?.name ?? "",
    rootPath: "data-assets",
    navigateBackTo: dataAssetsNavigateToVar(),
    ...(isEditor && {
      rightActions: {
        ctaButton: {
          type: "primary",
          icon: "plus",
          children: "New Expectation",
          disabled: !dataAssetId,
          onClick: () => setIsDrawerOpen(true),
        },
      },
    }),
    customBreadcrumbs: getDataAssetBreadcrumbs({
      dataSource: data?.dataAsset?.datasourceV2,
      tooltipPlacement: "right",
    }),
    footer: <DataAssetTabs currentTab="metrics" />,
  }

  return (
    <PageHeader headerContent={headerContent}>
      <MainContent>
        <MainContentBlock>
          {dataAssetId && (
            <SimpleNewExpectationDrawer
              dataAssetId={dataAssetId}
              open={isDrawerOpen}
              close={() => setIsDrawerOpen(false)}
            />
          )}
          <Row className="sentry-mask">
            <Col className="sentry-mask">
              <Space direction="vertical" size="large" style={{ display: "flex" }}>
                {isDemoData && <DataAssetOverviewAlert assetName={assetName} />}
                {dataAssetId && <MetricsTable assetId={dataAssetId} />}
              </Space>
            </Col>
          </Row>
        </MainContentBlock>
      </MainContent>
    </PageHeader>
  )
}
