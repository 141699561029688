import { useApolloClient } from "@apollo/client"
import { NoticeType } from "antd/es/message/interface"
import { useEffect, useState } from "react"
import { unmaskFragment } from "src/api/graphql/fragment-masking"
import { JobListItemInfoDocument } from "src/pages/AppLogs/components/JobListItem.tsx"
import { GenerateDataQualityCheckExpectationsJob, mapJobtoUnionType } from "src/pages/AppLogs/components/JobUnion.ts"
import { useJobListItems } from "src/pages/AppLogs/hooks/useJobListItems.ts"
import { usePreviousValue } from "src/global/hooks/usePreviousValue.ts"
import { AggregateCoverageStatsDocument } from "src/pages/DataAssets/components/AggregateAssetCoverageStats"

export function useGetAutogenerateStatus() {
  const { items: jobs } = useJobListItems()
  const [job, setJob] = useState<GenerateDataQualityCheckExpectationsJob>()
  const [jobMessage, setJobMessage] = useState<string>("")
  const [jobStatus, setJobStatus] = useState<NoticeType>()
  const [shouldEvict, setShouldEvict] = useState<boolean>(false)
  const jobStatusPreviousValue = usePreviousValue(jobStatus)
  const client = useApolloClient()

  useEffect(() => {
    const autogenerateJob = jobs.find(
      ({ jobType }) => jobType === "GENERATE_DATA_QUALITY_CHECK_EXPECTATIONS_REQUEST_RECEIVED",
    )
    if (jobs && autogenerateJob) {
      const _job = mapJobtoUnionType(unmaskFragment(JobListItemInfoDocument, autogenerateJob))
      if (_job.jobType === "GenerateDataQualityCheckExpectationsJob") {
        const assetCount = _job.sourceResources.filter(({ entityType }) => entityType === "DataAsset").length
        switch (_job.status) {
          case "inProgress":
            setShouldEvict(true)
            setJobStatus("loading")
            setJobMessage(`Generating Expectations for ${assetCount} asset${assetCount > 1 ? "s" : ""}`)
            break
          case "complete":
            setJobStatus("success")
            setJobMessage(`Generated Expectations for ${assetCount} asset${assetCount > 1 ? "s" : ""}`)
            break
          case "error":
            setJobStatus("error")
            setJobMessage("Failed to generate Expectations.")
            break
        }
        setJob(_job)
      }
    }
  }, [jobStatus, jobs])

  useEffect(() => {
    // Make sure we refetch the newly created expectations
    // otherwise the user will either assume the job failed
    // despite messaging, or need to manually refresh
    if (jobStatus !== "loading" && shouldEvict) {
      setShouldEvict(false)
      client.cache.evict({ fieldName: "expectations" })
      client.cache.gc()
      client.refetchQueries({
        include: [
          AggregateCoverageStatsDocument, // Refetch the aggregate coverage stats
        ],
      })
    }
  }, [client, jobStatus, shouldEvict])

  return {
    job,
    jobMessage,
    jobStatus,
    jobStatusPreviousValue,
    setJobStatus,
  }
}
