import { Tooltip as AntTooltip, TooltipProps as AntTooltipProps } from "antd"
import styled from "styled-components"
import { Icon, IconTypes } from "src/global/ui/Icon"
import { useState } from "react"

export const StyledTooltipIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.neutralColorPalette.blacks.colorTextQuaternary};

  svg {
    display: block;
    margin: auto;
  }
`

const StyledTooltipButton = styled.button`
  border: none;
  background-color: transparent;
  cursor: help;
`

type TooltipProps = AntTooltipProps & {
  icon?: IconTypes
  ariaLabel?: string
  descriptorId?: string
}

const Tooltip = ({ icon, descriptorId, ariaLabel, title, children, ...props }: TooltipProps) => {
  const [isOpen, setIsOpen] = useState(false)

  const focusOnIcon = () => {
    setIsOpen(true)
  }

  const focusOffIcon = () => {
    setIsOpen(false)
  }

  return (
    <>
      {icon ? (
        <AntTooltip id={descriptorId} title={title} open={isOpen} aria-label={ariaLabel} {...props}>
          <StyledTooltipButton
            onFocus={focusOnIcon}
            onBlur={focusOffIcon}
            onMouseOver={focusOnIcon}
            onMouseOut={focusOffIcon}
          >
            <StyledTooltipIcon name={icon} small width="18px" height="24px" />
          </StyledTooltipButton>
          {children}
        </AntTooltip>
      ) : (
        <AntTooltip id={descriptorId} title={title} aria-label={ariaLabel} {...props}>
          {children}
        </AntTooltip>
      )}
    </>
  )
}
export default Tooltip
