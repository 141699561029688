/* eslint-disable react-refresh/only-export-components */ // FIXME
import { AppRouter } from "src/routes/AppRouter.tsx"
import posthog from "posthog-js"
import { POSTHOG_API_HOST } from "src/global/config.ts"
import { REACT_APP_POSTHOG_API_KEY } from "src/global/env.ts"
import { Sentry } from "src/global/observability/sentry/sentry.ts"
import { ConfiguredThemeProvider } from "src/global/ui/themes/ConfiguredThemeProvider.tsx"
import Auth0ProviderWithHistory from "src/global/authentication/Auth0ProviderWithHistory.tsx"
import LayoutContextProvider from "src/global/layout/layoutContext.tsx"
import { useCleanupEnableAgentRequestState } from "src/global/hooks/useCleanupEnableAgentRequestState.ts"
import { HelmetProvider } from "react-helmet-async"

function App() {
  posthog.init(REACT_APP_POSTHOG_API_KEY, { api_host: POSTHOG_API_HOST })
  useCleanupEnableAgentRequestState()
  return (
    <ConfiguredThemeProvider>
      <Auth0ProviderWithHistory>
        <LayoutContextProvider>
          <HelmetProvider>
            <AppRouter />
          </HelmetProvider>
        </LayoutContextProvider>
      </Auth0ProviderWithHistory>
    </ConfiguredThemeProvider>
  )
}

export default Sentry.withProfiler(App)
