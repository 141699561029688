import { Col, Row } from "antd"
import { StatDisplay } from "src/pages/DataAssets/components/StatDisplay"
import { StatDisplaySet } from "src/pages/DataAssets/components/StatDisplaySet"
import { theme } from "src/global/ui/themes/theme"
import { graphql } from "src/api/graphql/gql.ts"
import { FragmentType, unmaskFragment } from "src/api/graphql/fragment-masking.ts"
import { useAnalytics } from "src/global/hooks/analytics/useAnalytics"
import { useEffect } from "react"
import { Button } from "src/global/ui/Button/Button"

const COVERAGE_TOOLTIP = "Whether this Data Asset has at least one schema, volume, or completeness Expectation."
export const ADD_EXPECTATION_CTA_TEXT = "Add Expectation"

export type CoverageAssetCoverageStatsFragment = FragmentType<typeof Coverage_AssetCoverageStatsDocument>

export const Coverage_AssetCoverageStatsDocument = graphql(`
  fragment Coverage_AssetCoverageStats on AssetCoverageStats {
    hasCompletenessCoverage
    hasSchemaCoverage
    hasVolumeCoverage
  }
`)

type AssetCoverageStatsProps = {
  assetStats: CoverageAssetCoverageStatsFragment
  openDrawer: () => void
}

function AssetCoverageStats({ assetStats, openDrawer }: AssetCoverageStatsProps) {
  const unmaskedAssetStats = unmaskFragment(Coverage_AssetCoverageStatsDocument, assetStats)

  const { hasSchemaCoverage, hasVolumeCoverage, hasCompletenessCoverage } = unmaskedAssetStats

  const posthog = useAnalytics()

  useEffect(() => {
    if (posthog) {
      posthog.capture("metric_coverage_stats.viewed", {
        coverage_schema: hasSchemaCoverage,
        coverage_volume: hasVolumeCoverage,
        coverage_completeness: hasCompletenessCoverage,
      })
    }
  }, [hasCompletenessCoverage, hasSchemaCoverage, hasVolumeCoverage, posthog])

  const DQICoverageStats = [
    {
      label: "Schema",
      hasCoverage: hasSchemaCoverage,
    },
    {
      label: "Volume",
      hasCoverage: hasVolumeCoverage,
    },
    {
      label: "Completeness",
      hasCoverage: hasCompletenessCoverage,
    },
  ]

  return (
    <Row style={{ marginBottom: theme.spacing.xs, width: "620px" }}>
      <StatDisplaySet title="Coverage" info={COVERAGE_TOOLTIP}>
        {DQICoverageStats.map((stat) => (
          <Col key={stat.label} span={7}>
            <StatDisplay
              label={stat.label}
              cta={
                !stat.hasCoverage ? (
                  <AddExpectationButton
                    onClick={() => {
                      posthog?.capture("metric_coverage_stats_cta.click", {
                        stat_type: stat.label.toLowerCase(),
                        coverage_type: "individual",
                        stat_display_title: "Coverage",
                      })
                      openDrawer()
                    }}
                    ariaLabel={`Add ${stat.label.toLocaleLowerCase()} expectation`}
                  />
                ) : undefined
              }
            >
              {stat.hasCoverage ? <StatDisplay.Success /> : <StatDisplay.Warning />}
            </StatDisplay>
          </Col>
        ))}
      </StatDisplaySet>
    </Row>
  )
}

type AddExpectationButtonProps = {
  onClick: () => void
  ariaLabel: string
}
function AddExpectationButton({ onClick, ariaLabel }: AddExpectationButtonProps) {
  return (
    <Button type="default" onClick={onClick} icon="plus" size="small" aria-label={ariaLabel}>
      {ADD_EXPECTATION_CTA_TEXT}
    </Button>
  )
}

export { AssetCoverageStats }
