import { Alert } from "antd"
import { Icon } from "src/global/ui/Icon"
import { BodyStrong } from "src/global/ui/typography/Text/Text.tsx"

export const MESSAGE = "Validating your Data Asset"

const DESCRIPTION = (
  <span>
    Now that you have created an Expectation for this Data Asset, click
    {` `}
    <BodyStrong>
      <Icon name="play" size="13px" />
      {` `}
      Validate
    </BodyStrong>
    {` `}
    to confirm your assertions on this Data Asset.
  </span>
)

export function ValidateExpectationsAlert() {
  return <Alert message={MESSAGE} description={DESCRIPTION} type="info" showIcon />
}
