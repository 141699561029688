import { useMemo } from "react"
import { graphql } from "src/api/graphql/gql.ts"
import { ExpectationRenderer } from "src/pages/DataAssets/views/Expectations/Expectation/ExpectationRenderer.tsx"
import { ExpectationInformation } from "src/pages/DataAssets/views/Expectations/Expectation/ExpectationInformation.tsx"
import { ExpectationConfigForm } from "src/pages/DataAssets/views/Expectations/Expectation/uiForms/ExpectationConfigForm.tsx"
import { ExpectationMetaInfo, getJsonSchemaBySnakeCaseName } from "src/global/schemas/expectation-metadata-utils.ts"
import { removeAdvancedKwargs } from "src/pages/DataAssets/views/Expectations/Expectation/utils.tsx"
import { FragmentType, unmaskFragment } from "src/api/graphql/fragment-masking.ts"

type Props = {
  value: Record<string, unknown>
  onChange: (newValue: Record<string, unknown>) => void
  expectationMetaInfo: ExpectationMetaInfo
  dataAsset?: FragmentType<typeof ExpectationEditor_DataAssetMetricRunFragmentDoc>
  renderer?: ExpectationRenderer
  showInfo?: boolean
}

const ExpectationEditor_DataAssetMetricRunFragmentDoc = graphql(`
  fragment ExpectationEditor_DataAssetMetricRun on AssetRef {
    id
    latestMetricRun {
      dataAssetId
      metrics {
        columnDataType
        columnName
        mean
        median
        nullCount
        valueRangeMax
        valueRangeMin
        valueRangeMaxUnion {
          __typename
          ...MetricValueStringTypeFragment
          ...MetricValueFloatTypeFragment
        }
        valueRangeMinUnion {
          __typename
          ...MetricValueStringTypeFragment
          ...MetricValueFloatTypeFragment
        }
      }
      rowCount
    }
    datasourceV2 {
      id
      config
    }
  }
`)

export function ExpectationEditorSimplified({
  value,
  onChange,
  expectationMetaInfo,
  dataAsset,
  renderer,
  showInfo = true,
}: Props) {
  const jsonSchema = useMemo(
    () => (expectationMetaInfo.type ? getJsonSchemaBySnakeCaseName(expectationMetaInfo.type) : undefined),
    [expectationMetaInfo.type],
  )

  if (!jsonSchema) {
    return null // this is not a realistic case; it's just hard to assure the compiler given the way that schema lookups are typed at the moment
  }
  return (
    <div>
      {showInfo && <ExpectationInformation metaInfo={expectationMetaInfo} renderer={renderer} />}
      <ExpectationConfigForm
        key={expectationMetaInfo.type}
        value={value}
        jsonSchema={jsonSchema}
        onChange={({ data }) => {
          if (data) {
            onChange(removeAdvancedKwargs(data))
          }
        }}
        config={{
          dataAssetWithLatestMetricRun: unmaskFragment(ExpectationEditor_DataAssetMetricRunFragmentDoc, dataAsset),
        }}
      />
    </div>
  )
}
