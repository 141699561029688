import { Form } from "antd"
import styled from "styled-components"

export const StyledFormItem = styled(Form.Item)`
  &.ant-form-item {
    margin-bottom: ${({ theme }) => theme.spacing.xxs};
    margin-top: 3px;
  }

  order: 1;
`
