/* eslint-disable react-refresh/only-export-components */ // FIXME
import React, { ComponentType } from "react"
import Ajv from "ajv"
import { getAjv, JsonSchema, OwnPropsOfRenderer } from "@jsonforms/core"
import { useJsonForms } from "@jsonforms/react"
import { isEmpty } from "lodash-es"
import { renderLayoutElements } from "src/global/jsonforms/layouts/render-layout-elements.tsx"
import type { UISchema } from "@great-expectations/jsonforms-antd-renderers"

export interface AntDLayoutRendererProps extends OwnPropsOfRenderer {
  elements: UISchema<unknown>[]
  direction?: "row" | "column"
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function AntDLayoutComponent({
  elements,
  schema,
  path,
  enabled,
  renderers,
  cells,
  direction = "column",
}: AntDLayoutRendererProps) {
  if (isEmpty(elements)) {
    return null
  }
  return (
    <>{renderLayoutElements(elements, schema as JsonSchema, path as string, !!enabled, renderers, cells, direction)}</>
  )
}
export const AntDLayoutRenderer = React.memo(AntDLayoutComponent)

export interface AjvProps {
  ajv: Ajv
}

// TODO fix @typescript-eslint/ban-types
// eslint-disable-next-line @typescript-eslint/ban-types
export const withAjvProps = <P extends {}>(Component: ComponentType<AjvProps & P>) =>
  function WithAjvProps(props: P) {
    const ctx = useJsonForms()
    const ajv = getAjv({ jsonforms: { ...ctx } })

    return <Component {...props} ajv={ajv} />
  }
