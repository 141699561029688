import { ConfigProvider, Layout, Menu as AntDMenu } from "antd"
import { useEffect, useState, useMemo, useCallback } from "react"
import { Icon } from "src/global/ui/Icon"
import { useLocation } from "react-router-dom"
import { useLayoutContext } from "src/global/layout/layoutContext.tsx"
import { ProfileDropdown } from "src/pages/Users/components/ProfileDropdown.tsx"
import { SETTINGS_MENU_KEY, useMenuItems } from "src/global/ui/Menu/useMenuItems.tsx"
import { menuCSSID } from "src/global/ui/Menu/menuCSS.ts"
import styled, { useTheme } from "styled-components"
import { intersection, without } from "lodash-es"

const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 150px); /* 150 is height of logo + height of sider trigger */
  overflow: scroll;

  /* hides scrollbar */
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`

const BottomMenu = styled(AntDMenu)`
  margin-bottom: ${({ theme }) => theme.spacing.m};
`

export function Menu() {
  const [collapsed, setCollapsed] = useState(false)
  const [openKeys, setOpenKeys] = useState<string[]>([])
  const location = useLocation()
  const theme = useTheme()

  const { topMenuItems, bottomMenuItems } = useMenuItems({
    toggleSettings: openKeys.includes(SETTINGS_MENU_KEY)
      ? () => setOpenKeys(without(openKeys, SETTINGS_MENU_KEY))
      : () => setOpenKeys([...openKeys, SETTINGS_MENU_KEY]),
  })

  const {
    routeInfo: { currentNestedRoutes, pathname },
    setPathname,
  } = useLayoutContext()
  // first path segment after `/organizations/<ORG_SLUG`
  const path = currentNestedRoutes?.[2]?.path.split("/").pop() ?? null
  const selectedKeys = useMemo(() => (path ? [path] : []), [path])

  /*
   * Setting navigation path to context
   */
  useEffect(() => {
    if (pathname !== location.pathname) setPathname(location.pathname)
  }, [currentNestedRoutes, location.pathname, pathname, setPathname])

  const getSettingsKeys = useCallback(() => {
    if (!topMenuItems.find((item) => item?.key === SETTINGS_MENU_KEY)) {
      return []
    }

    return (
      // @ts-expect-error not worth fixing this
      topMenuItems
        .find((item) => item?.key === SETTINGS_MENU_KEY)
        // @ts-expect-error not worth fixing this
        .children.map((item) => item.key)
        .filter(Boolean)
        .concat(
          // @ts-expect-error not worth fixing this
          topMenuItems
            .find((item) => item?.key === SETTINGS_MENU_KEY)
            // @ts-expect-error not worth fixing this
            .children.flatMap((item) => item.children)
            .filter(Boolean)
            // @ts-expect-error not worth fixing this
            .map((item) => item.key),
        )
    )
    // topMenuItems is a constant, so this is fine
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // expand the submenu when navigating to certain routes
  useEffect(() => {
    if (intersection(getSettingsKeys(), selectedKeys).length > 0) {
      setOpenKeys([SETTINGS_MENU_KEY])
    }
  }, [getSettingsKeys, selectedKeys])

  return (
    <div id={menuCSSID}>
      <Layout.Sider
        aria-label="Side Menu"
        trigger={<Icon name={collapsed ? "arrowRight" : "arrowLeft"} />}
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
        breakpoint="lg"
      >
        <ProfileDropdown collapsed={collapsed} />
        <ConfigProvider
          theme={{
            components: {
              Menu: {
                colorTextDisabled: theme.colors.primaryColors.gxSubMenuItem,
                groupTitleColor: theme.colors.primaryColors.gxSubMenuItem,
                itemBorderRadius: 0,
                itemColor: theme.colors.neutralColorPalette.whites.white,
                itemBg: theme.colors.primaryColors.gxPrimaryDark,
                subMenuItemBg: theme.colors.neutralColorPalette.backgroundsAndBorders.gxSurfaceTertiary,
                itemHoverBg: theme.colors.primaryColors.gxAccentMedium + "99",
                itemHoverColor: theme.colors.neutralColorPalette.whites.white,
                itemSelectedBg: theme.colors.primaryColors.gxPrimaryMedium,
                itemSelectedColor: theme.colors.neutralColorPalette.whites.white,
                itemMarginInline: 0,
              },
            },
          }}
        >
          <MenuContainer>
            <AntDMenu
              selectedKeys={selectedKeys}
              mode="inline"
              items={topMenuItems}
              openKeys={!collapsed ? openKeys : undefined}
            />
            <BottomMenu selectedKeys={selectedKeys} mode="inline" items={bottomMenuItems} />
          </MenuContainer>
        </ConfigProvider>
      </Layout.Sider>
    </div>
  )
}
