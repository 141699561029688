import { message } from "antd"
import { useMutation } from "@apollo/client"
// eslint-disable-next-line no-restricted-imports -- deprecated file
import { DeleteDataAssetDocument, DatasourcesWithRunsDocument } from "src/api/graphql/graphql-operations.ts"
import { findIndex } from "lodash-es"
import { MESSAGE_DURATION_SECONDS } from "src/global/config.ts"
import { DeleteResourceModal } from "src/global/ui/DeleteResourceModal/DeleteResourceModal.tsx"
import { AggregateCoverageStatsDocument } from "src/pages/DataAssets/components/AggregateAssetCoverageStats"

export const CannotDeleteDataAssetWarning = "Unable to delete Data Asset"

interface DeleteDataAssetModalProps {
  isVisible: boolean
  setIsVisible: (isVisible: boolean) => void
  dataAsset: { name: string; id: string }
}

export function DeleteDataAssetModal({ isVisible, setIsVisible, dataAsset }: DeleteDataAssetModalProps) {
  const [deleteDataAsset, { loading: mutationInProgress, error: deleteMutationError, reset: resetMutation }] =
    useMutation(DeleteDataAssetDocument, {})

  const onDelete = () => {
    deleteDataAsset({
      variables: { id: dataAsset.id },
      refetchQueries: [AggregateCoverageStatsDocument],
      onCompleted: () => {
        message.success(`Successfully deleted ${dataAsset.name} Data Asset`, MESSAGE_DURATION_SECONDS)
        setIsVisible(false)
      },
      onError: () => {},
      update(cache, result) {
        const updatedDataSource = result.data?.deleteAsset?.datasourceV2
        if (!updatedDataSource) {
          return
        }
        cache.updateQuery({ query: DatasourcesWithRunsDocument }, (data) => {
          const updatedDataSourceIndex = findIndex(data?.datasourcesV2 ?? [], ({ id }) => id === updatedDataSource.id)
          if (!data || !(updatedDataSourceIndex >= 0)) {
            return
          }

          return {
            ...data,
            datasources: [...data.datasourcesV2].splice(updatedDataSourceIndex, 1, updatedDataSource),
          }
        })
        cache.evict({ id: `AssetRef:${dataAsset.id}` })
      },
    })
  }
  const onReset = () => {
    setIsVisible(false)
    deleteMutationError && resetMutation()
  }

  return (
    <DeleteResourceModal
      open={isVisible}
      name={dataAsset.name}
      resourceTitle="Data Asset"
      alternateResourceTitle="Data Asset, its Expectations, and its validation results"
      onCancel={onReset}
      okButtonProps={{
        loading: mutationInProgress,
      }}
      onOk={onDelete}
      errorMessage={deleteMutationError?.message}
    />
  )
}
