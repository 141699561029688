import { useQuery } from "@apollo/client"
import { useMemo, useEffect } from "react"
import { RunHistoryItem } from "src/pages/DataAssets/views/Validations/RunHistory.tsx"
// eslint-disable-next-line no-restricted-imports -- deprecated file
import {
  ValidationResultColumnNamesDocument,
  AggregateSuiteValidationResultDocument,
  ValidationResultDocument,
  ValidationResultQuery,
  ValidationResultQueryVariables,
  ExpectationSuitesDocument,
  ExpectationSuitesQuery,
  ExpectationSuitesQueryVariables,
  ExpectationSuiteDocument,
  ExpectationSuiteQuery,
  ExpectationSuiteQueryVariables,
} from "src/api/graphql/graphql-operations.ts"
import { ValidationsTab_DataAssetQuery } from "src/api/graphql/graphql.ts"
import { sortRunsByDate } from "src/global/utils/sortByAscDate.ts"
import { useUrlParams, useResetUrlParams } from "src/global/utils/urlParams.ts"
import { useAnalytics } from "src/global/hooks/analytics/useAnalytics.ts"
import { useLocation } from "react-router-dom"

interface Validation {
  __typename: "Validation"
  id: string
  checkpoints: {
    __typename: "Checkpoint"
    id: string
    name: string
  }[]
  runHistory: ({
    __typename: "ValidationResult"
    runId: string
    ranAt: string
    success: boolean
    id: string
    checkpointId?: string | null
    validation?: {
      __typename: "Validation"
      assetRefId?: string | null
    } | null
  } | null)[]
}

export const useRunHistoriesForValidations = (
  validations: Validation[] | undefined,
  dataAssetData: ValidationsTab_DataAssetQuery | undefined,
  expectationSuiteId: string,
) => {
  return useMemo(() => {
    return (
      validations
        ?.flatMap((validation) => validation.runHistory)
        ?.filter((run) => run?.validation?.assetRefId === dataAssetData?.dataAsset?.id)
        .map((run) => {
          return {
            ...run,
            toUrl: `/data-assets/${dataAssetData?.dataAsset?.id}/validations/expectation-suites/${expectationSuiteId}/results/${run?.id}`,
          } as RunHistoryItem
        })
        .sort(sortRunsByDate("desc")) ?? []
    )
  }, [validations, dataAssetData?.dataAsset?.id, expectationSuiteId])
}

export const useAggregateSuite = (expectationSuiteId: string, dataAssetId?: string) => {
  const {
    data: aggregateSuite,
    loading: aggregateSuiteLoading,
    error: aggregateSuiteError,
    refetch: refetchAggregateSuite,
  } = useQuery(AggregateSuiteValidationResultDocument, {
    variables: {
      asset_id: dataAssetId ?? "",
      expectation_suite_id: expectationSuiteId,
    },
    skip: !dataAssetId || !expectationSuiteId,
  })

  return { aggregateSuite, aggregateSuiteLoading, aggregateSuiteError, refetchAggregateSuite }
}

export function useExpectationSuite(expectationSuiteId: string) {
  const {
    data: expectationSuiteData,
    loading: expectationSuiteLoading,
    error: expectationSuiteError,
    refetch: refetchExpectationSuite,
  } = useQuery<ExpectationSuiteQuery, ExpectationSuiteQueryVariables>(ExpectationSuiteDocument, {
    variables: {
      id: expectationSuiteId,
    },
    skip: !expectationSuiteId,
  })

  return {
    expectationSuiteData,
    expectationSuiteLoading: expectationSuiteData && expectationSuiteLoading ? false : expectationSuiteLoading, // avoid loading state when data is present.
    expectationSuiteError,
    refetchExpectationSuite,
  }
}

export const useColumnNamesData = (validationResultId: string) => {
  const { data: columnNamesData, loading: columnNamesLoading } = useQuery(ValidationResultColumnNamesDocument, {
    variables: {
      id: validationResultId,
    },
    skip: !validationResultId,
  })

  return { columnNamesData, columnNamesLoading }
}

export function useExpectationSuites(assetId: string) {
  const {
    data: expectationSuitesData,
    loading: expectationSuitesLoading,
    error: expectationSuitesError,
  } = useQuery<ExpectationSuitesQuery, ExpectationSuitesQueryVariables>(ExpectationSuitesDocument, {
    variables: {
      options: {
        filterByAssetRefId: assetId,
      },
    },
    skip: !assetId,
  })

  return { expectationSuitesData, expectationSuitesLoading, expectationSuitesError }
}

export function useValidationResult(
  validationResultId: string,
  isTableLevelIncluded: boolean,
  columnNames: string[],
  columnNamesLoading: boolean,
  onCompleted?: () => void,
) {
  const { data: validationResultData, loading: validationResultLoading } = useQuery<
    ValidationResultQuery,
    ValidationResultQueryVariables
  >(ValidationResultDocument, {
    variables: {
      id: validationResultId,
      includeTableLevel: isTableLevelIncluded,
      columnFilter: columnNamesLoading ? [] : columnNames,
    },
    onCompleted: () => {
      if (onCompleted) {
        onCompleted()
      }
    },
    skip: !validationResultId,
  })

  return { validationResultData, validationResultLoading }
}

export const useSlackNotificationEvent = () => {
  const [{ slack }] = useUrlParams({ slack: "" })
  const resetParams = useResetUrlParams()
  const posthog = useAnalytics()
  useEffect(() => {
    if (slack === "true") {
      posthog?.capture("validation_result.accessed_from_slack_notification")
      resetParams(["slack"])
    }
  }, [slack, posthog, resetParams])
}

export const useEmailAlertsEvent = () => {
  const [{ validation_result_email }] = useUrlParams({ validation_result_email: "" })
  const resetParams = useResetUrlParams()
  const posthog = useAnalytics()
  const location = useLocation()
  const path = location.pathname.split("/").find((path) => path.includes("results") || path.includes("expectations"))

  useEffect(() => {
    const capturePosthogEvent = (routePath: string) => {
      switch (routePath) {
        case "results":
          posthog?.capture("validation_result.accessed_from_email_alert")
          break
        case "expectations":
          posthog?.capture("expectations_tab.accessed_from_email_alert")
          break
        default:
          break
      }
      resetParams(["validation_result_email"])
    }
    if (validation_result_email === "true") {
      path && capturePosthogEvent(path)
    }
  }, [path, posthog, resetParams, validation_result_email])
}
