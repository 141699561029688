import { REACT_APP_USE_DEV_MODE, REACT_APP_USE_MOCKS } from "src/global/env.ts"

export function devMode() {
  return REACT_APP_USE_DEV_MODE
}

export function shouldUseMswMocks() {
  // we are not currently using REACT_APP_USE_MOCKS in prod, and it will be removed if confirmed it's not needed
  return devMode() || REACT_APP_USE_MOCKS
}
