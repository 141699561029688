import { ReactNode } from "react"
import { useCurrentUser } from "src/global/hooks/useCurrentUser.ts"
import { ApolloAuth0AuthenticatedProvider } from "src/api/ApolloAuthenticatedProvider.tsx"
import { Navigate } from "react-router-dom"
import { LoadingState } from "src/global/ui/LoadingState"

type AuthWrapperProps = {
  children: ReactNode
  redirectPath?: string
}

export const AuthWrapper = ({ children }: AuthWrapperProps) => {
  const { isAuthenticated, isLoading } = useCurrentUser()
  const redirectPath = "/start"

  if (isLoading) {
    return <LoadingState loading />
  }

  if (!isAuthenticated) {
    return <Navigate to={redirectPath} />
  }

  return <ApolloAuth0AuthenticatedProvider>{children}</ApolloAuth0AuthenticatedProvider>
}
