import { useQuery } from "@apollo/client"
// eslint-disable-next-line no-restricted-imports -- deprecated file
import { GetAgentStatusDocument } from "src/api/graphql/graphql-operations.ts"
import { REACT_APP_AGENT_STATUS_POLL_INTERVAL } from "src/global/env.ts"

export function PollForAgentStatus() {
  useQuery(GetAgentStatusDocument, {
    pollInterval: REACT_APP_AGENT_STATUS_POLL_INTERVAL,
    canonizeResults: true,
    fetchPolicy: "network-only",
  })
  return null
}
