import { JsonForm } from "src/pages/DataAssets/components/JsonForm.tsx"
import { JsonFormsCore } from "@jsonforms/core"
import {
  ValueSetUISchemaRegistryEntry,
  useUISchemaFromJSONSchema,
} from "src/pages/DataAssets/views/Expectations/Expectation/uiForms/schemas/expectation-catalog-ui-schemas.tsx"
import { OSSMetaNotesRegistryEntry } from "src/pages/DataAssets/views/Expectations/Expectation/uiForms/customRenderers/MetaNotesControl.tsx"
import { ColumnNameRegistryEntry } from "src/pages/DataAssets/views/Expectations/Expectation/uiForms/customRenderers/ColumnNameControl.tsx"
import { JSONSchema } from "json-schema-to-ts"
import { ExpectationFormData } from "src/global/schemas/expectation-catalog-types.ts"
import { MinMaxRegistryEntry } from "src/pages/DataAssets/views/Expectations/Expectation/uiForms/customRenderers/MinMaxControl.tsx"
import { jsonSchemas } from "src/global/schemas/expectation-catalog-schemas.ts"
import {
  getJsonSchemaWithCDMValues,
  handleChangeWithCDMValues,
} from "src/pages/DataAssets/views/Expectations/Expectation/uiForms/cdmUtils.ts"
import { DataAssetWithLatestMetricsRunQuery } from "src/api/graphql/graphql.ts"
import { SQLEditorRegistryEntry } from "src/pages/DataAssets/views/Expectations/Expectation/uiForms/customRenderers/SQLEditorControl.tsx"
import {
  WindowedWithOffsetUISchemaRegistry,
  FixedMinMaxNoStrictUISchemaRegistry,
  FixedWithMostlyUISchemaRegistry,
  WindowedWithPercentSliderUISchemaRegistry,
  FixedMinMaxWithStrictUISchemaRegistry,
  PlaintextRendererUISchemaRegistry as PlaintextLabelRegistryEntry,
} from "src/pages/DataAssets/views/Expectations/Expectation/uiForms/windowUtils.tsx"
import { LabelRendererRegistryEntry } from "src/global/jsonforms/controls/AlertControl.tsx"
import { SupportedExpectation } from "src/global/schemas/expectation-metadata-utils.ts"
import { GroupRendererRegistryEntry } from "src/global/jsonforms/layouts/GroupRenderer.tsx"
import { RowConditionRegistryEntry } from "src/pages/DataAssets/views/Expectations/Expectation/uiForms/customRenderers/RowConditionControl/RowConditionControl.tsx"
import { handleBaselineConfig as handleBaseWindowConfig } from "src/pages/DataAssets/views/Expectations/Expectation/CreateExpectationDrawer/windowedExpectationUtils.ts"
import { NumericSliderRegistryEntry } from "src/pages/DataAssets/views/Expectations/Expectation/uiForms/customRenderers/NumericSliderControl.tsx"

export type ExpectationJsonSchema = {
  [key in SupportedExpectation]: (typeof jsonSchemas)[key]["schema"]
}[SupportedExpectation]

export type DataAssetWithLatestMetricRunConfig = {
  dataAssetWithLatestMetricRun: DataAssetWithLatestMetricsRunQuery["dataAsset"]
}
export interface ExpectationConfigFormProps {
  jsonSchema: ExpectationJsonSchema | JSONSchema
  value?: Record<string, unknown>
  onChange: (value: Pick<JsonFormsCore, "data" | "errors">) => void
  config?: DataAssetWithLatestMetricRunConfig
}

export const ExpectationConfigForm = ({ jsonSchema, value, onChange, config }: ExpectationConfigFormProps) => {
  const configuredJsonSchema = getJsonSchemaWithCDMValues(jsonSchema as ExpectationJsonSchema, config)
  const expectationClass =
    typeof jsonSchema !== "boolean" &&
    jsonSchema.properties?.metadata !== undefined &&
    typeof jsonSchema.properties?.metadata !== "boolean" &&
    typeof jsonSchema.properties?.metadata.properties?.expectation_class !== "boolean" &&
    typeof jsonSchema.properties?.metadata.properties?.expectation_class.const === "string"
      ? jsonSchema.properties?.metadata.properties?.expectation_class.const
      : ""
  const uiSchema = useUISchemaFromJSONSchema(expectationClass)

  const handleChange = (newValue?: ExpectationFormData) => {
    const dataWithCDMValues = handleChangeWithCDMValues(expectationClass, value, newValue, config)
    onChange({ data: dataWithCDMValues })
  }

  const patchedValue = handleBaseWindowConfig(value)

  return (
    <JsonForm
      jsonSchema={configuredJsonSchema as JSONSchema}
      uiSchema={uiSchema}
      data={patchedValue as Record<string, unknown>}
      updateData={handleChange}
      customRendererRegistryEntries={[
        OSSMetaNotesRegistryEntry,
        ColumnNameRegistryEntry,
        MinMaxRegistryEntry,
        SQLEditorRegistryEntry,
        LabelRendererRegistryEntry,
        PlaintextLabelRegistryEntry,
        GroupRendererRegistryEntry,
        RowConditionRegistryEntry,
        NumericSliderRegistryEntry, // covers windowed versions of completeness expectations
      ]}
      uiSchemaRegistryEntries={[
        ValueSetUISchemaRegistryEntry,
        FixedMinMaxNoStrictUISchemaRegistry,
        FixedWithMostlyUISchemaRegistry,
        WindowedWithOffsetUISchemaRegistry,
        FixedMinMaxWithStrictUISchemaRegistry,
        WindowedWithPercentSliderUISchemaRegistry,
      ]}
      config={{ ...config }}
    />
  )
}
