import React from "react"
import { StepContainer } from "src/pages/DataAssets/drawers/ConnectToDataSource/common/StepContainer.ts"
import { Button, Flex } from "antd"
import { AlertBanner } from "src/global/ui/Alert"
import { ErrorStepProps, StepComponentWithSlot } from "src/pages/DataAssets/drawers/ConnectToDataSource/types"

// Base component that handles error display
export const BaseErrorStep: React.FC<ErrorStepProps> = ({ error, resetErrorBoundary }) => {
  return (
    <StepContainer>
      <Flex align="center" vertical gap="large" style={{ width: "80%", margin: "5% auto 0" }}>
        <AlertBanner message="Encountered an unexpected error" description={error.message} />
        <Flex justify="end">
          <Button type="primary" onClick={resetErrorBoundary}>
            Back
          </Button>
        </Flex>
      </Flex>
    </StepContainer>
  )
}

export function ErrorStep(props: ErrorStepProps) {
  return function ({ children }: StepComponentWithSlot) {
    return children(
      {
        back: () => {
          props.resetErrorBoundary()
          return Promise.resolve(true)
        },
        showHeader: false,
      },
      <BaseErrorStep {...props} />,
    )
  }
}
