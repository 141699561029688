import Tooltip from "src/global/ui/Tooltip/Tooltip.tsx"

export const ConditionalTooltip = ({
  children,
  showTooltip,
  tooltipText,
}: Readonly<{ children: React.ReactNode; showTooltip: boolean; tooltipText: string }>) => {
  if (!showTooltip) return <>{children}</>
  return (
    <Tooltip title={tooltipText}>
      <div>{children}</div>
    </Tooltip>
  )
}
