import { UilSort } from "@iconscout/react-unicons"
import { Select, Space } from "antd"
import { EXPECTATION_PICKER_FILTER_PLACEHOLDER } from "src/pages/DataAssets/views/Expectations/Expectation/CreateExpectationDrawer/words.ts"
import { DataQualityIssue, DataQualityIssues } from "src/global/schemas/expectation-metadata-data-quality-issues.ts"

type FilterExpectationsProps = {
  value?: DataQualityIssue
  onSelect: (value: DataQualityIssue) => void
}

const options: Array<{ label: DataQualityIssue; value: DataQualityIssue }> = DataQualityIssues.map((dqi) => ({
  label: dqi,
  value: dqi,
}))

export const FilterExpectations = ({ value, onSelect }: FilterExpectationsProps) => {
  return (
    <Space align="center" size="small">
      <span>Filter by:</span>
      <Select
        allowClear
        value={value}
        suffixIcon={<UilSort style={{ width: 16 }} />}
        style={{ minWidth: 210 }}
        placeholder={EXPECTATION_PICKER_FILTER_PLACEHOLDER}
        options={options}
        onChange={onSelect}
      />
    </Space>
  )
}
