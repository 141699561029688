import { Button } from "src/global/ui/Button/Button.tsx"
import { message, Tooltip } from "antd"

import { MESSAGE_DURATION_SECONDS } from "src/global/config.ts"
import { ReactNode, useEffect, useState } from "react"
import { FragmentType, unmaskFragment } from "src/api/graphql/fragment-masking"
import {
  GenerateExpectationsButtonFragmentDocument,
  GenerateExpectationsButtonJobsFragmentDocument,
} from "src/pages/DataAssets/views/Expectations/GenerateExpectationsButton/GenerateExpectationsButton.tsx"
import { Link } from "src/global/ui/typography/Link/Link.tsx"

interface GenerateExpectationsButtonUIProps {
  // loading makes the button spin if true
  loading: boolean
  // disabled makes the button unclickable if true
  disabled: boolean
  // onClick is called when the button is clicked
  onClick: (batchDefinitionId: string) => Promise<void>
  // assetData specifies GQL data dependencies
  assetData?: FragmentType<typeof GenerateExpectationsButtonFragmentDocument> | null
  // jobData specifies GQL data dependencies
  jobData?: FragmentType<typeof GenerateExpectationsButtonJobsFragmentDocument> | null
  // isEditor adjusts behavior for a given user role
  isEditor: boolean
  // onJobComplete is called when the corresponding job is complete
  onJobComplete: () => void
  // isAgentEnabled adjusts behavior for the feature flag value
  isAgentEnabled: boolean
}

const INTRO_TOOLTIP = (
  <span>
    Generate Expectations recommended for your data. This feature is powered by analytical AI that may make mistakes.
    For more information, see our{" "}
    <Link to="https://greatexpectations.io/terms-of-service" target="_blank" isLightText>
      terms of service
    </Link>
    .
  </span>
)

const RUNNER_REQUIRED_TOOLTIP = (
  <span>
    This feature requires a{" "}
    <Link
      to="https://docs.greatexpectations.io/docs/cloud/deploy/deployment_patterns#fully-hosted-deployment"
      target="_blank"
      isLightText
    >
      fully-hosted deployment
    </Link>
    .
  </span>
)

export function GenerateExpectationsButtonUI(props: GenerateExpectationsButtonUIProps) {
  const {
    loading: _loading,
    disabled: _disabled,
    onClick,
    assetData: maskedAssetData,
    jobData: maskedJobData,
    isEditor,
    isAgentEnabled,
    onJobComplete,
  } = props
  const assetData = unmaskFragment(GenerateExpectationsButtonFragmentDocument, maskedAssetData)
  const jobData = unmaskFragment(GenerateExpectationsButtonJobsFragmentDocument, maskedJobData)
  const [inProgress, setInProgress] = useState(false)
  const [shouldShowDoneToast, setShouldShowDoneToast] = useState(false)
  const loading = _loading || inProgress

  const batchDefinitionId = assetData?.splitter?.id
  const jobStatus = jobData?.jobs?.find(
    (job) =>
      job.jobType === "RUN_RD_AGENT_REQUEST" && job.sourceResources.find((sr) => sr.entityId === batchDefinitionId),
  )?.status

  let disabledReason: ReactNode = null
  const hasRunEchoes = assetData?.hasRunEchoes === true
  const isSupportedDataSource = assetData?.datasourceV2?.type === "SNOWFLAKE"
  if (hasRunEchoes) {
    disabledReason = "Expectations have already been generated for this Data Asset"
  } else if (!isSupportedDataSource) {
    disabledReason = "Only Snowflake is supported at this time"
  } else if (!isEditor) {
    disabledReason = "This feature requires Editor or Admin permissions"
  } else if (isAgentEnabled) {
    disabledReason = RUNNER_REQUIRED_TOOLTIP
  } else if (!batchDefinitionId) {
    disabledReason = "No batch found for this Data Asset"
  }

  const tooltip = loading ? null : disabledReason || INTRO_TOOLTIP
  const btnContent = loading ? (
    <span>Generating Expectations</span>
  ) : (
    <span>
      Generate Expectations&nbsp; <sup>BETA</sup>
    </span>
  )
  const disabled = _disabled || !!disabledReason || loading

  const handleClick = async () => {
    if (!batchDefinitionId) {
      return
    }
    setInProgress(true)
    try {
      await onClick(batchDefinitionId)
      message.loading("Expectation generation started", MESSAGE_DURATION_SECONDS)
    } catch (e) {
      message.error("Failed to generate Expectations", MESSAGE_DURATION_SECONDS)
      console.error(e)
      setInProgress(false)
    }
  }

  useEffect(() => {
    switch (jobStatus) {
      case "complete":
        setInProgress(false)
        if (shouldShowDoneToast) {
          message.success("Expectations generated", MESSAGE_DURATION_SECONDS)
        }
        onJobComplete()
        setShouldShowDoneToast(false)
        break
      case "error":
        setInProgress(false)
        if (shouldShowDoneToast) {
          message.error("Failed to generate Expectations", MESSAGE_DURATION_SECONDS)
        }
        setShouldShowDoneToast(false)
        break
      case "inProgress":
        setInProgress(true)
        setShouldShowDoneToast(true)
        break
      case "queued":
        setInProgress(true)
        setShouldShowDoneToast(true)
        break
      case undefined:
        break
      default:
        break
    }
  }, [jobStatus, onJobComplete, shouldShowDoneToast, setInProgress])

  return (
    <Tooltip title={tooltip}>
      <Button type="primary" icon="bolt" loading={loading} disabled={disabled} onClick={() => handleClick()}>
        {btnContent}
      </Button>
    </Tooltip>
  )
}
