import { useEffect } from "react"
// eslint-disable-next-line no-restricted-imports -- deprecated file
import { JobFragment } from "src/api/graphql/graphql-operations.ts"
import { useJobByID } from "src/global/hooks/useJobByID.ts"

interface JobStateProps {
  jobId: string
  onComplete: (job: JobFragment) => void
  onError: (job: JobFragment) => void
}

export function JobStateObserver({ jobId, onComplete, onError }: JobStateProps) {
  /* A render-less component to handle observation of an Agent Job after the jobId
  has been returned by create Job mutation

  Usage Pattern:

  function myComponent(...) {
    const [jobId, setJobId] = useState<string | null>()

    const [...] = useMutation(...CreateAgentJobDoc, {onComplete: (data) => {setJobId(...)}

    return (
      <>
      ...
        {jobId && <JobStateObserver jobId={jobId} onComplete={onJobComplete} onError={onJobError} />}
      </>
    )
  }

  */
  const job = useJobByID(jobId)

  useEffect(() => {
    if (job?.status === "complete") {
      onComplete(job)
    } else if (job?.status === "error") {
      onError(job)
    }
  }, [job, onComplete, onError])

  return null
}
