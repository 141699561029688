/* eslint-disable react-refresh/only-export-components */ // FIXME
import { withJsonFormsControlProps } from "@jsonforms/react"
import { ControlProps, JsonFormsRendererRegistryEntry, rankWith, scopeEndsWith } from "@jsonforms/core"
import { Form } from "antd"
import { MetaNotes, canonicalizeMetaNotes } from "src/pages/DataAssets/views/Expectations/Expectation/utils/parsing.ts"
import TextArea from "antd/es/input/TextArea"

export const OSSMetaNotesRegistryEntry: JsonFormsRendererRegistryEntry = {
  tester: rankWith(
    100, //increase rank as needed
    scopeEndsWith("notes"),
  ),
  renderer: withJsonFormsControlProps(MetaNotesControl),
}

type MetaNotesControlProps = ControlProps & {
  data?: {
    notes?: {
      format: string
      content: Array<string>
    }
  }
}

function MetaNotesControl({ handleChange, path, data }: MetaNotesControlProps) {
  const metaNotes: MetaNotes = canonicalizeMetaNotes(data?.notes ? data.notes : data)
  const placeholder = "Example notes about this Expectation. **Markdown** `supported`."

  return (
    <Form<{ markdown: boolean; content: string }>
      id="meta-notes-form"
      component={false}
      initialValues={{ content: metaNotes.content.join("\n") }}
      onValuesChange={(_, { content }) => {
        if (content) {
          const changeObj = {
            content: content.split("\n"),
            format: "markdown",
          }
          handleChange(path, changeObj)
        } else {
          handleChange(path, null)
        }
      }}
    >
      <Form.Item label="Notes" name="content">
        <TextArea placeholder={placeholder} />
      </Form.Item>
    </Form>
  )
}
