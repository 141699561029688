import React from "react"
import { FilterBox } from "src/global/ui/FilterBox/FilterBox.tsx"
import { ActiveListType, FailuresToggle } from "src/global/ui/Button/FailuresToggle.tsx"
import { LeftPanelWrapper } from "src/pages/DataAssets/views/Validations/ValidationTab.styles.tsx"
import { RunHistoryItem, RunHistoryUI } from "src/pages/DataAssets/views/Validations/RunHistory.tsx"
import { ExpectationSuiteSelector } from "src/pages/DataAssets/views/AssetDetails/ExpectationSuiteSelector/ExpectationSuiteSelector.tsx"
import { Grid } from "antd"
import { useExpectationSuites } from "src/pages/DataAssets/views/Validations/ValidationHooks.tsx"
import { ValidationsTab_DataAssetQuery } from "src/api/graphql/graphql.ts"

// Define an interface for the props
interface ValidationsTabFilterProps {
  aggregateFilteredSearchColumnNames: string[]
  aggregateSuiteLoading: boolean
  assetId: string
  columnNamesLoading: boolean
  dataAssetData: ValidationsTab_DataAssetQuery | undefined
  expectationSuiteId: string
  filteredSearchedColumnNames: string[]
  onAnchorSelection: (value: string) => void
  onSearch: (value: string) => void
  runHistoriesForAllValidations: RunHistoryItem[]
  runHistoriesLoading: boolean
  selectedRun: string
  setFilterByFailures: (value: ActiveListType) => void
  validationResultId: string
  validationResultLoading: boolean
}

export const ValidationsTabFilter: React.FC<ValidationsTabFilterProps> = ({
  aggregateFilteredSearchColumnNames,
  aggregateSuiteLoading,
  assetId,
  columnNamesLoading,
  dataAssetData,
  expectationSuiteId,
  filteredSearchedColumnNames,
  onAnchorSelection,
  onSearch,
  runHistoriesForAllValidations,
  runHistoriesLoading,
  selectedRun,
  setFilterByFailures,
  validationResultId,
  validationResultLoading,
}) => {
  const { useBreakpoint } = Grid
  const { lg } = useBreakpoint()
  const showAggregateRuns = validationResultId.length === 0
  const { expectationSuitesError } = useExpectationSuites(assetId)

  return (
    <LeftPanelWrapper direction="vertical" size="large" $lg={lg}>
      <FailuresToggle onToggleClick={setFilterByFailures} defaultValue="all" disabled={showAggregateRuns} />
      <ExpectationSuiteSelector currentTab="validations" />
      <RunHistoryUI
        error={expectationSuitesError}
        loading={runHistoriesLoading}
        validationRunHistories={runHistoriesForAllValidations}
        validationId={validationResultId}
        expectationSuiteId={expectationSuiteId}
        assetId={dataAssetData?.dataAsset?.id || ""}
      />
      <FilterBox
        onSearch={onSearch}
        list={showAggregateRuns ? aggregateFilteredSearchColumnNames : filteredSearchedColumnNames}
        loading={
          showAggregateRuns
            ? aggregateSuiteLoading
            : runHistoriesLoading || validationResultLoading || columnNamesLoading
        }
        onSelect={onAnchorSelection}
        selectedItem={selectedRun}
        title="Table of contents"
      />
    </LeftPanelWrapper>
  )
}
