import { useState, useMemo, ReactNode } from "react"
import { StringValueType } from "src/api/graphql/graphql.ts"
import { Button } from "src/global/ui/Button/Button.tsx"
import styled, { css } from "styled-components"
import { Tag } from "src/global/ui/Tag"
import { ParamHistory, ParamTypes } from "src/pages/DataAssets/views/Expectations/Expectation/Expectation.tsx"
import {
  addDynamicStyledText,
  WindowTooltips,
} from "src/pages/DataAssets/views/Expectations/Expectation/utils/addDynamicStyledText.tsx"
import { formatNumber } from "src/global/utils/formatNumber.tsx"
import { Param, UpdatedParam } from "src/global/ui/Param/Param.tsx"
import { CodeSnippetEditor } from "src/global/ui/CodeSnippetEditor/CodeSnippetEditor.tsx"
import { isEmpty } from "lodash-es"
import { windowedExpectationTemplates } from "src/global/config.ts"
import type { WindowWithConstraint } from "src/pages/DataAssets/views/Expectations/Expectation/CreateExpectationDrawer/types.ts"
import { Collapse, CollapseProps, ConfigProvider, Tooltip } from "antd"
import { specialCaseTemplate } from "src/pages/DataAssets/views/Expectations/Expectation/CreateExpectationDrawer/windowedExpectationUtils.ts"
import { theme } from "src/global/ui/themes/theme"

// the maximum number of params we will render for a given string
export const STRING_PARAM_RENDER_LIMIT = 500
// the number of params visible before the `See all` button is clicked
export const STRING_PARAM_TRUNCATE_LIMIT = 7
export const ARRAY_BASED_EXPECTATION_PRESCRIPTIVE_RENDER_KEYS = [
  "column_list",
  "column_set",
  "value_set",
  "type_list",
  "like_pattern_list",
  "regex_list",
]
export const ARRAY_BASED_EXPECTATION_PRESCRIPTIVE_RENDER_KEYS_STARTS_WITH = ["v__"]
export const ARRAY_BASED_EXPECTATION_DIAGNOSTIC_RENDER_KEYS_STARTS_WITH = ["ov__", "ev__", "exp__"]

const StyledExpectation = styled.div<{ $isExpectationDeleted?: boolean; $isDeletionAction?: boolean }>`
  ${({ $isDeletionAction }) => css`
    ${$isDeletionAction &&
    css`
      text-decoration: line-through;
    `}
  `}
`

const StyledExpectationWithCodeBlock = styled(StyledExpectation)`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
`

const SeeMoreButton = styled(Button)`
  display: inline-flex;
`

function formatStringNumber(input: string): boolean {
  if (isNaN(Number(input)) || input.length > 16) {
    return true
  }
  return false
}

const formatLaguage = (key: string): string => {
  switch (key) {
    case "sql":
      return "SQL"
    case "python":
      return "Python"
    case "yaml":
      return "YAML"
    case "json":
      return "JSON"
    default:
      return key
  }
}

// TODO: template should be populated with description, but currently is not
// when it is, we can remove this function
const parseTemplate = (template: string | null | undefined, description: string | null | undefined) => {
  if (!formatStringNumber(String(template))) {
    template = formatNumber(String(template))
  }

  return template || description || "No description"
}

function parseCodeBlock(codeBlock: StringValueType["codeBlock"]) {
  const result = JSON.parse(codeBlock ?? "{}")
  return {
    lng: result?.language ?? "",
    tmpl: result?.code_template_str ?? "",
  }
}

function parseParams(paramsJson: StringValueType["params"]) {
  const params: ParamTypes = JSON.parse(paramsJson ?? "{}")
  return params
}

function getWindowsOffset(windows: WindowWithConstraint[]): string {
  /**
   * `windows` should be guaranteed to have at least one value
   * at the time this fn gets called
   *
   * Potential outputs:
   * + 2%
   * - 15%
   * +/- 3%
   */
  if (!windows.length) {
    return ""
  }

  const offset = {
    value: windows[0].offset.positive,
    max: "",
    min: "",
  }
  windows.forEach((w) => {
    const match = w.parameter_name.match(/_([a-zA-Z]+$)/) ?? []
    switch (match[1]) {
      case "max":
        offset.max = "+"
        break
      case "min":
        offset.min = "-"
        break
    }
  })
  const boundedOffset = offset.max && offset.min
  return `${offset.max}${boundedOffset ? "/" : ""}${offset.min}${boundedOffset ? " " : ""}${offset.value * 100}%`
}

function getWindowsConstraintFn(constraint_fn: string): string {
  switch (constraint_fn) {
    case "mean":
      return "average"
    default:
      return constraint_fn
  }
}

// TODO: Remove this function once dynamic renderers are in gx-core.
function patchCompletenessColumn(stringParams: StringValueType["params"]) {
  const parsed = JSON.parse(stringParams ?? "{}")

  if ("kwargs" in parsed) {
    // Note: this should only apply to completeness expectations for now
    parsed.column = {
      value: parsed.kwargs.value.column,
      schema: { type: "string" },
    }
  }

  return parsed
}

function parseWindowedParams(params?: ParamTypes, stringParams?: StringValueType["params"]) {
  const windows: WindowWithConstraint[] | undefined = params?.windows
  if (!windows || !windows.length) {
    return null
  }
  const windowedParams: ParamTypes = {
    ...patchCompletenessColumn(stringParams),
    range: { value: `${windows[0]?.range ?? ""}` },
    constraint_fn: { value: getWindowsConstraintFn(windows[0]?.constraint_fn) },
    offset: { value: getWindowsOffset(windows) },
  }
  return windowedParams
}

function replaceTemplateParams(template: string, params: ParamTypes) {
  return template.replace(
    /**
     * \$ - matches $ literally
     * (\w+) - matches one or more word characters (alphanumeric or underscore)
     * g - global flag to match all occurrences
     */
    /\$(\w+)/g,
    // for each matched group, replace with corresponding param if present
    (match, key: string) => {
      let param = params[key]?.value
      if (Array.isArray(param)) {
        param = param.join(", ")
      }
      return param ?? match // If no matching param, leave as is
    },
  )
}

type RenderCodeBlockProps = {
  template: StringValueType["template"]
  codeBlock: StringValueType["codeBlock"]
  params: ParamTypes
}
function RenderCodeBlock({ codeBlock, params, template }: RenderCodeBlockProps) {
  const [showSQL, setShowSQL] = useState(false)
  // only run json.parse once per codeblock string if possible
  const { language, value } = useMemo(() => {
    const { lng, tmpl } = parseCodeBlock(codeBlock)

    const result = replaceTemplateParams(tmpl, params)

    return {
      language: lng,
      value: result,
    }
  }, [codeBlock, params])

  if (!language && !value) return null

  const items: CollapseProps["items"] = [
    {
      key: "1",
      label: `${showSQL ? "Hide" : "Show"} ${formatLaguage(language)}`,
      children: (
        <CodeSnippetEditor
          name={template?.replace(/\s/g, "-")}
          language={language}
          value={value ?? ""}
          readOnly={true}
          minLines={1}
          maxLines={20}
          fontSize={14}
          showLineNumbers={false}
          showGutter={false}
          width="100%"
        />
      ),
    },
  ]

  return (
    <ConfigProvider
      theme={{
        components: {
          Collapse: {
            contentPadding: `0px !important`,
            headerPadding: `0 0 ${theme.spacing.xxs} 0 !important`,
          },
        },
      }}
    >
      <Collapse
        items={items}
        ghost
        size="small"
        defaultActiveKey={[]}
        style={{ width: "100%" }}
        onChange={() => setShowSQL(!showSQL)}
      />
    </ConfigProvider>
  )
}

interface TooltipWrapperProps {
  children: ReactNode
  title?: string
}

const TooltipWrapper = ({ children, title }: TooltipWrapperProps) => {
  // The <div> is necessary to ensure the surrounding element supports
  // mouseEnter and mouseLeave events.
  const element = title ? (
    <Tooltip title={title}>
      <div style={{ display: "inline-block" }}>{children}</div>
    </Tooltip>
  ) : (
    <>{children}</>
  )
  return element
}

const curryDynamicTagRenderer = (
  evrConfig?: { danger?: boolean },
  isExpectationDeleted?: boolean,
  isDeletionAction?: boolean,
) =>
  function RenderDynamicTextTag(
    value: string | number | string[],
    id?: string,
    history?: ParamHistory,
    tooltip?: string,
    danger?: boolean,
    strikethrough?: boolean,
  ) {
    let newValue = value
    if (typeof value === "number") {
      newValue = formatNumber(value)
    }
    if (evrConfig?.danger !== undefined) {
      return (
        <TooltipWrapper key={id} title={tooltip}>
          <Tag key={id} $danger={evrConfig.danger || danger} $strikethrough={strikethrough}>
            {newValue}
          </Tag>
        </TooltipWrapper>
      )
    }
    if (history || isExpectationDeleted || isDeletionAction) {
      return (
        <TooltipWrapper key={id} title={tooltip}>
          <UpdatedParam
            key={id}
            $isParamDeleted={history?.removed}
            $isParamAdded={history?.added}
            $isOnDarkBackground={isExpectationDeleted}
            $isDeletionAction={isDeletionAction}
          >
            {newValue}
          </UpdatedParam>
        </TooltipWrapper>
      )
    }
    return (
      <TooltipWrapper key={id} title={tooltip}>
        <Param key={id}>{newValue}</Param>
      </TooltipWrapper>
    )
  }

type RenderStringProps = {
  template: string | null | undefined
  params: ParamTypes
  codeBlock: StringValueType["codeBlock"]
  isExpectationDeleted?: boolean
  isDeletionAction?: boolean
  evrConfig?: { danger?: boolean }
  isPreview?: boolean
  windowTooltips?: WindowTooltips
}
function RenderString({
  template,
  params,
  codeBlock,
  isExpectationDeleted,
  isDeletionAction,
  evrConfig,
  isPreview,
  windowTooltips,
}: RenderStringProps) {
  let arrayBasedExpectationPrescriptiveParamCount = 0
  ARRAY_BASED_EXPECTATION_PRESCRIPTIVE_RENDER_KEYS.forEach((key) => {
    const keyParamCount = params[key]?.value?.length ?? 0
    if (keyParamCount > arrayBasedExpectationPrescriptiveParamCount) {
      arrayBasedExpectationPrescriptiveParamCount = keyParamCount
    }
  })
  const arrayBasedExpectationDiagnosticParamCount = Object.keys(params).filter(
    (key) =>
      ARRAY_BASED_EXPECTATION_DIAGNOSTIC_RENDER_KEYS_STARTS_WITH.some((subString) => key.startsWith(subString)) &&
      params[key]?.render_state, // diagnostic set params without a render_state don't get rendered
  ).length

  const [truncate, setTruncate] = useState<boolean>(
    arrayBasedExpectationPrescriptiveParamCount > STRING_PARAM_TRUNCATE_LIMIT ||
      arrayBasedExpectationDiagnosticParamCount > STRING_PARAM_TRUNCATE_LIMIT,
  )

  if (!template) return null

  const limit =
    (Array.isArray(params.observed_value?.value) && params.observed_value.value.length > STRING_PARAM_RENDER_LIMIT) ||
    arrayBasedExpectationDiagnosticParamCount > STRING_PARAM_RENDER_LIMIT
      ? STRING_PARAM_RENDER_LIMIT
      : undefined
  const seeMoreButtonMessage = limit ? `See first ${STRING_PARAM_RENDER_LIMIT}` : "See all"

  return (
    <>
      {addDynamicStyledText({
        template: template,
        params,
        getDynamicStyleTag: curryDynamicTagRenderer(evrConfig, isExpectationDeleted, isDeletionAction),
        limit,
        truncate,
        isExpectationDeleted,
        isDeletionAction,
        windowTooltips,
      })}
      {truncate && (
        <SeeMoreButton size="small" type="text" onClick={() => setTruncate(false)}>
          {seeMoreButtonMessage}
        </SeeMoreButton>
      )}
      {codeBlock && !isPreview && <RenderCodeBlock codeBlock={codeBlock} params={params} template={template} />}
    </>
  )
}

type StringRenderComponentProps = {
  value: StringValueType
  evrConfig?: { danger?: boolean }
  isExpectationDeleted?: boolean
  isDeletionAction?: boolean
  description?: string | null
  kwargs?: string | null
  isPreview?: boolean
  expectationType?: string
  isValidationResult?: boolean
}

function StringRenderComponent({
  value,
  evrConfig,
  isExpectationDeleted,
  isDeletionAction,
  description,
  kwargs,
  isPreview,
  expectationType,
  isValidationResult,
}: StringRenderComponentProps) {
  const { params: stringParams, codeBlock } = value
  if (typeof stringParams !== "string") {
    throw new Error("type error: unable to parse stringParams. expected string, got " + typeof stringParams)
  }

  const parsedKwargs = useMemo(() => JSON.parse(kwargs ?? "false"), [kwargs])

  // don't run json.parse on every render cycle if we still have the same string
  const params = useMemo(() => {
    let params
    if (!isValidationResult) {
      params = parseWindowedParams(parsedKwargs, stringParams)
    }
    return params ?? parseParams(stringParams)
  }, [isValidationResult, parsedKwargs, stringParams])

  const template = useMemo(() => {
    const originalTemplate = parseTemplate(value.template, description)
    const expectationRenderingFeatureFlags = { volumeChangeDetection: true }
    if (expectationType && !isValidationResult) {
      const windowedExpectationTemplate = windowedExpectationTemplates[expectationType]
      const shouldRenderWindowedTemplate = "constraint_fn" in params && windowedExpectationTemplate
      if (shouldRenderWindowedTemplate) {
        const preposition = params?.offset?.value?.includes("+/-") ? "within" : "between"
        return specialCaseTemplate(
          windowedExpectationTemplate({ originalTemplate, preposition }),
          expectationType,
          params,
          expectationRenderingFeatureFlags,
        )
      }
    }
    return originalTemplate
  }, [expectationType, isValidationResult, value, params, description])

  const windowTooltips = useMemo(() => {
    // At this point we're only concerned with min/max expectations for windowed params.
    // This function will likely need updating once we incorporate additional expectation types.
    if (parsedKwargs && "windows" in parsedKwargs) {
      return parsedKwargs.windows.reduce((acc: WindowTooltips, window: WindowWithConstraint) => {
        if (window.parameter_name.match(/_min$/)) {
          acc.min_value = `-${window.offset.negative * 100}% of the ${getWindowsConstraintFn(window.constraint_fn)} of the last ${window.range} minimum values`
        }
        if (window.parameter_name.match(/_max$/)) {
          acc.max_value = `+${window.offset.positive * 100}% of the ${getWindowsConstraintFn(window.constraint_fn)} of the last ${window.range} maximum values`
        }
        return { ...acc }
      }, {})
    }

    return undefined
  }, [parsedKwargs])

  const Wrapper = !isEmpty(JSON.parse(codeBlock ?? "{}")) ? StyledExpectationWithCodeBlock : StyledExpectation

  return (
    <Wrapper aria-label="Expectation summary" $isDeletionAction={isDeletionAction}>
      <RenderString
        template={template}
        params={params}
        codeBlock={codeBlock}
        evrConfig={evrConfig}
        isExpectationDeleted={isExpectationDeleted}
        isDeletionAction={isDeletionAction}
        isPreview={isPreview}
        windowTooltips={windowTooltips}
      />
    </Wrapper>
  )
}

export { StringRenderComponent }
