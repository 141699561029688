import { Image } from "src/global/ui/Image"

export const CodeIcon = () => {
  return (
    <Image
      type="code"
      title="Use code snippet"
      style={{ display: "flex" }}
      svgProps={{
        width: "15px",
      }}
    />
  )
}
