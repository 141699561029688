import { MainContent, MainContentBlock } from "src/global/layout/MainContent.tsx"
import styled from "styled-components"
import { GetStarted } from "src/global/ui/GetStarted/GetStarted.tsx"
import { DataSourceChoice, NewAssetDrawer } from "src/pages/DataAssets/drawers/NewAssetDrawer/NewAssetDrawer.tsx"
import { NewDemoAssetDrawer } from "src/pages/DataAssets/drawers/DemoAssetDrawer/NewDemoAssetDrawer.tsx"
import { useCallback, useState } from "react"

import { Heading2, LG } from "src/global/ui/typography/Text/Text.tsx"
import { App, Col, Row } from "antd"
import { theme } from "src/global/ui/themes/theme.ts"
import { Icon } from "src/global/ui/Icon"
import { SalesDemoModalConfig } from "src/global/ui/Menu/SalesDemoModal.tsx"
import { DataSourceCards, DataSourceCard } from "src/pages/DataAssets/components/PickNewOrExistingDataSource.tsx"
import { useAnalytics } from "src/global/hooks/analytics/useAnalytics.ts"

const CenteredContainer = styled.div`
  height: calc(100vh - 200px);
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
`

export function DataAssetsEmptyScreen({ isEditorOrAdmin }: Readonly<{ isEditorOrAdmin: boolean }>) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [isDemoDataDrawerOpen, setIsDemoDataDrawerOpen] = useState(false)
  const [dataSourceChoice, setDataSourceChoice] = useState<DataSourceChoice>()

  const { modal } = App.useApp()
  const { capture } = useAnalytics()

  const handleOpenSalesDemoModal = useCallback(() => {
    capture("request_sales_demo.datasource_card_clicked")
    modal.info(SalesDemoModalConfig)
  }, [modal, capture])

  const onDatasourceSelect = (choice: DataSourceChoice) => {
    setIsDrawerOpen(true)
    setDataSourceChoice(choice)
  }

  const emptyStateTitle = "Connect to a data source to get started"

  const showDataAssetCards = isEditorOrAdmin

  return (
    <MainContent>
      <MainContentBlock>
        <CenteredContainer>
          <GetStarted large title={emptyStateTitle}>
            {showDataAssetCards && (
              <>
                <DataSourceCards large setChoice={onDatasourceSelect} />

                <Heading2 style={{ marginTop: theme.spacing.s }}>Not ready to connect to your data?</Heading2>
                <Row gutter={16}>
                  <Col span={12}>
                    <DataSourceCard
                      large
                      type="demo"
                      onClick={() => setIsDemoDataDrawerOpen(true)}
                      image={<Icon name="flask" />}
                      text="Use demo data"
                    />
                  </Col>
                  <Col span={12}>
                    <DataSourceCard
                      large
                      type="demo"
                      onClick={handleOpenSalesDemoModal}
                      image={<Icon name="playCircle" />}
                      text="Request a demo"
                    />
                  </Col>
                </Row>

                <NewAssetDrawer
                  dataSourceChoiceFromEmptyState={dataSourceChoice}
                  isOpen={isDrawerOpen}
                  setIsOpen={setIsDrawerOpen}
                />
                <NewDemoAssetDrawer isOpen={isDemoDataDrawerOpen} setIsOpen={setIsDemoDataDrawerOpen} />
              </>
            )}
            {!isEditorOrAdmin && <LG>Only Editors and Admins can add Data Assets.</LG>}
          </GetStarted>
        </CenteredContainer>
      </MainContentBlock>
    </MainContent>
  )
}
