import styled from "styled-components"
import { ReactNode } from "react"
import { ImageProps } from "src/global/ui/Image/Image.tsx"
import { BodyNormal, Heading1 } from "src/global/ui/typography/Text/Text.tsx"

const EmptySection = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
`

const StyledHeading = styled(Heading1)`
  margin-top: 40px;
  margin-bottom: 16px;
`

export interface EmptyStateProps {
  illustration?: ImageProps["type"]
  title: string
  children?: string | ReactNode
}

export const EmptyState = ({ children, title }: EmptyStateProps) => (
  <EmptySection>
    <StyledHeading>{title}</StyledHeading>
    {!!children && <BodyNormal>{children}</BodyNormal>}
  </EmptySection>
)
