import { Button } from "src/global/ui/Button/Button.tsx"
import styled from "styled-components"
import { BodyNormal, Heading1, Heading2 } from "src/global/ui/typography/Text/Text.tsx"
import { Flex } from "antd"

interface ErrorStateProps {
  errorMessage?: string
  errorCode?: string
  resetErrorBoundary?: () => void
  className?: string
  includeTryAgainSubtitle?: boolean
}

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
  flex-grow: 1;
`

const StyledLabelRegular = styled(BodyNormal)`
  margin-top: 16px;
  margin-bottom: 16px;
`
const StyledHeading = styled(Heading1)`
  color: ${(props) => props.theme.colors.primaryColors.gxAccentMedium};
  margin-top: 16px;
  margin-bottom: 16px;
`

function ErrorState({
  resetErrorBoundary,
  errorMessage = "something went wrong",
  errorCode,
  className,
  includeTryAgainSubtitle = true,
}: ErrorStateProps) {
  return (
    <MainContainer className={className}>
      <Flex justify="center" align="center" vertical>
        {Boolean(errorCode) && <StyledHeading>{errorCode}</StyledHeading>}
        <Heading2>{`Oops, ${errorMessage}...`}</Heading2>
        {includeTryAgainSubtitle && <StyledLabelRegular>Try again later</StyledLabelRegular>}
        {resetErrorBoundary && (
          <Button onClick={resetErrorBoundary} type="default">
            Try Again
          </Button>
        )}
      </Flex>
    </MainContainer>
  )
}

export { ErrorState }
