import { Empty, Space } from "antd"
import { PageHeader } from "src/global/ui/PageHeader"
import { ErrorState } from "src/global/ui/error"
import { pluralize } from "src/global/utils/strings.ts"
import { MainContent, MainContentBlock } from "src/global/layout/MainContent.tsx"
import { DataAssetsList } from "src/pages/DataAssets/components/DataAssetsList.tsx"
import { DataAssetsEmptyScreen } from "src/pages/DataAssets/components/DataAssetsEmptyScreen.tsx"
import { ConnectToDataButton } from "src/pages/DataAssets/components/ConnectToDataButton.tsx"
import { DataAssetsFilter } from "src/pages/DataAssets/components/DataAssetsFilter.tsx"
import { useDataAssets } from "src/pages/DataAssets/hooks/useDataAssets.ts"
import { useUrlParams } from "src/global/utils/urlParams.ts"
import { useRequireRole } from "src/global/hooks/useRequireRole.ts"
// eslint-disable-next-line no-restricted-imports -- deprecated file
import { DatasourcesWithRunsDocument } from "src/api/graphql/graphql-operations.ts"
import { ManageDatasourcesButton } from "src/pages/DataAssets/drawers/ManageDataSources/ManageDatasourcesButton.tsx"
import { useRefetchOnJobComplete } from "src/pages/DataAssets/views/AssetDetails/useRefetchOnJobComplete.ts"
import {
  AggregateAssetCoverageStats,
  AggregateCoverageStatsDocument,
} from "src/pages/DataAssets/components/AggregateAssetCoverageStats"
import { useApolloClient, useQuery } from "@apollo/client"
import { useIsFeatureEnabled } from "src/global/hooks/useIsFeatureEnabled"
import { graphql } from "src/api/graphql/gql.ts"
import { exists } from "src/global/utils/typeguards"
import { DataAssetsTable } from "src/pages/DataAssets/components/DataAssetsTable"

export const DataAssets = () => {
  const useTableView = useIsFeatureEnabled("testCoverageMetricsDataAssetTable")

  if (useTableView) {
    return <DataAssetsTableView />
  }

  return <DataAssetsListView />
}

export const DataAssetTableDocument = graphql(`
  query DataAssetTableDocument {
    datasourcesV2 {
      id
      type
      name
      assets {
        id
        aggregateSuccessStatus
        lastValidatedAt
        name
        coverageStats {
          hasCompletenessCoverage
          hasSchemaCoverage
          hasVolumeCoverage
        }
      }
    }
  }
`)

export function DataAssetsTableView() {
  const isEditorOrAdmin = useRequireRole("EDITOR")
  const { data, loading, error } = useQuery(DataAssetTableDocument)

  const assetData = data?.datasourcesV2.flatMap((dataSource) =>
    dataSource.assets.filter(exists).map(({ id, name, lastValidatedAt, aggregateSuccessStatus, coverageStats }) => ({
      id,
      type: dataSource.type,
      dataSourceName: dataSource.name,
      name: name,
      lastValidation: lastValidatedAt ? lastValidatedAt : null,
      success: aggregateSuccessStatus ?? null,
      schema: coverageStats?.hasSchemaCoverage ?? false,
      completeness: coverageStats?.hasCompletenessCoverage ?? false,
      volume: coverageStats?.hasVolumeCoverage ?? false,
    })),
  )

  const hasDataAssets = assetData && assetData.length > 0
  const showTable = loading || hasDataAssets
  const showGettingStarted = !loading && !hasDataAssets && !error

  const dataSourceCount = data?.datasourcesV2?.length ?? 0
  const dataAssetCount = assetData?.length ?? 0

  return (
    <DataAssetsPageHeader loading={loading} dataAssetCount={dataAssetCount} dataSourceCount={dataSourceCount}>
      <MainContent>
        {error && (
          <MainContentBlock>
            <ErrorLoadingDataAssets />
          </MainContentBlock>
        )}
        {showGettingStarted && (
          <MainContentBlock>
            <DataAssetsEmptyScreen isEditorOrAdmin={isEditorOrAdmin} />
          </MainContentBlock>
        )}
        {hasDataAssets && (
          <MainContentBlock>
            <AggregateAssetCoverageStats />
          </MainContentBlock>
        )}
        {showTable && (
          <MainContentBlock size="large">
            <DataAssetsTable canEdit={isEditorOrAdmin} assetData={assetData} loading={loading} />
          </MainContentBlock>
        )}
      </MainContent>
    </DataAssetsPageHeader>
  )
}

export const DataAssetsListView = () => {
  const isEditorOrAdmin = useRequireRole("EDITOR")

  const { datasourceCount, assetCount, loading, error, isDataAssetsEmpty, assetAndDatasourceTuples } =
    useDataAssets(DatasourcesWithRunsDocument)

  const apollo = useApolloClient()

  const refetchOnJobCompleted = () => {
    apollo.refetchQueries({
      include: [AggregateCoverageStatsDocument, DatasourcesWithRunsDocument],
    })
    apollo.reFetchObservableQueries()
  }
  useRefetchOnJobComplete({ jobType: "ValidateDataJob", refetch: refetchOnJobCompleted })

  return (
    <DataAssetsPageHeader loading={loading} dataAssetCount={assetCount} dataSourceCount={datasourceCount}>
      {isDataAssetsEmpty ? (
        <DataAssetsEmptyScreen isEditorOrAdmin={isEditorOrAdmin} />
      ) : (
        <MainContent>
          <MainContentBlock>
            {error ? (
              <ErrorLoadingDataAssets />
            ) : (
              <>
                {!isDataAssetsEmpty && <AggregateAssetCoverageStats />}
                <DataAssetsList
                  dataAssets={assetAndDatasourceTuples}
                  header={<DataAssetsFilter />}
                  emptyState={<NoResultsState />}
                  loading={loading}
                />
              </>
            )}
          </MainContentBlock>
        </MainContent>
      )}
    </DataAssetsPageHeader>
  )
}

type DataAssetsPageHeaderProps = {
  loading: boolean
  dataAssetCount: number
  dataSourceCount: number
  children: React.ReactNode
}
function DataAssetsPageHeader({ loading, dataAssetCount, dataSourceCount, children }: DataAssetsPageHeaderProps) {
  const canEdit = useRequireRole("EDITOR")
  return (
    <PageHeader
      headerContent={{
        title: "Data Assets",
        subtitle: `${dataSourceCount} ${pluralize("Data Source", dataSourceCount)}, ${dataAssetCount} ${pluralize(
          "Data Asset",
          dataAssetCount,
        )}`,
        rightActions: dataSourceCount
          ? {
              reactNode: canEdit && (
                <Space direction="horizontal">
                  <ManageDatasourcesButton />
                  <ConnectToDataButton />
                </Space>
              ),
            }
          : {},
      }}
      loading={loading}
    >
      {children}
    </PageHeader>
  )
}

function NoResultsState() {
  const [{ search: searchTerm, failures }] = useUrlParams({
    failures: "",
    search: "",
  })

  return (
    <Empty
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      description={
        failures === "true"
          ? "Nice job! No failed validations were found."
          : `Can't find Data Asset or Data Source with ${searchTerm} `
      }
    />
  )
}

function ErrorLoadingDataAssets() {
  return <ErrorState errorMessage="There was a problem loading Data Assets" />
}
