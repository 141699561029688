import { createContext, Dispatch, SetStateAction } from "react"
import { SupportedDataSource } from "src/pages/DataAssets/schemas/data-source-schemas.ts"
import type { PossibleDataSource } from "src/pages/DataAssets/drawers/ConnectToDataSource/types.ts"
import { DataQualityIssues } from "src/api/graphql/graphql"

export type ConnectToDataSourceContext = {
  // step progression aids
  activeStep: number
  nextStep: () => void
  prevStep: () => void
  previouslyActiveStep: number
  isLoading: boolean
  setIsLoading: Dispatch<SetStateAction<boolean>>

  // credentials form data
  formData: Record<string, unknown>
  setFormData: Dispatch<SetStateAction<Record<string, unknown>>>

  // all tables fetched from the data source
  allTableNames: string[]
  setAllTableNames: Dispatch<SetStateAction<string[]>>

  // tables the user has selected to add to coverage, including previously selected tables
  selectedTables: string[]
  setSelectedTables: Dispatch<SetStateAction<string[]>>

  // tables that the user has selected to be added during the current session
  filteredTables: string[]
  setFilteredTables: Dispatch<SetStateAction<string[]>>

  // data source
  dataSource?: PossibleDataSource
  setDataSource: Dispatch<SetStateAction<PossibleDataSource>>
  dataSourceType?: SupportedDataSource
  isDemo?: boolean

  // data quality issues
  selectedDataQualityIssues: DataQualityIssues[]
  setSelectedDataQualityIssues: Dispatch<SetStateAction<DataQualityIssues[]>>
}

const ConnectToDataSourceContext = createContext<ConnectToDataSourceContext>({
  activeStep: 0,
  nextStep: () => {},
  prevStep: () => {},
  previouslyActiveStep: 0,
  isLoading: true,
  setIsLoading: () => {},
  formData: {},
  setFormData: () => {},
  allTableNames: [],
  setAllTableNames: () => {},
  selectedTables: [],
  setSelectedTables: () => {},
  filteredTables: [],
  setFilteredTables: () => {},
  dataSource: undefined,
  setDataSource: () => {},
  dataSourceType: undefined,
  selectedDataQualityIssues: [],
  setSelectedDataQualityIssues: () => {},
})

export default ConnectToDataSourceContext
