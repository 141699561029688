import { Button, Card, Col, Dropdown as _Dropdown, Row } from "antd"
import { formatOrgRole } from "src/pages/OrganizationRoot/roles.ts"
import { useOrganizations } from "src/pages/OrganizationRoot/hooks/useOrganizations.ts"
import { Image } from "src/global/ui/Image"
import { LoadingState } from "src/global/ui/LoadingState"
import styled from "styled-components"
import { withLDConsumer } from "launchdarkly-react-client-sdk"

import { useCurrentUser } from "src/global/hooks/useCurrentUser.ts"
import { useAnalytics } from "src/global/hooks/analytics/useAnalytics.ts"
import { LG } from "src/global/ui/typography/Text/Text.tsx"

const Dropdown = styled(_Dropdown)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 100%;
  padding: 0;
  margin: 0;
  border: 0;
  border-radius: 0;
  background: transparent;
  cursor: pointer;

  &.ant-dropdown-trigger:hover {
    background: ${({ theme }) => theme.colors.primaryColors.gxPrimaryDark};
  }
`

const OverlayCard = styled(Card)`
  width: 350px;
  max-width: 500px;
  margin-left: ${({ theme }) => theme.spacing.xs};

  > .ant-card-body {
    padding-left: 0;
    padding-right: 0;
  }
`

const StyledDropdownRow = styled(Row)`
  width: 100%;
  max-width: 100%;
  height: 80px;
`

const ButtonOrgName = styled(LG)`
  font-size: 14px;
  text-align: left;
  color: ${({ theme }) => theme.colors.neutralColorPalette.whites.white};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const OverlayText = styled(LG)`
  text-align: center;
  margin-bottom: ${({ theme }) => theme.spacing.xs};
  display: block;
  padding-left: ${({ theme }) => theme.spacing.xs};
  padding-right: ${({ theme }) => theme.spacing.xs};
  overflow-wrap: break-word;
`

function Overlay() {
  const posthog = useAnalytics()
  const { logout, user } = useCurrentUser()

  const { currentOrg, isLoading } = useOrganizations()

  if (isLoading) {
    return (
      <OverlayCard>
        <LoadingState loading absolute={false} />
      </OverlayCard>
    )
  }

  // create onLogoutButtonClick function that is called when log out button is clicked  (see below)
  const onLogoutButtonClick = () => {
    posthog?.capture("$logout")
    logout()
  }

  return (
    <OverlayCard>
      <OverlayText>{user?.email}</OverlayText>
      <OverlayText>
        {currentOrg?.title} — {formatOrgRole(currentOrg?.role)}
      </OverlayText>
      {/*added className to test launch darkly click experiment*/}
      <Row align="middle" justify="center">
        <Button onClick={onLogoutButtonClick}>Log Out</Button>
      </Row>
    </OverlayCard>
  )
}

// using withLDConsumer to get access to the Launch Darkly client
const OverlayWrapper = withLDConsumer()(Overlay)
interface OrgDropdownProps {
  collapsed?: boolean
}

export function ProfileDropdown(props: OrgDropdownProps) {
  const overlay = <OverlayWrapper />
  const { currentOrg } = useOrganizations()

  if (props.collapsed) {
    return (
      <Dropdown overlay={overlay} trigger={["click"]}>
        <button data-cy="profile-dropdown">
          <Image
            type="geLogoSmall"
            svgProps={{ height: "40px", width: "80px" }}
            style={{ height: "40px", width: "80px" }}
          />
        </button>
      </Dropdown>
    )
  }

  return (
    <Dropdown overlay={overlay} trigger={["click"]}>
      <button data-cy="profile-dropdown" style={{ height: "100px" }}>
        <StyledDropdownRow justify="space-between" align="middle">
          <Col>
            <Row justify="center" align="middle">
              <Image
                aria-label="Great Expectations"
                type="geLogoWithLightText"
                svgProps={{ height: "40px", width: "200px" }}
                style={{ height: "40px", width: "200px" }}
              />
            </Row>
            <Row style={{ paddingLeft: "25px", paddingTop: "8px" }} justify="start">
              <ButtonOrgName>{currentOrg?.title || ""}</ButtonOrgName>
            </Row>
          </Col>
        </StyledDropdownRow>
      </button>
    </Dropdown>
  )
}
