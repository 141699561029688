import { useEffect } from "react"
import { LDClient } from "launchdarkly-js-client-sdk"
import { useLDClient } from "launchdarkly-react-client-sdk"
import { useOrganizations } from "src/pages/OrganizationRoot/hooks/useOrganizations.ts"
import { useCurrentUser } from "src/global/hooks/useCurrentUser.ts"

type UserParams = {
  key: string
  email: string
  name: string
}

type OrgParams = {
  key: string
  organizationName: string
  organizationId: string
}

type ContextParams = {
  kind: "multi"
  user: UserParams
  organization: OrgParams
}

// this hook is used to set the organization context for launch darkly
export function useSetFeatureFlagUserAndOrgContext(): void {
  const { isAuthenticated, user } = useCurrentUser()
  const ldClient: LDClient | undefined = useLDClient()
  const { currentOrg } = useOrganizations()

  // multi contexts are used to set multiple contexts (here user and org)
  useEffect(() => {
    if (isAuthenticated && user && currentOrg) {
      ldClient?.identify({
        kind: "multi",
        user: { key: user.id, email: user.email, name: user.name },
        organization: {
          key: currentOrg.id,
          organizationName: currentOrg.name,
          organizationId: currentOrg.id,
        },
      } satisfies ContextParams)
    }
  }, [isAuthenticated, ldClient, user, currentOrg])
}
