import { OrganizationRole } from "src/api/graphql/graphql.ts"
import { Sentry } from "src/global/observability/sentry/sentry.ts"

type FormattedOrganizationRole = "Superconductive Admin" | "Admin" | "Editor" | "Viewer"

export type AppDisplayableOrgRoles = Extract<
  OrganizationRole,
  "ORGANIZATION_ADMIN" | "EDITOR" | "VIEWER" | "SUPER_ADMIN" | "SUPERCONDUCTIVE_ADMIN"
>

// formatOrgRole transforms the raw API value into friendly format
export function formatOrgRole(raw?: OrganizationRole | null): FormattedOrganizationRole | "" {
  switch (raw) {
    case "ORGANIZATION_ADMIN":
      return "Admin"
    case "EDITOR":
      return "Editor"
    case "VIEWER":
      return "Viewer"
    case "SUPER_ADMIN":
    case "SUPERCONDUCTIVE_ADMIN" as OrganizationRole: // for backwards compatibility
      return "Superconductive Admin"
    default:
      return ""
  }
}

// getOrgRole transforms the friendly format into the raw API value
export function getOrgRole(str: FormattedOrganizationRole): OrganizationRole | "" {
  switch (str) {
    case "Admin":
      return "ORGANIZATION_ADMIN"
    case "Editor":
      return "EDITOR"
    case "Viewer":
      return "VIEWER"
    default:
      return ""
  }
}

export function getRoleLevel(role: OrganizationRole): number {
  switch (role) {
    case "SUPER_ADMIN":
    case "SUPERCONDUCTIVE_ADMIN" as OrganizationRole: // for backwards compatibility
      return 0
    case "ORGANIZATION_ADMIN":
      return 1
    case "EDITOR":
      return 2
    case "VIEWER":
      return 3
  }
  Sentry.captureMessage(`Unknown OrganizationRole Value: '${role}'`)
  return 10000
}

export function sortRoles(a: OrganizationRole, b: OrganizationRole): number {
  let comparison = 0
  if (getRoleLevel(a) > getRoleLevel(b)) comparison = -1
  else if (getRoleLevel(a) < getRoleLevel(b)) comparison = 1
  return comparison
}

// userAssignableRoles are the organization roles that can be assigned to a user from the UI
const _userAssignableRoles: OrganizationRole[] = ["ORGANIZATION_ADMIN", "EDITOR", "VIEWER"]
export const userAssignableRoles = _userAssignableRoles.sort(sortRoles) as Array<OrganizationRole>

// tokenAssignableRoles are the organization roles that can be assigned to a user token from the UI
const _tokenAssignableRoles: OrganizationRole[] = ["EDITOR", "VIEWER"]
export const tokenAssignableRoles = _tokenAssignableRoles.sort(sortRoles) as Array<OrganizationRole>
