import { customAntdTheme, theme } from "src/global/ui/themes/theme.ts"
import { ConfigProvider, message } from "antd"
import { AppCss } from "src/global/styles/globalCss.tsx"
import { ThemeProvider } from "styled-components"
import { MESSAGE_PLACEMENT_FROM_TOP } from "src/global/config.ts"

type ConfiguredThemeProviderProps = {
  children: React.ReactNode
}
export const ConfiguredThemeProvider = ({ children }: ConfiguredThemeProviderProps) => {
  message.config({ top: MESSAGE_PLACEMENT_FROM_TOP })

  return (
    <ThemeProvider theme={theme}>
      <ConfigProvider theme={customAntdTheme}>
        <AppCss />
        {children}
      </ConfigProvider>
    </ThemeProvider>
  )
}
