import { Typography } from "antd"
import { LinkProps as AntdLinkProps } from "antd/lib/typography/Link"
import { LinkProps as RRLinkProps, useNavigate } from "react-router-dom"
import { useOptionalOrganizationSlug } from "src/pages/OrganizationRoot/hooks/useOrganizationSlug.ts"
import { exists } from "src/global/utils/typeguards.ts"
import styled, { css } from "styled-components"
import { defaultTextColor } from "src/global/ui/typography/Text/Text.tsx"
import React from "react"

type MergedLinkProps = Omit<AntdLinkProps & RRLinkProps, "href" | "ref" | "to">

export interface LinkProps extends MergedLinkProps {
  // Describes a location that is the destination of some navigation, either via history.push or history.replace.
  // Use this instead of href
  to: string
}

/*
 * Use UnstyledLink when you need to customize the appearance of the link.
 */
function UnstyledLink(props: LinkProps) {
  const { to, replace, onClick: _onClick, ...other } = props
  const orgSlugUtils = useOptionalOrganizationSlug()
  const navigateOutsideOrg = useNavigate()
  const isExternal = /^(tel:|mailto:|https?:\/\/)/.test(to || "")
  const toWithOrgPrefixMaybe = !isExternal && orgSlugUtils.exists && exists(to) ? orgSlugUtils.orgPrefixedPath(to) : to
  const navigate = orgSlugUtils.exists ? orgSlugUtils.navigateInOrg : navigateOutsideOrg

  const onClick: LinkProps["onClick"] = isExternal
    ? undefined
    : (event) => {
        event.preventDefault()
        if (exists(to)) {
          navigate(to, { replace })
        }
      }

  if (other.target === "_blank") {
    other.rel = "noopener noreferrer"
  }

  return <Typography.Link href={toWithOrgPrefixMaybe} onClick={_onClick ?? onClick} {...other} />
}

const StyledLinkTypography = styled(UnstyledLink)<{ $isLightText?: boolean }>`
  &.ant-typography {
    ${({ theme, $isLightText }) => css`
      text-decoration: underline;
      font-size: ${theme.typography.fontSize.small};
      line-height: ${theme.typography.lineHeight.medium};
      font-weight: ${theme.typography.fontWeight.regular};
      transition: color 0.3s;
      &:hover {
        text-decoration: underline;
      }

      ${$isLightText
        ? css`
            color: ${theme.colors.neutralColorPalette.whites.white};
          `
        : css`
            color: ${defaultTextColor(theme)};
          `}
    `};
  }
`

interface StyledLinkProps extends LinkProps {
  // Use isLightText when the link is on a dark background
  isLightText?: boolean
}

/*
 * StyledLink is the UnstyledLink component with styling matching our style guide.
 * https://www.figma.com/design/qAgFR1xUVtUAosF6LQv33f/GX-Cloud-Design-System?node-id=6748-144920&t=RVtsdebJP5zPAfmO-0
 */
function StyledLink({ isLightText, children, ...props }: React.PropsWithChildren<StyledLinkProps>) {
  return (
    <StyledLinkTypography $isLightText={isLightText} {...props}>
      {children}
    </StyledLinkTypography>
  )
}

export { StyledLink as Link, UnstyledLink }
