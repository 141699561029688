import { Card, ConfigProvider, Flex, Checkbox } from "antd"
import { Button } from "src/global/ui/Button/Button"
import { theme } from "src/global/ui/themes/theme"
import { DateFnsDatePicker } from "src/global/ui/DatePicker/DateFnsDatePicker"
import locale from "antd/es/date-picker/locale/en_US"
import Base from "antd/lib/typography/Base"
import { NoUndefinedRangeValueType } from "rc-picker/lib/PickerInput/RangePicker"
import { parseDateRangeString, formatDateRangeString } from "src/pages/DataAssets/components/dataAssetsTableUtils"

type FilterDateRangeDropdownProps = {
  setSelectedKeys: (keys: string[]) => void
  selectedKeys: string[]
  confirm: () => void
}

export const FilterDateRangeDropdown = ({ setSelectedKeys, selectedKeys, confirm }: FilterDateRangeDropdownProps) => {
  const [startDate, endDate, exclude] = selectedKeys.length
    ? parseDateRangeString(selectedKeys[0])
    : [null, null, false]

  const onChange = (date: NoUndefinedRangeValueType<Date>, dateStrings: string[]) => {
    if (dateStrings[0] && dateStrings[1]) {
      const [startDate, endDate] = date as [Date, Date]
      setSelectedKeys([formatDateRangeString(startDate, endDate, exclude)])
    } else {
      // Preserve exclude state when clearing dates
      setSelectedKeys([formatDateRangeString(null, null, exclude)])
    }
  }

  const onExcludeChange = (checked: boolean) => {
    setSelectedKeys([formatDateRangeString(startDate, endDate, checked)])
  }

  return (
    <Card
      styles={{
        body: {
          padding: theme.spacing.xs,
        },
        actions: {
          padding: `0 ${theme.spacing.xs}`,
        },
      }}
      actions={[
        <Flex key="actions" gap={theme.spacing.xxs} justify="space-between">
          <Button
            key="reset"
            type="text"
            disabled={!selectedKeys.length}
            onClick={() => {
              setSelectedKeys([])
            }}
          >
            Reset
          </Button>
          <Button key="filter" type="primary" onClick={() => confirm()}>
            Ok
          </Button>
        </Flex>,
      ]}
    >
      <Flex gap={theme.spacing.xxs} vertical style={{ width: 250 }}>
        <Base>Select range</Base>
        <Checkbox checked={exclude} onChange={(e) => onExcludeChange(e.target.checked)}>
          Exclude
        </Checkbox>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: theme.colors.primaryColors.gxAccent,
            },
          }}
        >
          <DateFnsDatePicker.RangePicker
            value={startDate && endDate ? [startDate, endDate] : null}
            locale={locale}
            onChange={onChange}
          />
        </ConfigProvider>
      </Flex>
    </Card>
  )
}
