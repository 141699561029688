import { DatasourceTypeV2 } from "src/api/graphql/graphql.ts"
import Databricks from "src/global/assets/images/databricks.png"
import Pandas from "src/global/assets/images/pandas.png"
import Postgres from "src/global/assets/images/postgresql.png"
import Snowflake from "src/global/assets/images/snowflake.svg?url"
import Spark from "src/global/assets/images/spark.png"
import SQL from "src/global/assets/images/sql.svg?url"
import SQLite from "src/global/assets/images/sqlite.png"

type DataSourceImageLogo = DatasourceTypeV2

const images: Record<DataSourceImageLogo, string> = {
  DATABRICKS_SQL: Databricks,
  SPARK: Spark,
  PANDAS: Pandas,
  POSTGRES: Postgres,
  SQL: SQL,
  SQLITE: SQLite,
  SNOWFLAKE: Snowflake,
}

export function getImageLogo(name: DataSourceImageLogo): string {
  return images[name]
}
