// CSS & Styles
import styled from "styled-components"
import { Input } from "antd"

const StyledTokenSection = styled.div`
  background: ${({ theme }) => `${theme.colors.neutralColorPalette.whites.white}`};
  margin-bottom: 20px;
`

const StyledDataPlaneContents = styled.div`
  ${({ theme }) => `
    padding: ${theme.spacing.xs};
  `}
`

const StyledPathRow = styled.div`
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
`

const StyledPath = styled(Input)`
  width: 100%;
  flex: 1;
`

const StyledModalContents = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`

const StyledMessage = styled.span`
  font-size: 14px;
  font-weight: 600;
`

export { StyledTokenSection, StyledDataPlaneContents, StyledPathRow, StyledPath, StyledModalContents, StyledMessage }
