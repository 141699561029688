import { isEmpty } from "lodash-es"
import { JobErrorCodeEnum, translateJobErrorToDescription } from "src/pages/AppLogs/components/JobErrorDescriptions.ts"
import { mapJobtoUnionType } from "src/pages/AppLogs/components/JobUnion.ts"
import { JobWithTableNamesFragment } from "src/api/graphql/graphql.ts"
import { useJobByID } from "src/global/hooks/useJobByID.ts"
import { AlertProps } from "antd"

type TestConnectionMutationErrorProps = Partial<AlertProps>

type Props = {
  jobId: string | undefined
  onError: ({ description, message }: TestConnectionMutationErrorProps) => void
  onComplete: (job: Pick<JobWithTableNamesFragment, "tableNames">) => void
}

export function useAssetCreationJobStatus({ jobId, onError, onComplete }: Props) {
  // todo: refactor useJobID to return the union type instead
  const jobData = useJobByID(jobId)
  const jobDataStatus = jobData?.status
  if (!jobData || isEmpty(jobData)) {
    return
  }

  const job = mapJobtoUnionType(jobData)
  let descriptionShown = job?.errorMessage
  // When removing FF, just remove first condition
  // When completing taxonomy V1, remove the GENERIC_UNHANDLED_ERROR condition
  //   bc when theres no helpful taxonomy info, the stack trace is only actionable info
  if (
    job?.jobError &&
    job?.jobError.errorCode &&
    job?.jobError.errorCode !== JobErrorCodeEnum.GENERIC_UNHANDLED_ERROR
  ) {
    const translatedErrorMessage = translateJobErrorToDescription(job.jobError.errorCode)
    if (translatedErrorMessage) {
      descriptionShown = translatedErrorMessage
    }
  }

  const isTestConnectionJob = job?.jobType === "CreateTestDatasourceJob"
  const isListTableNamesJob = job?.jobType === "CreateListTableNamesJob"

  switch (jobDataStatus) {
    case "complete":
      if (isTestConnectionJob || isListTableNamesJob) {
        if (job.tableNames?.length && job.tableNames.length > 0) {
          return onComplete({ tableNames: job.tableNames })
        }
        if (job.tableNames?.length === 0) {
          return onError({
            message: "No tables were visible in your schema.",
            description:
              "Check that the database and schema are spelled correctly, and that your user, role, and warehouse have permissions to view tables.",
            type: "warning",
          })
        }
      }
      return onComplete({}) // gross to return an empty object, but the union between feature-flagged fragments will be shortlived

    case "error":
      return onError({ description: descriptionShown ?? "An unknown error occurred" })
  }
}
