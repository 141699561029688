import { getRenderer, ExpectationContainer } from "src/pages/DataAssets/views/Expectations/Expectation/utils.tsx"
import { StyledPreviewSection } from "src/pages/DataAssets/views/Expectations/Expectation/Preview.styles.tsx"
import { ExpectationRenderer } from "src/pages/DataAssets/views/Expectations/Expectation/ExpectationRenderer.tsx"
import { useMemo } from "react"

export function Preview({ renderer, success }: { renderer: ExpectationRenderer; success?: boolean }) {
  const previewRenderer = useMemo(() => ({ ...renderer, isPreview: true }), [renderer])
  return (
    <StyledPreviewSection>
      <ExpectationContainer success={success}>
        <div>{getRenderer(previewRenderer)}</div>
      </ExpectationContainer>
    </StyledPreviewSection>
  )
}
