import { Tag } from "antd"
import { kebabCase, orderBy, snakeCase } from "lodash-es"
import { ExpectationJsonSchema } from "src/pages/DataAssets/views/Expectations/Expectation/uiForms/ExpectationConfigForm.tsx"
import { ExpectationCardProps } from "src/pages/DataAssets/views/Expectations/Expectation/CreateExpectationDrawer/types.ts"
import {
  CamelCasedExpectationType,
  getExpectationMetaInfoFromType,
} from "src/global/schemas/expectation-metadata-utils.ts"
import { SupportedExpectationCards } from "src/pages/DataAssets/views/Expectations/Expectation/utils/supported-expectation-cards.ts"
import { DataQualityIssue } from "src/global/schemas/expectation-metadata-data-quality-issues.ts"

export function getPathForCreatedExpectation(
  assetId: string,
  selectedExpectationSuiteId: string,
  config: string,
  jsonSchema?: ExpectationJsonSchema,
) {
  if (jsonSchema) {
    return getPathForExpectation(assetId, selectedExpectationSuiteId, config, jsonSchema)
  }
  return deprecatedGetPathForExpectation(assetId, selectedExpectationSuiteId, config)
}

function getPathForExpectation(
  assetId: string,
  selectedExpectationSuiteId: string,
  config: string,
  jsonSchema?: ExpectationJsonSchema,
) {
  const parsedConfigValue = JSON.parse(config)
  const domainType = jsonSchema?.properties.metadata.properties.domain_type.const
  let anchorTag
  if (parsedConfigValue.expectation_type === "unexpected_rows_expectation") {
    anchorTag = "#Custom-SQL-Expectations"
  } else if (domainType === "multicolumn") {
    const columnList = parsedConfigValue.column_list.sort()
    const encodedColumnList = columnList.map((column: string) => encodeURIComponent(column))
    anchorTag = `#${encodedColumnList.join("--")}`
  } else if (domainType === "column_pair") {
    const columnA = parsedConfigValue.column_A
    const columnB = parsedConfigValue.column_B
    const columnList = [columnA, columnB].sort()
    const encodedColumnList = columnList.map((column: string) => encodeURIComponent(column))
    anchorTag = `#${encodedColumnList.join("--")}`
  } else if (domainType === "table") {
    anchorTag = "#Table-level-Expectations"
  } else if (domainType === "column") {
    anchorTag = `#${encodeURIComponent(parsedConfigValue.column)}`
  } else {
    anchorTag = ""
  }
  return `/data-assets/${encodeURIComponent(assetId)}/expectations/expectation-suites/${encodeURIComponent(
    selectedExpectationSuiteId,
  )}${anchorTag}`
}

function deprecatedGetPathForExpectation(assetId: string, selectedExpectationSuiteId: string, config: string) {
  const parsedConfigValue = JSON.parse(config)
  let columnName: string
  const column = parsedConfigValue.kwargs.column
  if (column !== undefined) {
    columnName = `#${encodeURIComponent(column)}`
  } else {
    columnName = ""
  }
  return `/data-assets/${encodeURIComponent(assetId)}/expectations/expectation-suites/${encodeURIComponent(
    selectedExpectationSuiteId,
  )}${columnName}`
}

export function schemaToCardData(schemaName: CamelCasedExpectationType) {
  const metaInfo = getExpectationMetaInfoFromType(schemaName)
  return {
    value: snakeCase(schemaName),
    title: metaInfo.title,
    dataQualityIssues: metaInfo.dataQualityIssues,
    meta: {
      description: metaInfo.dataQualityIssues?.map((dqi) => <Tag key={kebabCase(dqi)}>{dqi}</Tag>),
    },
    hoverable: true,
  }
}
export function getFilteredExpectationsCardsData(
  searchInput?: string,
  selectedCategoryFilter?: DataQualityIssue,
): ExpectationCardProps[] {
  return orderBy(
    SupportedExpectationCards.map((key) => schemaToCardData(key as CamelCasedExpectationType))
      .filter((cardData) =>
        selectedCategoryFilter ? cardData.dataQualityIssues?.includes(selectedCategoryFilter) : true,
      )
      .filter((cardData) => cardData.title.toUpperCase().includes(searchInput ? searchInput.trim().toUpperCase() : "")),
    "value",
    "asc",
  )
}

function fakerStringAlpha(length: number): string {
  let result = ""
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz"
  let counter = 0
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * characters.length))
    counter += 1
  }
  return result
}

/**
 * Expectation Parameters are evaluated in a batched operation,
 * which means that two expectations with dynamic parameters
 * on the same column could potentially face a namespace collision.
 * This function ensures we have acceptable segregation between
 * Expectation Parameters and their intended expectation configuration.
 *
 * Expectation Parameters also have a fairly opinionated parser,
 * in that the presence of numbers, or of characters that could
 * be interpreted as mathematical operators: +, -, *, /
 * will cause it to attempt to parse the string as an
 * expression. If we limit the parameter "salt" to just
 * a-zA-Z, then we can avoid that pitfall while still
 * retaining acceptable uniqueness between parameter names.
 *
 * @returns {string} a 16-char string of a-zA-Z
 */
export const getParameterSafeUniqueId = (): string => {
  return fakerStringAlpha(16)
}
