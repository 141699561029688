import { Flex, Card, Typography } from "antd"
import { ReactNode } from "react"
import { useAnalytics } from "src/global/hooks/analytics/useAnalytics"
import { Icon } from "src/global/ui/Icon"
import { theme } from "src/global/ui/themes/theme"
import Tooltip from "src/global/ui/Tooltip/Tooltip"

const { Text } = Typography

type StatDisplaySetProps = {
  title: string
  info: string
  children: ReactNode
}

const cardStyles = {
  body: {
    borderTop: "none",
    paddingLeft: theme.spacing.s,
    paddingRight: theme.spacing.s,
    paddingTop: theme.spacing.xxs,
    paddingBottom: theme.spacing.xs,
    border: `1px solid ${theme.colors.neutralColorPalette.backgroundsAndBorders.gxBorder}`,
  },
  header: {
    borderBottom: "none",
    backgroundColor: theme.colors.neutralColorPalette.backgroundsAndBorders.gxBgSecondary,
    border: `1px solid ${theme.colors.neutralColorPalette.backgroundsAndBorders.gxBorder}`,
    paddingLeft: theme.spacing.s,
  },
}

export function StatDisplaySet({ title, info, children }: StatDisplaySetProps) {
  const posthog = useAnalytics()
  const onHover = () => {
    posthog?.capture("metric_coverage_stats_tooltip.hover", {
      title,
    })
  }
  return (
    <Card
      size="small"
      styles={cardStyles}
      style={{ width: "100%" }}
      title={
        <Flex gap={theme.spacing.xxs} align="center">
          <Text strong>{title}</Text>
          <Tooltip onOpenChange={onHover} title={info}>
            <Icon
              name="infoCircle"
              size="16px"
              lineHeight="0"
              color={theme.colors.neutralColorPalette.blacks.colorTextQuaternary}
            />
          </Tooltip>
        </Flex>
      }
    >
      <Flex gap={theme.spacing.l}>{children}</Flex>
    </Card>
  )
}
