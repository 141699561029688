import { graphql } from "src/api/graphql/gql.ts"
import { RunValidationButtonUI } from "src/pages/DataAssets/views/Expectations/RunValidationButton/RunValidationButtonUI.tsx"
import { useRequireRole } from "src/global/hooks/useRequireRole.ts"
import { useApolloClient, useMutation, useQuery } from "@apollo/client"
import { SplitterOptionsInput } from "src/api/graphql/graphql.ts"
import {
  ExpectationsTab_GetExpectationsDocument,
  AssetCoverageStatsDocument,
} from "src/pages/DataAssets/views/Expectations/SimpleExpectationsTab.tsx"
import { useCallback } from "react"
import { AggregateCoverageStatsDocument } from "src/pages/DataAssets/components/AggregateAssetCoverageStats"

interface RunValidationButtonProps {
  // assetID is the ID of the asset to validate
  assetID: string
  hasGXManagedExpectations: boolean
}

export const CreateRunGXManagedCheckpointJobDocument = graphql(`
  mutation CreateRunGXManagedCheckpointJob($input: CreateRunGxManagedCheckpointJobInput!) {
    createRunGxManagedCheckpointJob(input: $input) {
      jobId
    }
  }
`)

export const RunValidationButtonJobsFragmentDocument = graphql(`
  fragment RunValidationButtonJobsFragment on Query {
    jobs {
      id
      status
      jobType
      sourceResources {
        entityId
        entityType
      }
      jobError {
        errorStackTrace
      }
    }
  }
`)

export const RunValidationButtonAssetRefFragmentDocument = graphql(`
  fragment RunValidationButtonFragment on AssetRef {
    id
    checkpoints {
      id
    }
    splitter {
      ...BatchDefinitionDescription_Splitter
    }
  }
`)

export const RunValidationButtonJobsDocument = graphql(`
  query RunValidationButtonJobs {
    ...RunValidationButtonJobsFragment
  }
`)

export const RunValidationButtonDocument = graphql(`
  query RunValidationButton($assetId: UUID!) {
    dataAsset(id: $assetId) {
      ...RunValidationButtonFragment
    }
  }
`)

export function RunValidationButton(props: RunValidationButtonProps) {
  const { assetID, hasGXManagedExpectations } = props
  const apollo = useApolloClient()
  const isEditor = useRequireRole("EDITOR")
  const skipQueries = !assetID
  const { data: assetData, loading: queryLoading } = useQuery(RunValidationButtonDocument, {
    variables: { assetId: assetID },
    skip: skipQueries,
  })

  const { data: jobData } = useQuery(RunValidationButtonJobsDocument, {
    pollInterval: 1000,
    skip: skipQueries,
  })

  const [createJob, { loading: mutationLoading }] = useMutation(CreateRunGXManagedCheckpointJobDocument)

  const handleClick = async (splitterOptions?: SplitterOptionsInput) => {
    await createJob({ variables: { input: { dataAssetId: assetID, splitterOptions } } })
  }

  // useCallback allows this function to be used as a dependency in useEffect
  // thereby preventing an infinite render loop
  const onJobComplete = useCallback(() => {
    // Forcing refetch expectations here to update the validation
    // We can do better if job will return the updated validation result
    apollo.refetchQueries({
      include: [ExpectationsTab_GetExpectationsDocument, AggregateCoverageStatsDocument, AssetCoverageStatsDocument],
    })
    apollo.reFetchObservableQueries()
  }, [apollo])

  return (
    <RunValidationButtonUI
      loading={mutationLoading}
      disabled={queryLoading || !assetID || !hasGXManagedExpectations}
      onClick={handleClick}
      onJobComplete={onJobComplete}
      assetData={assetData?.dataAsset}
      jobData={jobData}
      isEditor={isEditor}
    />
  )
}
