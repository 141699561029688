import { css } from "styled-components"
import { Theme } from "src/global/ui/themes/theme.ts"

export const menuCSSID = "gx-main-menu"
export const submenuPopupClassName = "gx-main-menu-submenu"

export function getMenuCSS(theme: Theme) {
  return css`
    #${menuCSSID} {
      aside {
        background-color: ${theme.colors.primaryColors.gxPrimaryDark};
      }

      .ant-menu-item {
        &.ant-menu-item-selected {
          border-right: 3px solid ${theme.colors.primaryColors.gxAccentMedium};

          .ant-menu-item-icon {
            color: ${theme.colors.primaryColors.gxAccentMedium};
          }
        }
      }

      .ant-menu-sub {
        padding-top: ${theme.spacing.xs};
      }

      .ant-layout-sider-trigger {
        text-align: left;
        padding-left: ${theme.spacing.s};
        color: ${theme.colors.primaryColors.gxMenuToggleIcon};

        &:hover {
          color: ${theme.colors.primaryColors.gxAccentMedium};
        }
      }

      .ant-layout-sider {
        overflow: auto;
        height: 100vh;
        position: sticky;
        top: 0;
        left: 0;

        > .ant-layout-sider-children {
          height: unset;
        }
      }
    }

    /* popup submenu and tooltips are rendered as portals (different html scope) */
    .${submenuPopupClassName} {
      && [role="menu"] {
        background-color: ${theme.colors.primaryColors.gxPrimaryDark};
      }
    }
  `
}
