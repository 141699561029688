import { useEffect } from "react"
import { LoadingState } from "src/global/ui/LoadingState"
import { useCurrentUser } from "src/global/hooks/useCurrentUser.ts"
import { useNavigate } from "react-router-dom"
import { graphql } from "src/api/graphql/gql.ts"
import { useQuery, useReactiveVar } from "@apollo/client"
import { currentOrgIdVar } from "src/pages/OrganizationRoot"

export const OrganizationDocument = graphql(`
  query Organization_DefaultRedirect($id: ID!) {
    organization(id: $id) {
      id
      slug
      onboardingSurveySeen
    }
  }
`)

export const DefaultRedirect = () => {
  const navigate = useNavigate()
  const { isAuthenticated, isLoading: authLoading } = useCurrentUser()

  const currentOrgId = useReactiveVar(currentOrgIdVar)
  const { data, loading: isLoadingQueryOrganization } = useQuery(OrganizationDocument, {
    variables: { id: currentOrgId },
    skip: !currentOrgId,
  })
  const organization = data?.organization

  const isLoading = authLoading || (isAuthenticated && isLoadingQueryOrganization)

  useEffect(() => {
    function getRedirectRoute() {
      if (!organization?.slug) {
        return navigate("/")
      }

      const showSurvey = !organization?.onboardingSurveySeen
      if (showSurvey) {
        return navigate(`/organizations/${organization.slug}/welcome`)
      }

      return navigate(`/organizations/${organization.slug}/data-assets`)
    }

    if (!isLoading && isAuthenticated) {
      getRedirectRoute()
    }
  }, [isAuthenticated, isLoading, organization?.slug, organization?.onboardingSurveySeen, navigate])

  return <LoadingState loading={isLoading} />
}
