import { CheckCircleFilled } from "@ant-design/icons"
import { Button, Flex } from "antd"
import { AgentSetupMockModal } from "src/pages/AgentSetup/components/AgentSetupMockModal.tsx"
import { useAnalytics } from "src/global/hooks/analytics/useAnalytics.ts"
import { useOrganizationSlug } from "src/pages/OrganizationRoot/hooks/useOrganizationSlug.ts"
import { useOrganizations } from "src/pages/OrganizationRoot/hooks/useOrganizations.ts"
import { Heading1 } from "src/global/ui/typography/Text/Text.tsx"
import { useTheme } from "styled-components"

export type AgentSetupCompleteReason = "started_agent" | "created_data_source"

interface AgentSetupCompleteProps {
  reason: AgentSetupCompleteReason
}

export function AgentSetupComplete({ reason }: AgentSetupCompleteProps) {
  const { navigateInOrg } = useOrganizationSlug()
  const currentOrg = useOrganizations().currentOrg
  const theme = useTheme()
  const analytics = useAnalytics()
  const DATA_ASSETS_URL = `/data-assets`

  function onClick() {
    analytics?.capture("agent_setup.complete", { reason: reason, organization_name: currentOrg?.name })
    navigateInOrg(DATA_ASSETS_URL)
  }

  const modalContent = (
    <Flex
      align="center"
      justify="center"
      vertical
      wrap="wrap"
      style={{ padding: `${theme.spacing.xl} ${theme.spacing.m}`, width: "600px" }}
      gap={theme.spacing.m}
    >
      <Flex vertical align="center" justify="center" gap={theme.spacing.xxs}>
        <CheckCircleFilled style={{ fontSize: "24px", color: theme.colors.success.gxSuccess }} />
        <Flex vertical align="center" justify="center">
          <Heading1>Great job!</Heading1>
          <Heading1>You&apos;ve successfully set up GX Cloud</Heading1>
        </Flex>
      </Flex>
      <Button type="primary" size="large" onClick={onClick}>
        Start using GX Cloud
      </Button>
    </Flex>
  )

  return <AgentSetupMockModal content={modalContent} />
}
