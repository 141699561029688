import { List, Alert } from "antd"
import styled from "styled-components"

export const StyledListItem = styled(List.Item)`
  padding: ${({ theme }) => theme.spacing.m};
  background-color: ${({ theme }) => theme.colors.neutralColorPalette.whites.white};
`

export const StyledAppAlert = styled(Alert)<{ $toggle: boolean }>`
  display: ${({ $toggle }) => ($toggle ? "block" : "none")};
  margin: ${({ theme }) => `${theme.spacing.xs} 0`};
`

export const Inline = styled.div`
  display: flex;
  flex-direction: row;
`
