// 'address' means the information required to address a schema object (like a table) excluding the schema object's name
// e.g. in Snowflake, you need a database and a schema to address a table

import { DatasourceTypeV2 } from "src/api/graphql/graphql.ts"
import { parseJSONStringConfigDEPRECATED } from "src/pages/DataAssets/views/AssetDetails/utils.tsx"
import { SupportedDataSourceJson } from "src/pages/DataAssets/schemas/data-source-schemas.ts"

// e.g. in Postgres, you only need a schema
export function getAddressFromDataSource(dataSource: SupportedDataSourceJson) {
  switch (dataSource.type) {
    case "snowflake":
      if (typeof dataSource.connection_string === "string") {
        const { database, schema } =
          getSchemaAndDatabaseFromSnowflakeConnectionString(dataSource.connection_string) ?? {}

        return database && schema ? `${database} / ${schema}` : dataSource.name
      }
      return `${dataSource.connection_string?.database} / ${dataSource.connection_string?.schema}`
    case "databricks_sql":
      if (typeof dataSource.connection_string === "string") {
        const { database, schema } =
          getSchemaAndCatalogAsDatabaseFromDatabricksSQLConnectionString(dataSource.connection_string) ?? {}

        return database && schema ? `${database} / ${schema}` : dataSource.name
      }
      break
    case "postgres":
      if (typeof dataSource.connection_string === "string") {
        const { database } = getDatabaseFromPostgresConnectionString(dataSource.connection_string) ?? {}

        return database ?? dataSource.name
      }
  }
}

export function getAddressFromConfig(dataSourceName: string, config?: string, dataSourceType?: DatasourceTypeV2) {
  if (!config || !dataSourceType) {
    return dataSourceName
  }

  const parsedConfig = parseJSONStringConfigDEPRECATED(config)

  switch (dataSourceType) {
    case "SNOWFLAKE":
      if (typeof parsedConfig.connection_string === "string") {
        const { database, schema } =
          getSchemaAndDatabaseFromSnowflakeConnectionString(parsedConfig.connection_string) ?? {}
        return database && schema ? `${database}.${schema}` : dataSourceName
      }

      return `${parsedConfig.connection_string?.database}.${parsedConfig.connection_string?.schema}`
    case "DATABRICKS_SQL":
      if (typeof parsedConfig.connection_string === "string") {
        const { database, schema } =
          getSchemaAndCatalogAsDatabaseFromDatabricksSQLConnectionString(parsedConfig.connection_string) ?? {}

        return database && schema ? `${database}.${schema}` : dataSourceName
      }
      break
    case "POSTGRES":
      if (typeof parsedConfig.connection_string === "string") {
        const { database } = getDatabaseFromPostgresConnectionString(parsedConfig.connection_string) ?? {}

        return database ?? dataSourceName
      }
  }
}

export function getDatabaseAndSchemaFromDataSource(dataSource: SupportedDataSourceJson): {
  database?: string
  schema?: string
} {
  switch (dataSource.type) {
    case "snowflake":
      if (typeof dataSource.connection_string === "string") {
        return getSchemaAndDatabaseFromSnowflakeConnectionString(dataSource.connection_string) ?? {}
      }
      return { database: dataSource.connection_string?.database, schema: dataSource.connection_string?.schema }
    case "postgres":
      if (typeof dataSource.connection_string === "string") {
        return getDatabaseFromPostgresConnectionString(dataSource.connection_string) ?? {}
      }
      return {}
    case "databricks_sql":
      if (typeof dataSource.connection_string === "string") {
        return getSchemaAndCatalogAsDatabaseFromDatabricksSQLConnectionString(dataSource.connection_string) ?? {}
      }
      return {}
    default:
      return {}
  }
}

export function getSchemaAndDatabaseFromSnowflakeConnectionString(connectionString: string) {
  try {
    const url = new URL(connectionString)
    if (!url.pathname) {
      return undefined
    }
    const [, database, schema] = url.pathname.split("/", 3)

    return {
      schema,
      database,
    }
  } catch (e) {
    console.error(e)
    return undefined
  }
}

export function getDatabaseFromPostgresConnectionString(connectionString: string) {
  try {
    const url = new URL(connectionString)
    if (!url.pathname) {
      return undefined
    }
    const [, database] = url.pathname.split("/", 3)

    return { database }
  } catch (e) {
    console.error(e)
    return undefined
  }
}

export function getSchemaAndCatalogAsDatabaseFromDatabricksSQLConnectionString(connectionString: string) {
  try {
    const url = new URL(connectionString)
    const params = url.searchParams
    const schema = params.get("schema")
    const catalog = params.get("catalog")

    return { database: catalog ?? undefined, schema: schema ?? undefined }
  } catch (e) {
    console.error(e)
    return undefined
  }
}
