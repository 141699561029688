import { AggregateSuiteValidationResult, ExpectationValidationResult } from "src/api/graphql/graphql.ts"
// eslint-disable-next-line no-restricted-imports -- deprecated file
import { AggregateSuiteValidationResultQuery } from "src/api/graphql/graphql-operations.ts"
import { getExpectationGroupName } from "src/global/utils/getExpectationGroupName.ts"
import { sortAscWithTableExpectationsFirst } from "src/global/utils/sortAscWithTableExpectationsFirst.ts"

export const getGroupNameAggregateValidationResults = (
  aggregateValidations: AggregateSuiteValidationResultQuery | undefined,
): [string, AggregateSuiteValidationResult[]][] => {
  const groupNameExpectationIdResultMap: Record<string, AggregateSuiteValidationResult[]> = {}
  aggregateValidations?.aggregateSuiteValidationResult.forEach((aggregateValidation) => {
    const most_recent_result = aggregateValidation.results[aggregateValidation.results.length - 1]
    const groupName = getExpectationGroupName(most_recent_result.expectationConfig)
    if (groupName in groupNameExpectationIdResultMap) {
      groupNameExpectationIdResultMap[groupName]?.push(aggregateValidation)
    } else {
      const listAggregateValidation = []
      listAggregateValidation.push(aggregateValidation)
      groupNameExpectationIdResultMap[groupName] = listAggregateValidation
    }
  })
  return Object.entries(groupNameExpectationIdResultMap)
}

const PLACEHOLDER_CONFIG = {
  __typename: "ExpectationConfiguration",
  expectationType: "placeholder",
  renderedContent: [],
} satisfies ExpectationValidationResult["expectationConfig"]

function isNotNull<T>(value: T | null): value is T {
  return value !== null
}

function toRunTimes(validation: AggregateSuiteValidationResult): (string | null)[] {
  return validation.results.map((eavr) => eavr.runTime || null)
}

function tableFirstAsc(
  a: [string, AggregateSuiteValidationResult[]],
  b: [string, AggregateSuiteValidationResult[]],
): number {
  return sortAscWithTableExpectationsFirst(a[0], b[0])
}

function createPlaceholderValidationResult(
  exampleValidation: ExpectationValidationResult,
  runTime: string,
): ExpectationValidationResult {
  const { expectationConfig, meta } = exampleValidation

  return {
    __typename: "ExpectationValidationResult",
    runTime,
    meta,
    expectationConfig: {
      ...(expectationConfig ?? {}),
      ...PLACEHOLDER_CONFIG,
    },
    renderedContent: [],
    result: "{}",
    success: null,
  } satisfies ExpectationValidationResult
}

function fillValidationGapsWithPlaceholders(
  validationResults: ExpectationValidationResult[],
  uniqueRunTimes: string[],
): ExpectationValidationResult[] {
  return uniqueRunTimes.map((rt) => {
    const result = validationResults.find((r) => r.runTime === rt)
    return result ?? createPlaceholderValidationResult(validationResults[0], rt)
  })
}

export function groupValidationResultsWithPlaceholderItems(
  aggregateValidations: AggregateSuiteValidationResultQuery | undefined,
): [string, AggregateSuiteValidationResult[]][] {
  if (!aggregateValidations) return []

  const uniqueRunTimes = Array.from(
    new Set(aggregateValidations.aggregateSuiteValidationResult.flatMap(toRunTimes).filter(isNotNull).sort().reverse()),
  )

  return getGroupNameAggregateValidationResults(aggregateValidations)
    .sort(tableFirstAsc)
    .map(([groupName, validationResults]) => {
      const validationResultsWithPlaceholders = validationResults.map((avr) => {
        return {
          ...avr,
          results: fillValidationGapsWithPlaceholders(avr.results, uniqueRunTimes),
        }
      })

      return [groupName, validationResultsWithPlaceholders]
    })
}
