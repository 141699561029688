import { vanillaCells } from "@jsonforms/vanilla-renderers"
import { JsonFormsCellRendererRegistryEntry, JsonFormsRendererRegistryEntry } from "@jsonforms/core"

import { CategorizationRegistryEntry } from "src/global/jsonforms/complex/CategorizationRenderer.tsx"
import { CategoryRegistryEntry } from "src/global/jsonforms/complex/CategoryControl.tsx"
import { UnknownCellRegistryEntry } from "src/global/jsonforms/unknown-renderer.tsx"
import { rendererRegistryEntries as _rendererRegistryEntries } from "@great-expectations/jsonforms-antd-renderers"

export const ossRendererRegistryEntries: JsonFormsRendererRegistryEntry[] = [
  ..._rendererRegistryEntries.slice(1),
  CategorizationRegistryEntry,
  CategoryRegistryEntry,
  ..._rendererRegistryEntries.slice(0, 1),
]

export const cellRegistryEntries: JsonFormsCellRendererRegistryEntry[] = [...vanillaCells, UnknownCellRegistryEntry]
