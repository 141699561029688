import { Select } from "antd"
import { SortAscendingOutlined, SortDescendingOutlined } from "@ant-design/icons"
import { DataAssetSortOption } from "src/pages/DataAssets/utils.ts"
import { ReactNode } from "react"
import { useTheme } from "styled-components"
import { AlignCenter } from "src/pages/DataAssets/DataAssets.styles.tsx"
import { sortFilterIconStyle } from "src/global/ui/Icon/Icon.tsx"

type Props = {
  order: DataAssetSortOption
  setOrder: (sortOption: DataAssetSortOption) => void
}

function DataAssetSort({ order, setOrder }: Props) {
  const theme = useTheme()

  const orderOptions: { name: string; value: DataAssetSortOption; icon: ReactNode }[] = [
    {
      name: "Data Asset name",
      value: "asset-name-asc",
      icon: <SortAscendingOutlined style={sortFilterIconStyle} />,
    },
    {
      name: "Data Asset name",
      value: "asset-name-desc",
      icon: <SortDescendingOutlined style={sortFilterIconStyle} />,
    },
    {
      name: "Data Source name",
      value: "data-source-asc",
      icon: <SortAscendingOutlined style={sortFilterIconStyle} />,
    },
    {
      name: "Data Source name",
      value: "data-source-desc",
      icon: <SortDescendingOutlined style={sortFilterIconStyle} />,
    },
    {
      name: "Last validation (newest)",
      value: "validation-time-desc",
      icon: <SortDescendingOutlined style={sortFilterIconStyle} />,
    },
    {
      name: "Last validation (oldest)",
      value: "validation-time-asc",
      icon: <SortAscendingOutlined style={sortFilterIconStyle} />,
    },
  ]

  return (
    <Select<DataAssetSortOption>
      popupMatchSelectWidth={false}
      value={order}
      onChange={(val) => setOrder(val)}
      aria-label="Sort data assets"
    >
      {orderOptions.map((opt, i) => (
        <Select.Option value={opt.value} key={i} aria-label={opt.name}>
          <AlignCenter>
            {opt.icon}
            <span style={{ marginRight: theme.spacing.xxs, width: "152px" }}>{opt.name}</span>
          </AlignCenter>
        </Select.Option>
      ))}
    </Select>
  )
}

export { DataAssetSort }
