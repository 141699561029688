import { GroupName } from "src/pages/DataAssets/views/Expectations/Expectation/words.ts"

export function sortAscWithTableExpectationsFirst(a: string, b: string) {
  if (a === GroupName.TABLE) {
    return -1
  }
  if (b === GroupName.TABLE) {
    return 1
  }
  return a.localeCompare(b)
}
