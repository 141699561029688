import { useLazyQuery, useQuery } from "@apollo/client"
import { graphql } from "src/api/graphql/gql.ts"

export const GetAgentStatusDocument = graphql(`
  query GetAgentStatus {
    agentStatus {
      __typename
      active
    }
  }
`)

export function useLazyAgentStatus() {
  // Returns a function that can be called to execute the query to check the agent status along with a loading state of the query.
  const [loadAgentStatus, { loading }] = useLazyQuery(GetAgentStatusDocument, {
    fetchPolicy: "network-only", // Force to fetch from server, cache result to use in sidebar updates etc.
  })
  return { executeFunction: loadAgentStatus, loading: loading }
}

export function useAgentStatus() {
  const { data } = useQuery(GetAgentStatusDocument, {
    fetchPolicy: "cache-first", // default but including just to be explicit
  })
  const isAgentActive = data?.agentStatus.active
  return isAgentActive
}
