import { Col, Flex, Row, Space, Card as AntDCard } from "antd"

import { Icon, IconTypes } from "src/global/ui/Icon/Icon.tsx"
import { BodyNormal, Heading3 } from "src/global/ui/typography/Text/Text.tsx"
import styled, { useTheme } from "styled-components"
import { Image } from "src/global/ui/Image"
import { DataQualityIssue } from "src/global/schemas/expectation-metadata-data-quality-issues.ts"
import {
  DataQualityIssueDefaultExpectationMap,
  selectedExpectationFromSchema,
  useDataQualityFilteredExpectationOptions,
} from "src/global/hooks/useDataQualityFilteredExpectationOptions.ts"
import { SelectedExpectation } from "src/pages/DataAssets/views/Expectations/Expectation/CreateExpectationDrawer/types.ts"
import { useAnalytics } from "src/global/hooks/analytics/useAnalytics.ts"
import { useCallback, useMemo } from "react"

const Card = styled(AntDCard)`
  border: 1px solid ${({ theme }) => theme.colors.neutralColorPalette.backgroundsAndBorders.gxBorder};
`

const svgStyle = (color: string) => ({
  fill: color,
  height: "32px",
  width: "32px",
})

type Props = {
  onSelectExpectation: (selectedExpectation: SelectedExpectation) => void
}

const dataQualityIssuesDescription: {
  description: string
  dataQualityIssues: DataQualityIssue[]
}[] = [
  {
    description: "Expectations across an entire asset or batch",
    dataQualityIssues: ["Volume", "Schema"],
  },
  {
    description: "Expectations within one or more specific columns",
    dataQualityIssues: ["Completeness", "Uniqueness", "Numeric", "Validity"],
  },
  {
    description: "Custom Expectation",
    dataQualityIssues: ["SQL"],
  },
]

type DataQualityIssuesWithDescriptions = {
  description: string
  dataQualityIssues: {
    name: string
    description: string
    icon: IconTypes | JSX.Element
    firstExpectation?: string
  }[]
}[]

export const DataQualityIssuesCards = ({ onSelectExpectation }: Props) => {
  const { capture } = useAnalytics()
  const theme = useTheme()
  const { valueToSchema } = useDataQualityFilteredExpectationOptions()

  const dataQualityIssuesWithDescriptions = useMemo(
    () =>
      dataQualityIssuesDescription.reduce<DataQualityIssuesWithDescriptions>(
        (acc, { description, dataQualityIssues }) => {
          return [
            ...acc,
            {
              description,
              dataQualityIssues: dataQualityIssues.map((name) => {
                const firstExpectation =
                  name === "SQL"
                    ? "Custom Expectation with SQL"
                    : DataQualityIssueDefaultExpectationMap[name as DataQualityIssue]?.schema.title
                switch (name) {
                  case "Volume":
                    return {
                      name,
                      description: "Number of rows",
                      icon: "chartBar",
                      firstExpectation,
                    }
                  case "Schema":
                    return {
                      name,
                      description: "Column presence, types, and count",
                      icon: "fileNetwork",
                      firstExpectation,
                    }
                  case "Completeness":
                    return {
                      name,
                      description: "Null and non-null values",
                      icon: "documentInfo",
                      firstExpectation,
                    }
                  case "Uniqueness":
                    return {
                      name,
                      description: "Unique values and column cardinality",
                      icon: "keyholeCircle",
                      firstExpectation,
                    }
                  case "Numeric":
                    return {
                      name,
                      description: "Column value ranges and aggregates",
                      icon: <Image type="numeric" svgProps={svgStyle(theme.colors.primaryColors.gxAccentMedium)} />,
                      firstExpectation,
                    }
                  case "Validity":
                    return {
                      name,
                      description: "Value pattern matching",
                      icon: "fileCheckAlt",
                      firstExpectation,
                    }
                  case "SQL":
                    return {
                      name,
                      description: "Write your own SQL statement",
                      icon: <Image type="code" svgProps={svgStyle(theme.colors.primaryColors.gxAccentMedium)} />,
                      firstExpectation: "Custom Expectation with SQL",
                    }
                  default:
                    throw new Error(`Unexpected data quality issue name: ${name}`)
                }
              }),
            },
          ]
        },
        [],
      ),
    [theme.colors.primaryColors.gxAccentMedium],
  )

  const curryClickHandler = useCallback(
    (dataQualityIssue: (typeof dataQualityIssuesWithDescriptions)[number]["dataQualityIssues"][number]) => {
      return () => {
        onSelectExpectation(
          selectedExpectationFromSchema(
            valueToSchema[dataQualityIssue.firstExpectation as string],
            dataQualityIssue.name as DataQualityIssue,
          ),
        )
        capture("expectation_dqi.selected", {
          data_quality_issue: dataQualityIssue.name,
        })
      }
    },
    [capture, onSelectExpectation, valueToSchema],
  )

  return (
    <Flex vertical gap={42}>
      {dataQualityIssuesWithDescriptions.map((category) => {
        return (
          <>
            <Space direction="vertical" size="middle">
              <BodyNormal key={category.description}>{category.description}</BodyNormal>
              <Row gutter={[8, 8]}>
                {category.dataQualityIssues.map((dataQualityIssue) => {
                  return (
                    <Col key={dataQualityIssue.name} span={12}>
                      <Card hoverable onClick={curryClickHandler(dataQualityIssue)}>
                        <Flex gap={8}>
                          {typeof dataQualityIssue.icon === "string" ? (
                            <Icon
                              color={theme.colors.primaryColors.gxAccentMedium}
                              size="32px"
                              name={dataQualityIssue.icon}
                            />
                          ) : (
                            dataQualityIssue.icon
                          )}
                          <Flex vertical gap={8}>
                            <Heading3>{dataQualityIssue.name}</Heading3>
                            <BodyNormal>{dataQualityIssue.description}</BodyNormal>
                          </Flex>
                        </Flex>
                      </Card>
                    </Col>
                  )
                })}
              </Row>
            </Space>
          </>
        )
      })}
    </Flex>
  )
}
