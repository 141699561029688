import { useState } from "react"
import { Button } from "src/global/ui/Button/Button.tsx"
import { ManageDatasourcesDrawer } from "src/pages/DataAssets/drawers/ManageDataSources/ManageDatasourcesDrawer.tsx"

export function ManageDatasourcesButton() {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  return (
    <>
      <Button onClick={() => setIsDrawerOpen(true)}>Manage Data Sources</Button>
      <ManageDatasourcesDrawer open={isDrawerOpen} close={() => setIsDrawerOpen(false)} />
    </>
  )
}
