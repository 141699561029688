import { copyToClipboard } from "src/global/utils/clipboard.ts"
import { Button } from "src/global/ui/Button/Button.tsx"

export const ShareButton = () => {
  const url = window.location.href
  const copyUrlToClipboard = () => copyToClipboard(url, "Link copied")

  return (
    <Button icon="link" onClick={copyUrlToClipboard}>
      Share
    </Button>
  )
}
