import { Card, Checkbox, ConfigProvider, Flex, Radio, RadioChangeEvent } from "antd"
import { Button } from "src/global/ui/Button/Button"
import { theme } from "src/global/ui/themes/theme"
import Base from "antd/lib/typography/Base"
import { CheckboxValueType } from "antd/es/checkbox/Group"
import { DQIMetric, parseDQIFilterValue } from "src/pages/DataAssets/components/dataAssetsTableUtils"
import { useState } from "react"
import { isEqual } from "lodash-es"

export type FilterCoverageDropdownProps = {
  setSelectedKeys: (keys: string[]) => void
  selectedKeys: string[]
  confirm: () => void
}

type FilterState = {
  metric: DQIMetric
  selectedValues: string[]
}

const initialState: FilterState = {
  metric: "schema",
  selectedValues: [],
}

export const FilterCoverageDropdown = ({ setSelectedKeys, selectedKeys, confirm }: FilterCoverageDropdownProps) => {
  const initState = (): FilterState => {
    if (!selectedKeys.length) {
      return initialState
    }

    // If multiple DQIs are set, take the first one
    const result = parseDQIFilterValue(selectedKeys[0])
    if (!result) {
      return initialState
    }

    return {
      metric: result.metric,
      selectedValues: [result.covered ? "covered" : "notCovered"],
    }
  }

  /*
   * State management here is a bit tricky because
   *   * we don't represent 0 or both checkboxes checked external to this component
   *   * AntD doesn't unmount this when it closes
   * So we need to do the following:
   *   * Maintain internal state to prepresent the state of the checkboxes
   *   * Override the internal state if the external state (selectedKeys) changes
   */
  const [state, setState] = useState<FilterState>(initState)
  const stateAsSelectedKeys = toSelectedKeys(state)
  if (!isEqual(stateAsSelectedKeys, selectedKeys)) {
    setState(initState())
  }

  const handleMetricChange = (e: RadioChangeEvent) => {
    const metric = e.target.value
    setState((prev) => ({ ...prev, metric }))

    if (state.selectedValues.length === 1) {
      const covered = state.selectedValues[0] === "covered"
      setSelectedKeys([`${metric}:${covered}`])
    } else {
      setSelectedKeys([])
    }
  }

  const handleCoverageChange = (checkedValues: CheckboxValueType[]) => {
    setState((prev) => ({ ...prev, selectedValues: checkedValues as string[] }))
    if (checkedValues.length === 1) {
      const covered = checkedValues[0] === "covered"
      setSelectedKeys([`${state.metric}:${covered}`])
    } else {
      setSelectedKeys([])
    }
  }

  const handleReset = () => {
    setState(initialState)
    setSelectedKeys([])
  }

  return (
    <Card
      styles={{
        actions: {
          padding: `0 ${theme.spacing.xs}`,
        },
      }}
      actions={[
        <Flex key="actions" gap={theme.spacing.xxs} justify="space-between">
          <Button key="reset" type="text" disabled={isEqual(state, initialState)} onClick={handleReset}>
            Reset
          </Button>
          <Button key="filter" type="primary" onClick={() => confirm()}>
            Ok
          </Button>
        </Flex>,
      ]}
    >
      <Flex gap={theme.spacing.xxs} vertical style={{ width: 250 }}>
        <Base>Select coverage metric</Base>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: theme.colors.primaryColors.gxAccent,
            },
          }}
        >
          <Radio.Group value={state.metric} onChange={handleMetricChange} style={{ width: "100%" }}>
            <Flex vertical gap={theme.spacing.xxs}>
              <Radio value="schema">Schema</Radio>
              <Radio value="volume">Volume</Radio>
              <Radio value="completeness">Completeness</Radio>
            </Flex>
          </Radio.Group>

          <Base style={{ marginTop: theme.spacing.xs }}>Coverage Status</Base>

          <Checkbox.Group value={state.selectedValues} onChange={handleCoverageChange} style={{ width: "100%" }}>
            <Flex vertical gap={theme.spacing.xxs}>
              <Checkbox value="covered">Covered</Checkbox>
              <Checkbox value="notCovered">Not covered</Checkbox>
            </Flex>
          </Checkbox.Group>
        </ConfigProvider>
      </Flex>
    </Card>
  )
}

function toSelectedKeys(filterState: FilterState): string[] {
  const { metric, selectedValues } = filterState
  if (selectedValues.length === 1) {
    const covered = selectedValues[0] === "covered"
    return [`${metric}:${covered}`]
  }

  return []
}
