import { useState, useCallback } from "react"
import { AlertBanner } from "src/global/ui/Alert/AlertBanner.tsx"
import { Form, message, Space } from "antd"
import { Drawer } from "src/global/ui/Drawer/Drawer.tsx"
import { useMutation, useQuery } from "@apollo/client"
import { LoadingState } from "src/global/ui/LoadingState"
import { graphql } from "src/api/graphql/gql.ts"
import { useIsFeatureEnabled } from "src/global/hooks/useIsFeatureEnabled.ts"
import { ExpectationEditorSimplified } from "src/pages/DataAssets/views/Expectations/Expectation/ExpectationEditorSimplified.tsx"
import { ExpectationPickerSimplified } from "src/pages/DataAssets/views/Expectations/Expectation/CreateExpectationDrawer/ExpectationPickerSimplified.tsx"
import {
  SaveExpectationFooter,
  SelectExpectationFooter,
} from "src/pages/DataAssets/views/Expectations/Expectation/CreateExpectationDrawer/CreateExpectationDrawerFooters.tsx"
import { SelectedExpectation } from "src/pages/DataAssets/views/Expectations/Expectation/CreateExpectationDrawer/types.ts"
import {
  ExpectationMetaInfo,
  ExpectationTitle,
  SnakeCasedExpectationType,
} from "src/global/schemas/expectation-metadata-utils.ts"
import { handleWindowedPayload } from "src/pages/DataAssets/views/Expectations/Expectation/CreateExpectationDrawer/windowedExpectationUtils.ts"
import { handleRowConditionPayload } from "src/pages/DataAssets/views/Expectations/Expectation/uiForms/customRenderers/RowConditionControl/rowConditionParser.ts"
import {
  ExpectationsTab_GetExpectationsDocument,
  ExpectationsTabDataAssetDocument,
  AssetCoverageStatsDocument,
} from "src/pages/DataAssets/views/Expectations/SimpleExpectationsTab"
import { CREATE_EXPECTATION_SUCCESS } from "src/pages/DataAssets/views/Expectations/Expectation/CreateExpectationDrawer/words.ts"
import { MESSAGE_DURATION_SECONDS } from "src/global/config.ts"
import { CreateExpectationDrawerAlert } from "src/global/components/alerts/DemoData/CreateExpectationDrawerAlert.tsx"
import { useIsDemoData } from "src/global/hooks/useIsDemoData.ts"
import { theme } from "src/global/ui/themes/theme.ts"
import { useDemoDataAssetName } from "src/global/hooks/useDemoDataAssetName.ts"
import { DataQualityIssueBasedPicker } from "src/pages/DataAssets/views/Expectations/Expectation/CreateExpectationDrawer/DataQualityIssueBasedPicker/DataQualityIssueBasedPicker.tsx"
import { DataQualityExpectationSelect } from "src/pages/DataAssets/views/Expectations/DataQualityExpectationSelect.tsx"
import { useSelectExpectation } from "src/pages/DataAssets/views/Expectations/SimpleExpectationDrawer/useSelectExpectation.ts"
import { CreateExpectationFeedbackButton } from "src/pages/DataAssets/views/Expectations/Expectation/CreateExpectationDrawer/CreateExpectationFeedback.tsx"
import { AggregateCoverageStatsDocument } from "src/pages/DataAssets/components/AggregateAssetCoverageStats"

export const ExpectationDrawer_DataAssetDocument = graphql(`
  query expectationDrawer_DataAsset($id: UUID!) {
    dataAsset(id: $id) {
      id
      __typename
      ...ExpectationEditor_DataAssetMetricRun
      ...UseIsDemoData_DataAsset
      ...UseDemoDataAssetName_DataAsset
    }
  }
`)

const AddExpectationToAssetDocument = graphql(`
  mutation addExpectationToAsset($input: AddExpectationToDataAssetInput!) {
    addExpectationToDataAsset(input: $input) {
      expectation {
        geCloudId
      }
    }
  }
`)

type Props = {
  open: boolean
  close: () => void
  dataAssetId: string
}

export function SimpleNewExpectationDrawer({ open, dataAssetId, close }: Props) {
  const isDataQualityBasedSelectionEnabled = useIsFeatureEnabled("dataQualityBasedSelectionEnabled")

  const [drawerPage, setDrawerPage] = useState<DrawerPage>("1 Expectation Picker")

  const {
    expectationConfiguration,
    selectedExpectation,
    selectedDataQualityIssue,
    resetSelectionState,
    selectExpectation,
    handleConfigChange,
  } = useSelectExpectation()

  const [createExpectationMutation, { loading, error: addExpectationToAssetError, reset }] = useMutation(
    AddExpectationToAssetDocument,
    {
      refetchQueries: [
        { query: ExpectationsTab_GetExpectationsDocument, variables: { input: { dataAssetId } } },
        { query: ExpectationsTabDataAssetDocument, variables: { id: dataAssetId as string } },
        AssetCoverageStatsDocument,
        AggregateCoverageStatsDocument,
      ],
    },
  )

  const [form] = Form.useForm()

  const resetState = useCallback(() => {
    setDrawerPage("1 Expectation Picker")
    resetSelectionState()
    reset()
  }, [reset, resetSelectionState])

  const handleClose = useCallback(() => {
    close()
    resetState()
    resetSelectionState()
  }, [close, resetState, resetSelectionState])

  const saveExpectation = useCallback(
    async (addMore: boolean | undefined) => {
      try {
        await form.validateFields()

        if (!selectedExpectation?.value) return // compiler assurance

        await createExpectationMutation({
          variables: {
            input: {
              dataAssetId,
              expectationConfiguration: JSON.stringify(
                handleRowConditionPayload(
                  handleWindowedPayload(
                    {
                      ...expectationConfiguration,
                      expectation_type: selectedExpectation.value,
                    },
                    selectedExpectation.value as SnakeCasedExpectationType,
                  ),
                ),
              ),
            },
          },
        })

        message.success(CREATE_EXPECTATION_SUCCESS, MESSAGE_DURATION_SECONDS)
        addMore ? resetState() : handleClose()
      } catch {
        // nothing to do here; form validation will render error text as feedback
      }
    },
    [
      form,
      selectedExpectation?.value,
      createExpectationMutation,
      dataAssetId,
      expectationConfiguration,
      resetState,
      handleClose,
    ],
  )

  const { data } = useQuery(ExpectationDrawer_DataAssetDocument, {
    variables: {
      id: dataAssetId,
    },
    skip: !dataAssetId,
  })

  const onSelectExpectation = (expectation: SelectedExpectation) => {
    selectExpectation(expectation)
    setDrawerPage("2 Expectation Editor")
  }
  const isDemoData = useIsDemoData(data?.dataAsset)
  const assetName = useDemoDataAssetName(data?.dataAsset)
  const selectedDataQualityIssueMessage = selectedDataQualityIssue ? ` | ${selectedDataQualityIssue}` : ``

  const drawerPickerPage = isDataQualityBasedSelectionEnabled ? (
    <DataQualityIssueBasedPicker onSelectExpectation={onSelectExpectation} />
  ) : (
    <ExpectationPickerSimplified onSelectExpectation={onSelectExpectation} />
  )

  return (
    <Drawer
      title={`New Expectation${selectedDataQualityIssueMessage}`}
      placement="right"
      size="large"
      footer={
        <Footer
          drawerPage={drawerPage}
          onSave={saveExpectation}
          onBack={resetState}
          onSelectExpectation={onSelectExpectation}
          loading={loading}
        />
      }
      destroyOnClose
      open={open}
      onClose={handleClose}
      extra={<CreateExpectationFeedbackButton />}
    >
      {isDemoData && (
        <Space direction="vertical" style={{ marginBottom: theme.spacing.xs }}>
          <CreateExpectationDrawerAlert
            assetName={assetName}
            page={drawerPage === "1 Expectation Picker" ? "picker" : "editor"}
            selectedExpectationTitle={selectedExpectation?.title as ExpectationTitle}
          />
        </Space>
      )}
      {drawerPage === "1 Expectation Picker" && drawerPickerPage}
      {drawerPage === "2 Expectation Editor" && selectedExpectation?.title ? (
        <Form form={form} layout="vertical">
          {isDataQualityBasedSelectionEnabled && (
            <DataQualityExpectationSelect
              selectedDataQualityIssue={selectedDataQualityIssue}
              onSelectExpectation={onSelectExpectation}
              selectedExpectation={selectedExpectation}
            />
          )}
          <ExpectationEditorSimplified
            value={expectationConfiguration}
            expectationMetaInfo={{ ...selectedExpectation, type: selectedExpectation.value } as ExpectationMetaInfo}
            onChange={handleConfigChange}
            dataAsset={data?.dataAsset ?? undefined}
            showInfo={!isDataQualityBasedSelectionEnabled}
          />
          {addExpectationToAssetError && (
            <AlertBanner message="Failed to add Expectation" description={addExpectationToAssetError.message} />
          )}
        </Form>
      ) : (
        <LoadingState loading={loading} />
      )}
    </Drawer>
  )
}

type DrawerPage = "1 Expectation Picker" | "2 Expectation Editor"
type FooterProps = {
  drawerPage: DrawerPage
  onBack: () => void
  onSave: (addMore?: boolean) => void
  loading: boolean
  onSelectExpectation: (expectation: SelectedExpectation) => void
}
function Footer({ drawerPage, onBack, onSave, loading, onSelectExpectation }: FooterProps) {
  const isDataQualityBasedSelectionEnabled = useIsFeatureEnabled("dataQualityBasedSelectionEnabled")

  if (drawerPage === "1 Expectation Picker" && !isDataQualityBasedSelectionEnabled) {
    return <SelectExpectationFooter onSelectExpectation={onSelectExpectation} />
  }
  if (drawerPage === "2 Expectation Editor") {
    return (
      <SaveExpectationFooter
        onBack={onBack}
        onSave={onSave}
        onSaveAndAdd={() => {
          onSave(true)
        }}
        saveLoading={loading}
      />
    )
  }
  return null
}
