import { Flex, ModalFuncProps, Space } from "antd"
import { AppModalConfig } from "src/global/ui/AppModal/AppModal.tsx"
import { BodyNormal, Heading1 } from "src/global/ui/typography/Text/Text.tsx"
import { Helmet } from "react-helmet-async"
import { Emoji } from "src/global/ui/Image/Emoji.tsx"
import { useEffect } from "react"
import { AnalyticsEvent, useAnalytics } from "src/global/hooks/analytics/useAnalytics.ts"
import { CurrentUserContext, useCurrentUser } from "src/global/hooks/useCurrentUser.ts"
import { useTheme } from "styled-components"

type SalesDemoConfigInput = {
  title: string
  body: string[]
  emoji: { label: string; symbol: number }
  eventName: AnalyticsEvent
  postValidation: boolean
}
export const CONTACT_SALES_EMAIL = "info@greatexpectations.io"

/** Request a Demo modal */

export const REQUEST_SALES_DEMO_TEXT = {
  title: "Schedule a demo",
  body: [
    "See how GX Cloud can work for you with a peronalized demo.",
    "Our team will walk you through best practices and handy features to help you get the most out of it.",
  ],
  emoji: {
    label: "light bulb",
    symbol: 128161,
  },
  eventName: "request_sales_demo.modal_seen",
  postValidation: false,
} satisfies SalesDemoConfigInput

export const SalesDemoModalConfig = createModalConfig(REQUEST_SALES_DEMO_TEXT)

/** AHA Sales Demo modal */

export const AHA_SALES_DEMO_TEXT = {
  title: "Congrats on your first validation!",
  body: ["Talk to us to discover how to use GX Cloud at scale."],
  emoji: {
    label: "party popper",
    symbol: 127881,
  },
  eventName: "request_sales_demo.post_validation_modal_seen",
  postValidation: true,
} satisfies SalesDemoConfigInput

export const AhaSalesDemoModalConfig = createModalConfig(AHA_SALES_DEMO_TEXT)

/** Helper functions */

function createModalConfig(input: SalesDemoConfigInput): ModalFuncProps {
  return {
    ...AppModalConfig,
    width: 850,
    okButtonProps: { style: { display: "none" } },
    title: <Title title={input.title} emoji={input.emoji} eventName={input.eventName} />,
    content: <Content body={input.body} postValidation={input.postValidation} />,
  }
}

function Title({
  title,
  emoji,
  eventName,
}: Pick<SalesDemoConfigInput, "title" | "emoji"> & { eventName: AnalyticsEvent }) {
  const { capture } = useAnalytics()
  useEffect(() => {
    capture(eventName)
  }, [capture, eventName])
  const theme = useTheme()
  return (
    <Flex align="center" justify="center" style={{ paddingBottom: theme.spacing.xs }}>
      <Heading1>
        <Space direction="horizontal">
          <Emoji {...emoji} />
          {title}
        </Space>
      </Heading1>
    </Flex>
  )
}

export function Content({ body, postValidation }: Pick<SalesDemoConfigInput, "body" | "postValidation">) {
  const { user } = useCurrentUser()

  return (
    <Flex vertical justify="space-between">
      <Flex vertical justify="center" align="center">
        {body.map((text, index) => (
          <BodyNormal key={index}>{text}</BodyNormal>
        ))}
        <BodyNormal>
          Need help scheduling? Reach out to{" "}
          <a href={`mailto:${CONTACT_SALES_EMAIL}`} target="_blank" rel="noreferrer">
            {CONTACT_SALES_EMAIL}
          </a>
          .
        </BodyNormal>
      </Flex>
      <Helmet>
        <script type="text/javascript" src="https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js" />
      </Helmet>
      <Flex style={{ width: "800px", flex: 1 }}>
        <div
          style={{ flexGrow: 1, margin: 0, padding: 0 }}
          className="meetings-iframe-container"
          data-src={buildUserPopulatedUrl(user, postValidation)}
        />
      </Flex>
    </Flex>
  )
}

export const REQUEST_DEMO_LINK = new URL("https://meetings.hubspot.com/klamken/request-a-gx-demo?embed=true")

export const POST_VALIDATION_LINK = new URL("https://meetings.hubspot.com/klamken/contact-sales-gx-cloud?embed=true")

export function buildUserPopulatedUrl(user: CurrentUserContext["user"], postValidation = false): string {
  const url = new URL(postValidation ? POST_VALIDATION_LINK : REQUEST_DEMO_LINK)
  if (user?.email) {
    url.searchParams.append("email", user.email)
  }
  if (user?.given_name) {
    url.searchParams.append("firstName", user.given_name)
  }
  if (user?.family_name) {
    url.searchParams.append("lastName", user.family_name)
  }
  return url.toString()
}
