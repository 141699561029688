import { DatasourceTypeV2 } from "src/api/graphql/graphql.ts"
import { useIsFeatureEnabled } from "src/global/hooks/useIsFeatureEnabled.ts"
import { useRequireRole } from "src/global/hooks/useRequireRole.ts"
import { isAssetEditable } from "src/pages/DataAssets/schemas/data-asset-schemas.ts"
import { SupportedDataSource } from "src/pages/DataAssets/schemas/data-source-schemas.ts"
import { supportedDataSources } from "src/global/utils/supportedDataSources"

export function useIsAssetEditable(dataSourceType: DatasourceTypeV2, assetType: string) {
  const isUserEditorOrHigher = useRequireRole("EDITOR")
  const isSqlSupportEnabled = useIsFeatureEnabled("genericSqlDataSourceSupport")
  const isSqliteSupportEnabled = useIsFeatureEnabled("sqliteDataSourceSupport")

  if (!isUserEditorOrHigher) {
    return false
  }
  if (supportedDataSources.includes(dataSourceType)) {
    return true
  }
  if (dataSourceType === "SQL") {
    return isSqlSupportEnabled
  }
  if (dataSourceType === "SQLITE") {
    return isSqliteSupportEnabled
  }
  return isAssetEditable(dataSourceType as SupportedDataSource, assetType)
}
