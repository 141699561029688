import { StyledTokenActionsWrapper } from "src/pages/Tokens/components/TokensTable.styles.tsx"
import { useLayoutContext } from "src/global/layout/layoutContext.tsx"
// eslint-disable-next-line no-restricted-imports -- deprecated file
import {
  OrganizationApiTokensDocument,
  OrganizationApiTokensQuery,
  GetUserTokensDocument,
  GetUserTokensQuery,
  RevokeUserApiTokenDocument,
  RevokeOrganizationApiTokenDocument,
} from "src/api/graphql/graphql-operations.ts"
import { Button } from "src/global/ui/Button/Button.tsx"
import { AppModal } from "src/global/ui/AppModal/AppModal.tsx"
import { useState } from "react"
import { useMutation } from "@apollo/client"

interface RowActionsProps {
  title: string
  tokenId: string
  isDeletable: boolean
}

export const TokenRowActions = ({ tokenId, isDeletable, title }: RowActionsProps) => {
  const isUser = title.toLowerCase().includes("user")
  const [revokeUserApiTokenMutation] = useMutation(RevokeUserApiTokenDocument, {})
  const [revokeOrganizationApiTokenMutation] = useMutation(RevokeOrganizationApiTokenDocument, {})
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  const { setAlertConfig } = useLayoutContext()
  const onOkCallback = isUser
    ? async () => {
        await revokeUserApiTokenMutation({
          variables: {
            input: {
              id: tokenId,
            },
          },
          update: (cache, { data }) => {
            cache.updateQuery<GetUserTokensQuery>({ query: GetUserTokensDocument }, (queryData) => {
              return {
                ...queryData,
                __typename: "Query",
                allUserApiTokens: {
                  __typename: "UserApiTokenConnection",
                  edges: [
                    ...(queryData?.allUserApiTokens?.edges.filter((edge) => {
                      return edge?.node?.id !== data?.revokeUserApiToken?.id
                    }) ?? []),
                  ],
                },
              }
            })
          },
        })
          .then((response) => {
            if (response.errors) {
              setAlertConfig({
                message: `Error deleting token`,
                status: "error",
              })
              console.error(`Error deleting token:\n${response.errors}`)
            }
          })
          .catch((e: { message: string }) => {
            setAlertConfig({
              message: `Error deleting token`,
              status: "error",
            })
            console.error(`Error deleting token:\n${e.message}`)
          })
      }
    : async () => {
        await revokeOrganizationApiTokenMutation({
          variables: {
            input: {
              id: tokenId,
            },
          },
          update: (cache, { data }) => {
            cache.updateQuery<OrganizationApiTokensQuery>({ query: OrganizationApiTokensDocument }, (queryData) => {
              return {
                ...queryData,
                __typename: "Query",
                allOrganizationApiTokens: {
                  __typename: "OrganizationApiTokenConnection",
                  edges: [
                    ...(queryData?.allOrganizationApiTokens?.edges.filter((edge) => {
                      return edge?.node?.id !== data?.revokeOrganizationApiToken?.id
                    }) ?? []),
                  ],
                },
              }
            })
          },
        })
          .then((response) => {
            if (response.errors) {
              setAlertConfig({
                message: `Error deleting token`,
                status: "error",
              })
              console.error(`Error deleting token:\n${response.errors}`)
            }
          })
          .catch((e: { message: string }) => {
            setAlertConfig({
              message: `Error deleting token`,
              status: "error",
            })
            console.error(`Error deleting token:\n${e.message}`)
          })
      }

  return (
    <>
      <StyledTokenActionsWrapper>
        <Button
          aria-label="Delete"
          icon="trash"
          title="Delete Token"
          type="text"
          disabled={!isDeletable}
          onClick={() => setShowDeleteModal(true)}
        />
      </StyledTokenActionsWrapper>
      <AppModal
        open={showDeleteModal}
        title="Delete Token"
        width={550}
        okText="Delete"
        cancelText="Cancel"
        onCancel={() => setShowDeleteModal(false)}
        okButtonProps={{
          danger: true,
          type: "primary",
        }}
        onOk={async () => await onOkCallback()}
      >
        <>
          Are you sure you want to disable and delete this token? <br />
          This action can&apos;t be undone.
        </>
      </AppModal>
    </>
  )
}
