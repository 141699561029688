import { useQuery } from "@apollo/client"
// eslint-disable-next-line no-restricted-imports -- deprecated file
import { GetDataSourceIdsDocument } from "src/api/graphql/graphql-operations.ts"

export function useHasDataSources() {
  const { loading, data } = useQuery(GetDataSourceIdsDocument, {
    fetchPolicy: "cache-first", // default policy but including to be explicit
  })
  const hasDataSources = !!data?.datasourcesV2?.length
  return { loading, hasDataSources }
}
