import { Alert } from "antd"
import { Icon } from "src/global/ui/Icon"
import { BodyStrong } from "src/global/ui/typography/Text/Text.tsx"

export const MESSAGE = "Begin by creating an Expectation"

const DESCRIPTION = (
  <span>
    To create an Expectation, click
    {` `}
    <BodyStrong>
      <Icon name="plus" size="11px" />
      {` `}
      New Expectation
    </BodyStrong>
    .
  </span>
)

export function CreateExpectationsAlert() {
  return <Alert message={MESSAGE} description={DESCRIPTION} type="info" showIcon />
}
