import React from "react"
import { Radio, Row, Col, Form } from "antd"
import { StyledCol, StyledInput } from "src/global/ui/OnboardingSurvey/styles.tsx"

const jobTitles = [
  { value: "Data Engineer", label: "Data Engineer", span: 12 },
  { value: "Data Analyst", label: "Data Analyst", span: 12 },
  { value: "Data Scientist", label: "Data Scientist", span: 8 },
  { value: "Other", label: "Other", span: 6 },
]

interface JobTitleItemProps {
  value: string
  label: string
  span?: number
}

const JobTitleItem: React.FC<JobTitleItemProps> = ({ value, label, span = 12 }) => {
  const isSpecial = value === "Other" || value === "Data Scientist"
  const ColComponent = isSpecial ? StyledCol : Col
  const additionalProps = value === "Other" ? { style: { paddingLeft: "81px" } } : {}

  return (
    <ColComponent span={span} {...additionalProps}>
      <Radio value={value}>{label}</Radio>
    </ColComponent>
  )
}

interface JobTitleItemsProps {
  showOther: boolean
}

export const JobTitleItems: React.FC<JobTitleItemsProps> = ({ showOther }) => {
  return (
    <Row justify="start">
      {jobTitles.map(({ value, label, span }) => (
        <JobTitleItem key={value} value={value} label={label} span={span} />
      ))}
      {showOther && (
        <Col style={{ paddingLeft: "28px" }} span={8}>
          <Form.Item
            style={{ height: "0px" }}
            name="otherJobTitle"
            rules={[{ required: showOther, message: "Job title is required" }]}
          >
            <StyledInput autoComplete="off" aria-label="Job title input" />
          </Form.Item>
        </Col>
      )}
      <StyledCol span={12}>
        <Radio value="Data Architect">Data Architect</Radio>
      </StyledCol>
    </Row>
  )
}
