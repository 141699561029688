import { Breadcrumb } from "antd" // eslint-disable-line no-restricted-imports
import { BreadcrumbProps as AntDBreadcrumbProps, ItemType } from "antd/es/breadcrumb/Breadcrumb"
import { useLocation, useMatches } from "react-router-dom"
import { Handle } from "src/routes/useRoutes.tsx"
import { BreadcrumbTitle } from "src/global/ui/AppBreadcrumb/BreadcrumbTitle.tsx"
import styled, { css } from "styled-components"

export interface BreadcrumbProps extends Omit<AntDBreadcrumbProps, "items"> {
  // items overrides the breadcrumbs that would otherwise be computationally generated by default
  items?: ItemType[]
}

export type MatchAlias = ReturnType<typeof useMatches>[number]

interface Match extends MatchAlias {
  handle: Handle
}

function useBreadcrumbs(opts: { skip?: boolean }): ItemType[] {
  const matches = useMatches() as Match[]
  const loc = useLocation()
  const all = loc.pathname.split("/").filter(Boolean)

  if (opts.skip) {
    return []
  }

  if (all.length === 0) {
    return []
  }

  const slugPrefix = all[0] === "organizations" ? `/${all[0]}/${all[1]}` : ""
  const segments = slugPrefix ? all.slice(2) : all

  if (segments.length === 0) {
    return []
  }

  const result: ItemType[] = matches
    .filter((match) => Boolean(match.handle?.crumb))
    .map((match) => {
      const crumb =
        match.handle?.crumb?.({
          data: match.data,
          location: loc,
        }) ?? {}

      const href = match.pathname === loc.pathname ? undefined : match.pathname.replace(slugPrefix, "")

      return {
        title: <BreadcrumbTitle icon={crumb.icon} text={crumb.title} href={href} />,
      }
    })

  return result
}

const StyledBreadcrumb = styled(Breadcrumb)<{ $listStyle?: string; $marginInline?: string }>`
  ${({ $listStyle, $marginInline }) => css`
    ol {
      list-style: ${$listStyle ?? "none"};
      margin-bottom: 0;
    }

    li {
      margin-inline: ${$marginInline ?? "4px"} !important;
      padding-inline: 0 !important;
    }
  `}
`

interface AppBreadcrumbProps extends BreadcrumbProps {
  $listStyle?: string
  $marginInline?: string
}

// AppBreadcrumb is like AntD Breadcrumb, but it generates breadcrumbs based on the current URL
// unless specifically overridden by the items prop
function AppBreadcrumb(props: AppBreadcrumbProps) {
  const items = useBreadcrumbs({
    skip: Boolean(props.items),
  })

  return (
    <StyledBreadcrumb
      $listStyle={props.$listStyle}
      $marginInline={props.$marginInline}
      items={props.items ?? items}
      separator={<div />}
    />
  )
}
export { AppBreadcrumb }
