import { Alert, Space, message } from "antd"
import { useMutation } from "@apollo/client"

import { AppModal } from "src/global/ui/AppModal/AppModal.tsx"
import { Icon } from "src/global/ui/Icon"
// eslint-disable-next-line no-restricted-imports -- deprecated file
import { DatasourcesDocument, DatasourcesQuery, DeleteDatasourceDocument } from "src/api/graphql/graphql-operations.ts"
import { theme } from "src/global/ui/themes/theme.ts"
import { DatasourceV2 } from "src/api/graphql/graphql.ts"
import { MESSAGE_DURATION_SECONDS } from "src/global/config.ts"
import { Heading1, LG } from "src/global/ui/typography/Text/Text.tsx"

interface DeleteDatasourceModalProps {
  isVisible: boolean
  setIsVisible: (isVisible: boolean) => void
  datasource: Pick<DatasourceV2, "name" | "id">
}

export const DeleteDatasourceModal = ({ isVisible, setIsVisible, datasource }: DeleteDatasourceModalProps) => {
  const [deleteDatasourceMutation, { loading: mutationInProgress, error, reset: resetMutation }] = useMutation(
    DeleteDatasourceDocument,
    {},
  )

  const deleteDatasource = () => {
    deleteDatasourceMutation({
      variables: { id: datasource.id },
      onCompleted: () => {
        message.success(`Successfully deleted ${datasource.name} Data Source`, MESSAGE_DURATION_SECONDS)
        setIsVisible(false)
      },
      onError: () => {},
      update(cache) {
        cache.updateQuery(
          {
            query: DatasourcesDocument,
          },
          (data: DatasourcesQuery | null) =>
            data?.datasourcesV2 !== null && data?.datasourcesV2 !== undefined
              ? {
                  ...data,
                  datasources: data.datasourcesV2.filter((d) => d.id !== datasource.id),
                }
              : null,
        )
        cache.evict({ id: `DatasourceV2:${datasource.id}` })
      },
    })
  }
  const onReset = () => {
    setIsVisible(false)
    error && resetMutation()
  }
  return (
    <AppModal
      open={isVisible}
      title={
        <Space>
          <Icon size={theme.spacing.m} name="exclamationCircle" color={theme.colors.warning.colorWarning} />
          <Heading1>Confirm Deletion</Heading1>
        </Space>
      }
      width={550}
      okText="Delete"
      cancelText="Cancel"
      onCancel={onReset}
      okButtonProps={{
        danger: true,
        loading: mutationInProgress,
      }}
      onOk={() => {
        deleteDatasource()
      }}
    >
      <Space direction="vertical">
        {error && <Alert message="Error deleting Data Source" type="error" description={error.message} />}
        <LG>
          Delete Data Source <b>{datasource.name}</b>?
        </LG>
      </Space>
    </AppModal>
  )
}
