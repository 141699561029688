import { cloneDeep } from "lodash-es"
import { WindowedMinMaxExpectations } from "src/global"
import {
  ExpectationConfig,
  MaybeStrict,
  MostlyWindow,
  ToBeBetween,
  UIDefinedWindow,
  WindowConfig,
  WindowWithConstraint,
} from "src/pages/DataAssets/views/Expectations/Expectation/CreateExpectationDrawer/types.ts"
import { SnakeCasedExpectationType } from "src/global/schemas/expectation-metadata-utils.ts"
import { getParameterSafeUniqueId } from "src/pages/DataAssets/views/Expectations/Expectation/CreateExpectationDrawer/utils.tsx"
import { ParamTypes } from "src/pages/DataAssets/views/Expectations/Expectation/Expectation.tsx"
import {
  WindowedMinMaxExpectation,
  WindowedCompletenessExpectation,
  WindowedCompletenessExpectations,
} from "src/global/config.ts"

interface CreateWindowProps {
  config: WindowConfig
  minMax: string
  selectedExpectation: string
  unique_id: string
}

export const createWindow = ({
  config,
  minMax,
  selectedExpectation,
  unique_id: uuid,
}: CreateWindowProps): WindowWithConstraint => ({
  constraint_fn: "mean",
  parameter_name: `${uuid}_${selectedExpectation}_${minMax}`,
  range: config.range,
  offset: {
    positive: config.percent,
    negative: config.percent,
  },
  strict: "strict" in config ? config.strict : false,
})

export const handleMinMaxWindow = (
  config: ExpectationConfig<UIDefinedWindow>,
  toBeBetween: UIDefinedWindow,
  selectedExpectation: SnakeCasedExpectationType,
) => {
  config.min_value = config.max_value = null
  const windows: WindowWithConstraint[] = []
  const unique_id = getParameterSafeUniqueId()

  if (toBeBetween.offset.includes("-")) {
    windows.push(createWindow({ config: toBeBetween, minMax: "min", selectedExpectation, unique_id }))
    config.min_value = { $PARAMETER: `${unique_id}_${selectedExpectation}_min` }
    config.strict_min = toBeBetween.strict
  }
  if (toBeBetween.offset.includes("+")) {
    windows.push(createWindow({ config: toBeBetween, minMax: "max", selectedExpectation, unique_id }))
    config.max_value = { $PARAMETER: `${unique_id}_${selectedExpectation}_max` }
    config.strict_max = toBeBetween.strict
  }

  config.windows = windows

  return config
}

export const handleMostlyWindow = (
  config: ExpectationConfig<UIDefinedWindow>,
  toBeBetween: ToBeBetween<MostlyWindow>,
  selectedExpectation: SnakeCasedExpectationType,
) => {
  const opConfig: ExpectationConfig<UIDefinedWindow> = { ...config }
  const tbbConfig: MostlyWindow = { ...toBeBetween }
  if ("percent" in tbbConfig) {
    const id = getParameterSafeUniqueId()
    return {
      ...opConfig,
      mostly: { $PARAMETER: `${id}_${selectedExpectation}_min` },
      windows: [
        ...(tbbConfig.offset?.includes("-")
          ? [createWindow({ config: tbbConfig, minMax: "min", selectedExpectation, unique_id: id })]
          : []),
        ...(tbbConfig.offset.includes("+")
          ? [createWindow({ config: tbbConfig, minMax: "max", selectedExpectation, unique_id: id })]
          : []),
      ],
    }
  }

  delete opConfig.windows // captures when editing from windowed to fixed variant

  const fixedTBBConfig: ToBeBetween<MaybeStrict> = { ...(tbbConfig as object) }
  delete fixedTBBConfig.strict

  return { ...opConfig, ...(fixedTBBConfig as object) }
}

/**
 * If we send an empty string for min/max values, it's possible the backend
 * will interpret it as a parameter string and attempt to parse it as one.
 * To avoid this, `sanitizeMinMax` introspects both top-level and to_be_between
 * min/max values and replaces empty strings with `null`.
 * @param config ExpectationConfig
 * @returns sanitized ExpectationConfig
 */
export const sanitizeMinMax = (config: ExpectationConfig<UIDefinedWindow>) => {
  const sanitizeValue = (value: unknown) => {
    if (value === "") return null
    if (value === "0") return 0
    return value
  }

  const sanitizedConfig = {
    ...config,
    ...("min_value" in config && { min_value: sanitizeValue(config.min_value) }),
    ...("max_value" in config && { max_value: sanitizeValue(config.max_value) }),
    ...(config.to_be_between && {
      to_be_between: {
        ...config.to_be_between,
        ...("min_value" in config.to_be_between && { min_value: sanitizeValue(config.to_be_between.min_value) }),
        ...("max_value" in config.to_be_between && { max_value: sanitizeValue(config.to_be_between.max_value) }),
      },
    }),
  }

  return sanitizedConfig
}

export const handleWindowedPayload = (
  configObj: ExpectationConfig<UIDefinedWindow>,
  selectedExpectation: SnakeCasedExpectationType,
) => {
  const config = cloneDeep(configObj)
  if (!config.to_be_between) {
    // If a standard expectation, we need to make sure the windows prop does not exist
    return sanitizeMinMax(config)
  }

  const { to_be_between } = config
  delete config.to_be_between

  // all the scenarios that signal we're dealing with min/max
  if (!("mostly" in to_be_between) && !("mostly" in config) && "offset" in to_be_between && "range" in to_be_between) {
    return handleMinMaxWindow(config, to_be_between as UIDefinedWindow, selectedExpectation)
  }

  // all the scenarios that signal we're dealing with mostly
  if ("mostly" in to_be_between || "mostly" in config) {
    return handleMostlyWindow(config, to_be_between as MostlyWindow, selectedExpectation)
  }

  // If we get to this point and still have an "offset" or "strict" value, delete it
  // because the expectation has none of the markers of a dynamic expectation
  // and the presence of "offset" or "strict" or "strict" will break the schema validation

  const fixedVariantValues: ToBeBetween<MaybeStrict> = { ...to_be_between }
  if ("offset" in fixedVariantValues) {
    delete fixedVariantValues.offset
  }

  if ("strict" in fixedVariantValues) {
    delete fixedVariantValues.strict
  }

  // Another instance where we may need to modify config.windows, i.e.
  // A dynamic expectation where the user may have opted to use its fixed variant instead
  config.windows = []

  // if neither of the above conditions are met, return the object as it was,
  const updatedConfig = {
    ...config,
    // in the final return case there may be min/max value or other config key fields
    // being managed in fixedVariantValues that need to be returned to the to level
    ...fixedVariantValues,
  }

  return sanitizeMinMax(updatedConfig)
}

function getOffsetMarker(windows: WindowWithConstraint[]) {
  const bounds = {
    positive: false,
    negative: false,
  }

  windows.forEach((w) => {
    if (w.parameter_name.match(/_min$/)) {
      bounds.negative = true
    }
    if (w.parameter_name.match(/_max$/)) {
      bounds.positive = true
    }
  })

  return `${bounds.positive ? "+" : ""}${bounds.positive && bounds.negative ? "/" : ""}${bounds.negative ? "-" : ""}`
}

export const constructToBeBetweenCombinator = (
  expectationConfig: Record<string, unknown>,
  expectationType: string | null | undefined,
) => {
  const constructedConfig = { ...expectationConfig }

  if ("windows" in constructedConfig) {
    const windows = constructedConfig.windows as WindowWithConstraint[]
    if (windows.length > 0) {
      constructedConfig.to_be_between = {
        offset: getOffsetMarker(windows),
        percent: windows[0].offset.negative,
        range: windows[0].range,
        strict: windows[0].strict,
      }
      return constructedConfig
    }
  }

  if (expectationType && WindowedMinMaxExpectations.includes(expectationType as WindowedMinMaxExpectation)) {
    constructedConfig.to_be_between = {
      min_value: constructedConfig.min_value,
      max_value: constructedConfig.max_value,
      strict_min: constructedConfig.strict_min,
      strict_max: constructedConfig.strict_max,
    }
  }

  if (
    expectationType &&
    WindowedCompletenessExpectations.includes(expectationType as WindowedCompletenessExpectation)
  ) {
    constructedConfig.to_be_between = {
      mostly: constructedConfig.mostly,
    }
  }

  return constructedConfig
}

export const handleBaselineConfig = (expectationConfig?: ExpectationConfig<UIDefinedWindow>) => {
  // In the occasional case we have an expectation config with min_value/max_value and
  // offset both present, we need to delete the offset since it's unrelated to
  // a min/max constraint configuration.
  const config = { ...expectationConfig }
  if (config && "to_be_between" in config) {
    // spread these to avoid editing original object reference
    const to_be_between = { ...config.to_be_between }

    // if we're dealing with min/max expectations, we'll have min_value or max_value present
    if (to_be_between && "offset" in to_be_between && ("min_value" in to_be_between || "max_value" in to_be_between)) {
      delete to_be_between.offset
      return { ...config, to_be_between }
    }

    // if we're dealing with dynamic completeness expectations, we'll have `mostly` present as well as windows
    // we need to check for windows because it's valid for the fixed expectation to have `mostly` in to_be_between
    if (
      to_be_between &&
      "mostly" in to_be_between &&
      "windows" in config &&
      (config.windows as WindowWithConstraint[]).length > 0
    ) {
      if ((to_be_between.mostly as number) > 0) {
        const conf = { ...config, to_be_between: { mostly: to_be_between.mostly } }
        return conf
      }
      return { ...config, to_be_between }
    }
  }
  return config
}

interface ExpectationRenderingFeatureFlags {
  // Add feature flags that are needed to handle special cases
  // This can be empty if no feature flags are needed
  volumeChangeDetection?: boolean
}

export const specialCaseTemplate = (
  originalTemplate: string,
  expectationType: string,
  params: ParamTypes,
  featureFlags: ExpectationRenderingFeatureFlags,
) => {
  // Handle special cases e.g. for volume change detection expectation rendering
  // Can be extended to handle other special cases
  // Feature flags can be optionally passed to handle different cases
  // that may be hidden behind a feature flag
  if (
    featureFlags?.volumeChangeDetection &&
    expectationType === "expect_table_row_count_to_be_between" &&
    params.offset?.value === "-0%" &&
    params.range?.value === "1" &&
    params.constraint_fn?.value === "average" &&
    params.strict_min?.value === true
  ) {
    return "Must have a row count greater than the row count of the last run."
  }
  return originalTemplate
}
