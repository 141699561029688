import styled from "styled-components"
import { Heading1, Heading2 } from "src/global/ui/typography/Text/Text.tsx"
import { useAnalytics } from "src/global/hooks/analytics/useAnalytics.ts"

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
  flex-grow: 1;
`
const StyledTopHeading = styled(Heading1)`
  color: ${(props) => props.theme.colors.primaryColors.gxAccentDark};
  margin-top: 16px;
  margin-bottom: 16px;
`

function NotFound() {
  const analytics = useAnalytics()
  analytics?.capture("invalid_url.accessed")

  return (
    <MainContainer>
      <StyledTopHeading>404</StyledTopHeading>
      <Heading2>Oops, we couldn&apos;t find this page...</Heading2>
    </MainContainer>
  )
}

export { NotFound }
