import { AlertCard } from "src/global/ui/Card/Card.tsx"
import { Icon } from "src/global/ui/Icon"
import { styled, useTheme } from "styled-components"
import { Flex } from "antd"
import { InfoBox } from "src/global/components/alerts/IncidentAlertSection/InfoBox.tsx"
import { IncidentAlertList } from "src/global/components/alerts/IncidentAlertSection/IncidentAlertList.tsx"
import { AddNewAlert } from "src/global/components/alerts/IncidentAlertSection/AddNewAlert.tsx"

const StyledAlertCard = styled(AlertCard)`
  border: none;
`
const BorderLine = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutralColorPalette.backgroundsAndBorders.gxBgPrimary};
  margin: 0 ${({ theme }) => theme.spacing.xs};
`

export const INCIDENT_ALERT_SECTION_TITLE = "Send to destination"

export const IncidentAlertsSection = () => {
  const theme = useTheme()
  return (
    <>
      <BorderLine />
      <StyledAlertCard
        actions={null}
        metaProps={{
          title: INCIDENT_ALERT_SECTION_TITLE,
          avatar: <Icon name="fastMail" small />,
        }}
        description={
          <Flex align="center" vertical gap={theme.spacing.xs}>
            <InfoBox />
            <IncidentAlertsActions />
          </Flex>
        }
      />
    </>
  )
}

function IncidentAlertsActions() {
  const theme = useTheme()

  return (
    <Flex style={{ width: "100%" }} justify="center" vertical gap={theme.spacing.xs}>
      <IncidentAlertList />
      <AddNewAlert />
    </Flex>
  )
}
