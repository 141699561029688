import { Tag as AntdTag, TagProps as AntDTagProps } from "antd"
import { PropsWithChildren } from "react"
import styled, { css } from "styled-components"

type StyleProps = {
  $danger?: boolean
  $strikethrough?: boolean
  $noBottomMargin?: boolean
}

const StyledTag = styled(AntdTag)<StyleProps>`
  ${({ theme, $danger, $strikethrough, $noBottomMargin }) => css`
    margin: 0 4px ${$noBottomMargin ? 0 : theme.spacing.xxs} 0;
    font-weight: 600;
    padding: 0 8px;
    font-size: 14px;
    line-height: 22px;
    ${$strikethrough
      ? css`
          color: ${theme.colors.neutralColorPalette.blacks.colorTextTertiary};
          font-style: italic;
          text-decoration: line-through;
        `
      : css`
          color: ${theme.colors.neutralColorPalette.blacks.colorText};
        `}
    ${$danger
      ? css`
          background-color: ${theme.colors.error.gxErrorBackground};
          border-color: ${theme.colors.error.gxError};
        `
      : css`
          background-color: ${theme.colors.neutralColorPalette.backgroundsAndBorders.gxSurfaceSecondary};
          border: none;
        `};
  `}
`

export function Tag({
  $danger,
  $strikethrough,
  $noBottomMargin,
  children,
}: PropsWithChildren<AntDTagProps & StyleProps>) {
  return (
    <StyledTag $danger={$danger} $strikethrough={$strikethrough} $noBottomMargin={$noBottomMargin}>
      {children}
    </StyledTag>
  )
}
