import { useFragment_experimental } from "@apollo/client"
import { ExecutableDefinitionNode } from "graphql"
import { differenceWith, isEqual } from "lodash-es"
import { useEffect, useMemo, useState } from "react"
// eslint-disable-next-line no-restricted-imports -- deprecated file
import {
  CheckpointWithJobsFragmentDoc,
  DataAssetJobsFragmentDoc,
  JobFragment,
} from "src/api/graphql/graphql-operations.ts"

export type JobState =
  | JobStateInProgress
  | JobStateError
  | ValidateDataJobStateComplete
  | GenerateExpectationSuiteJobStateComplete

export type JobStateError = { status: "error"; errorMessage: string }

export type JobStateInProgress = { status: "inProgress" | "queued" }

export type GenerateExpectationSuiteJobStateComplete = {
  status: "complete"
  expectationSuiteId?: string
  checkpointId?: string
}

export type ValidateDataJobStateComplete = {
  status: "complete"
  validationResultId?: string
}

type JobStateInput =
  | GenerateExpectationSuiteJobStateInput
  | ValidateDataJobStateInput
  | GenerateColumnDescriptiveMetricsJobStateInput

type GenerateExpectationSuiteJobStateInput = {
  type: "GenerateExpectationSuite"
  dataAssetId: string
}

type ValidateDataJobStateInput = {
  type: "ValidateData"
  checkpointId: string
}

type GenerateColumnDescriptiveMetricsJobStateInput = {
  type: "GenerateColumnDescriptiveMetrics"
  dataAssetId: string
}

export function useJobState(input: JobStateInput): JobState | undefined {
  const { data } = useFragment_experimental(inputToFragmentOptions(input))
  const filteredJobs: JobFragment[] = useMemo(
    () => filterJobsBasedOnJobType({ jobs: data?.jobs, input }),
    [data, input],
  )
  const [prevJobs, setPrevJobs] = useState<JobFragment[]>([])
  const [jobState, setJobState] = useState<JobState>()

  useEffect(() => {
    if (!isEqual(filteredJobs, prevJobs)) {
      const updatedOrNewJobs = differenceWith(filteredJobs, prevJobs, isEqual)
      if (updatedOrNewJobs.some((job) => ["inProgress", "queued"].includes(job.status))) {
        setJobState({ status: "inProgress" })
        setPrevJobs(filteredJobs)
        return
      }

      const newlyFinishedJob = updatedOrNewJobs
        .filter((job) => {
          const prevJob = prevJobs.find(({ id }) => id === job.id)
          if (!prevJob) {
            // if there is no prevJob, then this is a new job
            // the new job may have finished prior to polling and thus we may have missed the inProgress state
            return ["complete", "error"].includes(job.status)
          }
          return ["complete", "error"].includes(job.status) && job.status !== prevJob.status
        })
        .pop()
      if (newlyFinishedJob) {
        switch (newlyFinishedJob.status) {
          case "complete":
            setJobState(getCompleteJobStateForJobType({ input, job: newlyFinishedJob }))
            break
          case "error":
            setJobState({ status: newlyFinishedJob.status, errorMessage: newlyFinishedJob.errorMessage ?? "" })
            break
        }
      }
      setPrevJobs(filteredJobs)
    }
  }, [filteredJobs, input, prevJobs, input.type])

  return jobState
}

function filterJobsBasedOnJobType({ jobs, input }: { jobs?: JobFragment[]; input: JobStateInput }) {
  switch (input.type) {
    case "GenerateExpectationSuite":
      return (
        jobs?.filter(({ sourceResources }) => {
          const dataAssetSourceResource = sourceResources.find(({ entityType }) => entityType === "DataAsset")
          const datasourceSourceResource = sourceResources.find(({ entityType }) => entityType === "Datasource")
          return dataAssetSourceResource?.entityId === input.dataAssetId && datasourceSourceResource
        }) ?? []
      )
    case "ValidateData":
      return (
        jobs?.filter(({ sourceResources }) => {
          const checkpointSourceResource = sourceResources.find(({ entityType }) => entityType === "Checkpoint")
          return checkpointSourceResource?.entityId === input.checkpointId
        }) ?? []
      )
    case "GenerateColumnDescriptiveMetrics": {
      const return_value =
        jobs?.filter(({ sourceResources }) => {
          const dataAssetSourceResource = sourceResources.find(({ entityType }) => entityType === "DataAsset")
          const datasourceSourceResource = sourceResources.find(({ entityType }) => entityType === "Datasource")
          return dataAssetSourceResource?.entityId === input.dataAssetId && !datasourceSourceResource
        }) ?? []
      return return_value
    }
  }
}

// gets JobState for job type with 'complete' status
function getCompleteJobStateForJobType({
  input,
  job,
}: {
  input: JobStateInput
  job: JobFragment
}): ValidateDataJobStateComplete | GenerateExpectationSuiteJobStateComplete {
  switch (input.type) {
    case "GenerateExpectationSuite": {
      // Get newly created Expectation Suite ID
      const expectationSuiteId = job.createdResources?.find(
        ({ entityType }) => entityType === "ExpectationSuite",
      )?.entityId
      const checkpointId = job.createdResources?.find(({ entityType }) => entityType === "Checkpoint")?.entityId
      return {
        status: "complete",
        expectationSuiteId,
        checkpointId,
      }
    }
    case "ValidateData": {
      // Get newly created Validation Result ID
      const validationResultId = job.createdResources?.find(
        ({ entityType }) => entityType === "SuiteValidationResult",
      )?.entityId
      return {
        status: "complete",
        validationResultId: validationResultId,
      }
    }
    case "GenerateColumnDescriptiveMetrics": {
      return {
        status: "complete",
      }
    }
  }
}

function inputToFragmentOptions(input: JobStateInput) {
  switch (input.type) {
    case "GenerateColumnDescriptiveMetrics":
    case "GenerateExpectationSuite":
      return {
        canonizeResults: true,
        fragment: DataAssetJobsFragmentDoc,
        fragmentName: (DataAssetJobsFragmentDoc.definitions[0] as ExecutableDefinitionNode).name?.value,
        from: `AssetRef:${input.dataAssetId}`,
      }
    case "ValidateData":
      // this won't work until the jobs field is populated client-side
      return {
        canonizeResults: true,
        fragment: CheckpointWithJobsFragmentDoc,
        fragmentName: (CheckpointWithJobsFragmentDoc.definitions[0] as ExecutableDefinitionNode).name?.value,
        from: `Checkpoint:${input.checkpointId}`,
      }
  }
}
