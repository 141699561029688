import { useContext, useEffect } from "react"
import ConnectToDataSourceContext from "src/pages/DataAssets/drawers/ConnectToDataSource/ConnectToDataSourceContext.ts"

export function EmptyStep() {
  /**
   * The EmptyStep component's only job is to shovel the form one additional step
   * in the current direction of the user's progress.
   *
   * This is necessary to facilitate empty or disabled steps, such as an "Enter Credentials"
   * step when adding assets to a Data Source that already exists.
   */
  const { activeStep, nextStep, prevStep, previouslyActiveStep } = useContext(ConnectToDataSourceContext)

  useEffect(() => {
    if (previouslyActiveStep < activeStep) {
      nextStep()
    }

    if (previouslyActiveStep > activeStep) {
      prevStep()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * The assumption is that this component won't be visible long enough
   * to render anything, but send out null just to make React & TS happy.
   */
  return null
}
