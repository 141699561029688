import { Table, TableProps } from "antd"
import { ExpandableContainer } from "src/global/ui/ExpandableContainer"
import { Button } from "src/global/ui/Button/Button.tsx"
import { Token } from "src/pages/Tokens/Tokens.tsx"
import {
  StyledButtonContainer,
  StyledColumnHeader,
  StyledTable,
} from "src/pages/Tokens/components/TokensTable.styles.tsx"
import { TokenRowActions } from "src/pages/Tokens/components/TokenRowActions.tsx"
import { useState } from "react"
import { TokenModal } from "src/pages/Tokens/components/TokenModal.tsx"
// eslint-disable-next-line no-restricted-imports -- deprecated file
import {
  OrganizationApiTokensDocument,
  OrganizationApiTokensQuery,
  GetUserTokensDocument,
  GetUserTokensQuery,
  IssueUserApiTokenDocument,
  IssueOrganizationApiTokenDocument,
} from "src/api/graphql/graphql-operations.ts"
import { useMutation } from "@apollo/client"

const { Column } = Table

interface TokensTableProps extends TableProps<Token> {
  sectionTitle: string
  description: string
  data: Token[]
  createButtonText: string
  isDeletable: boolean
  loading: boolean
}

export const TokensTable = ({
  sectionTitle,
  description,
  data,
  isDeletable,
  createButtonText,
  loading,
}: TokensTableProps) => {
  const [isModalVisible, setIsModalVisible] = useState(false)

  const handleCreateClick = () => {
    setIsModalVisible(true)
  }

  const [issueUserApiTokenMutation] = useMutation(IssueUserApiTokenDocument, {
    update: (cache, { data }) => {
      const newToken = data?.issueUserApiToken
      if (!newToken) return
      cache.updateQuery<GetUserTokensQuery>({ query: GetUserTokensDocument }, (queryData) => {
        return {
          ...queryData,
          __typename: "Query",
          allUserApiTokens: {
            __typename: "UserApiTokenConnection",
            edges: [
              ...(queryData?.allUserApiTokens?.edges ?? []),
              {
                __typename: "UserApiTokenEdge",
                node: newToken,
              },
            ],
          },
        }
      })
    },
  })

  const [issueOrganizationApiTokenMutation] = useMutation(IssueOrganizationApiTokenDocument, {
    update: (cache, { data }) => {
      const newToken = data?.issueOrganizationApiToken
      if (!newToken) return
      cache.updateQuery<OrganizationApiTokensQuery>({ query: OrganizationApiTokensDocument }, (queryData) => {
        return {
          ...queryData,
          __typename: "Query",
          allOrganizationApiTokens: {
            __typename: "OrganizationApiTokenConnection",
            edges: [
              ...(queryData?.allOrganizationApiTokens?.edges ?? []),
              {
                __typename: "OrganizationApiTokenEdge",
                node: newToken,
              },
            ],
          },
        }
      })
    },
  })

  const mutation = createButtonText.toLowerCase().includes("user")
    ? issueUserApiTokenMutation
    : issueOrganizationApiTokenMutation

  return (
    <ExpandableContainer
      title={sectionTitle}
      headerExtra={description}
      subtitle={`${data.length} Total`}
      collapsible={false}
      contentSpacing={false}
    >
      <StyledTable
        dataSource={data}
        pagination={{ hideOnSinglePage: true, position: ["bottomRight"] }}
        loading={loading}
      >
        <Column
          key="name"
          dataIndex="name"
          title={<StyledColumnHeader>Token name</StyledColumnHeader>}
          className="name-column"
          sorter={(a: Token, b: Token) => a.name.localeCompare(b.name)}
          sortDirections={["ascend", "descend"]}
        />
        <Column
          key="role"
          dataIndex="role"
          title={<StyledColumnHeader>Role</StyledColumnHeader>}
          className="role-column"
          sortDirections={["ascend", "descend"]}
        />
        <Column
          render={(token) => {
            return <TokenRowActions title={createButtonText} tokenId={token.id} isDeletable={isDeletable} />
          }}
        />
      </StyledTable>
      <StyledButtonContainer>
        <Button type="default" aria-label={createButtonText} onClick={handleCreateClick} title={createButtonText}>
          {createButtonText}
        </Button>
        <TokenModal
          mutation={mutation}
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          title={createButtonText}
        />
      </StyledButtonContainer>
    </ExpandableContainer>
  )
}
