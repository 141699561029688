import { uniq } from "lodash-es"
import { ExpectationConfiguration } from "src/api/graphql/graphql.ts"
// eslint-disable-next-line no-restricted-imports -- deprecated file
import { ExpectationChangesQuery } from "src/api/graphql/graphql-operations.ts"
import { getExpectationGroupName } from "src/global/utils/getExpectationGroupName.ts"
import { pluralize } from "src/global/utils/strings.ts"
import { sortAscWithTableExpectationsFirst } from "src/global/utils/sortAscWithTableExpectationsFirst.ts"
import { nonNull } from "src/global/utils/nonNull.ts"

export const getColumnsFromExpectationsList = (
  expectationsList: ExpectationConfiguration[] | undefined,
  search: string,
) => {
  const listResult =
    expectationsList
      ?.filter(nonNull)
      .map(getExpectationGroupName)
      .filter((group) => group.toLowerCase().includes(search.toLowerCase()))
      .sort(sortAscWithTableExpectationsFirst) ?? []
  return uniq(listResult)
}

export const getColumnsWithChanges = (
  search: string,
  expectationsWithChanges?: ExpectationChangesQuery["expectationChanges"],
) => {
  const allExpectationsInitialConfigurations = expectationsWithChanges?.map((expectationWithChanges) => {
    // get the initial create entry for that expectation
    const initialCreate = expectationWithChanges?.changes.find((change) => {
      return change.action === "create"
    })
    return initialCreate?.expectationConfiguration ?? expectationWithChanges.changes[0]?.expectationConfiguration
  })
  return getColumnsFromExpectationsList(allExpectationsInitialConfigurations, search)
}

export const getSubtitle = (expectationCount: number, loading: boolean) => {
  const expectationsSubtitle = String(expectationCount) + " " + pluralize("Expectation", expectationCount)

  return loading ? "" : `${expectationsSubtitle}`
}
