import { JsonFormsRendererRegistryEntry, rankWith, scopeEndsWith, ControlProps } from "@jsonforms/core"
import { withJsonFormsControlProps } from "@jsonforms/react"
import { Form } from "antd"
import {
  ROW_CONDITION_FIELD_LABEL,
  ROW_CONDITION_TOOLTIP,
} from "src/pages/DataAssets/views/Expectations/Expectation/uiForms/customRenderers/words.ts"
import {
  Config,
  RowConditionForm,
} from "src/pages/DataAssets/views/Expectations/Expectation/uiForms/customRenderers/RowConditionControl/RowConditionForm.tsx"
import { Metric } from "src/api/graphql/graphql.ts"
import { useColumnTypeOperators } from "src/pages/DataAssets/views/Expectations/Expectation/uiForms/customRenderers/RowConditionControl/useColumnTypeOperators.tsx"
import { RowCondition } from "src/pages/DataAssets/views/Expectations/Expectation/CreateExpectationDrawer/types.ts"
import { Rule } from "antd/es/form"

export const RowConditionRegistryEntry: JsonFormsRendererRegistryEntry = {
  tester: rankWith(
    90, //increase rank as needed
    scopeEndsWith("#/properties/row_condition"),
  ),
  renderer: withJsonFormsControlProps(RowConditionControl),
}

type RowConditionControlProps = ControlProps & {
  data: RowCondition
  config?: Config
}

function RowConditionControl({ data, path, handleChange, schema, config }: RowConditionControlProps) {
  const form = Form.useFormInstance()

  const { metrics } = config?.dataAssetWithLatestMetricRun?.latestMetricRun ?? { metrics: null }

  const columnOptions =
    metrics?.map((metric: Metric) => ({
      value: metric.columnName,
      label: metric.columnName,
    })) ?? null

  const serverOperators = Array.isArray(schema.properties?.operator?.enum) ? schema.properties.operator.enum : []

  const { options: operatorOptions, columnType } = useColumnTypeOperators(data, metrics, serverOperators)

  const isColumnSet = data?.column !== undefined
  const isOperatorSet = data?.operator !== undefined
  const isParameterSet = data?.parameter !== undefined
  // parameter does not apply when nullity operator is used
  const isParameterApplicable = data?.operator !== "is not null"
  const isConditionValid =
    (isColumnSet && isOperatorSet && !isParameterApplicable && !isParameterSet) ||
    (isColumnSet && isOperatorSet && isParameterApplicable && isParameterSet)
  const fieldRequiredRule: Rule[] = [
    {
      validator: async () => (isConditionValid ? Promise.resolve() : Promise.reject("Condition is not complete")),
    },
  ]

  return (
    <Form.Item
      name={ROW_CONDITION_FIELD_LABEL.toLowerCase()}
      label={ROW_CONDITION_FIELD_LABEL}
      tooltip={ROW_CONDITION_TOOLTIP}
      rules={isColumnSet ? fieldRequiredRule : undefined}
    >
      <RowConditionForm
        form={form}
        path={path}
        handleChange={handleChange}
        columnOptions={columnOptions}
        operatorOptions={operatorOptions}
        columnType={columnType}
        data={data}
        isParameterApplicable={isParameterApplicable}
      />
    </Form.Item>
  )
}
