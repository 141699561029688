import { List } from "antd"
import {
  EmailAlertsUnion,
  IncidentAlertItem,
} from "src/global/components/alerts/IncidentAlertSection/IncidentAlertItem.tsx"

export function IncidentAlertList() {
  const data: EmailAlertsUnion[] = [
    {
      __typename: "SlackEmailAlert",
      id: "1",
      emailAddress: "test@test.com",
      domain: "Slack",
      workspace: "CIA - Special Activities Center",
      channel: "Blackbriar Initiative",
    },
    {
      __typename: "TeamsEmailAlert",
      id: "2",
      emailAddress: "test@test.com",
      domain: "Teams",
      community: "MI6",
      channel: "Secret Intelligence Service",
    },
    {
      __typename: "PagerDutyEmailAlert",
      id: "3",
      emailAddress: "james.bond@test.com",
      domain: "PagerDuty",
      username: "james.bond",
    },
    {
      __typename: "EmailAlert",
      id: "4",
      emailAddress: "test@test.com",
      domain: "Generic",
    },
  ]
  return (
    <List
      dataSource={data}
      bordered
      renderItem={(item) => (
        <List.Item>
          <IncidentAlertItem emailAlert={item} onDelete={() => {}} />
        </List.Item>
      )}
    />
  )
}
