import {
  ARRAY_BASED_EXPECTATION_PRESCRIPTIVE_RENDER_KEYS,
  ARRAY_BASED_EXPECTATION_PRESCRIPTIVE_RENDER_KEYS_STARTS_WITH,
  ARRAY_BASED_EXPECTATION_DIAGNOSTIC_RENDER_KEYS_STARTS_WITH,
} from "src/pages/DataAssets/views/Expectations/Expectation/StringRenderComponent.tsx"

export const findParamListMarker =
  (findSimpleString?: boolean) => (evaluatedString: string, index: number, evaluatedStringArray: string[]) => {
    const markersToTruncate = [
      ...ARRAY_BASED_EXPECTATION_PRESCRIPTIVE_RENDER_KEYS,
      ...ARRAY_BASED_EXPECTATION_PRESCRIPTIVE_RENDER_KEYS_STARTS_WITH,
      ...ARRAY_BASED_EXPECTATION_DIAGNOSTIC_RENDER_KEYS_STARTS_WITH,
    ]
    const markersPresent = markersToTruncate.find((marker) => evaluatedString.startsWith(`$${marker}`))
    const isMarkerFound =
      Boolean(markersPresent) ||
      evaluatedString === " " ||
      evaluatedString === ", " ||
      (evaluatedString === "." && index === evaluatedStringArray.length - 1)
    if (findSimpleString) {
      return !isMarkerFound
    }
    return isMarkerFound
  }
