import { parseJSONStringConfig } from "src/pages/DataAssets/views/AssetDetails/utils.tsx"
import { demoDataSourceConfig, postgresDemoDataSourceConfig } from "src/pages/DataAssets/schemas/data-source-schemas.ts"
import { graphql } from "src/api/graphql/gql.ts"
import { FragmentType, unmaskFragment } from "src/api/graphql/fragment-masking.ts"

export const UseIsDemoData_DataAssetFragmentDoc = graphql(`
  fragment UseIsDemoData_DataAsset on AssetRef {
    datasourceV2 {
      id
      config
    }
  }
`)

type FragmentData = FragmentType<typeof UseIsDemoData_DataAssetFragmentDoc> | null | undefined

export function useIsDemoData(asset: FragmentData): boolean {
  if (!asset) return false
  const dataAsset = unmaskFragment(UseIsDemoData_DataAssetFragmentDoc, asset)
  const config = dataAsset.datasourceV2?.config && parseJSONStringConfig(dataAsset.datasourceV2?.config)

  return (
    !!config &&
    typeof config === "object" &&
    "connection_string" in config &&
    (config.connection_string === demoDataSourceConfig.connectionString ||
      config.connection_string === postgresDemoDataSourceConfig.connectionString)
  )
}
