// eslint-disable-next-line no-restricted-imports -- deprecated file
import { Expectation_ValidationResultFragment } from "src/api/graphql/graphql-operations.ts"
import { sortAscWithTableExpectationsFirst } from "src/global/utils/sortAscWithTableExpectationsFirst.ts"
import {
  StyledExpectationGroup,
  StyledList,
  StyledTag,
} from "src/pages/DataAssets/views/Validations/ValidationTab.styles.tsx"
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons"
import { pluralize } from "src/global/utils/strings.ts"
import { FC } from "react"
import { List, ListProps } from "antd"
import { ValidationResults } from "src/pages/DataAssets/views/Expectations/Expectation/ValidationResults.tsx"
import { removeInvalidHtmlCharacters } from "src/global/utils/removeInvalidHtmlCharacters.ts"

interface ValidationResultListProps {
  filteredValidations: Record<string, Expectation_ValidationResultFragment[]> | undefined | []
  selectedAnchor: string
}
export const ValidationResultList = ({ filteredValidations, selectedAnchor }: ValidationResultListProps) => {
  return (
    <>
      {Object.entries(filteredValidations ?? [])
        .sort(([a], [b]) => sortAscWithTableExpectationsFirst(a, b))
        .map(([groupName, validationResultGroup]) => {
          const failedCount = validationResultGroup.reduce((acc, result) => {
            if (!result.success) {
              return acc + 1
            }
            return acc
          }, 0)
          return (
            <div key={groupName}>
              <StyledExpectationGroup
                groupName={groupName}
                headerBottomBorder={false}
                $isSelected={selectedAnchor === removeInvalidHtmlCharacters(groupName.toLowerCase())}
                actionMenu={
                  !failedCount ? (
                    <StyledTag icon={<CheckCircleOutlined />} color="success">
                      All Expectations met
                    </StyledTag>
                  ) : (
                    <StyledTag icon={<CloseCircleOutlined />} color="error">
                      {failedCount} failed {pluralize("Expectation", failedCount)}
                    </StyledTag>
                  )
                }
              >
                <StyledList<FC<ListProps<Expectation_ValidationResultFragment>>>
                  itemLayout="vertical"
                  className="sentry-mask"
                  dataSource={validationResultGroup}
                  renderItem={(validationResult: Expectation_ValidationResultFragment) => (
                    <List.Item key={validationResult?.expectationConfig?.expectationId}>
                      <ValidationResults validationResults={validationResult} />
                    </List.Item>
                  )}
                />
              </StyledExpectationGroup>
            </div>
          )
        })}
    </>
  )
}
