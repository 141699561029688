/* eslint-disable react-refresh/only-export-components */ // FIXME
import { Card, Col, Flex, List, Row, Tag } from "antd"
import { DataSourceChoice } from "src/pages/DataAssets/drawers/NewAssetDrawer/NewAssetDrawer.tsx"
import { useState } from "react"
import { useQuery } from "@apollo/client"
import styled, { css } from "styled-components"

// eslint-disable-next-line no-restricted-imports -- deprecated file
import {
  DatasourceWithRunsFragment,
  DatasourcesWithRunsDocument,
  DatasourcesWithRunsQuery,
} from "src/api/graphql/graphql-operations.ts"
import { StyledLogoContainer } from "src/global/ui/List/MainContentList.tsx"
import { getImageLogo } from "src/pages/DataAssets/getImageLogo.ts"
import { Maybe } from "graphql/jsutils/Maybe"
import { Theme } from "src/global/ui/themes/theme.ts"
import { DatasourceTypeV2 } from "src/api/graphql/graphql.ts"
import { assertNever } from "src/pages/DataAssets/utils.ts"
import { Segmented } from "src/global/ui/Segmented/Segmented.tsx"
import { SupportedDataSource, isSupportedDataSource } from "src/pages/DataAssets/schemas/data-source-schemas.ts"
import { useIsDataSourceEditable } from "src/global/hooks/useIsDataSourceEditable.ts"
import { BodyStrong, DescriptionNormal, Heading3 } from "src/global/ui/typography/Text/Text.tsx"

type Props = {
  onChoice: (choice: DataSourceChoice) => void
  createNew?: boolean
}

export function PickNewOrExistingDataSource({ onChoice }: Props) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [rerender, setRerender] = useState<number>(Math.random())
  const { data, loading } = useQuery(DatasourcesWithRunsDocument, {
    onCompleted: (data) => {
      data.datasourcesV2.sort((a, b) => a.name.localeCompare(b.name))
      setRerender(Math.random()) // small, inexpensive hack to trigger a re-render without mutating meaningful state
    },
  })
  const hasDataSources = Boolean(data?.datasourcesV2.length)
  const [createNew, setCreateNew] = useState(false)

  return (
    <Flex vertical gap="middle" style={{ padding: "24px" }}>
      {hasDataSources && (
        <Row>
          <Col xs={20} sm={16} md={12} style={{ height: "30px" }}>
            <Segmented
              block={true}
              value={createNew ? "New Data Source" : "Existing Data Source"}
              options={["Existing Data Source", "New Data Source"]}
              onChange={(value) => (value === "New Data Source" ? setCreateNew(true) : setCreateNew(false))}
            />
          </Col>
        </Row>
      )}
      {createNew && <DataSourceCards setChoice={onChoice} />}
      {!createNew && (
        <DataSourceList
          data={data}
          loading={loading}
          onSelect={(dataSource: DatasourceWithRunsFragment, dataSourceType: SupportedDataSource) =>
            onChoice({ createNew: false, dataSource, dataSourceType })
          }
        />
      )}
    </Flex>
  )
}

type ListProps = {
  data: Maybe<DatasourcesWithRunsQuery>
  loading: boolean
  onSelect: (dataSource: DatasourceWithRunsFragment, dataSourceType: SupportedDataSource) => void
}

function DataSourceList({ data, loading, onSelect }: ListProps) {
  return (
    <List
      split
      dataSource={data?.datasourcesV2 ?? ([] as DatasourceWithRunsFragment[])}
      loading={loading}
      rowKey={(datasource) => datasource.id}
      renderItem={(datasource) => (
        <ListItem
          datasource={datasource}
          disabled={!isSupportedDataSource(datasource.type)}
          {...(isSupportedDataSource(datasource.type) && {
            onSelect: () => onSelect(datasource, datasource.type as unknown as SupportedDataSource),
          })}
        />
      )}
    />
  )
}

interface ListItemProps {
  datasource: DatasourceWithRunsFragment
  disabled: boolean
  onSelect?: () => void
}

function ListItem({ datasource, onSelect }: ListItemProps) {
  const isEditable = useIsDataSourceEditable(datasource.type)
  return (
    <List.Item style={{ ...{ cursor: !isEditable ? "not-allowed" : "pointer" } }} onClick={onSelect}>
      <List.Item.Meta
        title={<BodyStrong {...(!isEditable ? { $color: "colorTextSecondary" } : {})}>{datasource.name}</BodyStrong>}
        avatar={
          <StyledLogoContainer>
            <img src={getImageLogo(datasource.type)} alt={`${datasource.type} logo`} />
          </StyledLogoContainer>
        }
        description={<DescriptionNormal>{datasource.assets.length} Assets</DescriptionNormal>}
      />
    </List.Item>
  )
}

type DataSourceCardsProps = {
  setChoice: (choice: DataSourceChoice) => void
  large?: boolean
}
export function DataSourceCards({ setChoice, large = false }: DataSourceCardsProps) {
  return (
    <>
      <Row gutter={16}>
        <Col span={8}>
          <DataSourceCard
            large={large}
            type="SNOWFLAKE"
            onClick={(dataSourceType: SupportedDataSource) => setChoice({ createNew: true, dataSourceType })}
          />
        </Col>
        <Col span={8}>
          <DataSourceCard
            large={large}
            type="POSTGRES"
            onClick={(dataSourceType: SupportedDataSource) => setChoice({ createNew: true, dataSourceType })}
          />
        </Col>
        <Col span={8}>
          <DataSourceCard
            large={large}
            type="DATABRICKS_SQL"
            onClick={(dataSourceType: SupportedDataSource) => setChoice({ createNew: true, dataSourceType })}
          />
        </Col>
      </Row>
      {large ? null : (
        <Row gutter={16}>
          {/* TODO: add BigQuery */}
          <Row />
        </Row>
      )}
    </>
  )
}

type DataSourceCardProps =
  | {
      large?: boolean
      type: SupportedDataSource
      onClick: (type: SupportedDataSource) => void
    }
  | {
      large?: boolean
      type: Exclude<DatasourceTypeV2, SupportedDataSource>
    }
  | {
      type: "demo"
      image: React.ReactNode
      onClick: () => void
      text: string
      large: true
    }

interface StyledCardProps {
  $large?: boolean
  $supportedType: boolean
  theme: Theme
  onClick?: () => void
  hoverable: boolean
}

const StyledCard = styled(Card)<StyledCardProps>`
  ${({ $large, $supportedType, theme }) => css`
    height: ${$large ? "92px" : "100%"};
    width: ${$large ? "235px" : "auto"};
    border-color: ${theme.colors.neutralColorPalette.backgroundsAndBorders.gxBorder};
    cursor: ${$supportedType ? "pointer" : "not-allowed"};
    background: ${$supportedType
      ? theme.colors.neutralColorPalette.whites.white
      : theme.colors.neutralColorPalette.backgroundsAndBorders.gxSurfaceSecondary};
  `}
`

export function DataSourceCard(props: DataSourceCardProps) {
  const image = props.type === "demo" ? props.image : <img src={getImageLogo(props.type)} alt={`${props.type} logo`} />
  const text = props.type === "demo" ? props.text : getHumanReadableDataSourceType(props.type)
  const clickable = "onClick" in props

  let onClick = undefined
  if (props.type === "demo") {
    onClick = props.onClick
  } else if (clickable) {
    onClick = () => props.onClick(props.type)
  }

  return (
    <StyledCard onClick={onClick} hoverable={clickable} $large={props.large} $supportedType={clickable} role="button">
      <Card.Meta
        style={{ paddingTop: props.large ? "10px" : undefined }}
        title={<Heading3>{text}</Heading3>}
        avatar={<StyledLogoContainer>{image}</StyledLogoContainer>}
        description={!clickable ? <ComingSoonTag /> : null}
      />
    </StyledCard>
  )
}

function ComingSoonTag() {
  return <Tag style={{ borderRadius: "4px", background: "white" }}>Coming Soon!</Tag>
}

export function getHumanReadableDataSourceType(type: DatasourceTypeV2) {
  switch (type) {
    case "DATABRICKS_SQL":
      return "Databricks SQL"
    case "PANDAS":
      return "pandas"
    case "POSTGRES":
      return "PostgreSQL"
    case "SNOWFLAKE":
      return "Snowflake"
    case "SQL":
      return "SQL"
    case "SQLITE":
      return "SQLite"
    case "SPARK":
      return "Spark"
    default:
      // if you see a TypeScript error below, it's because you're missing a case statement
      // for a member of the union
      try {
        assertNever(type)
      } catch (e) {
        return type
      }
  }
}
