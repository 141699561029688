import { RuleEffect } from "@jsonforms/core"
import type { UISchema } from "@great-expectations/jsonforms-antd-renderers"
import { SqlDataSourceJsonSchema } from "src/pages/DataAssets/schemas/json-schemas/sql-data-source-json-schema.ts"

export const SqlDataSourcePage1UISchema: UISchema<typeof SqlDataSourceJsonSchema> = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Control",
      scope: "#/properties/type",
      rule: {
        effect: RuleEffect.HIDE,
        condition: {},
      },
    },
    {
      type: "Control",
      scope: "#/properties/name",
      label: "Data Source name",
    },
    {
      type: "Group",
      elements: [
        {
          type: "Control",
          scope: "#/properties/connection_string",
          label: "Connection string",
          options: {
            type: "multiline",
            required: true,
            placeholderText: `FIXME://USER_NAME:\${PASSWORD_ENV_VAR}@ACCOUNT_IDENTIFIER/DATABASE/SCHEMA?warehouse=WAREHOUSE&role=ROLE`,
            rules: [],
          },
          formItemProps: {
            tooltip:
              "To securely connect to your data, store sensitive values as environment variables on your local machine. Make sure to restart the shell after adding the variable.",
          },
        },
        {
          type: "Label",
          text: "Specify an environment variable by using template string substitution syntax, e.g. if your environment variable is PASSWORD_ENV_VAR, enter ${PASSWORD_ENV_VAR}",
          options: { type: "info" },
        },
      ],
    },
  ],
}

export const SqlDataSourcePage2UISchema: UISchema<typeof SqlDataSourceJsonSchema> = {
  type: "VerticalLayout",
  elements: [
    {
      label: "Data Asset",
      type: "Control",
      scope: "#/properties/assets",
    },
  ],
}
