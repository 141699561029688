import { Background } from "src/global/ui/OnboardingFlow/styles.tsx"
import SignupForm from "src/global/ui/SignupFlow/SignupForm.tsx"
import { SignupLoginModal } from "src/global/ui/LoginScreen/SignupLoginModal.tsx"
import { useEffect, useState } from "react"
import { useCurrentUser } from "src/global/hooks/useCurrentUser.ts"
import { useNavigate } from "react-router-dom"
import { Heading3, LG } from "src/global/ui/typography/Text/Text.tsx"

const SignupPage = () => {
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [submittedEmail, setSubmittedEmail] = useState("")
  const { isAuthenticated } = useCurrentUser()
  const navigate = useNavigate()

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/")
    }
  }, [isAuthenticated, navigate])

  const handleSubmitSuccess = (email: string) => {
    setSubmittedEmail(email)
    setIsSubmitted(true)
  }

  const submittedText = (
    <div>
      <LG>Follow the link sent to </LG>
      <Heading3>{submittedEmail}</Heading3>
      <LG>to finish signing up</LG>
    </div>
  )

  return (
    <Background>
      <SignupLoginModal
        headerSection={isSubmitted ? "Please check your email" : "Sign up for GX Cloud"}
        secondaryHeaderSectionText={isSubmitted ? submittedText : undefined}
        formsSection={isSubmitted ? null : <SignupForm onSubmitSuccess={handleSubmitSuccess} />}
      />
    </Background>
  )
}

export default SignupPage
