import { Maybe } from "graphql/jsutils/Maybe"
// eslint-disable-next-line no-restricted-imports -- deprecated file
import { Expectation_ValidationResultFragment } from "src/api/graphql/graphql-operations.ts"
import { getExpectationGroupName } from "src/global/utils/getExpectationGroupName.ts"
import { nonNull } from "src/global/utils/nonNull.ts"

export function groupValidationResults(expectations?: Maybe<Maybe<Expectation_ValidationResultFragment>[]>) {
  return expectations
    ?.filter(nonNull)
    .reduce<Record<string, Expectation_ValidationResultFragment[]>>((acc, expectation) => {
      const groupName = getExpectationGroupName(expectation.expectationConfig)
      return {
        ...acc,
        [groupName]: [...(Array.isArray(acc[groupName]) ? acc[groupName] : []), expectation],
      }
    }, {})
}
