import { METRIC_UNAVAILABLE } from "src/pages/DataAssets/views/AssetDetails/MetricsTable.tsx"

export function formatNumber(input: string | number | undefined): string {
  if (input === undefined) {
    // if input is undefined, then return placeholder dash. This is used in cases where the RowCount is not available.
    // because the full CDM job has not been run.
    return "-"
  }
  const parts = new Intl.NumberFormat(undefined, { maximumFractionDigits: 20 }).formatToParts(Number(input))
  const retranslatedValue = partsToEnglishLocale(parts)
    .map(({ value }) => value)
    .join("")

  // check to see that information wasn't lost
  if (retranslatedValue !== String(input)) {
    return String(input)
  }
  return parts.map(({ value }) => value).join("")
}

// assume notation value is "standard"
function partsToEnglishLocale(parts: Intl.NumberFormatPart[]): Intl.NumberFormatPart[] {
  return parts.map(({ type, value }) => {
    switch (type) {
      case "decimal":
        return { type, value: "." }
      case "group": // Number coercion doesn't like group separators
        return { type, value: "" }
      case "fraction":
      case "integer":
        return { type, value }
      default:
        // blow away anything else
        return { type, value: "" }
    }
  })
}

// formatter for numbers that are percentages. Used by MetricsTable.
export function formatNumberAsPercentage(input: string | number | null, precision: number = 2): string {
  // if input is null or undefined, return empty string
  if (input === null) {
    return METRIC_UNAVAILABLE
  }
  const output = Number(input).toFixed(precision) + "%"
  return output
}

export function formatNumberScaleMaxDigits(input: number, maxDigits: number = 3): string {
  if (input === null || input === undefined) {
    return ""
  }
  const output = new Intl.NumberFormat("en-US", { maximumFractionDigits: maxDigits }).format(input)
  return output
}
