/* eslint-disable react-refresh/only-export-components */ // FIXME
import type { Categorization, Category, ControlProps, JsonFormsRendererRegistryEntry } from "@jsonforms/core"
import { rankWith, uiTypeIs } from "@jsonforms/core"
import { JsonFormsDispatch, withJsonFormsControlProps } from "@jsonforms/react"
import { useCallback, useState } from "react"
import { Form, FormInstance, FormProps } from "antd"
import { SegmentedValue } from "antd/es/segmented"

import { Segmented } from "src/global/ui/Segmented/Segmented.tsx"
import { theme } from "src/global/ui/themes/theme.ts"
import { BodyNormal } from "src/global/ui/typography/Text/Text.tsx"

export interface CategorizationState {
  selectedCategory: Category
}

type Props = ControlProps

export const CategorizationRegistryEntry: JsonFormsRendererRegistryEntry = {
  renderer: withJsonFormsControlProps(CategorizationControl),
  tester: rankWith(10, uiTypeIs("Categorization")),
}

function CategorizationControl(props: Props) {
  const { uischema, visible, schema, path, handleChange, data } = props

  const categorization = uischema as unknown as Categorization
  const [selectedCategoryIndex, setSelectedCategory] = useState<number>(0)
  const selectedCategoryObject = categorization.elements[selectedCategoryIndex] as Category
  const form = Form.useFormInstance()

  const handleSegmentedChange = useCallback(
    (value: SegmentedValue) => {
      setSelectedCategory(Number(value))

      // cleanup if selected category is empty
      if (data !== undefined && categorization.elements[Number(value)].elements.length === 0) {
        handleChange(path, undefined)
      }
    },
    [data, categorization.elements, handleChange, path],
  )

  return (
    <div hidden={visible === false}>
      <Form {...getFormLayoutOptions(form)} form={form} layout="vertical">
        <BodyNormal>{categorization.label}</BodyNormal>
        <Form.Item style={{ marginTop: theme.spacing.xxs }}>
          <Segmented
            size="large"
            options={categorization.elements.map((el, index) => ({
              label: el.label,
              value: index,
            }))}
            onChange={(value) => handleSegmentedChange(value)}
          />
        </Form.Item>
        {(selectedCategoryObject.elements || []).map((child, index) => (
          <JsonFormsDispatch key={`${path}-${index}`} uischema={child} schema={schema} path={path} />
        ))}
      </Form>
    </div>
  )
}

export function getFormLayoutOptions(form: FormInstance): Partial<FormProps> {
  return {
    scrollToFirstError: true,
    component: form ? "div" : "form",
  }
}
