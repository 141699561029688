import styled from "styled-components"
import { Heading1, BodyStrong, LG } from "src/global/ui/typography/Text/Text.tsx"
import { Image } from "src/global/ui/Image"
import { formatLocalDate, formatLocalTime } from "src/global/utils/formatTime.ts"

interface MaintenanceProps {
  className?: string
  completionDate: string
}

const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  min-height: 100vh;
`
const MaintenanceSubtitle = styled(Heading1)`
  margin-bottom: 16px;
  margin-left: 24px;
`

const MaintenanceCaptionRegular = styled(LG)`
  margin-bottom: 16px;
  margin-left: 24px;
`

function MaintenanceScreen({ className, completionDate }: MaintenanceProps) {
  const IMG_DIMENSIONS = "120px"
  const localTime = formatLocalTime(completionDate)
  const localDate = formatLocalDate(completionDate)
  return (
    <MainContainer className={className}>
      <Image
        type="maintenance"
        svgProps={{ height: IMG_DIMENSIONS, width: IMG_DIMENSIONS }}
        style={{ height: IMG_DIMENSIONS, width: IMG_DIMENSIONS }}
      />
      <div>
        <MaintenanceSubtitle>GX Cloud is undergoing maintenance</MaintenanceSubtitle>
        <MaintenanceCaptionRegular>
          We are performing scheduled maintenance to improve your experience.
        </MaintenanceCaptionRegular>
        <MaintenanceCaptionRegular>
          We will be back at <BodyStrong>{localTime}</BodyStrong> on <BodyStrong>{localDate}</BodyStrong>.
        </MaintenanceCaptionRegular>
      </div>
    </MainContainer>
  )
}

export { MaintenanceScreen }
