import { useFlags } from "launchdarkly-react-client-sdk"

export const featureFlags = [
  "forceEnableAgent",
  "genericSqlDataSourceSupport",
  "maintenanceEnabled",
  "selfServiceAccountCreationEnabled",
  "selfServiceSignupButtonEnabled",
  "sqliteDataSourceSupport",
  "rdAssistantEnabled",
  "dataQualityBasedSelectionEnabled",
  "incidentAlertsEnabled",
  "testCoverageMetricsDataAssetTable",
  "completenessChangeDetectionEnabled",
  // User plan flags
  "teamPlanEnabled",
] as const

export type FeatureFlag = (typeof featureFlags)[number]
// we only support boolean flags currently
export type FlagSet = Record<FeatureFlag, boolean>

export function useIsFeatureEnabled(flagName: FeatureFlag): boolean {
  // TODO - send flag exposure event or property to posthog here, in the process of defining event guidelines with Tetris
  const flags = useFlags<FlagSet>()

  return Boolean(flags?.[flagName])
}
