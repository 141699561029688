import { PropsWithChildren, ReactElement } from "react"
import { Col, Row } from "antd"
import styled from "styled-components"
import { MainContentList } from "src/global/ui/List/MainContentList.tsx"

const CardsContainer = styled.div`
  padding: ${({ theme }) => theme.spacing.xs};
`

type PickerHeaderWithFilterOptionsProps = {
  searchOptions: ReactElement
  filterOptions: ReactElement
}

export const PickerHeaderWithFilterOptions = ({
  searchOptions,
  filterOptions,
  children,
}: PropsWithChildren<PickerHeaderWithFilterOptionsProps>) => {
  const header = (
    <Row gutter={16}>
      <Col flex={3}>{searchOptions}</Col>
      <Col>{filterOptions}</Col>
    </Row>
  )
  return (
    <MainContentList
      //-24 below removes the top-padding from the drawer's body class=ant-drawer-body
      customHeaderHeight="-24"
      header={header}
    >
      <CardsContainer>{children}</CardsContainer>
    </MainContentList>
  )
}
