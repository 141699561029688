import { removeLocalStorageItem } from "src/global/utils/localStorage.ts"
import { isEnableAgentRequestPendingVar, itemKey } from "src/pages/DataAssets/components/RequestAgentAlert.tsx"
import { useEffect } from "react"
import { useIsFeatureEnabled } from "src/global/hooks/useIsFeatureEnabled.ts"

/**
 * Updates localstorage to reflect the "state of the world" at a given point in time.
 */
export function useCleanupEnableAgentRequestState() {
  const isAgentEnabled = useIsFeatureEnabled("forceEnableAgent")

  useEffect(() => {
    if (isAgentEnabled) {
      isEnableAgentRequestPendingVar(false)
      removeLocalStorageItem(itemKey)
    }
  }, [isAgentEnabled])
}
