import { Icon } from "src/global/ui/Icon"
import styled, { css } from "styled-components"

const IconContainer = styled.div<{
  $containerStyles?: {
    marginLeft: string
    marginTop: string
  }
}>`
  display: flex;
  align-items: center;
  height: 100%;
  ${({ $containerStyles }) => {
    if ($containerStyles) {
      return css`
        margin-left: ${$containerStyles.marginLeft};
        margin-top: ${$containerStyles.marginTop};
      `
    }
  }};
`

const StyledIcon = styled(Icon)<{
  $isDropdownVisible?: boolean
  $showSearch?: boolean
  $iconStyles?: {
    paddingTop: string
  }
}>`
  transform: rotate(${({ $isDropdownVisible, $showSearch }) => ($isDropdownVisible && !$showSearch ? "180deg" : 0)});
  ${({ $showSearch, theme }) => {
    if ($showSearch) {
      return css`
        color: ${theme.colors.neutralColorPalette.blacks.colorText};
      `
    }
    return css`
      transition: all 0.3s ease;
    `
  }};
  transform-box: fill-box;
  transform-origin: center;
  padding-top: ${({ $isDropdownVisible, $showSearch, $iconStyles }) => {
    if ($isDropdownVisible && !$showSearch) {
      return $iconStyles ? $iconStyles.paddingTop : "3px"
    }
    return 0
  }};
`

export function SelectSuffixIcon({
  showSearch,
  isDropdownVisible,
  containerStyles,
  iconStyles,
}: {
  showSearch: boolean
  isDropdownVisible: boolean
  containerStyles?: {
    marginLeft: string
    marginTop: string
  }
  iconStyles?: {
    paddingTop: string
  }
}) {
  return (
    <IconContainer $containerStyles={containerStyles}>
      <StyledIcon
        $showSearch={showSearch}
        $isDropdownVisible={isDropdownVisible}
        $iconStyles={iconStyles}
        name={showSearch && isDropdownVisible ? "search" : "chevronDown"}
        size="16px"
      />
    </IconContainer>
  )
}
