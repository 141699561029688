import { Search } from "src/pages/DataAssets/components/Search.tsx"
import { DataAssetSort } from "src/pages/DataAssets/components/DataAssetsSort.tsx"
import Checkbox, { CheckboxChangeEvent } from "antd/es/checkbox"
import { useUrlParams } from "src/global/utils/urlParams.ts"
import { DatasourceTypeV2 } from "src/api/graphql/graphql.ts"
import { DataAssetSortOption, valueIsValidDataAssetSortOption } from "src/pages/DataAssets/utils.ts"
import { DefaultDataAssetSortOption } from "src/pages/DataAssets/hooks/useDataAssets.ts"
import { MainContentListHeader } from "src/global/ui/List/MainContentList.tsx"

export type DataAssetSortParams = {
  failures: string
  search: string
  datasourceType: DatasourceTypeV2[]
  order: DataAssetSortOption
}

function DataAssetsFilter() {
  const [{ failures, order: orderUrlParam }, setParams] = useUrlParams<DataAssetSortParams>({
    failures: "",
    search: "",
    datasourceType: [],
    order: DefaultDataAssetSortOption,
  })

  const onlyFailures = failures === "true"
  const onCheckboxChange = (e: CheckboxChangeEvent) => {
    setParams({ failures: `${e.target.checked}` })
  }

  const sanitizedOrder = valueIsValidDataAssetSortOption(orderUrlParam) ? orderUrlParam : DefaultDataAssetSortOption

  return (
    <MainContentListHeader
      search={<Search placeholder="Search for Data Asset or Data Source name" />}
      sort={
        <DataAssetSort
          order={sanitizedOrder}
          setOrder={(newOrder: DataAssetSortOption) => setParams({ order: newOrder })}
        />
      }
      checkbox={
        <Checkbox checked={onlyFailures} onChange={onCheckboxChange}>
          Only show failed validations
        </Checkbox>
      }
    />
  )
}

export { DataAssetsFilter }
