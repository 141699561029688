import type { UISchema } from "@great-expectations/jsonforms-antd-renderers"
import { Form, FormInstance } from "antd"
import { JSONSchema } from "json-schema-to-ts"
import { useState } from "react"
import { JsonForm } from "src/pages/DataAssets/components/JsonForm.tsx"

interface TokenFormProps {
  form: FormInstance
}

const schema = {
  title: "Token Form",
  type: "object",
  properties: {
    tokenName: {
      type: "string",
      title: "Token name",
    },
  },
} as const satisfies JSONSchema

const uischema = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Control",
      scope: "#/properties/tokenName",
      label: "Token name",
      options: {
        placeholderText: "Name as it will appear (this is not the token itself)",
        required: true,
      },
    },
  ],
} satisfies UISchema<typeof schema>

export const TokenForm = ({ form }: TokenFormProps) => {
  const [data, updateData] = useState<Record<string, unknown>>({
    tokenName: form.getFieldValue("tokenName") || "",
  })

  return (
    <Form form={form} layout="vertical" title="Create access token form">
      <JsonForm
        jsonSchema={schema}
        uiSchema={uischema}
        data={data}
        updateData={(val) => {
          form.setFieldValue("tokenName", val.tokenName)
          updateData(val)
        }}
      />
    </Form>
  )
}
