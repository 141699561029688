import { useQuery } from "@apollo/client"
import { graphql } from "src/api/graphql/gql"
import { Col, Row } from "antd"
import { StatDisplay } from "src/pages/DataAssets/components/StatDisplay"
import { StatDisplaySet } from "src/pages/DataAssets/components/StatDisplaySet"
import { theme } from "src/global/ui/themes/theme"
import { useAnalytics } from "src/global/hooks/analytics/useAnalytics"
import { useEffect } from "react"
import { useUrlParams } from "src/global/utils/urlParams"
import { useIsFeatureEnabled } from "src/global/hooks/useIsFeatureEnabled"
import { formatDateRangeString } from "src/pages/DataAssets/components/dataAssetsTableUtils"
import { Button } from "src/global/ui/Button/Button"

export const ACTIVE_COVERAGE_TOOLTIP = "A composite score that considers active Data Assets and coverage."
export const ACTIVE_ASSETS_TOOLTIP = "The percentage of Data Assets that have had any Validations in the last 30 days."
export const VIEW_DATA_ASSETS_CTA_TEXT = "View Data Assets"
export const COVERAGE_TOOLTIP =
  "The percentage of Data Assets that have at least one schema, volume, or completeness Expectation."
export const SUCCESSFUL_COVERAGE_VALUE = 100

export const AggregateCoverageStatsDocument = graphql(`
  query AggregateCoverageStatsDocument {
    coverage {
      activeAssets
      completenessCoverage
      coverageScore
      schemaCoverage
      volumeCoverage
    }
  }
`)

type UrlParams = {
  filter: string
  sort: string
  search: string
}

function setUrlParamsWithDefaults(setUrlParams: (params: Partial<UrlParams>) => void, overrides: Partial<UrlParams>) {
  setUrlParams({
    filter: "",
    sort: "",
    search: "",
    ...overrides,
  })
}

function AggregateAssetCoverageStats() {
  const posthog = useAnalytics()
  const [, setUrlParams] = useUrlParams<UrlParams>({
    filter: "",
    sort: "",
    search: "",
  })
  const isTableViewEnabled = useIsFeatureEnabled("testCoverageMetricsDataAssetTable")

  const { data, loading } = useQuery(AggregateCoverageStatsDocument)

  const schemaCoverage = convertDecimalToPercent(data?.coverage?.schemaCoverage)
  const volumeCoverage = convertDecimalToPercent(data?.coverage?.volumeCoverage)
  const completenessCoverage = convertDecimalToPercent(data?.coverage?.completenessCoverage)
  const activeAssets = convertDecimalToPercent(data?.coverage?.activeAssets)
  const coverageScore = convertDecimalToPercent(data?.coverage?.coverageScore)

  useEffect(() => {
    if (!loading && data?.coverage) {
      posthog.capture("metric_coverage_stats_aggregate.viewed", {
        coverage_score: coverageScore,
        active_assets: activeAssets,
        schema_coverage: schemaCoverage,
        volume_coverage: volumeCoverage,
        completeness_coverage: completenessCoverage,
      })
    }
  }, [
    loading,
    posthog,
    data?.coverage,
    coverageScore,
    activeAssets,
    schemaCoverage,
    volumeCoverage,
    completenessCoverage,
  ])

  if (!data?.coverage) {
    return null
  }

  const groupDQICoverageStats = [
    {
      label: "Schema",
      value: schemaCoverage,
      filterKey: "schema",
    },
    {
      label: "Volume",
      value: volumeCoverage,
      filterKey: "volume",
    },
    {
      label: "Completeness",
      value: completenessCoverage,
      filterKey: "completeness",
    },
  ]

  const activeCoverageStats = [
    {
      label: "Active Coverage",
      value: coverageScore,
      tooltip: ACTIVE_COVERAGE_TOOLTIP,
    },
    {
      label: "Active Data Assets",
      value: activeAssets,
      tooltip: ACTIVE_ASSETS_TOOLTIP,
      ariaLabel: "Filter to inactive Data Assets",
      onClick: isTableViewEnabled
        ? () => {
            const thirtyDaysAgo = new Date()
            thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30)

            const tomorrow = new Date()
            tomorrow.setDate(tomorrow.getDate() + 1)

            posthog.capture("metric_coverage_stats_cta.click", {
              stat_type: "active_assets",
              coverage_type: "aggregate",
              stat_display_title: "Active Data Assets",
            })

            setUrlParamsWithDefaults(setUrlParams, {
              filter: JSON.stringify({
                lastValidation: formatDateRangeString(thirtyDaysAgo, tomorrow, true),
              }),
            })
          }
        : undefined,
    },
  ]

  return (
    <Row gutter={[16, 16]} style={{ marginBottom: theme.spacing.xxs }}>
      {activeCoverageStats.map((stat) => (
        <Col key={stat.label} span={6} style={{ minWidth: "250px" }}>
          <StatDisplaySet title={stat.label} info={stat.tooltip}>
            <StatDisplay
              key={stat.label}
              cta={
                stat.value < SUCCESSFUL_COVERAGE_VALUE && stat.onClick ? (
                  <FilterButton onClick={stat.onClick} ariaLabel={stat.ariaLabel} />
                ) : undefined
              }
            >
              <StatDisplay.Percent value={stat.value} />
              {stat.value < SUCCESSFUL_COVERAGE_VALUE ? <StatDisplay.Warning /> : <StatDisplay.Success />}
            </StatDisplay>
          </StatDisplaySet>
        </Col>
      ))}
      <Col span={12} style={{ minWidth: "600px" }}>
        <StatDisplaySet title="Coverage" info={COVERAGE_TOOLTIP}>
          {groupDQICoverageStats?.map((stat) => (
            <StatDisplay
              key={stat.label}
              label={stat.label}
              cta={
                stat.value < SUCCESSFUL_COVERAGE_VALUE && isTableViewEnabled && stat.filterKey ? (
                  <FilterButton
                    ariaLabel={`Filter to Data Assets missing ${stat.label.toLocaleLowerCase()} coverage`}
                    onClick={() => {
                      posthog.capture("metric_coverage_stats_cta.click", {
                        stat_type: stat.filterKey,
                        coverage_type: "aggregate",
                        stat_display_title: "Coverage",
                      })
                      setUrlParamsWithDefaults(setUrlParams, { filter: JSON.stringify({ [stat.filterKey]: false }) })
                    }}
                  />
                ) : undefined
              }
            >
              <StatDisplay.Percent value={stat.value} />
              {stat.value < SUCCESSFUL_COVERAGE_VALUE ? <StatDisplay.Warning /> : <StatDisplay.Success />}
            </StatDisplay>
          ))}
        </StatDisplaySet>
      </Col>
    </Row>
  )
}

type FilterButtonProps = {
  onClick: () => void
  ariaLabel?: string
}
function FilterButton({ onClick, ariaLabel }: FilterButtonProps) {
  return (
    <Button type="default" onClick={onClick} icon="filter" size="small" aria-label={ariaLabel}>
      {VIEW_DATA_ASSETS_CTA_TEXT}
    </Button>
  )
}

export { AggregateAssetCoverageStats, FilterButton }

function convertDecimalToPercent(decimal?: number) {
  if (decimal === undefined || decimal === null) return 0
  return decimal * 100
}
