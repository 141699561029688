import { CaptureOptions, CaptureResult, Properties } from "posthog-js"
import { usePostHog } from "posthog-js/react"
import { useMemo } from "react"
import { SERVICE_NAME } from "src/global/config.ts"
import { REACT_APP_ENV } from "src/global/env.ts"
import { useOrganizations } from "src/pages/OrganizationRoot/hooks/useOrganizations.ts"

/*
When possible, add events to the backend, as they are more reliable than front-end events (which may not reach us due to ad blockers, firewalls, etc.).
If testing customer adoption of a new button/field/page, PostHog autocapture can do this without a custom event type.
PostHog autocapture cannot handle certain actions like hover; see https://posthog.com/docs/product-analytics/autocapture#limitations-of-autocapture for details.
*/

export type AnalyticsEvent =
  | "Expectation with Conditional Param"
  | "Expectation with Evaluation Param"
  | "Hover over Evaluation Param value in Validation Result"
  | "expectation.create_succeeded"
  | "expectation.create_failed"
  | "expectation.selected"
  | "expectation_dqi.selected"
  | "checkpoint.view_snippet"
  | "checkpoint.run_from_agent"
  | "checkpoint.run_from_runner"
  | "$logout" // we have "$login" event send by auth0 so we need to have "$logout" as well
  | "survey.submitted"
  | "survey.seen"
  | "agent_setup.show_access_token_clicked"
  | "agent_setup.complete"
  | "agent_setup.feedback_submitted"
  | "agent_setup.setup_guide_clicked"
  | "agent_setup.prefer_to_use_api_clicked"
  | "invalid_url.accessed"
  | "add_asset.gxUser_modal_viewed"
  | "add_asset.snippet_copied"
  | "add_asset.continue_clicked"
  | "add_asset.existing_datasource.submit"
  | "validation_result.accessed_from_slack_notification"
  | "alerts.button_clicked"
  | "alerts.add_alert_button_clicked"
  | "banner_pricing_link.clicked"
  | "validation_result.accessed_from_email_alert"
  | "expectations_tab.accessed_from_email_alert"
  | "request_sales_demo.menu_item_clicked"
  | "request_sales_demo.modal_seen"
  | "request_sales_demo.modal_dismissed"
  | "request_sales_demo.floating_banner_clicked"
  | "request_sales_demo.floating_banner_dismissed"
  | "request_sales_demo.post_validation_modal_seen"
  | "request_sales_demo.post_validation_modal_dismissed"
  | "request_sales_demo.datasource_card_clicked"
  | "metric_coverage_stats_aggregate.viewed"
  | "metric_coverage_stats.viewed"
  | "metric_coverage_stats_tooltip.hover"
  | "metric_coverage_stats_active_tag_tooltip.hover"
  | "metric_coverage_stats_cta.click"

type GroupType = "organization"

interface AnalyticsHandle {
  // eslint-disable-next-line @typescript-eslint/no-invalid-void-type -- 3rd party type, we don't control it.
  capture: (event: AnalyticsEvent, properties?: Properties, opts?: CaptureOptions) => void | CaptureResult
  group: (type: GroupType, key: string, properties?: Properties) => void
  identify: (id: string, properties?: Properties) => void
}

const UNINITIALIZED = () => console.warn("Analytics not initialized")

const DEFAULT_ANALYTICS_HANDLE = {
  capture: UNINITIALIZED,
  group: UNINITIALIZED,
  identify: UNINITIALIZED,
} satisfies AnalyticsHandle

// useAnalytics provides analytics capabilities.
// It wraps `usePostHog` which should be imported in this file only (clean architecture).
export function useAnalytics(): AnalyticsHandle {
  const lib = usePostHog()
  const orgID = useOrganizations().currentOrg?.id

  // if we don't memoize this then we cause rerenders to any consumers that use these functions
  // in callbacks or useEffects
  return useMemo(
    () =>
      lib
        ? {
            // eslint-disable-next-line @typescript-eslint/no-invalid-void-type -- 3rd party type, we don't control it.
            capture: (event: AnalyticsEvent, properties?: Properties, opts?: CaptureOptions): void | CaptureResult => {
              lib.capture(
                event,
                { organization_id: orgID, environment: REACT_APP_ENV, service: SERVICE_NAME, ...properties },
                opts,
              )
            },
            group: (type: GroupType, key: string, properties?: Properties): void => {
              lib.group(type, key, properties)
            },
            identify: (id: string, properties?: Properties): void => {
              lib.identify(id, properties)
            },
          }
        : DEFAULT_ANALYTICS_HANDLE,
    [lib, orgID],
  )
}
