import { Descriptions } from "antd"
import { Preview } from "src/pages/DataAssets/views/Expectations/Expectation/Preview.tsx"
import { ExpectationRenderer } from "src/pages/DataAssets/views/Expectations/Expectation/ExpectationRenderer.tsx"
import { ExpectationInfoCard } from "src/global/ui/Card/Card.tsx"
import { useTheme } from "styled-components"
import { ExpectationMetaInfo } from "src/global/schemas/expectation-metadata-utils.ts"

type ExpectationInformationProps = {
  metaInfo: ExpectationMetaInfo
  renderer?: ExpectationRenderer
}

export const ExpectationInformation = ({ renderer, metaInfo }: ExpectationInformationProps) => {
  const theme = useTheme()

  const preview = renderer ? <Preview renderer={renderer} /> : null

  const infoCardBody =
    metaInfo.description && renderer ? (
      <Descriptions
        column={1}
        layout="vertical"
        labelStyle={{ color: theme.colors.neutralColorPalette.blacks.colorText }}
        items={[
          {
            label: "Description",
            children: metaInfo.description,
          },
          {
            label: "Current Expectation",
            children: preview,
          },
        ]}
      />
    ) : (
      metaInfo.description || preview
    )

  return (
    <ExpectationInfoCard title={metaInfo.title} dataQualityIssues={metaInfo.dataQualityIssues}>
      {infoCardBody}
    </ExpectationInfoCard>
  )
}
