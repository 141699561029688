import { makeFragmentData } from "src/api/graphql/fragment-masking.ts"
import {
  DataAssetBreadcrumbs_DataSourceFragmentDocument,
  getDataAssetBreadcrumbs,
} from "src/pages/DataAssets/views/AssetDetails/DataAssetBreadcrumbs.tsx"
import { List, Space } from "antd"
// eslint-disable-next-line no-restricted-imports -- deprecated file
import {
  UseDataAssets_DataAssetFragment,
  UseDataAssets_DatasourceFragment,
} from "src/api/graphql/graphql-operations.ts"
import { getImageLogo } from "src/pages/DataAssets/getImageLogo.ts"
import styled, { css } from "styled-components"
import { Pill } from "src/global/ui/Pill/Pill.tsx"
import { formatLocalCalendarDateWithTime, formatOutputBasedOnAbsoluteDate } from "src/global/utils/formatTime.ts"
import { Button } from "src/global/ui/Button/Button.tsx"
import { EditDataAssetButton } from "src/pages/DataAssets/components/EditDataAssetButton.tsx"
import { MainContentListItemMeta } from "src/global/ui/List/MainContentList.tsx"
import { useState } from "react"
import { DeleteDataAssetModal } from "src/pages/DataAssets/components/DeleteDataAssetModal.tsx"
import { useIsAssetEditable } from "src/global/hooks/useIsAssetEditable.ts"
import { useRequireRole } from "src/global/hooks/useRequireRole.ts"
import { AppBreadcrumb } from "src/global/ui/AppBreadcrumb/AppBreadcrumb.tsx"
import { DescriptionStrong } from "src/global/ui/typography/Text/Text.tsx"
import { UnstyledLink } from "src/global/ui/typography/Link/Link.tsx"

const StyledPill = styled(Pill)`
  pointer-events: none;
  ${({ theme }) => css`
    font-weight: ${theme.typography.fontWeight.semibold};
    font-size: ${theme.typography.fontSize.extraSmall};
    text-align: right;
  `};
`
const StyledLink = styled(UnstyledLink)`
  width: 100%;
  display: flex;
  p {
    margin: 0;
  }
`

const StyledItem = styled(List.Item)`
  &:hover {
    background-color: ${({ theme }) => theme.colors.primaryColors.gxAccentBackground};
  }
`

type DataAssetListItemProps = {
  asset: UseDataAssets_DataAssetFragment
  dataSource: Omit<UseDataAssets_DatasourceFragment, "assets">
  onEdit: () => void
}

export function DataAssetListItem({ asset, dataSource, onEdit }: DataAssetListItemProps) {
  const userHasEditorRole = useRequireRole("EDITOR")
  const [isModalVisible, setIsModalVisible] = useState(false)
  const isEditable = useIsAssetEditable(dataSource.type, asset.assetType ?? "")

  if (!asset.id || !asset.name) return null

  const assetLink = `/data-assets/${asset.id}/expectations`

  return (
    <StyledItem
      key={asset.id}
      aria-label={`Data Asset ${asset.name}`}
      actions={
        userHasEditorRole
          ? [
              <EditDataAssetButton key="edit" onClick={onEdit} disabled={!isEditable} />,
              <Button
                title="Delete Data Asset"
                onClick={() => setIsModalVisible(true)}
                type="text"
                key="delete"
                icon="trash"
              />,
            ]
          : []
      }
    >
      <DeleteDataAssetModal dataAsset={asset} isVisible={isModalVisible} setIsVisible={setIsModalVisible} />
      <StyledLink to={assetLink} title="Go to Data Asset details">
        <MainContentListItemMeta
          title={asset.name}
          avatar={<img src={getImageLogo(dataSource.type)} alt={`${dataSource.type} logo`} />}
          description={
            <AppBreadcrumb
              items={getDataAssetBreadcrumbs({
                dataSource: makeFragmentData(dataSource, DataAssetBreadcrumbs_DataSourceFragmentDocument),
                showDataSourceLogo: false,
              })}
            />
          }
        />
        <Space direction="vertical" size={[4, 8]} wrap>
          <MostRecentValidationInformation asset={asset} />
        </Space>
      </StyledLink>
    </StyledItem>
  )
}

function MostRecentValidationInformation({ asset }: { asset: UseDataAssets_DataAssetFragment }) {
  const latestRanAt = asset.lastValidatedAt
  const successStatus = asset.aggregateSuccessStatus
  if (!latestRanAt || successStatus === null) return null
  return (
    <Space direction="vertical" align="end">
      <DescriptionStrong>{`Last validated ${formatLocalCalendarDateWithTime(
        latestRanAt,
        formatOutputBasedOnAbsoluteDate,
      )}`}</DescriptionStrong>
      <StyledPill state={successStatus ? "Unselected Success" : "Unselected Failure"}>
        {successStatus ? "All passed" : "Failed"}
      </StyledPill>
    </Space>
  )
}
