import { ColumnLayoutProps, UISchema } from "@great-expectations/jsonforms-antd-renderers"
import {
  JsonFormsUISchemaRegistryEntry,
  UISchemaElement,
  LabelProps,
  RendererProps,
  JsonFormsRendererRegistryEntry,
} from "@jsonforms/core"
import { withJsonFormsLabelProps } from "@jsonforms/react"
import { AlertProps } from "antd"
import { jsonSchemas } from "src/global/schemas/expectation-catalog-schemas.ts"

// Create a PlaintextLabel type for non-functional conjunctions in form layout, e.g.
// "to be between" min_value "AND" max_value, where "AND" is the non-functional
// conjunction.
type PlaintextLabel = {
  type: "Label"
  text: string
  options: {
    type: AlertProps["type"] | "plain"
  }
  layoutProps: ColumnLayoutProps
}

// Some min/max expectations implement strict_min and strict_max, i.e.
// expect_column_min_to_be_between.
// This registry captures the fixed variant of those.
export const FixedMinMaxWithStrictUISchemaRegistry: JsonFormsUISchemaRegistryEntry = {
  tester: (schema, schemaPath) => {
    if (
      schemaPath === "#/properties/to_be_between" &&
      schema.properties &&
      "min_value" in schema.properties &&
      "strict_min" in schema.properties
    ) {
      return 100
    }
    return -1
  },
  uischema: {
    type: "HorizontalLayout",
    elements: [
      {
        type: "Control",
        scope: "#/properties/min_value",
        label: "",
        formItemProps: {
          tooltip: jsonSchemas.expectColumnMinToBeBetween.schema.properties.min_value.description,
        },
        layoutProps: {
          columns: 8,
        },
      },
      {
        type: "Control",
        scope: "#/properties/strict_min",
        label: "Strict",
        formItemProps: {
          tooltip: jsonSchemas.expectColumnMinToBeBetween.schema.properties.strict_min.description,
          style: {
            marginBottom: "24px",
          },
        },
        layoutProps: {
          columns: 2,
        },
      },
      {
        type: "Label",
        text: "AND",
        options: {
          type: "plain",
        },
        layoutProps: {
          columns: 1,
        },
      },
      {
        type: "Control",
        scope: "#/properties/max_value",
        label: "",
        formItemProps: {
          tooltip: jsonSchemas.expectColumnMinToBeBetween.schema.properties.max_value.description,
          style: {
            width: "100%",
          },
        },
        layoutProps: {
          columns: 8,
        },
      },
      {
        type: "Control",
        scope: "#/properties/strict_max",
        label: "Strict",
        formItemProps: {
          tooltip: jsonSchemas.expectColumnMinToBeBetween.schema.properties.strict_max.description,
        },
        layoutProps: {
          columns: 2,
        },
      },
    ],
  } satisfies UISchema<(typeof jsonSchemas.expectColumnMinToBeBetween.schema.properties.to_be_between.oneOf)[0]> & {
    type: "HorizontalLayout"
    elements: (UISchemaElement | PlaintextLabel)[]
  } as UISchemaElement,
}

// Some min/max expectations _don't_ implement strict_min and strict_max, i.e.
// expect_table_column_count_to_be_between.
// This registry captures the fixed variant of those.
export const FixedMinMaxNoStrictUISchemaRegistry: JsonFormsUISchemaRegistryEntry = {
  tester: (schema, schemaPath) => {
    if (
      schemaPath === "#/properties/to_be_between" &&
      schema.properties &&
      "min_value" in schema.properties &&
      !("strict_min" in schema.properties)
    ) {
      return 100
    }
    return -1
  },
  uischema: {
    type: "HorizontalLayout",
    elements: [
      {
        type: "Control",
        scope: "#/properties/min_value",
        label: "",
        formItemProps: {
          tooltip: jsonSchemas.expectColumnMinToBeBetween.schema.properties.min_value.description,
        },
        layoutProps: {
          columns: 10,
        },
      },
      {
        type: "Label",
        text: "AND",
        options: {
          type: "plain",
        },
        layoutProps: {
          columns: 1,
        },
      },
      {
        type: "Control",
        scope: "#/properties/max_value",
        label: "",
        formItemProps: {
          tooltip: jsonSchemas.expectColumnMinToBeBetween.schema.properties.max_value.description,
          style: {
            width: "100%",
          },
        },
        layoutProps: {
          columns: 10,
        },
      },
    ],
  } satisfies UISchema<(typeof jsonSchemas.expectColumnMinToBeBetween.schema.properties.to_be_between.oneOf)[0]> & {
    type: "HorizontalLayout"
    elements: (UISchemaElement | PlaintextLabel)[]
  } as UISchemaElement,
}

// Capture the fixed variant of expect_column_values_to_be_null and expect_column_values_to_not_be_null
export const FixedWithMostlyUISchemaRegistry: JsonFormsUISchemaRegistryEntry = {
  tester: (schema, schemaPath) => {
    if (schemaPath === "#/properties/to_be_between" && schema.properties && "mostly" in schema.properties) {
      return 100
    }
    return -1
  },
  uischema: {
    type: "HorizontalLayout",
    elements: [
      {
        type: "Control",
        scope: "#/properties/mostly",
        label: "",
        options: {
          addonAfter: "%",
        },
        layoutProps: {
          columns: 24,
        },
      },
    ],
  } satisfies UISchema<(typeof jsonSchemas.expectColumnValuesToBeNull.schema.properties.to_be_between.oneOf)[0]> & {
    type: "HorizontalLayout"
    elements: (UISchemaElement | PlaintextLabel)[]
  } as UISchemaElement,
}

// Capture the windowed variants of dynamic expectations that implement a user-defined
// "offset", i.e. +, -, +/-
// Chiefly applies to min/max expectations.
export const WindowedWithOffsetUISchemaRegistry: JsonFormsUISchemaRegistryEntry = {
  tester: (schema, schemaPath) => {
    if (schemaPath === "#/properties/to_be_between" && schema.properties && "offset" in schema.properties) {
      return 100
    }
    return -1
  },
  uischema: {
    type: "HorizontalLayout",
    elements: [
      {
        type: "Control",
        label: "",
        scope: "#/properties/offset",
        layoutProps: {
          columns: 4,
        },
      },
      {
        type: "Control",
        label: "",
        scope: "#/properties/percent",
        options: {
          addonAfter: "%",
        },
        layoutProps: {
          columns: 4,
        },
      },
      {
        type: "Label",
        text: "of the average of the last",
        options: {
          type: "plain",
        },
        layoutProps: {
          columns: 7,
        },
      },
      {
        type: "Control",
        label: "",
        scope: "#/properties/range",
        options: {
          addonAfter: "runs",
        },
        layoutProps: {
          columns: 5,
        },
      },
      {
        type: "Control",
        scope: "#/properties/strict",
        layoutProps: {
          columns: 3,
        },
      },
    ],
  } satisfies UISchema<(typeof jsonSchemas.expectColumnMinToBeBetween.schema.properties.to_be_between.oneOf)[1]> & {
    type: "HorizontalLayout"
    elements: (UISchemaElement | PlaintextLabel)[]
  } as UISchemaElement,
}

// Capture the windowed variants of dynamic expectations that have a `mostly` kwarg.
// This primarily applies to expect_column_values_to_be_null and expect_column_values_to_not_be_null.
export const WindowedWithPercentSliderUISchemaRegistry: JsonFormsUISchemaRegistryEntry = {
  tester: (schema, schemaPath) => {
    if (
      schemaPath === "#/properties/to_be_between" &&
      schema.properties &&
      "percent" in schema.properties &&
      ("minimum" in schema.properties.percent || "maximum" in schema.properties.percent)
    ) {
      return 150
    }
    return -1
  },
  uischema: {
    type: "HorizontalLayout",
    elements: [
      {
        type: "Control",
        label: "",
        scope: "#/properties/offset",
        layoutProps: {
          columns: 3,
        },
      },
      {
        type: "Control",
        label: "",
        scope: "#/properties/percent",
        options: {
          addonAfter: "%",
        },
        layoutProps: {
          columns: 10,
        },
      },
      {
        type: "Label",
        text: "of the average of the last",
        options: {
          type: "plain",
        },
        layoutProps: {
          columns: 4,
        },
      },
      {
        type: "Control",
        label: "",
        scope: "#/properties/range",
        options: {
          addonAfter: "runs",
        },
        layoutProps: {
          columns: 4,
        },
      },
      {
        type: "Control",
        scope: "#/properties/strict",
        layoutProps: {
          columns: 3,
        },
      },
    ],
  } satisfies UISchema<(typeof jsonSchemas.expectColumnMinToBeBetween.schema.properties.to_be_between.oneOf)[1]> & {
    type: "HorizontalLayout"
    elements: (UISchemaElement | PlaintextLabel)[]
  } as UISchemaElement,
}

export const PlaintextRendererUISchemaRegistry: JsonFormsRendererRegistryEntry = {
  tester: (uischema) => {
    if (uischema.type === "Label" && uischema.options?.type === "plain") {
      return 100
    }
    return 0
  },
  renderer: withJsonFormsLabelProps(PlaintextRenderer),
}

function PlaintextRenderer({ text }: LabelProps & RendererProps) {
  return <p style={{ marginBottom: "24px", marginTop: "0px" }}>{text}</p>
}
