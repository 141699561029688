import { InfoCircleFilled } from "@ant-design/icons"
import { Alert as AlertAntD, Flex, Tooltip } from "antd"
import { theme } from "src/global/ui/themes/theme.ts"

interface AlertInfoProps {
  message: string | JSX.Element
  description?: string | JSX.Element
  tooltip?: string
}

function AlertInfo({ message, description, tooltip }: AlertInfoProps) {
  return (
    <>
      {!tooltip && (
        <AlertAntD
          message={message}
          description={description}
          type="info"
          showIcon
          style={{ borderWidth: 0, padding: "12px" }}
        />
      )}
      {!!tooltip && (
        <AlertAntD
          message={
            <Flex>
              <Tooltip title={tooltip}>
                <InfoCircleFilled style={{ color: theme.colors.info, marginRight: theme.spacing.xxs }} />
              </Tooltip>
              {message}
            </Flex>
          }
          description={description}
          type="info"
          style={{ borderWidth: 0, padding: "12px" }}
        />
      )}
    </>
  )
}

export { AlertInfo }
