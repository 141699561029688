import { useApolloClient, useMutation, useQuery } from "@apollo/client"
import { graphql } from "src/api/graphql/gql.ts"
import { useRequireRole } from "src/global/hooks/useRequireRole.ts"
import { useIsFeatureEnabled } from "src/global/hooks/useIsFeatureEnabled.ts"
import { GenerateExpectationsButtonUI } from "src/pages/DataAssets/views/Expectations/GenerateExpectationsButton/GenerateExpectationsButtonUI.tsx"
import { useCallback } from "react"
import {
  ExpectationsTab_GetExpectationsDocument,
  AssetCoverageStatsDocument,
} from "src/pages/DataAssets/views/Expectations/SimpleExpectationsTab.tsx"
import { AggregateCoverageStatsDocument } from "src/pages/DataAssets/components/AggregateAssetCoverageStats"

interface GenerateExpectationsButtonProps {
  // assetID is the ID of the asset to generate expectations for
  assetID: string
}

export const GenerateExpectationsButtonFragmentDocument = graphql(`
  fragment GenerateExpectationsButtonFragment on AssetRef {
    id
    hasRunEchoes
    splitter {
      ... on WithID {
        id
      }
    }
    datasourceV2 {
      id
      type
    }
  }
`)

export const GenerateExpectationsButtonJobsFragmentDocument = graphql(`
  fragment GenerateExpectationsButtonJobsFragment on Query {
    jobs {
      id
      status
      jobType
      sourceResources {
        entityId
        entityType
      }
    }
  }
`)

export const CreateRunRdAgentJobDocument = graphql(`
  mutation CreateRunRdAgentJob($input: CreateRunRdAgentJobInput!) {
    createRunRdAgentJob(input: $input) {
      jobId
    }
  }
`)

export const GenerateExpectationsButtonDocument = graphql(`
  query GenerateExpectationsButton($id: UUID!) {
    dataAsset(id: $id) {
      ...GenerateExpectationsButtonFragment
    }
  }
`)

export const GenerateExpectationsButtonJobsDocument = graphql(`
  query GenerateExpectationsButtonJobs {
    ...GenerateExpectationsButtonJobsFragment
  }
`)

export function GenerateExpectationsButton(props: GenerateExpectationsButtonProps): JSX.Element | null {
  const { assetID } = props
  const apollo = useApolloClient()
  const featureEnabled = useIsFeatureEnabled("rdAssistantEnabled")
  const isAgentEnabled = useIsFeatureEnabled("forceEnableAgent")
  const isEditor = useRequireRole("EDITOR")
  const skipQueries = !assetID || !featureEnabled

  const { data: assetData, loading: queryLoading } = useQuery(GenerateExpectationsButtonDocument, {
    variables: { id: assetID },
    skip: skipQueries,
  })

  const { data: jobData } = useQuery(GenerateExpectationsButtonJobsDocument, {
    pollInterval: 2000,
    skip: skipQueries,
  })

  const [createJob, { loading: mutationLoading }] = useMutation(CreateRunRdAgentJobDocument)

  // useCallback allows this function to be used as a dependency in useEffect
  // thereby preventing an infinite render loop
  const onJobComplete = useCallback(() => {
    apollo.refetchQueries({
      include: [ExpectationsTab_GetExpectationsDocument, AssetCoverageStatsDocument, AggregateCoverageStatsDocument],
    })
    apollo.reFetchObservableQueries()
  }, [apollo])

  if (!featureEnabled) {
    // short circuit if the feature flag is off
    return null
  }

  const handleClick = async (batchDefinitionId: string) => {
    await createJob({ variables: { input: { batchDefinitionId } } })
  }

  return (
    <GenerateExpectationsButtonUI
      loading={mutationLoading}
      disabled={queryLoading}
      onClick={handleClick}
      onJobComplete={onJobComplete}
      assetData={assetData?.dataAsset}
      jobData={jobData}
      isEditor={isEditor}
      isAgentEnabled={isAgentEnabled}
    />
  )
}
