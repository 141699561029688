import { Flex, Table, TableProps, Tag, Input, Tooltip } from "antd"
import { Button } from "src/global/ui/Button/Button"
import { useState, useMemo } from "react"
import { DatasourceTypeV2 } from "src/api/graphql/graphql"
import { theme } from "src/global/ui/themes/theme"
import { useOrganizationSlug } from "src/pages/OrganizationRoot/hooks/useOrganizationSlug"
import { getImageLogo } from "src/pages/DataAssets/getImageLogo"
import { formatLocalCalendarDateWithTime } from "src/global/utils/formatTime"
import { DeleteDataAssetModal } from "src/pages/DataAssets/components/DeleteDataAssetModal"
import { FilterDateRangeDropdown } from "src/pages/DataAssets/components/FilterDateRangeDropdown"
import { FilterCoverageDropdown } from "src/pages/DataAssets/components/FilterCoverageDropdown"
import { Icon, IconTypes } from "src/global/ui/Icon"
import { useUrlParams } from "src/global/utils/urlParams"
import { SkeletonTable } from "src/global/components/SkeletonTable"
import {
  convertSortParamsToSortValue,
  convertSortValueToSortParams,
  convertFilterObjectToFilterParams,
  filterByStringField,
  filterByDateRange,
  sortByStringField,
  sortByDateField,
  sortByBooleanField,
  processFilterValueParam,
  parseDQIFilterValue,
  groupCoverageQueryParams,
} from "src/pages/DataAssets/components/dataAssetsTableUtils"
import { uniqBy } from "lodash-es"
import { makeVar } from "@apollo/client"
import styled from "styled-components"

const DATA_ASSETS_PATH = "/data-assets"

export const dataAssetsNavigateToVar = makeVar(DATA_ASSETS_PATH)

export type AssetData = {
  id: string
  type: DatasourceTypeV2
  name: string
  dataSourceName: string
  lastValidation: string | null
  success: boolean | null
  schema: boolean
  volume: boolean
  completeness: boolean
}

type UrlParams = {
  filter: string
  sort: string
  search: string
}

type DataAssetsTableProps = {
  canEdit: boolean
  assetData: AssetData[] | undefined
  loading: boolean
}

const StyledTable: typeof Table = styled(Table)`
  .ant-table {
    border: 1px solid ${({ theme }) => theme.colors.neutralColorPalette.backgroundsAndBorders.gxBorder};
  }
`

export function DataAssetsTable({ canEdit, assetData, loading }: DataAssetsTableProps) {
  const [params, setUrlParams] = useUrlParams<UrlParams>({
    filter: "",
    sort: "",
    search: "",
  })
  const { filter, sort: sortParam, search } = params

  const sort = convertSortParamsToSortValue(sortParam)

  const {
    dataAssets: dataAssetsParam = undefined,
    dataSources: dataSourcesParam = undefined,
    lastValidation: lastValidationParam = undefined,
    status: statusParam = undefined,
    schema: schemaParam = undefined,
    volume: volumeParam = undefined,
    completeness: completenessParam = undefined,
  } = filter ? JSON.parse(filter) : {}

  const handleChange: TableProps["onChange"] = (_, filters, sorter) => {
    const sorterIsArray = Array.isArray(sorter)
    setUrlParams({
      filter: convertFilterObjectToFilterParams(filters),
      sort: convertSortValueToSortParams(
        sorterIsArray ? sorter[0]?.columnKey : sorter?.columnKey,
        sorterIsArray
          ? (sorter[0]?.order as "ascend" | "descend" | undefined)
          : (sorter?.order as "ascend" | "descend" | undefined),
      ),
    })
  }

  const [showDeleteModel, setShowDeleteModal] = useState<{ id: string; name: string } | null>(null)
  const { navigateInOrg } = useOrganizationSlug()

  const uniqueDataSources = useMemo(() => {
    return uniqBy(assetData, "dataSourceName").map((asset) => asset.dataSourceName)
  }, [assetData])

  const dataAssetNames = useMemo(() => {
    return assetData?.map((asset) => asset.name) ?? []
  }, [assetData])

  const filteredAssetData = useMemo(() => {
    if (!search || !assetData) return assetData
    const searchTerm = search.toLowerCase()
    return assetData.filter(
      (asset) =>
        asset.name.toLowerCase().includes(searchTerm) || asset.dataSourceName.toLowerCase().includes(searchTerm),
    )
  }, [assetData, search])

  const sortedAssetData = useMemo(() => {
    if (!filteredAssetData) return filteredAssetData
    return filteredAssetData.sort((a, b) => -sortByDateField(a.lastValidation, b.lastValidation))
  }, [filteredAssetData])

  const baseColumns: TableProps["columns"] = [
    {
      title: "Data Asset",
      key: "name",
      dataIndex: "name",
      filteredValue: dataAssetsParam ? dataAssetsParam.split(",") : undefined,
      filters: dataAssetNames.map((name) => ({ text: name, value: name })),
      onFilter: (value, record) => filterByStringField(value as string, record.name),
      fixed: "left",
      width: 280,
      sorter: (a, b) => sortByStringField(a.name, b.name),
      sortOrder: sort.columnKey === "name" ? sort.order : undefined,
      render: (name, { type }) => (
        <Flex gap={theme.spacing.xs} align="center" style={{ overflow: "hidden", height: "100%" }}>
          <img src={getImageLogo(type)} alt={`${type} logo`} width={22} height={22} />
          <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", flex: 1 }}>{name}</div>
        </Flex>
      ),
    },
    {
      title: "Data Source",
      key: "dataSourceName",
      dataIndex: "dataSourceName",
      width: 250,
      ellipsis: true,
      filteredValue: dataSourcesParam ? dataSourcesParam.split(",") : undefined,
      filters: uniqueDataSources.map((source) => ({ text: source, value: source })),
      onFilter: (value, record) => record.dataSourceName === value,
      sortOrder: sort.columnKey === "dataSourceName" ? sort.order : undefined,
      sorter: (a, b) => sortByStringField(a.dataSourceName, b.dataSourceName),
      render: verticallyCentered,
    },
    {
      title: "Last validated",
      key: "lastValidation",
      dataIndex: "lastValidation",
      filterDropdown: FilterDateRangeDropdown,
      // We don't split the lastValidationParam because we want to filter by range, not an individual value
      filteredValue: processFilterValueParam(lastValidationParam),
      sorter: (a, b) => sortByDateField(a.lastValidation, b.lastValidation),
      sortOrder: sort.columnKey === "lastValidation" ? sort.order : undefined,
      onFilter: (value, record) => filterByDateRange(record.lastValidation, value as string),
      ellipsis: true,
      width: 210,
      render: (timestamp: string | null) =>
        verticallyCentered(timestamp ? formatLocalCalendarDateWithTime(timestamp) : null),
    },
    {
      title: "Status",
      key: "success",
      dataIndex: "success",
      align: "center",
      filters: [
        { text: "All passed", value: true },
        { text: "Failed", value: false },
      ],
      filteredValue: processFilterValueParam(statusParam),
      onFilter: (value, record) => record.success === value,
      sorter: (a, b) => sortByBooleanField(a.success, b.success),
      sortOrder: sort.columnKey === "success" ? sort.order : undefined,
      width: 105,
      render: (status) => renderSuccessStatus(status),
    },
    {
      title: "Coverage",
      key: "coverage",
      align: "center",
      filterDropdown: FilterCoverageDropdown,
      filteredValue: groupCoverageQueryParams({
        schema: schemaParam,
        volume: volumeParam,
        completeness: completenessParam,
      }),
      onFilter: (value, record) => {
        if (typeof value !== "string") return true
        const parsedValue = parseDQIFilterValue(value)
        if (!parsedValue) return true

        return record[parsedValue.metric] === parsedValue.covered
      },
      width: 120,
      render: (_, record) => (
        <Flex gap={theme.spacing.xs} align="center" justify="center" style={{ height: "100%" }}>
          <DataQualityIndicator covered={record.schema} tooltipPrefix="Schema" iconName="fileNetwork" />
          <DataQualityIndicator covered={record.volume} tooltipPrefix="Volume" iconName="chartBar" />
          <DataQualityIndicator covered={record.completeness} tooltipPrefix="Completeness" iconName="documentInfo" />
        </Flex>
      ),
    },
  ]
  const editorColumns: TableProps["columns"] = [
    {
      align: "center",
      width: 60,
      render: (_, { id, name }) =>
        verticallyCentered(
          <Button
            title="Delete Data Asset"
            onClick={(e) => {
              setShowDeleteModal({ id, name })
              e.stopPropagation()
            }}
            type="text"
            key="delete"
            icon="trash"
          />,
          "center",
        ),
    },
  ]

  const columns = canEdit ? [...baseColumns, ...editorColumns] : baseColumns

  return (
    <SkeletonTable loading={loading} columns={columns} title={() => <TableHeader loading={true} />}>
      {showDeleteModel && (
        <DeleteDataAssetModal
          isVisible={showDeleteModel !== null}
          setIsVisible={() => setShowDeleteModal(null)}
          dataAsset={showDeleteModel}
        />
      )}
      <StyledTable<AssetData>
        title={() => <TableHeader loading={loading} urlParams={params} setUrlParams={setUrlParams} />}
        virtual
        scroll={{ x: "max-content", y: 600, scrollToFirstRowOnChange: true }}
        tableLayout="fixed"
        pagination={false}
        columns={columns}
        onChange={handleChange}
        onRow={(asset) => ({
          onClick: () => {
            dataAssetsNavigateToVar(`${DATA_ASSETS_PATH}${location.search}`)
            navigateInOrg(`/data-assets/${asset.id}/expectations`)
          },
          style: { cursor: "pointer" },
        })}
        dataSource={sortedAssetData}
      />
    </SkeletonTable>
  )
}

type TableHeaderProps = {
  loading: boolean
  urlParams?: UrlParams
  setUrlParams?: (params: Partial<UrlParams>) => void
}
function TableHeader({ loading, urlParams, setUrlParams }: TableHeaderProps) {
  const hasUrlParams = Boolean(urlParams?.filter) || Boolean(urlParams?.sort) || Boolean(urlParams?.search)
  const clearSettingsText = "Clear all settings"

  return (
    <Flex justify="space-between" align="center">
      <Input.Search
        placeholder="Search for Data Asset or Data Source name"
        value={urlParams?.search}
        onChange={(e) => setUrlParams?.({ search: e.target.value })}
        style={{ width: 400 }}
        disabled={loading}
      />
      <Button
        disabled={loading || !hasUrlParams}
        icon={<Icon name="redo" small size="13px" />}
        aria-label={clearSettingsText}
        onClick={() => setUrlParams?.({ sort: "", filter: "", search: "" })}
        type="text"
      >
        {clearSettingsText}
      </Button>
    </Flex>
  )
}

function renderSuccessStatus(success: boolean | null) {
  switch (success) {
    case true:
      return verticallyCentered(<Tag color="success">All passed</Tag>, "center")
    case false:
      return verticallyCentered(<Tag color="error">Failed</Tag>, "center")
    default:
      return null
  }
}

function verticallyCentered(value: React.ReactNode, justify: "left" | "center" | "right" = "left") {
  return (
    <Flex align="center" justify={justify} style={{ height: "100%" }}>
      {value}
    </Flex>
  )
}

type DataQualityIndicatorProps = {
  covered: boolean
  tooltipPrefix: string
  iconName: IconTypes
}

function DataQualityIndicator({ covered, tooltipPrefix, iconName }: DataQualityIndicatorProps) {
  return (
    <Tooltip title={`${tooltipPrefix}: ${covered ? "Covered" : "Not covered"}`}>
      <Icon
        name={iconName}
        containerHeight="16px"
        size="16px"
        color={covered ? theme.colors.success.gxSuccess : theme.colors.neutralColorPalette.blacks.colorTextQuaternary}
      />
    </Tooltip>
  )
}
