import { useEffect, useMemo, useState } from "react"
import { useApolloClient, useQuery } from "@apollo/client"
import { Flex, Space } from "antd"
import { useParams } from "react-router-dom"

import { graphql } from "src/api/graphql/gql.ts"
import { PageHeader } from "src/global/ui/PageHeader/PageHeader.tsx"
import { MainContent, MainContentBlock } from "src/global/layout/MainContent.tsx"
import { getSubtitle } from "src/pages/DataAssets/views/Expectations/utils.ts"
import { theme } from "src/global/ui/themes/theme.ts"
import {
  SimpleEditExpectationDrawer,
  EditExpectationFragment,
} from "src/pages/DataAssets/views/Expectations/SimpleExpectationDrawer/SimpleEditExpectationDrawer.tsx"
import { useRequireRole } from "src/global/hooks/useRequireRole.ts"
import { ExpectationsTable } from "src/pages/DataAssets/views/Expectations/ExpectationsTable.tsx"
import { Image } from "src/global/ui/Image"
import {
  EmptyFilteredState,
  ExpectationsTableHeader,
  headerContent,
} from "src/pages/DataAssets/views/Expectations/SimpleExpectationsHeaders.tsx"
import { useIsDemoData } from "src/global/hooks/useIsDemoData.ts"
import { ValidateExpectationsAlert } from "src/global/components/alerts/DemoData/ValidateExpectationsAlert.tsx"
import { CreateExpectationsAlert } from "src/global/components/alerts/DemoData/CreateExpectationsAlert.tsx"
import { useGetAutogenerateStatus } from "src/global/hooks/useGetAutogenerateStatus.ts"
import { useEmailAlertsEvent } from "src/pages/DataAssets/views/Validations/ValidationHooks.tsx"
import { AssetCoverageStats } from "src/pages/DataAssets/views/Expectations/AssetCoverageStats"
import ActiveAssetStatusTag from "src/pages/DataAssets/views/Expectations/ActiveAssetStatusTag"
import { AggregateCoverageStatsDocument } from "src/pages/DataAssets/components/AggregateAssetCoverageStats"

export const ExpectationsTabDataAssetDocument = graphql(`
  query ExpectationsTabDataAsset($id: UUID!) {
    dataAsset(id: $id) {
      id
      name
      schedule {
        ...ScheduleSummary
      }
      splitter {
        ...BatchDefinitionDescription_Splitter
        ...EditBatchForm_Splitter
      }
      datasourceV2 {
        id
        ...DataAssetBreadcrumbs_DataSource
        name
        type
        config
      }
      ...UseIsDemoData_DataAsset
      ...UseDemoDataAssetName_DataAsset
    }
  }
`)

export const AssetCoverageStatsDocument = graphql(`
  query AssetCoverageStats($id: UUID!) {
    dataAsset(id: $id) {
      id
      coverageStats {
        ...Coverage_AssetCoverageStats
        ...ActiveAsset_CoverageStats
      }
    }
  }
`)

export const ExpectationsTab_GetExpectationsDocument = graphql(`
  query ExpectationsTab_GetExpectations($input: ExpectationsInput!) {
    expectations(input: $input) {
      geCloudId
      gxManaged
      description
      ...EditExpectationDrawer_Expectation
      ...ExpectationsTable_Expectation
    }
  }
`)

export const SimpleExpectationsTab = () => {
  // Analytics events
  useEmailAlertsEvent()

  const { assetId } = useParams<{
    assetId: string
  }>()
  const isEditor = useRequireRole("EDITOR")
  const [editingExpectationFragment, setEditingExpectationFragment] = useState<EditExpectationFragment | null>(null)
  const [isNewExpDrawerOpen, setIsNewExpDrawerOpen] = useState(false)
  const { jobStatus, job, jobStatusPreviousValue } = useGetAutogenerateStatus()

  const { data: dataAssetCoverageStats } = useQuery(AssetCoverageStatsDocument, {
    variables: { id: assetId ?? "" },
    skip: !assetId,
  })

  const autogenLoading = useMemo(
    () => jobStatus === "loading" && Boolean(job?.sourceResources.find(({ entityId }) => entityId === assetId)),
    [assetId, job, jobStatus],
  )

  const { data: dataAssetData, loading: dataAssetLoading } = useQuery(ExpectationsTabDataAssetDocument, {
    variables: {
      id: assetId ?? "", // would never pass empty string, but compilers must be satisfied
    },
    skip: !assetId,
  })

  const { data: expectationsFragment, loading: expectationsLoading } = useQuery(
    ExpectationsTab_GetExpectationsDocument,
    {
      variables: {
        input: { dataAssetId: assetId ?? "" }, // would never pass empty string, but compilers must be satisfied
      },
      skip: !assetId,
    },
  )
  const apollo = useApolloClient()

  useEffect(() => {
    // This covers the case where a user's camping on the expectations tab
    // while the autogenerate job runs & completes
    if (!autogenLoading && jobStatusPreviousValue === "loading") {
      apollo.refetchQueries({
        include: [ExpectationsTab_GetExpectationsDocument, AssetCoverageStatsDocument, AggregateCoverageStatsDocument],
      })
      apollo.reFetchObservableQueries()
    }
  }, [apollo, autogenLoading, jobStatusPreviousValue])

  const hasExpectations = !expectationsLoading && (expectationsFragment?.expectations?.length ?? 0) > 0
  const dataAssetName = dataAssetData?.dataAsset?.name ?? ""
  const subtitle = getSubtitle(expectationsFragment?.expectations?.length ?? 0, expectationsLoading)
  const datasources = dataAssetData?.dataAsset?.datasourceV2
  const apiManagedExpectations =
    expectationsFragment?.expectations?.filter((expectation) => !expectation.gxManaged) ?? []
  const gxManagedExpectations =
    expectationsFragment?.expectations?.filter((expectation) => !!expectation.gxManaged) ?? []
  const hasGXManagedExpectations = gxManagedExpectations.length > 0

  const isDemoData = useIsDemoData(dataAssetData?.dataAsset)

  const dataAssetTitleHeader = (
    <Flex gap={theme.spacing.xs}>
      {dataAssetName}{" "}
      {dataAssetCoverageStats?.dataAsset?.coverageStats && (
        <ActiveAssetStatusTag assetStats={dataAssetCoverageStats.dataAsset.coverageStats} />
      )}
    </Flex>
  )

  return (
    <PageHeader
      headerContent={headerContent({
        dataAssetName: dataAssetTitleHeader,
        subtitle,
        datasources,
        isEditor,
        assetId,
        isNewExpDrawerOpen,
        setIsNewExpDrawerOpen,
      })}
      loading={expectationsLoading || dataAssetLoading}
    >
      <MainContent>
        <MainContentBlock size="medium">
          {isDemoData && (
            <Space direction="vertical" style={{ marginBottom: theme.spacing.s }}>
              {hasExpectations ? <ValidateExpectationsAlert /> : <CreateExpectationsAlert />}
            </Space>
          )}
          {assetId && dataAssetCoverageStats?.dataAsset?.coverageStats && (
            <AssetCoverageStats
              assetStats={dataAssetCoverageStats.dataAsset.coverageStats}
              openDrawer={() => setIsNewExpDrawerOpen(true)}
            />
          )}
          <ExpectationsTable
            antdTableProps={{
              title: () => (
                <ExpectationsTableHeader
                  dataAssetId={assetId}
                  hasExpectations={hasExpectations}
                  hasGXManagedExpectations={hasGXManagedExpectations}
                />
              ),
              locale: {
                emptyText: () => (
                  <EmptyFilteredState
                    isEditor={isEditor}
                    assetId={assetId}
                    isNewExpDrawerOpen={isNewExpDrawerOpen}
                    setIsNewExpDrawerOpen={setIsNewExpDrawerOpen}
                    hasExpectations={gxManagedExpectations.length > 0}
                  />
                ),
              },
            }}
            id="simple-expectations-gx-table"
            dataTestid="simple-expectations-gx-table"
            data={gxManagedExpectations}
            loading={expectationsLoading || autogenLoading}
            assetId={assetId ?? ""}
            setEditingExpectationFragment={setEditingExpectationFragment}
          />
          {!!apiManagedExpectations.length && (
            <ExpectationsTable
              antdTableProps={{
                title: () => (
                  <Flex gap={theme.spacing.xxs}>
                    <Image
                      aria-label="code"
                      type="code"
                      style={{ fontSize: theme.spacing.xs, width: theme.spacing.s }}
                    />
                    <span>API-managed Expectations</span>
                  </Flex>
                ),
                style: { marginTop: theme.spacing.m },
                locale: {
                  emptyText: () => (
                    <EmptyFilteredState
                      isEditor={isEditor}
                      assetId={assetId}
                      isNewExpDrawerOpen={isNewExpDrawerOpen}
                      setIsNewExpDrawerOpen={setIsNewExpDrawerOpen}
                      hasExpectations={apiManagedExpectations.length > 0}
                    />
                  ),
                },
              }}
              id="simple-expectations-api-table"
              dataTestid="simple-expectations-api-table"
              data={apiManagedExpectations}
              loading={expectationsLoading}
              assetId={assetId ?? ""}
              setEditingExpectationFragment={setEditingExpectationFragment}
            />
          )}
          {assetId && editingExpectationFragment && (
            <SimpleEditExpectationDrawer
              dataAssetId={assetId}
              expectation={editingExpectationFragment}
              open={!!editingExpectationFragment}
              close={() => setEditingExpectationFragment(null)}
            />
          )}
        </MainContentBlock>
      </MainContent>
    </PageHeader>
  )
}
