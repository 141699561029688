import { Flex } from "antd"
import { assertNever } from "src/pages/DataAssets/utils.ts"
import { startCase } from "lodash-es"
import { useRequireRole } from "src/global/hooks/useRequireRole.ts"
import { Param } from "src/global/ui/Param/Param.tsx"
import { graphql } from "src/api/graphql/gql.ts"
import { FragmentType, unmaskFragment } from "src/api/graphql/fragment-masking.ts"
import { theme } from "src/global/ui/themes/theme.ts"
import { EditBatchPopover } from "src/pages/DataAssets/views/Expectations/EditBatchPopover.tsx"
import { BodyNormal } from "src/global/ui/typography/Text/Text.tsx"
import { Image } from "src/global/ui/Image"
import { SplitterUnion } from "src/api/graphql/graphql.ts"

export const BatchDefinitionDescription_SplitterFragmentDocument = graphql(`
  fragment BatchDefinitionDescription_Splitter on SplitterUnion {
    __typename
    ... on ColumnPartitionerYearly {
      __typename
      id
      columnName
    }
    ... on ColumnPartitionerMonthly {
      __typename
      id
      columnName
    }
    ... on ColumnPartitionerDaily {
      __typename
      id
      columnName
    }
    ... on SplitterYearAndMonthAndDay {
      id
      columnName
    }
    ... on SplitterYearAndMonth {
      id
      columnName
    }
    ... on SplitterYear {
      id
      columnName
    }
    ... on SplitterColumnValue {
      id
      columnName
    }
    ... on SplitterConvertedDateTime {
      id
      columnName
    }
    ... on SplitterDatetimePart {
      id
      columnName
    }
    ... on SplitterDividedInteger {
      id
      columnName
    }
    ... on SplitterHashedColumn {
      id
      columnName
    }
    ... on SplitterModInteger {
      id
      columnName
    }
    ... on SplitterMultiColumnValue {
      id
      columnNames
    }
    ... on SplitterWholeAsset {
      id
    }
  }
`)

type BatchDefinitionProps = {
  dataAssetId: string
  splitterDataFragment: FragmentType<typeof BatchDefinitionDescription_SplitterFragmentDocument>
}

export function BatchDefinitionWithEditing({ dataAssetId, splitterDataFragment }: BatchDefinitionProps) {
  const isEditorRole = useRequireRole("EDITOR")

  return (
    <Flex justify="space-between" style={{ width: "100%" }}>
      <Flex gap={theme.spacing.xxs} align="center" style={{ marginTop: theme.spacing.xxs }}>
        <Image aria-label="batch" type="batch" />
        <BatchDefinitionValidateDescription splitter={splitterDataFragment} />
      </Flex>
      {isEditorRole && (
        <Flex align="center">
          <EditBatchPopover dataAssetId={dataAssetId} />
        </Flex>
      )}
    </Flex>
  )
}

function BatchDefinitionValidateDescription({
  splitter,
}: {
  splitter: FragmentType<typeof BatchDefinitionDescription_SplitterFragmentDocument>
}) {
  const splitterValidateText = getSplitterValidateText(splitter)
  return <BodyNormal>{splitterValidateText}</BodyNormal>
}

export function getSplitterValidateText(
  maskedSplitter: FragmentType<typeof BatchDefinitionDescription_SplitterFragmentDocument>,
) {
  const unmaskedSplitter = unmaskFragment(BatchDefinitionDescription_SplitterFragmentDocument, maskedSplitter)
  if (!unmaskedSplitter?.__typename) {
    return {}
  }

  switch (unmaskedSplitter.__typename) {
    case "ColumnPartitionerYearly":
    case "ColumnPartitionerMonthly":
    case "ColumnPartitionerDaily":
    case "SplitterColumnValue":
    case "SplitterConvertedDateTime":
    case "SplitterDatetimePart":
    case "SplitterDividedInteger":
    case "SplitterHashedColumn":
    case "SplitterModInteger":
    case "SplitterMultiColumnValue":
    case "SplitterYear":
    case "SplitterYearAndMonth":
    case "SplitterYearAndMonthAndDay": {
      const formattedSplitterType = getFormattedSplitterType(unmaskedSplitter.__typename)
      return (
        <>
          Validate by {formattedSplitterType} on column{" "}
          <Param>
            {"columnName" in unmaskedSplitter ? unmaskedSplitter.columnName : unmaskedSplitter.columnNames.join(", ")}
          </Param>
        </>
      )
    }

    case "SplitterWholeAsset":
      return <>Validate the entire asset</>
    default:
      try {
        assertNever(unmaskedSplitter)
      } catch (e) {
        return {}
      }
  }
}

function getFormattedSplitterType(type: Exclude<SplitterUnion["__typename"], "SplitterWholeAsset">): string {
  switch (type) {
    case "SplitterColumnValue":
    case "SplitterConvertedDateTime":
    case "SplitterDatetimePart":
    case "SplitterDividedInteger":
    case "SplitterHashedColumn":
    case "SplitterModInteger":
    case "SplitterMultiColumnValue":
      return startCase(type.replace("Splitter", "")).toLowerCase()
    case "SplitterYear":
    case "ColumnPartitionerYearly":
      return "year"
    case "SplitterYearAndMonth":
    case "ColumnPartitionerMonthly":
      return "month"
    case "SplitterYearAndMonthAndDay":
    case "ColumnPartitionerDaily":
      return "day"
  }
}
