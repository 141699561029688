import { useState } from "react"
import { Button } from "src/global/ui/Button/Button.tsx"
import { AssetFlowIds, AlertsDrawer } from "src/global/components/alerts/AlertsDrawer.tsx"
import { useAnalytics } from "src/global/hooks/analytics/useAnalytics.ts"

export const AlertNotificationsButton = ({ assetFlowIds }: { assetFlowIds?: AssetFlowIds }) => {
  const [isDrawerVisible, setIsDrawerVisible] = useState(false)
  const posthog = useAnalytics()
  const onClick = () => {
    posthog?.capture("alerts.button_clicked")
    setIsDrawerVisible(true)
  }

  return (
    <>
      <Button icon="notifications" iconSize="16px" onClick={onClick}>
        Alerts
      </Button>
      <AlertsDrawer
        assetFlowIds={assetFlowIds}
        isVisible={isDrawerVisible}
        setIsVisible={setIsDrawerVisible}
        title="Alerts"
        width={400}
      />
    </>
  )
}
