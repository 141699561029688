import styled from "styled-components"
import { GetStarted } from "src/global/ui/GetStarted/GetStarted.tsx"
import { GenerateColumnDescriptiveMetricsButton } from "src/pages/DataAssets/views/AssetDetails/RunColumnDescriptiveMetricsButton.tsx"

const CenteredContainer = styled.div`
  height: calc(100vh - 200px);
  display: flex;
  flex-direction: column;
  align-items: center;
`

type MetricsTableEmptyScreenProps = {
  dataAssetId: string
  disabled?: boolean
}

export function MetricsTableEmptyScreen({ dataAssetId, disabled }: MetricsTableEmptyScreenProps) {
  const label = "Fetch Schema"
  const loadingLabel = "Fetching Schema..."

  return (
    <CenteredContainer>
      <GetStarted title="Profile your Data Asset and fetch descriptive metrics.">
        <GenerateColumnDescriptiveMetricsButton
          size="large"
          dataAssetId={dataAssetId}
          fetchMetricsOnLoad={true}
          type="primary"
          icon="analytics"
          label={label}
          loadingLabel={loadingLabel}
          disabled={disabled}
        />
      </GetStarted>
    </CenteredContainer>
  )
}
