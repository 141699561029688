import { Col, Flex, Space, Row, message, AlertProps } from "antd"
import { useCallback, useContext, useState } from "react"
import ConnectToDataSourceContext from "src/pages/DataAssets/drawers/ConnectToDataSource/ConnectToDataSourceContext.ts"
import { DataQualityIssues } from "src/api/graphql/graphql.ts"
import type {
  DataQualityIssueWithMetadata,
  StepComponentWithSlot,
} from "src/pages/DataAssets/drawers/ConnectToDataSource/types.ts"
import { StepContainer } from "src/pages/DataAssets/drawers/ConnectToDataSource/common/StepContainer.ts"
import { ApolloError, useMutation } from "@apollo/client"
// eslint-disable-next-line no-restricted-imports -- deprecated file
import { AddAssetsToDataSourceDocument } from "src/api/graphql/graphql-operations.ts"
import { MESSAGE_DURATION_SECONDS } from "src/global/config.ts"
import { pluralize } from "src/global/utils/strings.ts"
import { AlertBanner } from "src/global/ui/Alert"
import { DataQualityIssueCard } from "src/pages/DataAssets/drawers/ConnectToDataSource/common/DataQualityIssueCard.tsx"
import { AggregateCoverageStatsDocument } from "src/pages/DataAssets/components/AggregateAssetCoverageStats"
import { useIsFeatureEnabled } from "src/global/hooks/useIsFeatureEnabled"

export const DataQualityIssuesList: DataQualityIssues[] = [
  "SCHEMA",
  "VOLUME",
  "COMPLETENESS",
  "NUMERIC",
  "SQL",
  "UNIQUENESS",
  "VALIDITY",
] as const

export function MonitorIssuesStep({ children }: StepComponentWithSlot) {
  const { dataSource, filteredTables, selectedDataQualityIssues, setSelectedDataQualityIssues } =
    useContext(ConnectToDataSourceContext)
  const [assetCreationError, setAssetCreationError] = useState<null | AlertProps>(null)
  const [disableProgress, setDisableProgress] = useState<boolean>(false)
  const completenessChangeDetectionEnabled = useIsFeatureEnabled("completenessChangeDetectionEnabled")

  const selectableIssues: DataQualityIssueWithMetadata = {
    SCHEMA: {
      description: "Changes to column count or names",
      icon: "fileNetwork",
      isAvailable: true,
    },
    VOLUME: {
      description: "Non-increasing number of rows",
      icon: "chartBar",
      isAvailable: true,
    },
    COMPLETENESS: {
      description: "Null and non-null values",
      icon: "documentInfo",
      isAvailable: completenessChangeDetectionEnabled,
    },
    UNIQUENESS: {
      description: "Unique values and column cardinality",
      icon: "keyholeCircle",
      isAvailable: false,
    },
  }

  const handleDataQualityIssueClicked = useCallback(
    (clickedIssue: DataQualityIssues) => {
      if (selectedDataQualityIssues.includes(clickedIssue)) {
        setSelectedDataQualityIssues(selectedDataQualityIssues.filter((issue) => issue !== clickedIssue))
      } else {
        setSelectedDataQualityIssues([...selectedDataQualityIssues, clickedIssue])
      }
      setDisableProgress(false)
    },
    [selectedDataQualityIssues, setSelectedDataQualityIssues],
  )

  // Set up the addDataAssets mutation
  const [addDataAssets, { reset: resetSaveDatasourceMutation, loading }] = useMutation(AddAssetsToDataSourceDocument, {
    refetchQueries: [AggregateCoverageStatsDocument],
    onError: (error: ApolloError) => {
      setAssetCreationError({ message: error.message, type: "error" })
    },
    onCompleted: (result) => {
      const assets = result.addDataAssetsToDataSource?.assets
      message.success(
        String(assets?.length) + " " + pluralize("Data Asset", assets ? assets.length : 0) + " added",
        MESSAGE_DURATION_SECONDS,
      )
      resetSaveDatasourceMutation()
    },
  })

  const handleSubmit = useCallback(async () => {
    setAssetCreationError(null)
    try {
      if (dataSource && dataSource.id) {
        await addDataAssets({
          variables: {
            input: {
              dataSourceId: dataSource.id,
              tableNames: filteredTables,
              selectedDataQualityIssues,
            },
          },
        })
      }
    } catch (e) {
      setAssetCreationError({ message: "Failed to add Data Assets", description: e })
      return false
    }
    setDisableProgress(false)
    return true
  }, [addDataAssets, dataSource, filteredTables, selectedDataQualityIssues])

  return children(
    {
      loading: loading,
      disableProgress: loading || disableProgress,
      next: handleSubmit,
      nextLabel: selectedDataQualityIssues.length > 0 ? "Start monitoring" : "Finish",
      showHeader: false,
    },
    <StepContainer>
      <Flex vertical gap={42}>
        <Space direction="vertical" size="middle">
          <p>
            By default, GX Cloud automatically generates Expectations to detect the following common data quality
            issues. You can de-select recommendations you&apos;d like to opt out of.
          </p>
          <Row gutter={[8, 8]}>
            {DataQualityIssuesList.map((issue) => {
              return selectableIssues[issue] ? (
                <Col key={issue} span={12}>
                  <DataQualityIssueCard
                    issue={issue}
                    selected={selectedDataQualityIssues.includes(issue)}
                    metadata={selectableIssues[issue]}
                    handleOnClick={() => selectableIssues[issue]?.isAvailable && handleDataQualityIssueClicked(issue)}
                  />
                </Col>
              ) : (
                false
              )
            })}
          </Row>
        </Space>
        {assetCreationError && (
          <AlertBanner
            message={assetCreationError.message ?? "There was an error adding Data Source."}
            description={assetCreationError.description}
            type={assetCreationError.type}
          />
        )}
      </Flex>
    </StepContainer>,
  )
}
