import { DatasourceTypeV2 } from "src/api/graphql/graphql.ts"
// eslint-disable-next-line no-restricted-imports -- deprecated file
import { FilterDatasources_DatasourceFragment } from "src/api/graphql/graphql-operations.ts"
import { orderBy } from "lodash-es"
import { AssetAndDatasourceTuple } from "src/pages/DataAssets/hooks/useDataAssets.ts"

export function filterDatasources<T extends FilterDatasources_DatasourceFragment>(
  datasources: T[] | undefined,
  datasourceType: DatasourceTypeV2[],
  search: string,
): T[] {
  if (!datasources) return []
  return datasources
    .filter((datasource) => {
      const matchDatasourceType = datasourceType.length === 0 || datasourceType.includes(datasource.type) // Filter by datasource type
      const matchDataAsset =
        !search || datasource.assets.some((dataAsset) => dataAsset?.name.toLowerCase().includes(search.toLowerCase())) // Filter by dataAsset name
      const matchDatasourceName = datasource.name.toLowerCase().includes(search.toLowerCase()) // Filter by datasource name
      return matchDatasourceType && (matchDataAsset || matchDatasourceName)
    })
    .map((datasource) => {
      if (!search || datasource.name.toLowerCase().includes(search.toLowerCase())) return datasource
      const datasourceWithMatchedDataAssets = {
        ...datasource,
        assets: datasource.assets.filter((dataAsset) => {
          return dataAsset?.name.toLowerCase().includes(search.toLowerCase())
        }),
      }
      return datasourceWithMatchedDataAssets
    })
}
export type OrderDirection = "asc" | "desc"
type OrderEntity = "data-source" | "asset-name" | "validation-time"

export type DataAssetSortOption = `${OrderEntity}-${OrderDirection}`

export function sortDataAssets(tuples: AssetAndDatasourceTuple[], order: DataAssetSortOption) {
  switch (order) {
    case "asset-name-desc":
      return orderBy(tuples, ({ asset }) => asset.name?.toLowerCase(), ["desc"])

    case "asset-name-asc":
      return orderBy(tuples, ({ asset }) => asset.name?.toLowerCase(), ["asc"])

    case "data-source-desc":
      return orderBy(tuples, ({ datasource }) => datasource.name.toLowerCase(), ["desc"])

    case "data-source-asc":
      return orderBy(tuples, ({ datasource }) => datasource.name.toLowerCase(), ["asc"])

    case "validation-time-asc":
      return sortDataAssetsByRecentRuns(tuples, "asc")

    case "validation-time-desc":
      return sortDataAssetsByRecentRuns(tuples, "desc")
  }
}

export function sortDataAssetsByRecentRuns(tuples: AssetAndDatasourceTuple[], sortOrder: OrderDirection) {
  return orderBy(
    tuples,
    [
      (v) => {
        const mostRecentRun = v.asset.lastValidatedAt ? Date.parse(v.asset.lastValidatedAt) : null
        // Assets without validation results (null dates) should always come last
        return mostRecentRun || (sortOrder === "asc" ? Infinity : -Infinity)
      },
      (v) => v.asset.name, // Secondary sort by asset name
    ],
    [sortOrder, "asc"], // Primary sort by the derived mostRecentRun, secondary by asset name
  )
}

export function valueIsValidDataAssetSortOption(s: string): boolean {
  const option = s as DataAssetSortOption
  switch (option) {
    case "asset-name-asc":
    case "asset-name-desc":
    case "data-source-asc":
    case "data-source-desc":
    case "validation-time-asc":
    case "validation-time-desc":
      return true
    default:
      // if you see a TypeScript error below, it's because you're missing a case statement
      // for a member of the union
      try {
        assertNever(option)
      } catch (e) {
        return false
      }
  }
}

export function assertNever(x: never): never {
  throw new Error(`Unexpected value '${x}'`)
}
