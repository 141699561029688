import { ValidationResultChartList } from "src/pages/DataAssets/views/Validations/ValidationResultChartList.tsx"
import { ValidationResultList } from "src/pages/DataAssets/views/Validations/ValidationResultList.tsx"
import { NoFailuresScreen } from "src/pages/DataAssets/views/AssetDetails/utils.tsx"
// eslint-disable-next-line no-restricted-imports -- deprecated file
import {
  AggregateSuiteValidationResultQuery,
  Expectation_ValidationResultFragment,
} from "src/api/graphql/graphql-operations.ts"
import { ListContainer, SkeletonContainer } from "src/pages/DataAssets/views/Validations/ValidationTab.styles.tsx"
import { Grid, Skeleton } from "antd"

interface ValidationsTabResultsProps {
  listLoading: boolean
  showAggregateRuns: boolean
  aggregateSuite: AggregateSuiteValidationResultQuery | undefined
  selectedRun: string
  filteredValidations: Record<string, Expectation_ValidationResultFragment[]> | undefined
  noFailures: boolean
}

export const ValidationsTabResults: React.FC<ValidationsTabResultsProps> = ({
  listLoading,
  showAggregateRuns,
  aggregateSuite,
  selectedRun,
  filteredValidations,
  noFailures,
}) => {
  const { useBreakpoint } = Grid
  const { lg } = useBreakpoint()
  return (
    <ListContainer $lg={lg}>
      {listLoading ? (
        <SkeletonLoader />
      ) : (
        <>
          {noFailures && <NoFailuresScreen />}
          {showAggregateRuns ? (
            <ValidationResultChartList aggregateValidations={aggregateSuite} selectedAnchor={selectedRun} />
          ) : (
            <ValidationResultList filteredValidations={filteredValidations ?? []} selectedAnchor={selectedRun} />
          )}
        </>
      )}
    </ListContainer>
  )
}

function SkeletonLoader() {
  return (
    <>
      <SkeletonContainer>
        <Skeleton active paragraph={{ rows: 2 }} title={false} />
        <Skeleton active paragraph={{ rows: 2 }} title={false} />
      </SkeletonContainer>
      {Array(2)
        .fill(null)
        .map((_, index) => (
          <SkeletonContainer key={index} $column>
            <Skeleton active title={false} />
            <Skeleton active title={false} />
          </SkeletonContainer>
        ))}
    </>
  )
}
