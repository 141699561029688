import { GX_AGENT_DOCS_LINK, GX_AGENT_NOT_CONNECTED_ERROR_MESSAGE } from "src/pages/AppLogs/words.tsx"
import { AppModal } from "src/global/ui/AppModal/AppModal.tsx"

interface ColumnDescriptiveMetricsModalProps {
  isVisible: boolean
  setIsVisible: (isVisible: boolean) => void
}

export function AgentNotConnectedModal({ isVisible, setIsVisible }: ColumnDescriptiveMetricsModalProps) {
  const handleOk = () => {
    setIsVisible(false)
  }

  // Cancel button is hidden but handleCancel is needed to enable the "x" close button:
  const handleCancel = () => {
    setIsVisible(false)
  }

  return (
    <AppModal
      title={GX_AGENT_NOT_CONNECTED_ERROR_MESSAGE}
      open={isVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      cancelButtonProps={{ style: { display: "none" } }}
    >
      {GX_AGENT_DOCS_LINK}
    </AppModal>
  )
}
