// eslint-disable-next-line no-restricted-imports -- deprecated file
import { AggregateSuiteValidationResultQuery } from "src/api/graphql/graphql-operations.ts"
import { StyledExpectationGroup, StyledList } from "src/pages/DataAssets/views/Validations/ValidationTab.styles.tsx"
import { FC, useMemo } from "react"
import { List, ListProps } from "antd"
import { ValidationResultCharts } from "src/pages/DataAssets/views/Expectations/Expectation/ValidationResultCharts.tsx"
import { AggregateSuiteValidationResult } from "src/api/graphql/graphql.ts"
import { removeInvalidHtmlCharacters } from "src/global/utils/removeInvalidHtmlCharacters.ts"
import { groupValidationResultsWithPlaceholderItems } from "src/pages/DataAssets/views/Validations/groupValidationResultsWithPlaceholderItems.ts"
type ValidationResultChartListProps = {
  aggregateValidations: AggregateSuiteValidationResultQuery | undefined
  selectedAnchor: string
}

export const ValidationResultChartList = ({ aggregateValidations, selectedAnchor }: ValidationResultChartListProps) => {
  const groupNameExpectationIdResultArray = useMemo(
    () => groupValidationResultsWithPlaceholderItems(aggregateValidations),
    [aggregateValidations],
  )
  return (
    <>
      {groupNameExpectationIdResultArray.map(([groupName, validationResultGroup]) => {
        return (
          <div key={groupName}>
            <StyledExpectationGroup
              groupName={groupName}
              headerBottomBorder={false}
              $isSelected={selectedAnchor === removeInvalidHtmlCharacters(groupName.toLowerCase())}
            >
              <StyledList<FC<ListProps<AggregateSuiteValidationResult>>>
                itemLayout="vertical"
                dataSource={validationResultGroup}
                renderItem={(listValidationGroup: AggregateSuiteValidationResult) => (
                  <List.Item key={listValidationGroup?.expectationId}>
                    <ValidationResultCharts validationResultCharts={listValidationGroup?.results} />
                  </List.Item>
                )}
              />
            </StyledExpectationGroup>
          </div>
        )
      })}
    </>
  )
}
