import ReactMarkdown from "react-markdown"
import rehypeExternalLinks from "rehype-external-links"
import React from "react"
import { Flex, Typography } from "antd"
import { Icon } from "src/global/ui/Icon"
import styled, { useTheme } from "styled-components"

const { Text } = Typography

const StyledFlex = styled(Flex)`
  p {
    margin: 0;
  }
`

interface MetaNotesProps {
  metaNotes: {
    format: string
    content: Array<string>
  }
}

interface MarkdownProps {
  children: string
}

const String: React.FC<MarkdownProps> = ({ children }) => <div>{children}</div>

const Markdown: React.FC<MarkdownProps> = ({ children }) => (
  <ReactMarkdown rehypePlugins={[[rehypeExternalLinks, { target: "_blank", rel: ["nofollow", "noreferrer"] }]]}>
    {children}
  </ReactMarkdown>
)

export const MetaNotes = ({ metaNotes }: MetaNotesProps) => {
  const ContentWrapper = metaNotes.format === "markdown" ? Markdown : String
  const theme = useTheme()
  return (
    <StyledFlex gap={theme.spacing.xxs}>
      <Icon
        size="16px"
        style={{
          marginTop: "2px",
        }}
        color={theme.colors.neutralColorPalette.blacks.colorTextTertiary}
        name="commentExclamantion"
      />
      <Text
        style={{
          color: theme.colors.neutralColorPalette.blacks.colorTextTertiary,
        }}
      >
        {metaNotes.content.map((element, index) => (
          <ContentWrapper key={index}>{element}</ContentWrapper>
        ))}
      </Text>
    </StyledFlex>
  )
}
