import { theme } from "src/global/ui/themes/theme"
import { Flex, Typography } from "antd"
import { ReactNode } from "react"
import { Icon } from "src/global/ui/Icon"

const { Title, Text } = Typography

const SPACING_PRESERVER = "\u00A0"

type StatDisplayProps = {
  label?: string | undefined
  cta?: ReactNode
  children: ReactNode
}

type PercentProps = {
  value: number
}

export function StatDisplay({ label, cta, children }: StatDisplayProps) {
  return (
    <Flex vertical gap={theme.spacing.xs} align="start">
      <Text
        style={{
          color: theme.colors.neutralColorPalette.blacks.colorTextTertiary,
        }}
      >
        {label || SPACING_PRESERVER}
      </Text>
      <Flex gap={theme.spacing.xxs} align="center">
        {children}
      </Flex>
      <div style={{ visibility: cta ? "visible" : "hidden", height: "32px" }}>{cta || SPACING_PRESERVER}</div>
    </Flex>
  )
}

function Percent({ value }: PercentProps) {
  const roundedValue = Math.round(value)
  return (
    <Title level={2} style={{ margin: 0 }}>
      {roundedValue}%
    </Title>
  )
}

function Warning() {
  return <Icon name="warning" small color={theme.colors.warning.colorWarning} />
}
function Success() {
  return <Icon name="checkCircle" small color={theme.colors.success.gxSuccess} />
}

StatDisplay.Percent = Percent
StatDisplay.Success = Success
StatDisplay.Warning = Warning
