import { ListProps } from "antd"
import { DataAssetListItem } from "src/pages/DataAssets/components/DataAssetListItem.tsx"
import { ReactNode, useEffect, useState } from "react"
import { MainContentList } from "src/global/ui/List/MainContentList.tsx"
import { AssetAndDatasourceTuple } from "src/pages/DataAssets/hooks/useDataAssets.ts"
import { EditDataAssetDrawer } from "src/pages/DataAssets/components/EditDataAssetDrawer.tsx"
import { SupportedDataSource } from "src/pages/DataAssets/schemas/data-source-schemas.ts"

type DataAssetsListProps = Pick<ListProps<AssetAndDatasourceTuple>, "header" | "loading"> & {
  dataAssets: AssetAndDatasourceTuple[]
  emptyState?: ReactNode
}

export function DataAssetsList({ header, dataAssets, emptyState, loading: listLoading }: DataAssetsListProps) {
  const [idOfAssetToEdit, setIdOfAssetToEdit] = useState<string | undefined>()
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  useEffect(() => {
    if (idOfAssetToEdit) {
      setIsDrawerOpen(true)
    }
  }, [idOfAssetToEdit])

  const tuple = dataAssets.find(({ asset }) => asset.id === idOfAssetToEdit)
  return (
    <>
      <MainContentList<AssetAndDatasourceTuple>
        itemLayout="horizontal"
        header={header}
        loading={listLoading}
        locale={{ emptyText: emptyState ?? "No Data Assets Configured" }}
        dataSource={dataAssets}
        renderItem={({ asset, datasource }) => (
          <DataAssetListItem asset={asset} dataSource={datasource} onEdit={() => setIdOfAssetToEdit(asset.id)} />
        )}
      />
      <EditDataAssetDrawer
        isOpen={isDrawerOpen}
        afterClose={() => setIdOfAssetToEdit(undefined)}
        onClose={() => setIsDrawerOpen(false)}
        dataSourceType={tuple?.datasource.type as SupportedDataSource} // don't love casting but type is guaranteed isAssetEditable
        assetId={idOfAssetToEdit}
      />
    </>
  )
}
