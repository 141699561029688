import { isEqual, snakeCase } from "lodash-es"
import { useCallback, useState } from "react"
import { useAnalytics } from "src/global/hooks/analytics/useAnalytics.ts"
import { SelectedExpectation } from "src/pages/DataAssets/views/Expectations/Expectation/CreateExpectationDrawer/types.ts"
import { getJsonTemplateFromJsonSchemaSimplified } from "src/pages/DataAssets/views/Expectations/Expectation/utils.tsx"
import { DataQualityIssue } from "src/global/schemas/expectation-metadata-data-quality-issues.ts"
import { getJsonSchemaBySnakeCaseName } from "src/global/schemas/expectation-metadata-utils.ts"

export function useSelectExpectation() {
  const posthog = useAnalytics()
  const [expectationConfiguration, setExpectationConfiguration] = useState({})
  const [selectedExpectation, setSelectedExpectation] = useState<SelectedExpectation>()
  const [selectedDataQualityIssue, setSelectedDataQualityIssue] = useState<DataQualityIssue | undefined>()

  const handleConfigChange = useCallback((newConfig: Record<string, unknown>) => {
    setExpectationConfiguration((prevConfig) => {
      if (isEqual(prevConfig, newConfig)) {
        return prevConfig
      }
      return newConfig
    })
  }, [])

  const selectExpectation = useCallback(
    (expectation: SelectedExpectation) => {
      setSelectedExpectation(expectation)
      setSelectedDataQualityIssue(expectation.dataQualityIssues?.[0])
      const schema = getJsonSchemaBySnakeCaseName(expectation?.value)
      const config = getJsonTemplateFromJsonSchemaSimplified(schema)
      handleConfigChange(config)
      posthog?.capture("expectation.selected", {
        expectationType: expectation.title,
        categories: expectation.dataQualityIssues?.map((issue) => snakeCase(issue)),
      })
    },
    [handleConfigChange, posthog],
  )

  const resetSelectionState = useCallback(() => {
    setSelectedExpectation(undefined)
    setSelectedDataQualityIssue(undefined)
    handleConfigChange({})
  }, [handleConfigChange])

  return {
    expectationConfiguration,
    selectedExpectation,
    selectedDataQualityIssue,
    resetSelectionState,
    selectExpectation,
    handleConfigChange,
  }
}
