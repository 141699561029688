import { message } from "antd"
import { useCallback, useState } from "react"

import { graphql } from "src/api/graphql/gql.ts"
import { useMutation } from "@apollo/client"
import { Button } from "src/global/ui/Button/Button.tsx"
import { DeleteResourceModal } from "src/global/ui/DeleteResourceModal/DeleteResourceModal.tsx"
import { MESSAGE_DURATION_SECONDS } from "src/global/config.ts"
import {
  ExpectationsTab_GetExpectationsDocument,
  AssetCoverageStatsDocument,
} from "src/pages/DataAssets/views/Expectations/SimpleExpectationsTab.tsx"
import { AggregateCoverageStatsDocument } from "src/pages/DataAssets/components/AggregateAssetCoverageStats"

export const DeleteExpectationModal_DeleteManyExpectationsDocument = graphql(`
  mutation DeleteExpectationModal_DeleteManyExpectations($input: DeleteManyExpectationsInput!) {
    deleteManyExpectations(input: $input) {
      ids
    }
  }
`)
export interface DeleteExpectationModalProps {
  expectationIds: string[] | null
  assetId: string
  isBulkDelete?: boolean
}

export const DeleteExpectationModal = ({
  expectationIds,
  assetId,
  isBulkDelete = false,
}: DeleteExpectationModalProps) => {
  const [isOpen, setIsOpen] = useState(false)

  const [deleteManyExpectationsMutation, { loading, error }] = useMutation(
    DeleteExpectationModal_DeleteManyExpectationsDocument,
    {
      onCompleted: () => {
        message.success(
          isBulkDelete ? `Successfully deleted Expectations` : `Successfully deleted Expectation`,
          MESSAGE_DURATION_SECONDS,
        )
        setIsOpen(false)
      },
      onError: (error) => {
        console.error(isBulkDelete ? "Error deleting Expectations" : "Error deleting Expectation", error)
      },
      refetchQueries: [
        { query: ExpectationsTab_GetExpectationsDocument, variables: { input: { dataAssetId: assetId } } },
        AssetCoverageStatsDocument,
        AggregateCoverageStatsDocument,
      ],
    },
  )

  const deleteExpectation = useCallback(async () => {
    if (!expectationIds?.length) return
    await deleteManyExpectationsMutation({
      variables: { input: { expectationIds } },
    })
  }, [deleteManyExpectationsMutation, expectationIds])

  return (
    <>
      <Button
        type="text"
        title={isBulkDelete ? "Bulk-delete Expectations" : "Delete Expectation"}
        aria-label={isBulkDelete ? "Bulk-delete Expectations" : "Delete Expectation"}
        icon="trash"
        disabled={!expectationIds?.length || loading}
        onClick={() => setIsOpen(!isOpen)}
      />
      <DeleteResourceModal
        open={isOpen}
        isBulkDelete={isBulkDelete}
        name={isBulkDelete ? "Expectations" : "Expectation"}
        resourceTitle={isBulkDelete ? "Expectations" : "Expectation"}
        onCancel={() => setIsOpen(false)}
        okButtonProps={{
          loading,
          danger: true,
        }}
        onOk={() => deleteExpectation()}
        errorMessage={error?.message}
        alternateBodyText={
          isBulkDelete
            ? `Are you sure you want to delete ${expectationIds?.length} Expectation${expectationIds?.length === 1 ? "" : "s"} in this group? This action can't be undone.`
            : "Are you sure you want to delete this Expectation? This action can't be undone."
        }
      />
    </>
  )
}
