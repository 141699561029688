import { useAnalytics } from "src/global/hooks/analytics/useAnalytics.ts"
import { snakeCase } from "lodash-es"
import { getFilteredExpectationsCardsData } from "src/pages/DataAssets/views/Expectations/Expectation/CreateExpectationDrawer/utils.tsx"
import { ExpectationCardProps } from "src/pages/DataAssets/views/Expectations/Expectation/CreateExpectationDrawer/types.ts"
import { DataQualityIssue } from "src/global/schemas/expectation-metadata-data-quality-issues.ts"

type AnalyticsProps = {
  searchTerms?: string
  filtersApplied?: string[]
  suiteId?: string | null
  suiteName?: string | null
}

type CreateExpectationClickHandler = (
  props: {
    onSelectExpectation: (newExpectation: ExpectationCardProps) => void
    expectationCardProps: ExpectationCardProps
    selectionPosition: number
  } & AnalyticsProps,
) => () => void

export const useCreateExpectationSelection: () => CreateExpectationClickHandler = () => {
  const posthog = useAnalytics()

  return ({ onSelectExpectation, expectationCardProps: data, selectionPosition, ...additionalAnalytics }) => {
    const expectationType = data.title
    const categories = data.dataQualityIssues ? data.dataQualityIssues.map(snakeCase) : []

    return () => {
      onSelectExpectation(data)
      posthog?.capture("expectation.selected", {
        expectationType,
        categories,
        selectionPosition,
        ...additionalAnalytics,
      })
    }
  }
}

type UseFilteredExpectations = (
  props: {
    onSelectExpectation: (newExpectation: ExpectationCardProps) => void
    selectedCategoryFilter?: DataQualityIssue
    searchInput?: string
  } & Omit<AnalyticsProps, "searchTerms" | "filtersApplied">,
) => {
  filteredExpectationsCardsData: ExpectationCardProps[]
}

export const useFilteredExpectationsCards: UseFilteredExpectations = ({
  onSelectExpectation,
  selectedCategoryFilter,
  searchInput,
  ...analyticsProps
}) => {
  const cards = getFilteredExpectationsCardsData(searchInput, selectedCategoryFilter)
  const clickMaker = useCreateExpectationSelection()
  return {
    filteredExpectationsCardsData: cards.map((filteredExpectation, selectionPosition) => ({
      ...filteredExpectation,
      onClick: clickMaker({
        onSelectExpectation,
        expectationCardProps: filteredExpectation,
        selectionPosition,
        searchTerms: searchInput,
        filtersApplied: selectedCategoryFilter ? [snakeCase(selectedCategoryFilter)] : undefined,
        ...analyticsProps,
      }),
    })),
  }
}
