import { Alert as AlertAntD } from "antd"
import { Icon } from "src/global/ui/Icon"
import styled from "styled-components"
import { useGetFeatureFlagVariations } from "src/global/hooks/useGetFeatureFlagVariations.ts"
import { formatLocalDate, formatLocalTime } from "src/global/utils/formatTime.ts"
import { SupportEmailLink } from "src/global/ui/SupportEmailLink/SupportEmailLink.tsx"
import { BodyStrong } from "src/global/ui/typography/Text/Text.tsx"

const MaintenanceAlertMessage = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  * {
    padding: 2px;
  }
`

function MaintenanceBanner() {
  const maintenanceBannerData = useGetFeatureFlagVariations("maintenanceBannerEnabled")
  const data = maintenanceBannerData.data
  const startDate = formatLocalDate(data.startTime)
  const endDate = formatLocalDate(data.endTime)
  const spansMultipleDays = startDate !== endDate
  const startTime = formatLocalTime(data.startTime)
  const endTime = formatLocalTime(data.endTime)

  return (
    <div style={{ marginBottom: 20 }}>
      <AlertAntD
        message={
          spansMultipleDays ? (
            <MaintenanceAlertMessage>
              <span>
                Due to scheduled maintenance, GX Cloud may be intermittently unavailable from
                <BodyStrong>{startTime}</BodyStrong> on <BodyStrong>{startDate}</BodyStrong> to
                <BodyStrong>{endTime}</BodyStrong> on <BodyStrong>{endDate}</BodyStrong>. Contact
                <SupportEmailLink /> with any questions.
              </span>
            </MaintenanceAlertMessage>
          ) : (
            <MaintenanceAlertMessage>
              <span>
                Due to scheduled maintenance, GX Cloud may be intermittently unavailable from
                <BodyStrong>{startTime}</BodyStrong> to <BodyStrong>{endTime}</BodyStrong> on{" "}
                <BodyStrong>{endDate}</BodyStrong>. Contact <SupportEmailLink /> with any questions.
              </span>
            </MaintenanceAlertMessage>
          )
        }
        type="warning"
        showIcon
        icon={<Icon small name="exclamationTriangleSolid" />}
      />
    </div>
  )
}

export { MaintenanceBanner }
