import { useTheme } from "styled-components"
import { Card, Flex, Tag } from "antd"
import { capitalize } from "lodash-es"
import type { DataQualityIssueCardProps } from "src/pages/DataAssets/drawers/ConnectToDataSource/types"
import { Icon, IconTypes } from "src/global/ui/Icon"
import { BodyNormal, Heading3 } from "src/global/ui/typography/Text/Text.tsx"

export const DataQualityIssueCard = ({ issue, metadata, selected, handleOnClick }: DataQualityIssueCardProps) => {
  const theme = useTheme()
  const cardStyle = {
    background: metadata.isAvailable
      ? theme.colors.neutralColorPalette.whites.white
      : theme.colors.neutralColorPalette.blacks.colorFillTertiary,
    borderColor: selected
      ? theme.colors.primaryColors.gxAccentMedium
      : theme.colors.neutralColorPalette.backgroundsAndBorders.gxBorder,
  }
  const issueNameStyle = {
    color: !metadata.isAvailable
      ? theme.colors.neutralColorPalette.blacks.colorTextTertiary
      : theme.colors.neutralColorPalette.blacks.black,
  }

  const getIconColor = () => {
    if (selected) {
      return theme.colors.primaryColors.gxAccentMedium
    }
    if (!metadata.isAvailable) {
      return theme.colors.neutralColorPalette.blacks.colorTextTertiary
    }
    return theme.colors.neutralColorPalette.blacks.colorTextSecondary
  }

  return (
    <Card style={cardStyle} hoverable={metadata.isAvailable} onClick={handleOnClick}>
      <Flex gap={8}>
        <Icon color={getIconColor()} size="32px" name={metadata.icon as IconTypes} />
        <Flex vertical gap={8}>
          <Heading3 style={issueNameStyle}>{capitalize(issue)}</Heading3>
          <div style={{ position: "absolute", top: "15px", right: "15px" }}>
            {selected && <Icon color={theme.colors.primaryColors.gxAccentMedium} name="check" />}
            {!metadata.isAvailable && (
              <Tag
                style={{
                  borderColor: theme.colors.neutralColorPalette.backgroundsAndBorders.gxBorder,
                  borderStyle: "solid",
                  borderWidth: "thin",
                  borderRadius: theme.spacing.xxxs,
                  padding: "2px",
                  display: "inline-block",
                }}
              >
                Coming soon
              </Tag>
            )}
          </div>
          <BodyNormal $color="colorTextTertiary">{metadata.description}</BodyNormal>
        </Flex>
      </Flex>
    </Card>
  )
}
