import { SUPPORT_EMAIL_URL } from "src/global/config.ts"
import { Link } from "src/global/ui/typography/Link/Link.tsx"

/** For consistency, use this component for all usages of the support email address */
export function SupportEmailLink() {
  return (
    <Link to={SUPPORT_EMAIL_URL} target="_blank" rel="noopener noreferrer">
      support@greatexpectations.io
    </Link>
  )
}
