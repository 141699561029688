import { Flex } from "antd"
import { useTheme } from "styled-components"
import { Background, ScrollableMainContainer } from "src/global/ui/OnboardingFlow/styles.tsx"

type AgentSetupMockModalProps = {
  content?: React.ReactNode
}

export const AgentSetupMockModal: React.FC<AgentSetupMockModalProps> = ({ content }) => {
  const theme = useTheme()

  return (
    <Background>
      <ScrollableMainContainer>
        <Flex
          vertical
          style={{
            minWidth: 360,
            backgroundColor: theme.colors.neutralColorPalette.whites.white,
            borderRadius: theme.spacing.xs,
            boxShadow: "0 12px 40px rgb(0 0 0 / 12%)",
            overflow: "hidden",
          }}
        >
          {content}
        </Flex>
      </ScrollableMainContainer>
    </Background>
  )
}
