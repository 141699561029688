import { Flex, Tooltip, Form } from "antd"
import { getHourlyIntervalFromCron, getNextRunFromCron } from "src/global/utils/cron.ts"
import { formatLocalCalendarDateWithTimeAndTimeZone } from "src/global/utils/formatTime.ts"
import { graphql } from "src/api/graphql/gql.ts"
import { unmaskFragment, FragmentType } from "src/api/graphql/fragment-masking.ts"
import { Icon } from "src/global/ui/Icon"
import { theme } from "src/global/ui/themes/theme.ts"
import { useRequireRole } from "src/global/hooks/useRequireRole.ts"
import { EditSchedulePopover } from "src/pages/DataAssets/views/Expectations/EditSchedulePopover.tsx"
import { BodyNormal } from "src/global/ui/typography/Text/Text.tsx"
import { pluralize } from "src/global/utils/strings.ts"

export const ScheduleSummaryFragmentDocument = graphql(`
  fragment ScheduleSummary on Schedule {
    id
    isEnabled
    startTime
    schedule
    ...EditScheduleForm_Schedule
  }
`)

export function SimpleScheduleSummary({
  schedule: maskedSchedule,
}: {
  schedule: FragmentType<typeof ScheduleSummaryFragmentDocument> | undefined | null
}) {
  const schedule = unmaskFragment(ScheduleSummaryFragmentDocument, maskedSchedule)
  const isEditorRole = useRequireRole("EDITOR")

  const nextRunDateTime = schedule
    ? formatLocalCalendarDateWithTimeAndTimeZone(getNextRunFromCron(schedule?.schedule).toISOString())
    : ""
  const hourlyInterval = schedule ? getHourlyIntervalFromCron(schedule?.schedule) : 1
  const intervalDisplayString = `Every ${hourlyInterval} ${pluralize("hour", hourlyInterval)}`

  return (
    <Form.Item style={{ margin: 0 }}>
      <Flex justify="space-between">
        <Flex gap={theme.spacing.xxs} align="center">
          <Tooltip title="Validation schedule">
            <Icon small name="calendar" color={theme.colors.primaryColors.gxPrimaryMedium} />
          </Tooltip>
          {schedule?.isEnabled ? (
            <BodyNormal>
              Next validation {nextRunDateTime} ({intervalDisplayString})
            </BodyNormal>
          ) : (
            <BodyNormal $color="colorTextTertiary">No scheduled validation</BodyNormal>
          )}
        </Flex>
        <Flex gap={theme.spacing.xxs} align="center">
          {isEditorRole && <EditSchedulePopover schedule={schedule} />}
        </Flex>
      </Flex>
    </Form.Item>
  )
}
