import { useQuery } from "@apollo/client"
import { App } from "antd"
import { useCallback, useState } from "react"
import { useAnalytics } from "src/global/hooks/analytics/useAnalytics.ts"
import { graphql } from "src/api/graphql/gql"
import { getLocalStorageItem, LocalStorageItemName, setLocalStorageItem } from "src/global/utils/localStorage.ts"
import { AhaSalesDemoModalConfig } from "src/global/ui/Menu/SalesDemoModal.tsx"

const AhaRequestSalesDemoModalInfo = graphql(`
  query AhaRequestSalesDemoModalInfo {
    organization {
      id
      shouldPromoteTeamPlan
    }
  }
`)

export const LOCAL_STORAGE_KEY: LocalStorageItemName = "hasDissmissedAhaRequestDemoModal"

export function useAhaSalesDemoModal(): { openModalAfterDelay: () => void } {
  const { modal } = App.useApp()
  const [previouslyDismissed, setPreviouslyDismissed] = useState(getLocalStorageItem(LOCAL_STORAGE_KEY))
  const { data } = useQuery(AhaRequestSalesDemoModalInfo)
  const isOnDeveloperPlan = Boolean(data?.organization?.shouldPromoteTeamPlan)
  const shouldShowModal = isOnDeveloperPlan && !previouslyDismissed
  const { capture } = useAnalytics()

  const openModalAfterDelay = useCallback(() => {
    if (!shouldShowModal) return

    const timer = setTimeout(() => {
      modal.info({
        ...AhaSalesDemoModalConfig,
        onCancel: () => {
          const now = new Date().toISOString()
          setLocalStorageItem(LOCAL_STORAGE_KEY, now)
          setPreviouslyDismissed(now)
          capture("request_sales_demo.post_validation_modal_dismissed")
        },
      })
    }, 5000)

    return () => clearTimeout(timer)
  }, [shouldShowModal, modal, capture])

  return { openModalAfterDelay }
}
