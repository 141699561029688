import { Form, Input, message, Radio } from "antd"
import { RuleObject } from "antd/lib/form"
import debounce from "debounce-promise"
import { useState } from "react"
import { userAssignableRoles, formatOrgRole, getOrgRole } from "src/pages/OrganizationRoot/roles.ts"
import { DEBOUNCE_DELAY, MESSAGE_DURATION_SECONDS } from "src/global/config.ts"
import { Button } from "src/global/ui/Button/Button.tsx"
import { ButtonWrapper } from "src/pages/Users/Users.styles.tsx"
import { emailRegex } from "src/global/utils/emailValidationRegex.ts"
import { StyledRadioGroup } from "src/pages/Users/components/UserModal.styles.tsx"
import { StyledFormItem } from "src/global/ui/Form/FormItem.tsx"
import { FormModal } from "src/global/ui/AppModal/AppModal.tsx"
import { RoleTooltip } from "src/global/tooltips/RoleTooltip.tsx"
// eslint-disable-next-line no-restricted-imports -- deprecated file
import {
  InviteUserToOrganizationDocument,
  UsersDocument,
  InviteUserToOrganizationMutation,
  UsersQuery,
} from "src/api/graphql/graphql-operations.ts"
import { ApolloCache, FetchResult, useMutation, useQuery, ApolloError } from "@apollo/client"
import { StyledTooltipIcon } from "src/global/ui/Tooltip/Tooltip.tsx"
import { AlertBanner } from "src/global/ui/Alert/AlertBanner.tsx"
import { useTheme } from "styled-components"
import { SupportEmailLink } from "src/global/ui/SupportEmailLink/SupportEmailLink.tsx"

export const USER_ALREADY_BELONGS_TO_ORGANIZATION_ERROR_MESSAGE =
  "A user can belong to only one organization. This user is already associated with an organization. For assistance, contact"

const getApplicationErrorMessage = (message: string) => {
  const errorMessage = message ? `: ${message}` : ""
  return `There was a problem inviting this user${errorMessage}. For assistance, contact`
}

const roles = userAssignableRoles.map((name) => formatOrgRole(name))

const InviteUserModal = () => {
  const theme = useTheme()
  const { data, loading: qLoading, error: qError } = useQuery(UsersDocument)

  let emails = data?.allUsers?.edges.map((e) => e?.node?.email ?? "") ?? []

  // effectively disable client-side validation if there is an issue
  if (qLoading || qError) {
    emails = []
  }

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)
  const [form] = Form.useForm()

  const validateFormOnValueChanges = (isEmailInvalid?: boolean) => {
    const someValuesAreNotChanged = !form.isFieldsTouched(true)
    const isEmailInvalidOrErrorsInForm = isEmailInvalid ?? form.getFieldsError().some(({ errors }) => errors.length)
    setIsButtonDisabled(someValuesAreNotChanged || isEmailInvalidOrErrorsInForm)
  }

  const [iniviteUserToOrganization, { loading, error, reset: resetMutation }] = useMutation(
    InviteUserToOrganizationDocument,
    {
      update: updateCacheToAddUser,
      onCompleted: () => {
        reset()
        message.success("Invitation sent", MESSAGE_DURATION_SECONDS)
      },
      onError: (error) => {
        console.error("Error inviting user", error)
      },
    },
  )

  const reset = () => {
    setIsModalOpen(false)
    setIsButtonDisabled(true)
    form.resetFields()
    resetMutation()
  }
  const onFinish = () => {
    if (loading || error) return
    const { email, role } = form.getFieldsValue()
    const organizationRole = getOrgRole(role)
    if (organizationRole) {
      iniviteUserToOrganization({
        variables: {
          input: {
            email,
            organizationRole: organizationRole,
          },
        },
      })
    } else {
      throw new Error("Invalid organization role") // This is unlikely but in case we add new roles or change the enum this will come handly
    }
  }

  const changeEmailValidity = debounce((value: string) => {
    const isEmailInvalid = value.toLowerCase().match(emailRegex)
    if (!isEmailInvalid) {
      validateFormOnValueChanges(true)
      return "invalid"
    }
    const userAlreadyExist = emails.find((email) => email === value)

    validateFormOnValueChanges(userAlreadyExist ? true : false)
    return Promise.resolve(userAlreadyExist ? "alreadyExist" : "valid")
  }, DEBOUNCE_DELAY)

  const getErrorMessage = (error: ApolloError) => {
    if (error.message.includes("User already belongs to Organization")) {
      return (
        <>
          {USER_ALREADY_BELONGS_TO_ORGANIZATION_ERROR_MESSAGE} <SupportEmailLink />
        </>
      )
    }

    return (
      <>
        {getApplicationErrorMessage(error.message)} <SupportEmailLink />
      </>
    )
  }

  return (
    <>
      <Button type="primary" onClick={() => setIsModalOpen(true)}>
        Invite User
      </Button>
      <FormModal
        open={isModalOpen}
        onCancel={reset}
        title="Invite User"
        footer={
          <ButtonWrapper>
            <Button aria-label="Cancel" onClick={reset}>
              Cancel
            </Button>
            <Button
              style={{ marginLeft: theme.spacing.xs }}
              type="primary"
              danger={!!error}
              onClick={onFinish}
              disabled={isButtonDisabled}
              loading={loading}
            >
              Invite
            </Button>
          </ButtonWrapper>
        }
      >
        <Form
          form={form}
          name="inviteUser"
          onValuesChange={({ role }) => {
            if (role) {
              validateFormOnValueChanges()
            }
          }}
          layout="vertical"
        >
          <Form.Item
            label="Email"
            name="email"
            hasFeedback
            required
            rules={[
              () => {
                return {
                  async validator(_: RuleObject, value: string) {
                    const validateFormOnValueChanges = (isEmailInvalid: boolean) => {
                      setIsButtonDisabled(!form.isFieldsTouched(true) || isEmailInvalid)
                    }

                    if (!value) {
                      validateFormOnValueChanges(true)
                      return Promise.reject("Enter an email address")
                    }
                    const response = await changeEmailValidity(value)
                    if (response === "invalid") return Promise.reject("Invalid email address")
                    if (response === "alreadyExist")
                      return Promise.reject(new Error("This user has already been invited"))
                    if (response === "valid") return Promise.resolve()
                  },
                }
              },
            ]}
          >
            <Input placeholder="Email address" type="email" aria-label="email address input" />
          </Form.Item>
          <StyledFormItem
            label="Organization Role"
            name="role"
            required
            tooltip={{
              icon: <StyledTooltipIcon name="questionCircle" width="18px" />,
              title: RoleTooltip,
            }}
          >
            <StyledRadioGroup>
              {roles.map((role) => (
                <Radio key={role} value={role} aria-label={`${role} checkbox`}>
                  {role}
                </Radio>
              ))}
            </StyledRadioGroup>
          </StyledFormItem>
        </Form>
        {error && (
          <AlertBanner description={getErrorMessage(error)} message="Failed to invite user" type="error" showIcon />
        )}
      </FormModal>
    </>
  )
}

export { InviteUserModal }

function updateCacheToAddUser(cache: ApolloCache<unknown>, result: FetchResult<InviteUserToOrganizationMutation>) {
  cache.updateQuery({ query: UsersDocument }, (data: UsersQuery | null) => {
    const user = result?.data?.inviteUserToOrganization
    if (!user || data?.allUsers === null || data?.allUsers === undefined) {
      return null
    }
    const newEdge = { __typename: "UserEdge", node: user } as const
    return {
      ...data,
      allUsers: {
        ...data.allUsers,
        edges: [...data.allUsers.edges, newEdge],
      },
    }
  })
}
