import {
  Drawer as AntdDrawer, // eslint-disable-line no-restricted-imports
  DrawerProps as AntdDrawerProps,
  Flex,
  FlexProps,
} from "antd"
import { Button, ButtonProps } from "src/global/ui/Button/Button.tsx"
import styled from "styled-components"
import { DrawerStyles } from "antd/es/drawer/DrawerPanel"
import { Heading2 } from "src/global/ui/typography/Text/Text.tsx"

type SizeType = Exclude<AntdDrawerProps["size"], undefined>
type SizeValues = { width: number; height: number }
type DrawerSizesDict = Record<SizeType, SizeValues>

const footerMeasurements: DrawerSizesDict = {
  default: { width: 378, height: 65 },
  large: { width: 736, height: 73 },
}
export const ScrollableFlex = styled(Flex)<{ $size?: SizeType }>`
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100% - ${({ $size }) => footerMeasurements[$size ?? "large"].height}px);
  padding: ${({ theme }) => theme.spacing.s};
`

export const StyledFooter = styled(Flex)<{ $size: SizeType }>`
  height: ${({ $size }) => `${footerMeasurements[$size].height}px`};
  z-index: 4; /* AceEditor has z-index 3, we have to accommodate for it */
  padding: ${({ theme }) => `${theme.spacing.xxs} ${theme.spacing.xs}`};
  background: white;
`

// Override some of the default AntD header/body/footer styles
// because in cases where errors or other bottom-fixed elements
// appear in the drawer body, they'll get overflowed & buried
// under the footer
const drawerOverrideStyles: DrawerStyles = {
  header: {
    flex: "0.5",
  },
  body: {
    flex: "9",
    borderBottom: "none",
  },
  footer: {
    flexShrink: undefined,
    flex: "0.5",
  },
}

// Drawer is like AntD Drawer but with different defaults
export function Drawer({ title, ...props }: AntdDrawerProps) {
  return (
    <AntdDrawer
      destroyOnClose={true}
      maskClosable={false}
      styles={drawerOverrideStyles}
      placement="right"
      size="large"
      title={<Heading2>{title}</Heading2>}
      {...props}
    />
  )
}

type FooterSize = {
  size?: SizeType
}

function DrawerFooter(props: FooterSize & FlexProps) {
  return <StyledFooter gap="middle" justify="end" align="center" $size={props.size ?? "large"} {...props} />
}

function DrawerFooterButton(props: ButtonProps) {
  return <Button size="large" {...props} />
}

Drawer.Footer = DrawerFooter
Drawer.FooterButton = DrawerFooterButton
