import { Drawer } from "src/global/ui/Drawer/Drawer.tsx"
import { Button } from "src/global/ui/Button/Button.tsx"
import styled from "styled-components"
import { useCreateExpectationSelection } from "src/pages/DataAssets/views/Expectations/Expectation/CreateExpectationDrawer/useFilteredExpectationsCards.ts"
import { schemaToCardData } from "src/pages/DataAssets/views/Expectations/Expectation/CreateExpectationDrawer/utils.tsx"
import { SelectedExpectation } from "src/pages/DataAssets/views/Expectations/Expectation/CreateExpectationDrawer/types.ts"

const StyledFooterContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.xxs};
  margin: ${({ theme }) => theme.spacing.s} 0;
`

interface AssetDrawerFooterProps {
  onBack: () => void
  onSave: () => void
  onSaveAndAdd: () => void
  saveLoading: boolean
  saveDisabled?: boolean
}

interface SelectExpectationFooterProps {
  onSelectExpectation: (selectedExpectation: SelectedExpectation) => void
}

export const UNEXPECTED_ROW_CARD_PROPS = schemaToCardData("unexpectedRowsExpectation")

export function SelectExpectationFooter({ onSelectExpectation }: SelectExpectationFooterProps) {
  const clickMaker = useCreateExpectationSelection()
  return (
    <StyledFooterContentContainer>
      <div>Want to define your own test with SQL?</div>
      <Button
        onClick={clickMaker({
          onSelectExpectation,
          expectationCardProps: UNEXPECTED_ROW_CARD_PROPS,
          selectionPosition: 0,
        })}
        icon="plus"
      >
        Create custom Expectation
      </Button>
    </StyledFooterContentContainer>
  )
}

export function SaveExpectationFooter({
  onBack,
  onSave,
  onSaveAndAdd,
  saveLoading,
  saveDisabled = false,
}: AssetDrawerFooterProps) {
  return (
    <Drawer.Footer>
      <Drawer.FooterButton type="default" onClick={() => onBack()} id="create-expectation-for-asset-back-button">
        Back
      </Drawer.FooterButton>
      <Drawer.FooterButton
        type="default"
        onClick={() => onSave()}
        loading={saveLoading}
        disabled={saveDisabled}
        id="create-expectation-for-asset-save-button"
      >
        Save
      </Drawer.FooterButton>
      <Drawer.FooterButton
        type="primary"
        onClick={() => onSaveAndAdd()}
        loading={saveLoading}
        disabled={saveDisabled}
        id="create-expectation-for-asset-save-and-add-more-button"
      >
        Save & add more
      </Drawer.FooterButton>
    </Drawer.Footer>
  )
}
