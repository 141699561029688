import { RuleEffect } from "@jsonforms/core"
import type { UISchema } from "@great-expectations/jsonforms-antd-renderers"
import { Typography } from "antd"
import { CreateGXUserExtra } from "src/pages/DataAssets/components/CreateGXUserExtra.tsx"
const { Text } = Typography
import { PostgresDataSourceJsonSchema } from "src/pages/DataAssets/schemas/json-schemas/postgres-data-source-json-schema.ts"
import { Icon } from "src/global/ui/Icon/Icon.tsx"
import { theme } from "src/global/ui/themes/theme.ts"
import { getDatabaseFromPostgresConnectionString } from "src/pages/DataAssets/drawers/ConnectToDataSource/common/SelectTableNames/get-address-from-data-source.ts"

export const PostgresDataSourcePage1UISchema: UISchema<typeof PostgresDataSourceJsonSchema> = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Control",
      scope: "#/properties/type",
      rule: {
        effect: RuleEffect.HIDE,
        condition: {},
      },
    },
    {
      type: "Control",
      scope: "#/properties/name",
      label: "Data Source name",
      options: {
        placeholderText: "Data Source name",
      },
    },
    {
      type: "Group",
      elements: [
        {
          type: "Control",
          scope: "#/properties/connection_string",
          label: "Connection string",
          options: {
            type: "password",
            required: true,
            placeholderText: `postgresql+psycopg2://<username>:<password>@<host>:<port>/<database>`,
          },
          formItemProps: {
            extra: <CreateGXUserExtra dataSourceType="POSTGRES" />,
            rules: [
              {
                validator(rule, value: unknown) {
                  if (typeof value !== "string") {
                    return Promise.reject(new Error("Please enter a valid connection string"))
                  }
                  const { database } = getDatabaseFromPostgresConnectionString(value) ?? {}

                  if (!database) {
                    return Promise.reject(new Error("Postgres connection string must specify a database"))
                  }
                  return Promise.resolve()
                },
              },
            ],
            tooltip: {
              title: (
                <span>
                  Must use{" "}
                  <Text code style={{ color: theme.colors.neutralColorPalette.whites.white }}>
                    postgresql+psycopg2://
                  </Text>{" "}
                  data-connector. See{" "}
                  <a
                    href="https://docs.greatexpectations.io/docs/core/connect_to_data/sql_data/#procedure"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: `${theme.colors.neutralColorPalette.whites.white}`, textDecoration: "underline" }}
                  >
                    connect to SQL data
                  </a>{" "}
                  for more details.
                </span>
              ),
            },
          },
        },
      ],
    },
  ],
}

export const PostgresDataSourcePage2UISchema: UISchema<typeof PostgresDataSourceJsonSchema> = {
  type: "VerticalLayout",
  elements: [
    {
      label: "Data Asset",
      type: "Control",
      scope: "#/properties/assets",
      options: {
        removeButtonProps: {
          type: "text",
          icon: <Icon name="trash" size="16px" style={{ marginTop: "3px" }} />,
          children: "",
        },
      },
    },
  ],
}
