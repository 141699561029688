import React, { useState } from "react"
import { PageHeader } from "src/global/ui/PageHeader/PageHeader.tsx"
import { MainContent, MainContentBlock } from "src/global/layout/MainContent.tsx"
import { DataAssetHeaderActions } from "src/pages/DataAssets/views/AssetDetails/DataAssetHeaderActions.tsx"
import { ValidateExpectationsModal } from "src/pages/DataAssets/views/AssetDetails/ValidateExpectationsModal.tsx"
import { DataAssetTabs } from "src/pages/DataAssets/views/AssetDetails/DataAssetTabs.tsx"
import { ValidationsTab_DataAssetQuery } from "src/api/graphql/graphql.ts"
import { useExpectationSuite } from "src/pages/DataAssets/views/Validations/ValidationHooks.tsx"
import { useGetSplitterData } from "src/pages/DataAssets/views/AssetDetails/Splitters/useGetSplitterData.ts"
import { getDataAssetBreadcrumbs } from "src/pages/DataAssets/views/AssetDetails/DataAssetBreadcrumbs.tsx"
import { dataAssetsNavigateToVar } from "src/pages/DataAssets/components/DataAssetsTable"

// Define the types for the props
type ValidationsTabHeaderProps = {
  assetId: string
  dataAssetData: ValidationsTab_DataAssetQuery | undefined
  listLoading: boolean
  expectationSuiteId: string
}

export const ValidationsTabHeader: React.FC<React.PropsWithChildren<ValidationsTabHeaderProps>> = ({
  assetId,
  dataAssetData,
  listLoading,
  children,
  expectationSuiteId,
}) => {
  const [openValidateExpectationsModal, setOpenValidateExpectationsModal] = useState(false)

  const { expectationSuiteData } = useExpectationSuite(expectationSuiteId)
  const expectationCount = expectationSuiteData?.expectationSuiteV2?.expectations.length ?? 0

  const { splitter, isSplitterSupported } = useGetSplitterData({ isVisible: true })

  const showValidateSubsetButton = !!(isSplitterSupported && splitter)

  const subtitle = `${expectationCount} Expectation${expectationCount !== 1 ? "s" : ""}`

  return (
    <PageHeader
      headerContent={{
        title: dataAssetData?.dataAsset?.name || "",
        rootPath: "data-assets",
        subtitle,
        navigateBackTo: dataAssetsNavigateToVar(),
        rightActions: {
          reactNode: (
            <DataAssetHeaderActions
              assetId={assetId}
              showValidateSubsetButton={showValidateSubsetButton}
              noExpectations={expectationCount === 0}
              expectationSuiteId={expectationSuiteId}
            />
          ),
        },
        customBreadcrumbs: getDataAssetBreadcrumbs({
          dataSource: dataAssetData?.dataAsset?.datasourceV2,
          tooltipPlacement: "right",
        }),
        footer: <DataAssetTabs expectationSuiteId={expectationSuiteId} currentTab="validations" />,
      }}
      loading={listLoading}
    >
      <MainContent>
        <MainContentBlock>
          {children}
          <ValidateExpectationsModal
            expectationSuiteID={expectationSuiteId}
            assetRefID={assetId}
            open={openValidateExpectationsModal}
            onCancel={() => setOpenValidateExpectationsModal(false)}
            onOk={() => setOpenValidateExpectationsModal(false)}
          />
        </MainContentBlock>
      </MainContent>
    </PageHeader>
  )
}
