import { ReactNode } from "react"
import styled from "styled-components"
import { MaintenanceBanner } from "src/global/components/banners/MaintenanceBanner.tsx"

import { useGetFeatureFlagVariations } from "src/global/hooks/useGetFeatureFlagVariations.ts"

const StyledDiv = styled.div`
  padding: ${({ theme }) => theme.spacing.s};
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: ${({ theme }) => theme.spacing.xxs};
`

interface MainContentBlockProps {
  children: ReactNode
  size?: "xsmall" | "small" | "medium" | "large"
}

function MainContentBlock({ children, size = "small" }: MainContentBlockProps) {
  const widths = {
    xsmall: "900px",
    small: "1200px",
    medium: "1300px",
    large: "1500px",
  }

  return <div style={{ maxWidth: widths[size] }}>{children}</div>
}

interface MainContentProps {
  children: ReactNode
}

/**
 * This component should only be used once per page.
 */
function MainContent(props: MainContentProps) {
  const maintenanceFlagData = useGetFeatureFlagVariations("maintenanceBannerEnabled")

  return (
    <StyledDiv>
      {!!maintenanceFlagData?.enabled && <MaintenanceBanner />}
      {props.children}
    </StyledDiv>
  )
}

export { MainContent, MainContentBlock }
