import { Alert, Flex } from "antd"
import styled, { useTheme } from "styled-components"
import Slack from "src/global/ui/Image/assets/image/slack.svg?react"
import MSTeams from "src/global/ui/Image/assets/image/msTeams.svg?react"
import PagerDuty from "src/global/ui/Image/assets/image/pagerDuty.svg?react"

const INFO_BOX_TEXT =
  "Add email recipients to notify specific users or communication workspace when this Data Asset fails a Validation. Learn more about alerts, at [Documentation link name]. "

const WhiteBox = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.neutralColorPalette.whites.white};
  border-radius: ${({ theme }) => theme.spacing.xxxs};
`
const Logos = () => {
  const theme = useTheme()
  return (
    <Flex gap={theme.spacing.xxs} style={{ marginTop: theme.spacing.xxs }}>
      {[
        { Logo: Slack, key: "slack" },
        { Logo: MSTeams, key: "msTeams" },
        { Logo: PagerDuty, key: "pagerDuty" },
      ].map(({ Logo, key }) => (
        <WhiteBox key={key} justify="center" align="center">
          <Logo />
        </WhiteBox>
      ))}
    </Flex>
  )
}

export const InfoBox = () => {
  const theme = useTheme()
  return (
    <Alert
      description={
        <Flex vertical>
          {INFO_BOX_TEXT}
          <Flex gap={theme.spacing.xxs}>
            <Logos />
          </Flex>
        </Flex>
      }
      type="info"
    />
  )
}
