import styled from "styled-components"
import { Flex } from "antd"
import { Icon } from "src/global/ui/Icon/Icon.tsx"
import { theme } from "src/global/ui/themes/theme.ts"
import { getImageLogo } from "src/pages/DataAssets/getImageLogo.ts"

import { getHumanReadableDataSourceType } from "src/pages/DataAssets/components/PickNewOrExistingDataSource.tsx"
import { SupportedDataSource } from "src/pages/DataAssets/schemas/data-source-schemas.ts"
import React from "react"
import { BodyNormal, Heading3 } from "src/global/ui/typography/Text/Text.tsx"
import { UnstyledLink } from "src/global/ui/typography/Link/Link.tsx"

const StyledLogo = styled.img`
  width: ${({ theme }) => theme.spacing.s};
  height: ${({ theme }) => theme.spacing.s};
`

const TypeToDocsURLMap: Record<SupportedDataSource, string> = {
  DATABRICKS_SQL: "https://docs.greatexpectations.io/docs/cloud/connect/connect_databricks_sql", // Note: docs don't exist yet
  POSTGRES: "https://docs.greatexpectations.io/docs/cloud/connect/connect_postgresql",
  SNOWFLAKE: "https://docs.greatexpectations.io/docs/cloud/connect/connect_snowflake",
  SQL: "https://docs.greatexpectations.io/docs/oss/guides/connecting_to_your_data/fluent/database/connect_sql_source_data",
  SQLITE:
    "https://docs.greatexpectations.io/docs/oss/guides/connecting_to_your_data/fluent/database/connect_sql_source_data/?sql-database-type=sqlite",
}

type LogoAndLinkHeaderProps = {
  dataSourceType: SupportedDataSource
  text: string
  secondaryText?: string
  alert?: React.ReactNode
}

export function LogoAndLinkHeader({ dataSourceType, text, alert, secondaryText = "" }: LogoAndLinkHeaderProps) {
  return (
    <Flex vertical gap="large">
      <Flex gap="small" align="center">
        <StyledLogo src={getImageLogo(dataSourceType)} alt={`${dataSourceType} logo`} />
        <Heading3>{`${text} ${getHumanReadableDataSourceType(dataSourceType)} ${secondaryText}`}</Heading3>
        <DataSourceDocsLink dataSourceType={dataSourceType} />
      </Flex>
      {alert}
    </Flex>
  )
}

export function EditDataSourceDrawerHeader({
  dataSourceType,
  dataSourceName,
}: {
  dataSourceType: SupportedDataSource
  dataSourceName: string
}) {
  return (
    <Flex vertical gap="large">
      <Flex gap="small" align="center">
        <StyledLogo src={getImageLogo(dataSourceType)} alt={`${dataSourceType} logo`} />
        <Heading3>{dataSourceName}</Heading3>
        <DataSourceDocsLink dataSourceType={dataSourceType} />
      </Flex>
    </Flex>
  )
}

export function AddAssetDrawerHeader({
  dataSourceType,
  dataSourceName,
  dataSourceAddress,
}: {
  dataSourceType: SupportedDataSource
  dataSourceName: string
  dataSourceAddress?: string
}) {
  return (
    <Flex vertical gap="small">
      <Flex gap="small" align="center">
        <StyledLogo src={getImageLogo(dataSourceType)} alt={`${dataSourceType} logo`} />
        <Heading3>{dataSourceName}</Heading3>
      </Flex>
      {dataSourceAddress && <BodyNormal $color="gxPrimaryLight">{dataSourceAddress}</BodyNormal>}
    </Flex>
  )
}

type EditDataAssetDrawerHeaderProps = {
  dataSourceType: SupportedDataSource
  dataAssetName: string
}

export function EditDataAssetDrawerHeader({ dataSourceType, dataAssetName }: EditDataAssetDrawerHeaderProps) {
  return (
    <Flex vertical gap="large">
      <Flex gap="small" align="center">
        <StyledLogo src={getImageLogo(dataSourceType)} alt={`${dataSourceType} logo`} />
        <Heading3>{dataAssetName}</Heading3>
      </Flex>
    </Flex>
  )
}

export function DataSourceDocsLink({ dataSourceType }: { dataSourceType: SupportedDataSource }) {
  const docsURL = TypeToDocsURLMap[dataSourceType]
  return (
    <UnstyledLink to={docsURL} target="_blank">
      <Icon
        size="18px"
        name="externalLink"
        color={theme.colors.neutralColorPalette.blacks.colorTextQuaternary}
        style={{ marginTop: "4px" }}
        aria-label="external data source docs link"
      />
    </UnstyledLink>
  )
}
