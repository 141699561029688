import { Flex, Form } from "antd"
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react"
import { JsonForm } from "src/pages/DataAssets/components/JsonForm.tsx"
import {
  DataSourceUISchemaMap,
  defaultValues,
  getDataSourceJsonSchemaMap,
  getUISchemaRegistryEntries,
} from "src/pages/DataAssets/schemas/data-source-schemas.ts"
import { useAssetCreationJobStatus } from "src/pages/DataAssets/hooks/useAssetCreationJobStatus.tsx"
import ConnectToDataSourceContext from "src/pages/DataAssets/drawers/ConnectToDataSource/ConnectToDataSourceContext.ts"
import { GroupRendererRegistryEntry } from "src/global/jsonforms/layouts/GroupRenderer.tsx"
import type {
  StepComponentWithSlot,
  ErrorField,
  TestDSConnectionErrorProps,
} from "src/pages/DataAssets/drawers/ConnectToDataSource/types.ts"
import { StepContainer } from "src/pages/DataAssets/drawers/ConnectToDataSource/common/StepContainer.ts"
import { graphql } from "src/api/graphql/gql"
import { useMutation, useReactiveVar } from "@apollo/client"
import stringify from "json-stable-stringify"
import { AlertProps } from "antd/lib"
import { AlertBanner } from "src/global/ui/Alert"
import { isEqual, isEmpty } from "lodash-es"
import { useIsGXAgentEnabled } from "src/global/hooks/useIsGXAgentEnabled.ts"
import { ScrollableFlex } from "src/global/ui/Drawer/Drawer.tsx"
import {
  isEnableAgentRequestPendingVar,
  RequestAgentAlert,
} from "src/pages/DataAssets/components/RequestAgentAlert.tsx"
import { JobWithTableNamesFragment } from "src/api/graphql/graphql"
// eslint-disable-next-line no-restricted-imports
import { DatasourcesWithRunsDocument } from "src/api/graphql/graphql-operations"
import { theme } from "src/global/ui/themes/theme"

const CreateTestDataSourceConfigJobDocument = graphql(`
  mutation CreateTestDatasourceConfigJob($config: JSONString!) {
    createTestDatasourceJob(config: $config) {
      jobId
    }
  }
`)

const CreateDataSourceDocument = graphql(`
  mutation CreateDatasource($config: JSONString!) {
    createDatasource(config: $config) {
      datasourceV2 {
        name
        id
        type
        config
      }
    }
  }
`)

const UpdateRecentlyCreatedDataSourceDocument = graphql(`
  mutation UpdateRecentlyCreatedDataSource($input: UpdateDatasourceInput!) {
    updateDatasource(input: $input) {
      datasourceV2 {
        name
        id
        type
        config
      }
    }
  }
`)

export function EnterCredentialsStep({ children }: StepComponentWithSlot) {
  const { dataSource, dataSourceType, setDataSource, setAllTableNames, formData, setFormData } =
    useContext(ConnectToDataSourceContext)
  const [form] = Form.useForm()
  const initialData = useMemo(() => (dataSourceType ? defaultValues[dataSourceType] : {}), [dataSourceType])
  const [errorMessage, setErrorMessage] = useState<null | AlertProps>()
  const [dsTestPending, setDSTestPending] = useState<boolean>()
  const [dsEdited, setDSEdited] = useState<boolean>(false)
  const [disableProgress, setDisableProgress] = useState<boolean>(false)
  const agentEnabled = useIsGXAgentEnabled()
  const isEnableAgentRequestPending = useReactiveVar(isEnableAgentRequestPendingVar)

  // These refs make it possible to watch hook return values
  //  -- like those from `useAssetCreationJobStatus` --
  // without re-rendering or breaking out of an ongoing async operation,
  // which is necessary for this Step to interact safely with the Container's
  // Next & Back button handlers.
  const connectionError = useRef<boolean>(false)
  const jobComplete = useRef<boolean>(false)
  const createdDataSource = useRef<boolean>(false)
  const jobTableNames = useRef<string[]>([])

  useEffect(() => {
    if (isEmpty(formData)) {
      setFormData(initialData)
    }
  }, [formData, initialData, setFormData])

  // Pull in the form schema for the given data source
  const jsonSchema = useMemo(
    () => (dataSourceType ? getDataSourceJsonSchemaMap()[dataSourceType] : undefined),
    [dataSourceType],
  )

  // Register any custom registries for the json form
  const schemaRegistryEntries = useMemo(
    () => (dataSourceType ? getUISchemaRegistryEntries(dataSourceType) : undefined),
    [dataSourceType],
  )

  // We need to test the viability of the data source before
  // we save it. This helps catch issues like: bad connection strings, bad connections, etc...
  const [initiateDataSourceTest, { data: dsTestData, reset: resetDSTest }] = useMutation(
    CreateTestDataSourceConfigJobDocument,
  )

  // Set up the data source mutations
  const [createDatasource, { loading }] = useMutation(CreateDataSourceDocument, {
    refetchQueries: [{ query: DatasourcesWithRunsDocument }],
    onError: (error) => {
      setErrorMessage({ message: "Failed to create Data Source", description: error.message })
      throw new Error("failed to create data source")
    },
  })

  const [updateDatasource, { loading: updateLoading }] = useMutation(UpdateRecentlyCreatedDataSourceDocument, {
    refetchQueries: [{ query: DatasourcesWithRunsDocument }],
    onError: (error) => {
      setErrorMessage({ message: "Failed to update Data Source", description: error.message })
      throw new Error("failed to update data source")
    },
    onCompleted: (data) => {
      if (data.updateDatasource?.datasourceV2) {
        setDataSource(data.updateDatasource.datasourceV2)
      }
    },
  })

  // Update form data on each input stroke
  const onChange = useCallback(
    (newData: Record<string, unknown>) => {
      setDisableProgress(false)
      setErrorMessage(null)
      setDSTestPending(false)
      connectionError.current = false
      jobComplete.current = false
      createdDataSource.current = false
      jobTableNames.current = []
      if (!isEqual(formData, newData)) {
        setFormData(newData)
        dataSource && dataSource.id && setDSEdited(true)
      }
    },
    [dataSource, formData, setFormData],
  )

  /**
   * Data Source Connection monitors and handlers
   */
  const handleTestConnectionError: ({ description, message, type }: TestDSConnectionErrorProps) => void = useCallback(
    ({ description, message, type }) => {
      connectionError.current = true
      setErrorMessage({ description, message: message ?? null, type: type ?? "error" })
      setDSTestPending(false)
      resetDSTest()
      jobComplete.current = true
    },
    [resetDSTest],
  )

  const assetJobCompletionHandler = useCallback(
    async (job: Pick<JobWithTableNamesFragment, "tableNames">) => {
      if (!createdDataSource.current && job?.tableNames?.length && job.tableNames.length > 0) {
        jobTableNames.current = job.tableNames as string[]
        createdDataSource.current = true
        const result = await createDatasource({
          variables: {
            config: stringify(formData),
          },
        })
        setDataSource(result.data?.createDatasource?.datasourceV2)
        jobComplete.current = true
        setDSTestPending(false)
      }
    },
    [createDatasource, formData, setDataSource],
  )

  useAssetCreationJobStatus({
    jobId: dsTestPending ? dsTestData?.createTestDatasourceJob?.jobId : undefined,
    onError: handleTestConnectionError,
    onComplete: assetJobCompletionHandler,
  })

  /**
   * Form Validation & Submit handlers
   */
  // On "save" or "next" click, validate formData
  // Thrown an error if validation fails
  const validateForm = useCallback(async () => {
    setErrorMessage(null)
    const formValidationResult = await form
      .validateFields()
      .then((values: Record<string, unknown>) => ({ ...values, type: dataSourceType }))
      .catch((errorInfo: { errorFields: unknown[] }) => {
        return errorInfo
      })

    if ("errorFields" in formValidationResult) {
      setDisableProgress(true)
      const errors = (formValidationResult.errorFields as ErrorField[])
        .reduce((acc, e) => [...acc, ...e.errors], [] as string[])
        .join("; ")
      setErrorMessage({ message: "Please fix the following errors", description: errors })
      throw new Error("")
    }
  }, [form, dataSourceType])

  // This function allows us to watch a non-state-dependent
  // ref value - jobComplete.current - and resolve the
  // Promise once its value changes. This is necessary
  // in order to halt the ongoing async operation intiated
  // by the `next` handler.
  const waitForConnectionJob = useCallback(async () => {
    setDSTestPending(true)
    return new Promise<boolean>((resolve) => {
      const interval = setInterval(() => {
        if (jobComplete.current && createdDataSource.current) {
          setAllTableNames(jobTableNames.current)
          clearInterval(interval)
          resolve(!connectionError.current)
        }
      }, 0)
    })
  }, [setAllTableNames])

  // Initiate the sequence of operations to save a data source.
  // Each intermediate step (validateForm, initiateDataSourceTest)
  // should throw an error if it fails; this ensures
  // we exit the async op early and the error gets
  // reported up the chain.
  // Returning a boolean value indicates to the Container
  // whether it's safe to progress beyond this function.
  const handleSave = useCallback(async () => {
    try {
      setDisableProgress(true)

      await validateForm()

      if (dataSource && dataSource.id) {
        if (dsEdited) {
          await updateDatasource({
            variables: {
              input: {
                config: stringify({ ...formData, id: dataSource.id }),
                id: dataSource.id,
              },
            },
          })
          setAllTableNames([])
        }
        return true
      }

      await initiateDataSourceTest({
        variables: {
          config: stringify(formData),
        },
      })

      return await waitForConnectionJob()
    } catch (e) {
      setDSTestPending(false)
      setDisableProgress(false)
      return false
    }
  }, [
    dataSource,
    dsEdited,
    formData,
    initiateDataSourceTest,
    setAllTableNames,
    updateDatasource,
    validateForm,
    waitForConnectionJob,
  ])

  const nextLabel = useMemo(() => {
    if (dsTestPending) {
      return "Connecting"
    }

    if (dataSource && !isEmpty(dataSource)) {
      return "Next"
    }

    return "Connect"
  }, [dataSource, dsTestPending])

  // Early return if we don't have a dataSourceType
  // (this makes both TS & React happy, as long as there's no hooks after this point)
  if (!dataSourceType) {
    return null
  }

  if (!agentEnabled && isEnableAgentRequestPending) {
    return (
      <ScrollableFlex vertical gap="middle">
        <RequestAgentAlert />
      </ScrollableFlex>
    )
  }

  const [uiSchema] = DataSourceUISchemaMap[dataSourceType]

  return children(
    {
      loading: loading || updateLoading || dsTestPending,
      disableProgress: loading || updateLoading || disableProgress,
      next: handleSave,
      nextLabel,
    },
    <StepContainer>
      <Flex vertical gap="large">
        {!agentEnabled && <RequestAgentAlert />}
      </Flex>
      <Form form={form} layout="vertical" style={{ marginTop: theme.spacing.xs }}>
        <JsonForm
          jsonSchema={jsonSchema}
          uiSchema={uiSchema}
          uiSchemaRegistryEntries={schemaRegistryEntries}
          customRendererRegistryEntries={[GroupRendererRegistryEntry]}
          data={formData}
          updateData={onChange}
        />
        {errorMessage && <AlertBanner message={errorMessage.message} description={errorMessage.description} />}
      </Form>
    </StepContainer>,
  )
}
