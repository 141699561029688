import { Space } from "antd"
import { AppModal, AppModalProps } from "src/global/ui/AppModal/AppModal.tsx"
import { Heading2 } from "src/global/ui/typography/Text/Text.tsx"
import { Icon } from "src/global/ui/Icon/Icon.tsx"
import { useTheme } from "styled-components"
import { AlertBanner } from "src/global/ui/Alert/AlertBanner.tsx"
import { DELETE_MODAL_WIDTH } from "src/global/config.ts"

type DeleteResourceModalProps = AppModalProps & {
  // Unique name of the resource to be deleted (ex: "Sample Data Asset").  Will show in the message text.
  name: string
  // The type of resource being deleted (ex: "Data Asset"). Will show in the modal title and message text.
  resourceTitle: string
  // Optional: Display an alternate string for the resource title in the message text. Will default to the value of `resourceTitle` if not provided.
  alternateResourceTitle?: string
  errorMessage?: string
  alternateBodyText?: string
  isBulkDelete?: boolean
}

export const DeleteResourceModal = ({
  name,
  resourceTitle,
  alternateResourceTitle,
  errorMessage,
  alternateBodyText,
  isBulkDelete,
  ...props
}: DeleteResourceModalProps) => {
  const theme = useTheme()
  const title = isBulkDelete ? `Bulk-delete ${resourceTitle}` : `Delete ${resourceTitle}`
  return (
    <AppModal
      {...props}
      width={DELETE_MODAL_WIDTH}
      okButtonProps={{ ...props.okButtonProps, danger: true }}
      okText="Delete"
      title={
        <Space direction="horizontal" align="center">
          <Icon size="28px" name="trash" lineHeight="2px" color={theme.colors.error.gxError} />
          <Heading2>{title}</Heading2>
        </Space>
      }
    >
      <>
        {alternateBodyText ? (
          alternateBodyText
        ) : (
          <>
            This {alternateResourceTitle ?? resourceTitle} will be permanently deleted and cannot be restored. Are you
            sure you want to delete <b>{name}</b>?
          </>
        )}
      </>
      {errorMessage && <AlertBanner message={`Failed to delete ${resourceTitle}`} description={errorMessage} />}
    </AppModal>
  )
}
