import { Flex, Table, Tooltip, Tag } from "antd"
import type { TableProps } from "antd"
import { useMemo, useState, type Key } from "react"
import { capitalize, uniqBy } from "lodash-es"
import { intlFormatDistance } from "date-fns"

import { getDomainKwargs } from "src/global/utils/getExpectationGroupName.ts"
import { graphql } from "src/api/graphql/gql.ts"
import {
  ExpectationValidationResultV2,
  Domain,
  ExpectationsTable_ExpectationFragment,
  RenderedExpectationFragment,
} from "src/api/graphql/graphql.ts"
import { EditExpectationFragment } from "src/pages/DataAssets/views/Expectations/SimpleExpectationDrawer/SimpleEditExpectationDrawer.tsx"
import { getRenderer } from "src/pages/DataAssets/views/Expectations/Expectation/utils.tsx"
import { Button } from "src/global/ui/Button/Button.tsx"
import { DeleteExpectationModal } from "src/pages/DataAssets/views/Expectations/DeleteExpectationModal.tsx"
import { FragmentType, unmaskFragment } from "src/api/graphql/fragment-masking.ts"
import { Image } from "src/global/ui/Image"
import { Icon } from "src/global/ui/Icon"
import { parseMetaNotes } from "src/pages/DataAssets/views/Expectations/Expectation/utils/parsing.ts"
import { parseJSONStringConfig } from "src/pages/DataAssets/views/AssetDetails/utils.tsx"
import { MetaNotes } from "src/pages/DataAssets/views/Expectations/Expectation/MetaNotes.tsx"
import { Sentry } from "src/global/observability/sentry/sentry.ts"
import { useIsFeatureEnabled } from "src/global/hooks/useIsFeatureEnabled.ts"
import {
  getExpectationMetaInfo,
  GetExpectationMetaInfo_ExpectationFragmentDocument,
} from "src/global/schemas/expectation-metadata-utils"

export const ExpectationsTable_ExpectationFragmentDocument = graphql(`
  fragment ExpectationsTable_Expectation on ExpectationConfiguration {
    geCloudId
    gxManaged
    autogenerated
    kwargs
    expectationType
    description
    domain {
      domainKwargs
      domainType
      id
      domainColumns {
        name
        order
      }
    }
    renderedContent {
      ...RenderedExpectation
    }
    validationResults(input: { limit: 1 }) {
      runTime
      success
    }
    ...GetExpectationMetaInfo_Expectation
  }
`)

type ExpectationTableExpectationFragment = FragmentType<typeof ExpectationsTable_ExpectationFragmentDocument>

interface ExpectationsTableProps {
  antdTableProps: TableProps
  assetId: string
  data: ExpectationTableExpectationFragment[]
  loading: boolean
  id: string
  setEditingExpectationFragment: (fragment: EditExpectationFragment) => void
  dataTestid?: string
}

type DataType = {
  expectation: ExpectationData
  lastValidated: Omit<ExpectationValidationResultV2, "__typename">
  actions: { expectationFragment: EditExpectationFragment; expectationId: string | null }
  children?: DataType[]
  isGroupRow?: boolean
}

interface ExpectationData extends DomainData {
  content: string | JSX.Element | null
  id: string
  autogenerated: boolean
  dataQualityIssue?: string
}

type DomainData = {
  domainName: string
  sortValue: string
  truncateInfo: {
    truncate: boolean
    fullName: string
    restColumns?: string
  }
}

type OnChange = TableProps<DataType>["onChange"]
type SortOrder = "descend" | "ascend" | null

const assetDomainName = "Asset"
const customSQLDomainName = "Custom SQL"
const customSQLExpectationType = "unexpected_rows_expectation"
const firstLexicalCharacter = String.fromCharCode(0)

export const ExpectationsTable = (props: ExpectationsTableProps) => {
  const { assetId, data, id, loading, setEditingExpectationFragment, antdTableProps, dataTestid } = props

  const [domainSortOrder, setDomainSortOrder] = useState<SortOrder>(null)
  const [closedRowKeys, setClosedRowKeys] = useState<Key[]>([])
  const unmaskedData = unmaskFragment(ExpectationsTable_ExpectationFragmentDocument, data)
  const isAccordionEnabled = useIsFeatureEnabled("completenessChangeDetectionEnabled")

  const { dataSource, expandable } = useMemo(() => {
    const dataSource = isAccordionEnabled
      ? getGroupedExpectationData(unmaskedData, domainSortOrder)
      : getExpectationRowData(unmaskedData, domainSortOrder)

    if (!isAccordionEnabled) {
      return { dataSource, expandable: undefined }
    }

    const expandable: TableProps<DataType>["expandable"] = {
      expandedRowKeys: dataSource.map((group) => group.expectation.id).filter((id) => !closedRowKeys.includes(id)),
      onExpand: (expanded: boolean, record: DataType) => {
        expanded
          ? setClosedRowKeys((prev) => prev.filter((key) => key !== record.expectation.id))
          : setClosedRowKeys((prev) => [...prev, record.expectation.id])
      },
      expandIcon: ({ expanded, onExpand, record }) => {
        if (record.isGroupRow) {
          return (
            <Button
              onClick={(e) => {
                e.stopPropagation()
                onExpand(record, e)
              }}
              type="text"
              size="small"
              aria-label={expanded ? "Collapse group" : "Expand group"}
              icon={expanded ? "chevronDown" : "chevronRight"}
            />
          )
        }
        return null
      },
    }

    return {
      dataSource,
      expandable,
    }
  }, [unmaskedData, domainSortOrder, isAccordionEnabled, closedRowKeys])

  const handleChange: OnChange = () => {
    switch (domainSortOrder) {
      case null:
        setDomainSortOrder("ascend")
        break
      case "ascend":
        setDomainSortOrder("descend")
        break
      case "descend":
      default:
        setDomainSortOrder(null)
        break
    }
  }

  return (
    <Table
      {...antdTableProps}
      id={id}
      data-testid={dataTestid}
      columns={getColumns(unmaskedData, domainSortOrder, assetId ?? "", setEditingExpectationFragment)}
      dataSource={dataSource}
      loading={loading}
      onChange={handleChange}
      pagination={false}
      rowKey={({ expectation }: DataType) => expectation.id}
      expandable={expandable}
    />
  )
}

const getColumns = (
  data: ExpectationsTable_ExpectationFragment[],
  domainSortOrder: SortOrder,
  assetId: string,
  onEdit: (expectationFragment: EditExpectationFragment) => void,
): TableProps<DataType>["columns"] => [
  {
    dataIndex: "automaticallyGenerated",
    key: "automaticallyGenerated",
    width: "40px",
    render: (_, { expectation }) => {
      if (expectation.autogenerated) {
        return (
          <>
            <Tooltip title="Expectation was automatically generated" placement="right">
              <Icon name="bolt" color="#8c8c8c" />
            </Tooltip>
          </>
        )
      }
    },
  },
  {
    title: "Expectation",
    dataIndex: "expectation",
    key: "exp",
    filters: getDomainFilter(data),
    onFilter: (value, record) => {
      if (record.isGroupRow && record.children) {
        return record.children.some((child) => child.expectation.domainName === value)
      }
      return record.expectation.domainName === value
    },
    filterMultiple: true,
    sorter: (rowA, rowB) => {
      if (rowA.isGroupRow && rowB.isGroupRow) {
        return sortFn(rowA.expectation.sortValue, rowB.expectation.sortValue)
      }

      if (rowA.isGroupRow && rowA.children && rowA.children.length > 0) {
        const representativeA = rowA.children[0].expectation.sortValue
        return sortFn(representativeA, rowB.expectation.sortValue)
      }

      if (rowB.isGroupRow && rowB.children && rowB.children.length > 0) {
        const representativeB = rowB.children[0].expectation.sortValue
        return sortFn(rowA.expectation.sortValue, representativeB)
      }

      return sortFn(rowA.expectation.sortValue, rowB.expectation.sortValue)
    },
    sortOrder: domainSortOrder,
    render: (_, { expectation }) => expectation.content,
  },
  {
    title: "Last validated",
    dataIndex: "lastValidated",
    key: "lastValidated",
    filters: [
      { text: "Pass", value: true },
      { text: "Fail", value: false },
    ],
    onFilter: (value, record) => {
      if (record.isGroupRow && record.children) {
        return record.children.some((child) => child.lastValidated.success === value)
      }
      return record.lastValidated.success === value
    },
    filterMultiple: false,
    width: "250px",
    render: (_, { lastValidated, isGroupRow, children }) => {
      if (isGroupRow && children && children.length > 0) {
        const validatedCount = children.filter((child) => child.lastValidated.runTime !== null).length
        if (validatedCount === 0) {
          return null
        }
        const failedCount = children.filter(
          (child) => child.lastValidated.success === false && child.lastValidated.runTime !== null,
        ).length
        const totalCount = children.length
        if (failedCount === 0) {
          return <Tag color="green">All passed</Tag>
        }
        if (failedCount > 0) {
          return (
            <Tag color="error">
              {failedCount}/{totalCount} Failed
            </Tag>
          )
        }
      }
      const { success, runTime } = lastValidated
      if (runTime === null && success === null) {
        return null
      }
      const icon = success ? "success" : "failure"
      return (
        <Flex gap="small">
          <Image aria-label={icon} type={icon} /> {runTime}
        </Flex>
      )
    },
  },
  {
    title: "Actions",
    dataIndex: "actions",
    key: "actions",
    width: "100px",
    render: (_, { actions, isGroupRow, children }) => {
      if (isGroupRow && children) {
        const expectationIds = children.map((child) => child.actions.expectationId).filter(Boolean) as string[]
        if (expectationIds.length > 0) {
          return <DeleteExpectationModal expectationIds={expectationIds} assetId={assetId} isBulkDelete={true} />
        }
        return null
      }

      const { expectationId, expectationFragment } = actions
      return (
        <>
          <Button
            type="text"
            aria-label="Edit Expectation"
            icon="edit"
            disabled={!expectationFragment}
            onClick={() => (expectationFragment ? onEdit(expectationFragment) : undefined)}
          />
          <DeleteExpectationModal expectationIds={expectationId ? [expectationId] : null} assetId={assetId} />
        </>
      )
    },
  },
]

const sortFn = (a: string, b: string) => {
  return a.localeCompare(b, undefined, { numeric: true })
}

const noDomainPlaceholder = {
  domainName: assetDomainName,
  sortValue: firstLexicalCharacter,
  truncateInfo: { truncate: false, fullName: assetDomainName },
}

const getExpectationRowData = (
  data: ExpectationsTable_ExpectationFragment[],
  domainSortOrder: SortOrder,
): DataType[] => {
  if (!data) {
    return []
  }

  return data.map((exp) => {
    const { domainName, sortValue, truncateInfo } = getDomainData(exp, domainSortOrder)
    const metaNotes = exp?.renderedContent ? getMetaNotes(exp.renderedContent) : null
    const dataQualityIssue = getDataQualityIssue(exp)

    const content = (
      <Flex vertical gap="middle">
        {getRenderer({
          renderedValue: exp?.renderedContent?.[0],
          fallback: exp?.expectationType ?? undefined,
          kwargs: exp?.kwargs,
          description: exp?.description || getDescriptionFromKwargs(exp?.kwargs),
        }) ?? null}
        {metaNotes ? <MetaNotes metaNotes={metaNotes} /> : null}
      </Flex>
    )

    return {
      expectation: {
        content,
        id: exp?.geCloudId ?? "",
        autogenerated: exp?.autogenerated ?? false,
        sortValue,
        domainName,
        truncateInfo,
        dataQualityIssue,
      },
      lastValidated: {
        success: exp?.validationResults?.[0]?.success ?? null,
        runTime: exp?.validationResults?.[0]?.runTime ? fmtApprox(exp?.validationResults?.[0]?.runTime) : null,
      },
      actions: { expectationId: exp?.geCloudId ?? null, expectationFragment: exp as EditExpectationFragment },
    }
  })
}

const getMultiColSortValue = (domainSortOrder: SortOrder, columns?: Domain["domainColumns"]) => {
  if (!columns || !domainSortOrder) {
    return firstLexicalCharacter
  }
  if (columns.length === 1) {
    return columns[0]?.name ?? firstLexicalCharacter
  }
  const ascSortedColumns = [...columns].sort((a, b) => {
    const aOrder = a?.order ?? Number.MAX_SAFE_INTEGER
    const bOrder = b?.order ?? Number.MAX_SAFE_INTEGER
    if (aOrder < bOrder) {
      return -1
    }
    if (aOrder > bOrder) {
      return 1
    }
    return 0
  })
  const lastElement = ascSortedColumns[ascSortedColumns.length - 1]
  return domainSortOrder === "ascend"
    ? (ascSortedColumns[0]?.name ?? firstLexicalCharacter)
    : (lastElement?.name ?? firstLexicalCharacter)
}

const getDomainDataFromKwargs = (kwargs: string | undefined | null): DomainData => {
  if (!kwargs) {
    return noDomainPlaceholder
  }

  const parsedKwargs = parseJSONStringConfig(kwargs) as {
    column?: string
    column_list?: string[]
    description?: string
  }
  const parsedDomainName = parsedKwargs?.column ?? parsedKwargs?.column_list?.[0]

  if (parsedDomainName) {
    return {
      ...noDomainPlaceholder,
      domainName: parsedDomainName,
      sortValue: parsedDomainName,
    }
  }

  const parsedDescription = parsedKwargs?.description
  if (parsedDescription) {
    return {
      ...noDomainPlaceholder,
      sortValue: parsedDescription,
    }
  }

  return noDomainPlaceholder
}

const getDomainData = (expectation: ExpectationsTable_ExpectationFragment, domainSortOrder: SortOrder): DomainData => {
  const { domain, expectationType, kwargs } = expectation

  if (!domain) {
    return getDomainDataFromKwargs(kwargs)
  }

  const MAXCOLNR = 4
  const { column: columnName = "" } = getDomainKwargs({ __typename: "ExpectationConfiguration", domain })

  switch (domain.domainType) {
    case "COLUMN": {
      return {
        domainName: columnName,
        sortValue: columnName,
        truncateInfo: { truncate: false, fullName: columnName },
      }
    }
    case "COLUMN_PAIR":
    case "MULTICOLUMN": {
      const shouldTruncate = (domain.domainColumns?.length ?? 0) > MAXCOLNR
      const domainColumnsCopy = domain.domainColumns ? [...domain.domainColumns] : []
      const showCols = shouldTruncate ? domainColumnsCopy.splice(0, MAXCOLNR) : domain.domainColumns
      const joinColNames = (cols: Domain["domainColumns"] | undefined) =>
        cols ? cols.map((col) => col?.name).join(", ") : ""
      return {
        domainName: joinColNames(showCols),
        sortValue: getMultiColSortValue(domainSortOrder, domain.domainColumns),
        truncateInfo: {
          truncate: shouldTruncate,
          restColumns: joinColNames(domainColumnsCopy),
          fullName: joinColNames(domain.domainColumns),
        },
      }
    }
    case "TABLE": {
      const domainData = getDomainDataFromKwargs(kwargs)
      const domainName =
        expectationType === customSQLExpectationType ? customSQLDomainName : (domainData.domainName ?? assetDomainName)
      return {
        ...domainData,
        domainName,
        truncateInfo: { truncate: false, fullName: domainName },
      }
    }
    default: {
      Sentry.captureMessage(
        `ExpectationsTable getDomainData: handling unexpected domainType ${domain.domainType} on domain ${domain}`,
      )
      return getDomainDataFromKwargs(kwargs)
    }
  }
}

const getDomainFilter = (data: ExpectationsTable_ExpectationFragment[]) => {
  if (!data?.length) {
    return []
  }
  const domainCol = data.map((exp) => getDomainData(exp, "ascend"))
  const sortedFilterList = domainCol.sort((a, b) => sortFn(a.sortValue, b.sortValue))
  const uniqSortedFilterList = uniqBy(sortedFilterList, "truncateInfo.fullName")

  return uniqSortedFilterList.map(({ domainName, truncateInfo }) => ({
    text: <DomainFilterItem domainName={domainName} truncateInfo={truncateInfo} />,
    value: domainName,
  }))
}

function DomainFilterItem({ domainName, truncateInfo }: Omit<DomainData, "sortValue">) {
  return truncateInfo.truncate ? (
    <Tooltip placement="topRight" title={`...${truncateInfo.restColumns}`}>
      <span>{`${domainName}...`}</span>
    </Tooltip>
  ) : (
    <span>{domainName}</span>
  )
}

function getDescriptionFromKwargs(kwargs: string | undefined | null): string {
  if (!kwargs) {
    return ""
  }
  return (parseJSONStringConfig(kwargs) as { description?: string })?.description ?? ""
}

function fmtApprox(iso: string) {
  return capitalize(intlFormatDistance(new Date(iso), new Date()))
}

export function getMetaNotes(renderedContent: (RenderedExpectationFragment | null)[]) {
  const metanotes = renderedContent[0]?.value?.metaNotes
  if (!metanotes) {
    return null
  }
  try {
    return parseMetaNotes(metanotes)
  } catch {
    console.error("Parsing meta notes from expectations table failed.")
    return null
  }
}

const getDataQualityIssue = (
  expectation: FragmentType<typeof GetExpectationMetaInfo_ExpectationFragmentDocument>,
): string | undefined => {
  const metaInfo = getExpectationMetaInfo({ expectation })
  return metaInfo?.dataQualityIssues?.[0]
}

const getGroupedExpectationData = (
  data: ExpectationsTable_ExpectationFragment[],
  domainSortOrder: SortOrder,
): DataType[] => {
  if (!data?.length) {
    return []
  }

  const rowData = getExpectationRowData(data, domainSortOrder)

  const groupedData: Record<string, DataType[]> = {}

  rowData.forEach((row) => {
    const groupKey = row.expectation.dataQualityIssue || "Other"
    if (!groupedData[groupKey]) {
      groupedData[groupKey] = []
    }
    groupedData[groupKey].push(row)
  })

  Object.values(groupedData).forEach((group) => {
    group.sort((a, b) => {
      if (domainSortOrder === "ascend") {
        return sortFn(a.expectation.sortValue, b.expectation.sortValue)
      }
      if (domainSortOrder === "descend") {
        return sortFn(b.expectation.sortValue, a.expectation.sortValue)
      }
      return 0
    })
  })

  const result = Object.entries(groupedData).map(([groupName, groupItems]) => ({
    expectation: {
      content: (
        <strong>
          {groupName} ({groupItems.length})
        </strong>
      ),
      id: `group-${groupName}`,
      autogenerated: false,
      sortValue: groupName,
      domainName: groupName,
      truncateInfo: { truncate: false, fullName: groupName },
      dataQualityIssue: groupName,
    },
    lastValidated: {
      success: null,
      runTime: null,
    },
    actions: {
      expectationId: null,
      expectationFragment: null as unknown as EditExpectationFragment,
    },
    children: groupItems,
    isGroupRow: true,
  }))

  if (domainSortOrder) {
    result.sort((a, b) => {
      const aValue = a.children && a.children.length > 0 ? a.children[0].expectation.sortValue : a.expectation.sortValue

      const bValue = b.children && b.children.length > 0 ? b.children[0].expectation.sortValue : b.expectation.sortValue

      if (domainSortOrder === "ascend") {
        return sortFn(aValue, bValue)
      }
      return sortFn(bValue, aValue)
    })
  }

  return result
}
