import type { UISchema } from "@great-expectations/jsonforms-antd-renderers"
import { Typography } from "antd"
const { Text } = Typography
import { RuleEffect } from "@jsonforms/core"
import { CreateGXUserExtra } from "src/pages/DataAssets/components/CreateGXUserExtra.tsx"
import { DatabricksSQLDataSourceJsonSchema } from "src/pages/DataAssets/schemas/json-schemas/databricks-sql-data-source-json-schema.ts"
import { getSchemaAndCatalogAsDatabaseFromDatabricksSQLConnectionString } from "src/pages/DataAssets/drawers/ConnectToDataSource/common/SelectTableNames/get-address-from-data-source.ts"
import { Icon } from "src/global/ui/Icon/Icon.tsx"
import { theme } from "src/global/ui/themes/theme.ts"

export const DatabricksSQLDataSourcePage1UISchema: UISchema<typeof DatabricksSQLDataSourceJsonSchema> = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Control",
      scope: "#/properties/type",
      rule: {
        effect: RuleEffect.HIDE,
        condition: {},
      },
    },
    {
      type: "Control",
      scope: "#/properties/name",
      label: "Data Source name",
      options: {
        placeholderText: "Data Source name",
      },
    },
    {
      type: "Group",
      elements: [
        {
          type: "Control",
          scope: "#/properties/connection_string",
          label: "Connection string",
          options: {
            type: "password",
            required: true,
            placeholderText: `databricks://token:<token>@<host>:<port>?http_path=<http_path>&catalog=<catalog>&schema=<schema>`,
          },
          formItemProps: {
            extra: <CreateGXUserExtra dataSourceType="DATABRICKS_SQL" />,
            rules: [
              {
                validator(rule, value: unknown) {
                  if (typeof value !== "string") {
                    return Promise.reject(new Error("Please enter a valid connection string"))
                  }
                  const { database, schema } =
                    getSchemaAndCatalogAsDatabaseFromDatabricksSQLConnectionString(value) ?? {}

                  if (!database) {
                    return Promise.reject(new Error("DatabricksSQL connection string must specify a catalog"))
                  }
                  if (!schema) {
                    return Promise.reject(new Error("DatabricksSQL connection string must specify a schema"))
                  }
                  return Promise.resolve()
                },
              },
            ],
            tooltip: {
              title: (
                <span>
                  Must use{" "}
                  <Text code style={{ color: theme.colors.neutralColorPalette.whites.white }}>
                    databricks://
                  </Text>{" "}
                  data-connector. See{" "}
                  <a
                    href="https://docs.greatexpectations.io/docs/core/connect_to_data/sql_data/#procedure"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: `${theme.colors.neutralColorPalette.whites.white}`, textDecoration: "underline" }}
                  >
                    connect to SQL data
                  </a>{" "}
                  for more details.
                </span>
              ),
            },
          },
        },
      ],
    },
  ],
}

export const DatabricksSQLDataSourcePage2UISchema: UISchema<typeof DatabricksSQLDataSourceJsonSchema> = {
  type: "VerticalLayout",
  elements: [
    {
      label: "Data Asset",
      type: "Control",
      scope: "#/properties/assets",
      options: {
        removeButtonProps: {
          type: "text",
          icon: <Icon name="trash" size="16px" style={{ marginTop: "3px" }} />,
          children: "",
        },
      },
    },
  ],
}
