import styled from "styled-components"
import { Table } from "antd"
import { Theme } from "src/global/ui/themes/theme.ts"
import { Icon } from "src/global/ui/Icon"

const StyledTable = styled(Table)`
  .ant-table-tbody > tr {
    display: table-row;
  }

  .ant-pagination {
    padding: 0 16px;
  }
`

const StyledColumnHeader = styled.div`
  font-weight: ${({ theme }) => theme.typography.fontWeight.semibold};
  line-height: ${({ theme }) => theme.typography.lineHeight.large};
`

const StyledUserActionsWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  gap: 10px;
`

const FlexContainer = styled.div<{
  $alignsItemsCenter?: boolean
  $gap?: keyof Theme["spacing"]
}>`
  display: flex;
  ${({ $alignsItemsCenter }) => $alignsItemsCenter && `align-items: center;`}
  ${({ $gap, theme }) => $gap && `gap: ${theme.spacing[$gap]}`}
`

const ButtonWrapper = styled(FlexContainer)`
  justify-content: flex-end;
`
const BodyWrapper = styled(FlexContainer)`
  padding-left: ${({ theme }) => theme.spacing.l};
`

const WarningIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.warning.colorWarning};
`

export {
  StyledTable,
  StyledColumnHeader,
  StyledUserActionsWrapper,
  FlexContainer,
  WarningIcon,
  ButtonWrapper,
  BodyWrapper,
}
