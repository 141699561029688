/* eslint-disable react-refresh/only-export-components */ // FIXME
import { Drawer } from "src/global/ui/Drawer/Drawer.tsx"
import { DataSourceCrudDrawerStyles } from "src/pages/DataAssets/drawers/NewAssetDrawer/NewAssetDrawer.tsx"
import type { ConnectToDataSourceConfig } from "src/pages/DataAssets/drawers/ConnectToDataSource/types"

import { useMemo } from "react"
import { useApolloClient } from "@apollo/client"
// eslint-disable-next-line no-restricted-imports -- This Query is legacy
import { DatasourcesWithRunsDocument } from "src/api/graphql/graphql-operations.ts"
import { SelectAssetsStep } from "src/pages/DataAssets/drawers/ConnectToDataSource/steps/SelectAssetsStep"
import { MonitorIssuesStep } from "src/pages/DataAssets/drawers/ConnectToDataSource/steps/MonitorIssuesStep"
import { ConnectToDataSourceContainer } from "src/pages/DataAssets/drawers/ConnectToDataSource/ConnectToDataSourceContainer"

interface NewDemoAssetDrawerProps {
  isOpen: boolean
  setIsOpen: (open: boolean) => void
}

const title = "New Data Asset"

export function NewDemoAssetDrawer({ isOpen, setIsOpen }: NewDemoAssetDrawerProps) {
  const client = useApolloClient()
  const demoDataSourceConfig: ConnectToDataSourceConfig = useMemo(
    () => ({
      dataSourceType: "SNOWFLAKE",
      progressBar: true,
      isDemo: true,
      steps: [
        {
          title: "Connect to GX Demo Data Source",
          bodyComponent: SelectAssetsStep,
          footerButtons: {
            prev: {
              customAction: () => {
                setIsOpen(false)
              },
            },
            next: "Next",
          },
        },
        {
          title: "Monitor issues",
          bodyComponent: MonitorIssuesStep,
          footerButtons: {
            prev: "Back",
            next: {
              customAction: () => {
                client.cache.evict({ fieldName: "datasourceV2" })
                client.cache.gc()
                client.refetchQueries({
                  include: [DatasourcesWithRunsDocument], // Refetch DataAssets with runs
                })
                setIsOpen(false)
              },
            },
          },
        },
      ],
    }),
    [client, setIsOpen],
  )
  return (
    <Drawer styles={DataSourceCrudDrawerStyles} open={isOpen} onClose={() => setIsOpen(false)} title={title}>
      <ConnectToDataSourceContainer config={demoDataSourceConfig} />
    </Drawer>
  )
}
