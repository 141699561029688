import styled from "styled-components"
import { InlinePillContainer } from "src/pages/DataAssets/views/Expectations/Expectation/InlinePillContainer.ts"
import { Tooltip } from "antd"
import { parseStringWithColor } from "src/pages/DataAssets/views/Expectations/Expectation/utils/parseStringWithColor.ts"
import { useAnalytics } from "src/global/hooks/analytics/useAnalytics.ts"
import { useEffect } from "react"
import { DescriptionStrong } from "src/global/ui/typography/Text/Text.tsx"

const FunctionArgText = styled(DescriptionStrong)`
  display: inline-flex;
`

const EvaluationParameters = ({ param, tooltip }: { param: string; tooltip?: string }) => {
  const posthog = useAnalytics()
  const parsedTextObject = parseStringWithColor(param)
  const styledTextMap = parsedTextObject.map((obj, idx) => {
    if (obj.styleType === "param") {
      return (
        <FunctionArgText key={idx} $color="colorTextSecondary">
          {obj.value}
        </FunctionArgText>
      )
    }
    return obj.value
  })

  useEffect(() => {
    posthog?.capture("Expectation with Evaluation Param")
  }, [posthog])

  const onHover = () => {
    posthog?.capture("Hover over Evaluation Param value in Validation Result")
  }
  return (
    <Tooltip
      onOpenChange={onHover}
      autoAdjustOverflow
      title={
        tooltip && (
          <FunctionArgText as="span">
            <DescriptionStrong $color="gxAccentMedium">&quot;$PARAMETER&quot;:</DescriptionStrong>
            <DescriptionStrong $color="white">&quot;{tooltip}&quot;</DescriptionStrong>
          </FunctionArgText>
        )
      }
    >
      <InlinePillContainer>
        <DescriptionStrong as="span" $color="gxAccentMedium">
          {styledTextMap}
        </DescriptionStrong>
      </InlinePillContainer>
    </Tooltip>
  )
}

export default EvaluationParameters
