import { Divider, Empty, Flex } from "antd"
import { useQuery } from "@apollo/client"
import { Button } from "src/global/ui/Button/Button.tsx"
import {
  DataAssetBreadcrumbs_DataSourceFragmentDocument,
  getDataAssetBreadcrumbs,
} from "src/pages/DataAssets/views/AssetDetails/DataAssetBreadcrumbs.tsx"
import { DataAssetTabs } from "src/pages/DataAssets/views/AssetDetails/DataAssetTabs.tsx"
import { FragmentType } from "src/api/graphql/fragment-masking.ts"
import { SimpleScheduleSummary } from "src/pages/DataAssets/views/Expectations/SimpleScheduleSummary.tsx"
import { BatchDefinitionWithEditing } from "src/pages/DataAssets/views/AssetDetails/Splitters/BatchDefinitionWithEditing.tsx"
import { RunValidationButton } from "src/pages/DataAssets/views/Expectations/RunValidationButton/RunValidationButton.tsx"
import { theme } from "src/global/ui/themes/theme.ts"
import { SimpleNewExpectationDrawer } from "src/pages/DataAssets/views/Expectations/SimpleExpectationDrawer/SimpleNewExpectationDrawer.tsx"
import { AssetFlowIds } from "src/global/components/alerts/AlertsDrawer.tsx"
import { AlertNotificationsButton } from "src/global/components/alerts/AlertNotificationsButton.tsx"
import { ExpectationsTabDataAssetDocument } from "src/pages/DataAssets/views/Expectations/SimpleExpectationsTab.tsx"
import { GenerateExpectationsButton } from "src/pages/DataAssets/views/Expectations/GenerateExpectationsButton/GenerateExpectationsButton.tsx"
import { BodyStrong } from "src/global/ui/typography/Text/Text.tsx"
import { dataAssetsNavigateToVar } from "src/pages/DataAssets/components/DataAssetsTable"

type ActionsProps = {
  isEditor: boolean
  hasAlertBtn?: boolean
  assetId: string | undefined
  isNewExpDrawerOpen: boolean
  setIsNewExpDrawerOpen: (open: boolean) => void
}

type HeaderContentProps = {
  dataAssetName: string | JSX.Element
  subtitle: string
  datasources: FragmentType<typeof DataAssetBreadcrumbs_DataSourceFragmentDocument> | null | undefined
}

export const headerContent = ({
  dataAssetName,
  subtitle,
  isEditor,
  assetId,
  isNewExpDrawerOpen,
  setIsNewExpDrawerOpen,
  datasources,
}: ActionsProps & HeaderContentProps) => ({
  title: dataAssetName,
  subtitle: subtitle,
  rootPath: "data-assets",
  navigateBackTo: dataAssetsNavigateToVar(),
  rightActions: {
    reactNode: (
      <HeaderActions
        isEditor={isEditor}
        hasAlertBtn={true}
        assetId={assetId}
        isNewExpDrawerOpen={isNewExpDrawerOpen}
        setIsNewExpDrawerOpen={setIsNewExpDrawerOpen}
      />
    ),
  },
  customBreadcrumbs: getDataAssetBreadcrumbs({
    dataSource: datasources,
    tooltipPlacement: "right",
  }),
  footer: <DataAssetTabs currentTab="expectations" />,
})

export function ExpectationsTableHeader({
  dataAssetId,
  hasExpectations,
  hasGXManagedExpectations,
}: {
  dataAssetId: string | undefined
  hasExpectations: boolean
  hasGXManagedExpectations: boolean
}) {
  const { data } = useQuery(ExpectationsTabDataAssetDocument, {
    variables: { id: dataAssetId as string },
    skip: !dataAssetId,
  })

  return (
    <>
      <Flex justify="space-between" align="center">
        {data?.dataAsset?.splitter && (
          <BatchDefinitionWithEditing dataAssetId={data.dataAsset.id} splitterDataFragment={data.dataAsset.splitter} />
        )}
        {dataAssetId && (
          <RunValidationButton assetID={dataAssetId} hasGXManagedExpectations={hasGXManagedExpectations} />
        )}
      </Flex>
      {hasExpectations && (
        <>
          <Divider style={{ margin: `${theme.spacing.xs} 0` }} />
          <SimpleScheduleSummary schedule={data?.dataAsset?.schedule} />
        </>
      )}
    </>
  )
}

export function HeaderActions({
  isEditor,
  hasAlertBtn,
  assetId,
  isNewExpDrawerOpen,
  setIsNewExpDrawerOpen,
}: ActionsProps) {
  return (
    <>
      {isEditor && (
        <Flex justify="space-between" gap={theme.spacing.xxs}>
          {hasAlertBtn && <AlertNotificationsButton assetFlowIds={{ assetId } as AssetFlowIds} />}
          {assetId && <GenerateExpectationsButton assetID={assetId} />}
          <Button icon="plus" type="primary" onClick={() => setIsNewExpDrawerOpen(true)}>
            New Expectation
          </Button>
          {assetId && (
            <SimpleNewExpectationDrawer
              dataAssetId={assetId}
              open={isNewExpDrawerOpen}
              close={() => setIsNewExpDrawerOpen(false)}
            />
          )}
        </Flex>
      )}
    </>
  )
}

export function EmptyFilteredState({ isEditor, hasExpectations }: ActionsProps & { hasExpectations: boolean }) {
  if (!isEditor && !hasExpectations) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
  }

  const displayText = hasExpectations
    ? "No Expectations match filtering criteria"
    : "Add your first Expectation to start validating your Asset"

  return (
    <Flex align="center" vertical style={{ padding: `${theme.spacing.l} 0` }}>
      <BodyStrong>{displayText}</BodyStrong>
    </Flex>
  )
}
