/* eslint-disable react-refresh/only-export-components */ // FIXME
import { useCallback, useEffect, useMemo, useState } from "react"
import { PickNewOrExistingDataSource } from "src/pages/DataAssets/components/PickNewOrExistingDataSource.tsx"
import { Drawer } from "src/global/ui/Drawer/Drawer.tsx"
// eslint-disable-next-line no-restricted-imports -- deprecated file
import { DatasourceWithRunsFragment } from "src/api/graphql/graphql-operations.ts"
import { DrawerStyles } from "antd/es/drawer/DrawerPanel"
import { SupportedDataSource } from "src/pages/DataAssets/schemas/data-source-schemas.ts"
import { useApolloClient } from "@apollo/client"
import { ConnectToDataSourceConfig } from "src/pages/DataAssets/drawers/ConnectToDataSource/types"
import { EnterCredentialsStep } from "src/pages/DataAssets/drawers/ConnectToDataSource/steps/EnterCredentialsStep"
import { EmptyStep } from "src/pages/DataAssets/drawers/ConnectToDataSource/steps/EmptyStep"
import { SelectAssetsStep } from "src/pages/DataAssets/drawers/ConnectToDataSource/steps/SelectAssetsStep"
import { MonitorIssuesStep } from "src/pages/DataAssets/drawers/ConnectToDataSource/steps/MonitorIssuesStep"
import { ConnectToDataSourceContainer } from "src/pages/DataAssets/drawers/ConnectToDataSource/ConnectToDataSourceContainer"

export type DataSourceChoice =
  | {
      createNew: true
      dataSourceType?: SupportedDataSource
    }
  | {
      createNew: false
      dataSourceType: SupportedDataSource
      dataSource: DatasourceWithRunsFragment
    }

type Props = {
  isOpen: boolean
  setIsOpen: (open: boolean) => void
  dataSourceChoiceFromEmptyState?: DataSourceChoice
}

export const DataSourceCrudDrawerStyles: DrawerStyles = {
  body: { padding: "0px", display: "flex", flexDirection: "column", height: "100%", width: "100%" },
}

export function NewAssetDrawer({ isOpen, setIsOpen, dataSourceChoiceFromEmptyState }: Props) {
  const [dataSourceChoice, setDataSourceChoice] = useState<DataSourceChoice | undefined>(
    () => dataSourceChoiceFromEmptyState,
  )
  const [createNew, setCreateNew] = useState<boolean>(false)
  const [dataSourceType, setDataSourceType] = useState<SupportedDataSource | undefined>()
  const [dataSource, setDataSource] = useState<DatasourceWithRunsFragment | undefined>()
  const client = useApolloClient()

  useEffect(() => {
    if (dataSourceChoiceFromEmptyState?.createNew !== undefined) {
      handleDataSourceChoice(dataSourceChoiceFromEmptyState)
    }
  }, [createNew, dataSourceChoiceFromEmptyState])

  const handleDataSourceChoice = (choice: DataSourceChoice) => {
    setDataSourceChoice(choice)
    setCreateNew(choice.createNew)
    setDataSourceType(choice.dataSourceType)
    setDataSource(choice.createNew === false ? choice.dataSource : undefined)
  }

  const resetAllState = useCallback(() => {
    setDataSourceChoice(undefined)
    setCreateNew(false)
    setDataSourceType(undefined)
    setDataSource(undefined)
  }, [])

  useEffect(() => {
    if (dataSourceChoiceFromEmptyState) {
      setDataSourceChoice(dataSourceChoiceFromEmptyState)
    }
  }, [dataSourceChoiceFromEmptyState])

  const selectedDataSource = dataSourceChoice?.dataSourceType

  const dataSourceConfig: ConnectToDataSourceConfig = useMemo(
    () => ({
      dataSourceType,
      dataSource,
      progressBar: true,
      steps: [
        {
          title: "Connect to Data Source",
          bodyComponent: createNew ? EnterCredentialsStep : EmptyStep,
          footerButtons: {
            prev: {
              label: "Back",
              customAction: () => {
                resetAllState()
                if (dataSourceChoiceFromEmptyState) {
                  setIsOpen(false)
                }
              },
            },
            next: {
              label: "Connect",
            },
          },
        },
        {
          title: "Add Data Assets",
          bodyComponent: SelectAssetsStep,
          footerButtons: {
            prev: {
              label: "Back",
              customAction: (nextStep: number) => {
                if (nextStep < 0) {
                  resetAllState()
                  if (createNew) {
                    setDataSourceChoice({ createNew: true })
                    setCreateNew(true)
                  }
                }
              },
            },
          },
        },
        {
          title: "Monitor issues",
          bodyComponent: MonitorIssuesStep,
          footerButtons: {
            next: {
              customAction: () => {
                client.cache.evict({ fieldName: "datasourcesV2" })
                client.cache.gc()
                resetAllState()
                setIsOpen(false)
              },
            },
          },
        },
      ],
    }),
    [client.cache, createNew, dataSource, dataSourceChoiceFromEmptyState, dataSourceType, resetAllState, setIsOpen],
  )

  return isOpen ? (
    <Drawer
      styles={DataSourceCrudDrawerStyles}
      open
      onClose={() => {
        resetAllState()
        setIsOpen(false)
      }}
      title="New Data Asset"
    >
      <>
        {!selectedDataSource && <PickNewOrExistingDataSource onChoice={handleDataSourceChoice} />}
        {selectedDataSource && <ConnectToDataSourceContainer config={dataSourceConfig} />}
      </>
    </Drawer>
  ) : null
}
