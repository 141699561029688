import { useState } from "react"
import { AgentSetupComplete, AgentSetupCompleteReason } from "src/pages/AgentSetup/components/AgentSetupComplete.tsx"
import { AgentSetupFeedback } from "src/pages/AgentSetup/components/AgentSetupFeedback.tsx"
import { AgentSetupFeedbackSubmitted } from "src/pages/AgentSetup/components/AgentSetupFeedbackSubmitted.tsx"
import { AgentSetupInstructions } from "src/pages/AgentSetup/components/AgentSetupInstructions.tsx"
import { PollForDataSourceIds } from "src/pages/AgentSetup/components/PollForDataSourceIds.tsx"
import { useAgentStatus } from "src/global/hooks/useAgentStatus"
import { useHasDataSources } from "src/global/hooks/useHasDataSources"

type AgentSetupContent = "instructions" | "feedback" | "feedbackSubmitted"

interface AgentSetupProps {
  initialContent?: AgentSetupContent
}

export function AgentSetup({ initialContent }: AgentSetupProps) {
  const isAgentActive = useAgentStatus()
  const { hasDataSources } = useHasDataSources()
  const [content, setContent] = useState(initialContent || ("instructions" as AgentSetupContent))

  const visibleContent = () => {
    if (isAgentActive) {
      return <AgentSetupComplete reason={"started_agent" as AgentSetupCompleteReason} />
    }

    if (hasDataSources) {
      return <AgentSetupComplete reason={"created_data_source" as AgentSetupCompleteReason} />
    }

    switch (content) {
      case "instructions":
        return <AgentSetupInstructions onHelpClick={() => setContent("feedback" as AgentSetupContent)} />
      case "feedback":
        return (
          <AgentSetupFeedback
            onSubmit={() => setContent("feedbackSubmitted" as AgentSetupContent)}
            onContinue={() => setContent("instructions" as AgentSetupContent)}
          />
        )
      case "feedbackSubmitted":
        return <AgentSetupFeedbackSubmitted onContinue={() => setContent("instructions" as AgentSetupContent)} />
    }
  }
  return (
    <>
      {visibleContent()}
      <PollForDataSourceIds />
    </>
  )
}
