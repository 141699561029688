import styled from "styled-components"
import { BodyNormal, BodyStrong } from "src/global/ui/typography/Text/Text.tsx"

const StyledDescription = styled.div`
  margin-bottom: 10px;
`

export const RoleTooltip = (
  <BodyNormal $color="white">
    <StyledDescription>
      <BodyStrong $color="white">Viewers</BodyStrong> - can view Validation Results.
    </StyledDescription>
    <StyledDescription>
      <BodyStrong $color="white">Editors</BodyStrong> - can create and update Expectations, create access tokens.
    </StyledDescription>
    <StyledDescription>
      <BodyStrong $color="white">Admins</BodyStrong> - have full access, can manage users and org-wide Settings.
    </StyledDescription>
  </BodyNormal>
)
