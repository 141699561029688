
      export type PossibleTypesResultData = {
  "possibleTypes": {
    "MetricValueUnion": [
      "MetricValueFloatType",
      "MetricValueStringType"
    ],
    "Node": [
      "OrganizationApiToken",
      "User",
      "UserApiToken"
    ],
    "RenderedValueUnion": [
      "GraphType",
      "StringValueType",
      "TableType"
    ],
    "SplitterUnion": [
      "ColumnPartitionerDaily",
      "ColumnPartitionerMonthly",
      "ColumnPartitionerYearly",
      "SplitterColumnValue",
      "SplitterConvertedDateTime",
      "SplitterDatetimePart",
      "SplitterDividedInteger",
      "SplitterHashedColumn",
      "SplitterModInteger",
      "SplitterMultiColumnValue",
      "SplitterWholeAsset",
      "SplitterYear",
      "SplitterYearAndMonth",
      "SplitterYearAndMonthAndDay"
    ],
    "WithID": [
      "ColumnPartitionerDaily",
      "ColumnPartitionerMonthly",
      "ColumnPartitionerYearly",
      "SplitterColumnValue",
      "SplitterConvertedDateTime",
      "SplitterDatetimePart",
      "SplitterDividedInteger",
      "SplitterHashedColumn",
      "SplitterModInteger",
      "SplitterMultiColumnValue",
      "SplitterWholeAsset",
      "SplitterYear",
      "SplitterYearAndMonth",
      "SplitterYearAndMonthAndDay"
    ]
  }
};
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "MetricValueUnion": [
      "MetricValueFloatType",
      "MetricValueStringType"
    ],
    "Node": [
      "OrganizationApiToken",
      "User",
      "UserApiToken"
    ],
    "RenderedValueUnion": [
      "GraphType",
      "StringValueType",
      "TableType"
    ],
    "SplitterUnion": [
      "ColumnPartitionerDaily",
      "ColumnPartitionerMonthly",
      "ColumnPartitionerYearly",
      "SplitterColumnValue",
      "SplitterConvertedDateTime",
      "SplitterDatetimePart",
      "SplitterDividedInteger",
      "SplitterHashedColumn",
      "SplitterModInteger",
      "SplitterMultiColumnValue",
      "SplitterWholeAsset",
      "SplitterYear",
      "SplitterYearAndMonth",
      "SplitterYearAndMonthAndDay"
    ],
    "WithID": [
      "ColumnPartitionerDaily",
      "ColumnPartitionerMonthly",
      "ColumnPartitionerYearly",
      "SplitterColumnValue",
      "SplitterConvertedDateTime",
      "SplitterDatetimePart",
      "SplitterDividedInteger",
      "SplitterHashedColumn",
      "SplitterModInteger",
      "SplitterMultiColumnValue",
      "SplitterWholeAsset",
      "SplitterYear",
      "SplitterYearAndMonth",
      "SplitterYearAndMonthAndDay"
    ]
  }
};
      export default result;
    