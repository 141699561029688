import * as Sentry from "@sentry/react"
import { NODE_ENV, REACT_APP_ENV as environment, VITE_GIT_SHA } from "src/global/env.ts"
import { shouldSuppressEvent } from "src/global/observability/sentry/utils.ts"

const enabled = Boolean(environment && NODE_ENV === "production")

const replay = Sentry.replayIntegration({
  maskAllText: false,
  _experiments: {
    traceInternals: true,
  },
})

const tracing = Sentry.browserTracingIntegration({
  beforeStartSpan: (context) => {
    // replace org slugs with a wildcard so we aggregate spans across orgs
    let name = location.pathname.replace(/\/organizations\/[^/]*/, "/organizations/*")
    // replace UUIDs with a wildcard because by changing the name above, we lose Sentry's default behavior
    name = name.replace(/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/g, "*")
    return {
      ...context,
      name: name,
    }
  },
})

const beforeSend = (event: Sentry.ErrorEvent) => {
  if (shouldSuppressEvent(event)) {
    return null
  }
  return event
}

export const initializeSentry = () => {
  Sentry.init({
    dsn: "https://ce466bb01eb641f5b838615ed4682e28@sentry.greatexpectations.io/6767185",
    environment,
    release: VITE_GIT_SHA,
    beforeSend,
    integrations: [replay, tracing],
    enabled,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.5,
    // The sample rate for replays that begin recording immediately and last the
    // entirety of the user's session. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0, // Set to 0 because we manually start replays (excluding the synthetic tester) in ApolloAuthenticatedProvider
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs. This type of replay will record up to a minute
    // of events prior to the error and continue recording until the session ends.
    replaysOnErrorSampleRate: 0, // Set to 0 since we currently are capturing all non-synthetic tester replays

    beforeBreadcrumb(breadcrumb, hint) {
      if (breadcrumb.category === "ui.click" && hint) {
        const { target } = hint.event
        if (target.ariaLabel) {
          breadcrumb.message = target.ariaLabel
        }
      }
      return breadcrumb
    },
  })
}

export { Sentry }
