import { Flex, Select, Form, FormInstance } from "antd"
import { useCallback } from "react"

import { useTheme } from "styled-components"

import { ColumnNameSelector } from "src/pages/DataAssets/views/Expectations/Expectation/uiForms/customRenderers/ColumnNameSelector.tsx"
// eslint-disable-next-line no-restricted-imports -- deprecated file
import { DataAssetWithLatestMetricRunFragment } from "src/api/graphql/graphql-operations.ts"
import { Button } from "src/global/ui/Button/Button.tsx"
import { RowCondition } from "src/pages/DataAssets/views/Expectations/Expectation/CreateExpectationDrawer/types.ts"
import { ColumnType } from "src/pages/DataAssets/views/Expectations/Expectation/uiForms/customRenderers/RowConditionControl/useColumnTypeOperators.tsx"
import { RowConditionParameter } from "src/pages/DataAssets/views/Expectations/Expectation/uiForms/customRenderers/RowConditionControl/RowConditionParameter.tsx"

const EMPTY_DATA = { column: undefined, operator: undefined, parameter: undefined }

export type Config = { dataAssetWithLatestMetricRun: DataAssetWithLatestMetricRunFragment }

type SelectOption = { value: string; label: string }
export type RowConditionFormProps = {
  form: FormInstance
  data: RowCondition | undefined
  columnOptions: SelectOption[] | null
  columnType: ColumnType
  operatorOptions: SelectOption[]
  path: string
  handleChange: (path: string, value: unknown) => void
  isParameterApplicable: boolean
}

export const RowConditionForm = ({
  form,
  path,
  handleChange,
  columnOptions,
  columnType,
  operatorOptions,
  data,
  isParameterApplicable,
}: RowConditionFormProps) => {
  const handleColumnChange = useCallback(
    (_: string, value: string) => {
      // changing the column resets the other 2 fields
      handleChange(path, { column: value, operator: undefined, parameter: undefined })
      form.setFieldValue("operator", undefined)
      form.setFieldValue("parameter", undefined)
    },
    [handleChange, path, form],
  )
  const handleOperatorChange = useCallback(
    (value: string) => {
      const current = data ?? EMPTY_DATA
      const unsetParameter = value === "is not null"
      handleChange(path, {
        ...current,
        operator: value,
        parameter: unsetParameter ? undefined : current.parameter,
      })
      form.setFieldValue("parameter", undefined)
    },
    [data, handleChange, path, form],
  )
  const handleParameterChange = useCallback(
    (value: string) => {
      const current = data ?? EMPTY_DATA
      handleChange(path, { ...current, parameter: value })
    },
    [data, handleChange, path],
  )

  const fieldRequiredRule = [
    {
      required: true,
      message: "",
    },
  ]

  const { column, operator, parameter } = data ?? EMPTY_DATA

  const onClear = () => {
    form.setFieldValue("condition-column", undefined)
    form.setFieldValue("operator", undefined)
    form.setFieldValue("parameter", undefined)
    handleChange(path, undefined)
  }

  const theme = useTheme()
  return (
    <Flex gap={theme.spacing.xxs}>
      <Form.Item
        name="condition-column"
        style={{ width: "100%" }}
        initialValue={column}
        shouldUpdate={(prevValues, curValues) => {
          return prevValues !== curValues
        }}
      >
        <ColumnNameSelector
          label="Select condition column"
          path={path}
          onChange={(v) => handleColumnChange("", v)}
          options={columnOptions ?? undefined}
        />
      </Form.Item>
      <Form.Item
        name="operator"
        style={{ width: "55%" }}
        initialValue={operator}
        rules={column ? fieldRequiredRule : undefined}
        validateTrigger="onBlur"
      >
        <Select
          title="Select condition operator"
          placeholder="--"
          options={operatorOptions}
          onChange={handleOperatorChange}
        />
      </Form.Item>
      <Form.Item
        name="parameter"
        style={{ width: "100%", visibility: isParameterApplicable ? "visible" : "hidden" }}
        initialValue={parameter}
        rules={column && isParameterApplicable ? fieldRequiredRule : undefined}
        validateTrigger="onBlur"
      >
        <RowConditionParameter
          onChange={handleParameterChange}
          columnType={columnType}
          aria-label="Enter condition parameter"
        />
      </Form.Item>
      <Form.Item name="clear condition">
        <Button type="text" icon="timesCircle" aria-label="Clear condition" onClick={onClear} />
      </Form.Item>
    </Flex>
  )
}
