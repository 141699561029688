import { Space, Tag } from "antd"
import styled, { css } from "styled-components"
import { Icon, IconProps } from "src/global/ui/Icon"

type UserEvent = React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLSpanElement>
export type SuccessSelectedState = "Unselected Success" | "Selected Success" | "Unselected Failure" | "Selected Failure"

const StyledTag = styled(Tag)<{ $state: SuccessSelectedState }>`
  border-radius: 16px;
  cursor: pointer;
  margin-right: 0;
  transition:
    margin 0s,
    opacity 0.2s ease-in-out,
    border 0s;

  ${({ theme, $state }) => {
    switch ($state) {
      case "Unselected Success":
        return css`
          color: ${theme.colors.success.gxSuccess};
          border-color: ${theme.colors.success.gxSuccess};
          background: ${theme.colors.success.gxSuccessBackground};
          opacity: 0.6;
          margin: 1px;

          &:hover {
            background: ${theme.colors.success.gxSuccessBackground};
            border: 2px solid ${theme.colors.success.gxSuccess};
            opacity: 1;
            margin: 0;
          }
        `
      case "Selected Success":
        return css`
          color: ${theme.colors.neutralColorPalette.whites.white};
          border-color: ${theme.colors.success.gxSuccess};
          background: ${theme.colors.success.gxSuccess};
          opacity: 1;
          margin: 1px;

          &:hover {
            opacity: 0.6;
          }
        `
      case "Unselected Failure":
        return css`
          color: ${theme.colors.error.gxError};
          border-color: ${theme.colors.error.gxError};
          background: ${theme.colors.error.gxErrorBackground};
          opacity: 0.6;
          margin: 1px;

          &:hover {
            background: ${theme.colors.error.gxErrorBackground};
            opacity: 1;
            border: 2px solid ${theme.colors.error.gxError};
            margin: 0;
          }
        `
      case "Selected Failure":
        return css`
          color: ${theme.colors.neutralColorPalette.whites.white};
          border-color: ${theme.colors.error.gxError};
          background: ${theme.colors.error.gxError};
          opacity: 1;
          margin: 1px;

          &:hover {
            opacity: 0.6;
          }
        `
    }
  }};
`

export type PillProps = {
  onSelect?: (e: UserEvent) => void
  state: SuccessSelectedState
  children?: React.ReactNode
  ariaLabel?: string
}

// NOTE: need to pass through props for antd tooltips to work (see https://github.com/ant-design/ant-design/issues/15909)
export const Pill = ({ onSelect, children, state, ...props }: PillProps) => (
  <StyledTag
    aria-label={props.ariaLabel}
    $state={state}
    {...props}
    role={onSelect ? "button" : undefined}
    onClick={onSelect}
    onKeyDown={(e) => {
      if (e.key === "Enter") {
        e.preventDefault()
        if (onSelect) {
          onSelect(e)
        }
      }
    }}
  >
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: "4px",
        height: "100%",
      }}
    >
      <SuccessIcon state={state} />
      <Space />
      {children}
    </div>
  </StyledTag>
)

export type PillElement = React.ReactElement<PillProps>

function SuccessIcon({ state }: { state: SuccessSelectedState }) {
  const props: Partial<IconProps> = { size: "16px", display: "flex" }
  switch (state) {
    case "Unselected Success":
    case "Selected Success":
      return <Icon {...props} name="check" />
    case "Unselected Failure":
    case "Selected Failure":
      return <Icon {...props} name="close" />
  }
}
