/* eslint-disable react-refresh/only-export-components */ // FIXME
import { Col, Input } from "antd"
import styled, { css } from "styled-components"

import { Heading1, BodyNormal } from "src/global/ui/typography/Text/Text.tsx"

export const StyledCol = styled(Col)`
  ${({ theme }) => css`
    margin-top: ${theme.spacing.xxs};
  `}
`

export const geLogoStyle = { height: "70px", width: "70px" }
export const OnboardingSurveyModal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 550px;
  padding: 55px 30px;
  box-shadow: 0 12px 40px rgb(0 0 0 / 12%);
  ${({ theme }) => css`
    background-color: ${theme.colors.neutralColorPalette.whites.white};
    border-radius: ${theme.spacing.xs};
    border-top: 1px solid ${theme.colors.neutralColorPalette.backgroundsAndBorders.gxBorder};
  `};
`

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
`

export const HeaderSecondary = styled(BodyNormal)`
  ${({ theme }) => css`
    padding-bottom: ${theme.spacing.xxs};
    margin-left: ${theme.spacing.xs};
    margin-right: ${theme.spacing.xxs};
  `}
`

export const WelcomeHeaderText = styled(Heading1)`
  ${({ theme }) => css`
    margin-left: ${theme.spacing.xs};
  `}
`

export const StyledInput = styled(Input)`
  ${({ theme }) => css`
    height: ${theme.typography.lineHeight.medium};
    margin-top: ${theme.spacing.xxxs};
    width: ${theme.spacing.xxxxl};
  `}
`
