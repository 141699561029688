import { TRY_GX_CLOUD_URL } from "src/global/config.ts"
import { Link } from "src/global/ui/typography/Link/Link.tsx"

// These are error messages which are returned from the GX Agent
export const RETURNED_GX_AGENT_ERROR_MESSAGE_NO_AGENT = "Start the GX Agent, then retry the connection."
export const RETURNED_GX_AGENT_ERROR_MESSAGE_TIMEOUT = "Job timeout exceeded - no response received from the GX Agent."

export const GX_AGENT_NOT_CONNECTED_ERROR_MESSAGE = "The GX Agent isn't running"
export const GX_AGENT_INACTIVE = "There is no active agent connected to GX Cloud."
export const GX_AGENT_DOCS_LINK = (
  <>
    <span>For more information, see our </span>
    <Link to={TRY_GX_CLOUD_URL} target="_blank" rel="noopener noreferrer">
      documentation
    </Link>
    .
  </>
)
