import { AppModal, StyledCopyButton } from "src/global/ui/AppModal/AppModal.tsx"
import { CodeSnippetEditor } from "src/global/ui/CodeSnippetEditor/CodeSnippetEditor.tsx"
import { copyToClipboard } from "src/global/utils/clipboard.ts"
import styled from "styled-components"

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
`

type UnexpectedIndexQueryModalProps = Pick<React.ComponentProps<typeof AppModal>, "open" | "onCancel" | "onOk"> & {
  unexpectedIndexQuery: string
}

export function UnexpectedIndexQueryModal({
  open,
  onCancel,
  onOk,
  unexpectedIndexQuery,
}: UnexpectedIndexQueryModalProps) {
  const language = unexpectedIndexQuery.includes("SELECT") ? "sql" : "python"

  const displayQuery = (
    <>
      <p>To retrieve all unexpected values:</p>
      <CodeSnippetEditor
        value={unexpectedIndexQuery}
        language={language}
        readOnly
        fontSize={12}
        showLineNumbers={false}
        height="250px"
      />
      <StyledCopyButton icon="clipboard" aria-label="Copy" onClick={() => copyToClipboard(unexpectedIndexQuery)}>
        Copy query
      </StyledCopyButton>
    </>
  )

  return (
    <AppModal
      open={open}
      closable={true}
      title="Unexpected Values Query"
      width={750}
      onCancel={(e) => {
        e.stopPropagation()
        if (onCancel) {
          onCancel(e)
        }
      }}
      cancelButtonProps={{ style: { display: "none" } }}
      onOk={onOk}
      okButtonProps={{ style: { display: "none" } }}
    >
      <FlexContainer>{displayQuery}</FlexContainer>
    </AppModal>
  )
}
