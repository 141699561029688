/* eslint-disable react-refresh/only-export-components */ // FIXME
import { MainContent, MainContentBlock } from "src/global/layout/MainContent.tsx"
import { PageHeader } from "src/global/ui/PageHeader"
import { HeaderTypes } from "src/global/ui/PageHeader/types.ts"
import { JobsList } from "src/pages/AppLogs/components/JobsList.tsx"
import { AgentInactiveAlert } from "src/pages/AppLogs/components/AgentInactiveAlert.tsx"
import { useQuery } from "@apollo/client"
// eslint-disable-next-line no-restricted-imports -- deprecated file
import { DatasourcesDocument } from "src/api/graphql/graphql-operations.ts"
import { useAgentStatus } from "src/global/hooks/useAgentStatus.ts"
import { Tag, Tooltip } from "antd"
import { theme } from "src/global/ui/themes/theme.ts"
import { useIsGXAgentEnabled } from "src/global/hooks/useIsGXAgentEnabled.ts"
import { Link } from "src/global/ui/typography/Link/Link.tsx"

export const LOGS_PATH_NAME = "logs" as const

const AGENT_INACTIVE_TOOLTIP = (
  <>
    To activate the GX Agent, see{" "}
    <Link to="https://docs.greatexpectations.io/docs/cloud/deploy_gx_agent/" target="_blank" isLightText={true}>
      Deploy the GX Agent
    </Link>
    .
  </>
)

type AgentStatus = {
  text: string
  tooltip: JSX.Element | null // null means disabled tooltip
  badgeColor: string
}

export function AppLogs() {
  useQuery(DatasourcesDocument, { canonizeResults: true })

  const isGxAgentEnabled = useIsGXAgentEnabled()
  const isAgentActive = useAgentStatus()

  let status: AgentStatus

  switch (isAgentActive) {
    case undefined:
      status = {
        text: "Initializing Agent",
        tooltip: null,
        badgeColor: theme.colors.status.gxBadgeInitializing,
      }
      break
    case true:
      status = {
        text: "Active Agent",
        tooltip: null,
        badgeColor: theme.colors.status.gxBadgeActive,
      }
      break
    case false:
      status = {
        text: "Inactive Agent",
        tooltip: AGENT_INACTIVE_TOOLTIP,
        badgeColor: theme.colors.status.gxBadgeInactive,
      }
      break
  }

  const agentTag = (
    <Tooltip placement="right" title={status.tooltip}>
      <Tag color={status.badgeColor} style={{ cursor: "default" }}>
        {status.text}
      </Tag>
    </Tooltip>
  )

  const headerContent: HeaderTypes = {
    title: <>Logs {isGxAgentEnabled && agentTag}</>,
    rootPath: `/${LOGS_PATH_NAME}`,
  }

  return (
    <PageHeader headerContent={headerContent}>
      <MainContent>
        <MainContentBlock>
          {isGxAgentEnabled && !isAgentActive && <AgentInactiveAlert />}
          <JobsList />
        </MainContentBlock>
      </MainContent>
    </PageHeader>
  )
}

export { AGENT_INACTIVE_TOOLTIP }
