import { JSONSchema } from "json-schema-to-ts"
import { ConfigData, FormData } from "src/pages/DataAssets/components/JsonForm.tsx"

// This schema is taken from Mercury (instead of OSS) and modified to play more nicely with our json forms
// https://github.com/greatexpectationslabs/mercury/blob/develop/services/ge_cloud/schemas/SnowflakeDatasource.json

export const SnowflakeDataSourceJsonSchema = {
  title: "SnowflakeDatasource",
  type: "object",
  properties: {
    type: {
      title: "Type",
      default: "snowflake",
      type: "string",
      const: "snowflake",
      enum: ["snowflake"],
    },
    name: {
      title: "Name",
      type: "string",
    },
    id: {
      title: "Id",
      type: "string",
      format: "uuid",
    },
    assets: {
      title: "Assets",
      default: [],
      type: "array",
      items: {
        // "discriminator": {
        //   "propertyName": "type",
        //   "mapping": {
        //     "table": "#/definitions/TableAsset",
        //     "query": "#/definitions/QueryAsset"
        //   }
        // },
        oneOf: [
          {
            $ref: "#/definitions/TableAsset",
          },
          {
            $ref: "#/definitions/QueryAsset",
          },
        ],
      },
    },
    connection_string: {
      title: "Connection String",
      oneOf: [
        {
          $ref: "#/definitions/ConnectionDetails",
        },
        // {
        //   "title": "ConfigStr",
        //   "type": "string",
        //   "description": "Contains config templates to be substituted at runtime. Runtime values will never be serialized.",
        //   "format": "password",
        //   "minLength": 2,
        //   "pattern": ".*(?<!\\\\)\\$\\{(.*?)\\}|(?<!\\\\)\\$([_a-zA-Z][_a-zA-Z0-9]*).*",
        //   "writeonly": true,
        //   "examples": [
        //     "${MY_CONFIG_VAR}",
        //     "$MY_CONFIG_VAR"
        //   ]
        // },
        {
          // "title": "SnowflakeDsn",
          title: "Connection String",
          type: "string",
          description: "A snowflake connection string.",
          format: "uri",
          maxLength: 65536,
          minLength: 1,
          pattern: "snowflake://.*",
          examples: ["snowflake://user:password@account/database/schema?warehouse=wh&role=role"],
        },
      ],
    },
    create_temp_table: {
      title: "Create Temp Table",
      default: false,
      type: "boolean",
      const: false,
      enum: [false],
    },
    kwargs: {
      title: "Kwargs",
      default: {},
      description:
        "Optional dictionary of `kwargs` will be passed to the SQLAlchemy Engine as part of `create_engine(connection_string, **kwargs)`",
      type: "object",
    },
  },
  required: ["name", "connection_string"],
  additionalProperties: false,
  definitions: {
    Sorter: {
      title: "Sorter",
      type: "object",
      properties: {
        key: {
          title: "Key",
          type: "string",
        },
        reverse: {
          title: "Reverse",
          default: false,
          type: "boolean",
        },
      },
      required: ["key"],
    },
    SplitterColumnValue: {
      title: "SplitterColumnValue",
      description:
        "Base model for most fluent datasource related pydantic models.\n\nAdds yaml dumping and parsing methods.\n\nExtra fields are not allowed.\n\nSerialization methods default to `exclude_unset = True` to prevent serializing\nconfigs full of mostly unset default values.\nAlso prevents passing along unset kwargs to BatchSpec.\nhttps://docs.pydantic.dev/usage/exporting_models/",
      type: "object",
      properties: {
        column_name: {
          title: "Column Name",
          type: "string",
        },
        method_name: {
          title: "Method Name",
          default: "split_on_column_value",
          enum: ["split_on_column_value"],
          type: "string",
        },
      },
      required: ["column_name"],
      additionalProperties: false,
    },
    SplitterMultiColumnValue: {
      title: "SplitterMultiColumnValue",
      description:
        "Base model for most fluent datasource related pydantic models.\n\nAdds yaml dumping and parsing methods.\n\nExtra fields are not allowed.\n\nSerialization methods default to `exclude_unset = True` to prevent serializing\nconfigs full of mostly unset default values.\nAlso prevents passing along unset kwargs to BatchSpec.\nhttps://docs.pydantic.dev/usage/exporting_models/",
      type: "object",
      properties: {
        column_names: {
          title: "Column Names",
          type: "array",
          items: {
            type: "string",
          },
        },
        method_name: {
          title: "Method Name",
          default: "split_on_multi_column_values",
          enum: ["split_on_multi_column_values"],
          type: "string",
        },
      },
      required: ["column_names"],
      additionalProperties: false,
    },
    SplitterDividedInteger: {
      title: "SplitterDividedInteger",
      description:
        "Base model for most fluent datasource related pydantic models.\n\nAdds yaml dumping and parsing methods.\n\nExtra fields are not allowed.\n\nSerialization methods default to `exclude_unset = True` to prevent serializing\nconfigs full of mostly unset default values.\nAlso prevents passing along unset kwargs to BatchSpec.\nhttps://docs.pydantic.dev/usage/exporting_models/",
      type: "object",
      properties: {
        column_name: {
          title: "Column Name",
          type: "string",
        },
        method_name: {
          title: "Method Name",
          default: "split_on_divided_integer",
          enum: ["split_on_divided_integer"],
          type: "string",
        },
        divisor: {
          title: "Divisor",
          type: "integer",
        },
      },
      required: ["column_name", "divisor"],
      additionalProperties: false,
    },
    SplitterModInteger: {
      title: "SplitterModInteger",
      description:
        "Base model for most fluent datasource related pydantic models.\n\nAdds yaml dumping and parsing methods.\n\nExtra fields are not allowed.\n\nSerialization methods default to `exclude_unset = True` to prevent serializing\nconfigs full of mostly unset default values.\nAlso prevents passing along unset kwargs to BatchSpec.\nhttps://docs.pydantic.dev/usage/exporting_models/",
      type: "object",
      properties: {
        column_name: {
          title: "Column Name",
          type: "string",
        },
        method_name: {
          title: "Method Name",
          default: "split_on_mod_integer",
          enum: ["split_on_mod_integer"],
          type: "string",
        },
        mod: {
          title: "Mod",
          type: "integer",
        },
      },
      required: ["column_name", "mod"],
      additionalProperties: false,
    },
    SplitterYear: {
      title: "SplitterYear",
      // "description": "Base model for most fluent datasource related pydantic models.\n\nAdds yaml dumping and parsing methods.\n\nExtra fields are not allowed.\n\nSerialization methods default to `exclude_unset = True` to prevent serializing\nconfigs full of mostly unset default values.\nAlso prevents passing along unset kwargs to BatchSpec.\nhttps://docs.pydantic.dev/usage/exporting_models/",
      type: "object",
      properties: {
        column_name: {
          title: "Column Name",
          type: "string",
        },
        method_name: {
          title: "Method Name",
          default: "split_on_year",
          enum: ["split_on_year"],
          type: "string",
        },
      },
      required: ["column_name"],
      additionalProperties: false,
    },
    SplitterYearAndMonth: {
      title: "SplitterYearAndMonth",
      // "description": "Base model for most fluent datasource related pydantic models.\n\nAdds yaml dumping and parsing methods.\n\nExtra fields are not allowed.\n\nSerialization methods default to `exclude_unset = True` to prevent serializing\nconfigs full of mostly unset default values.\nAlso prevents passing along unset kwargs to BatchSpec.\nhttps://docs.pydantic.dev/usage/exporting_models/",
      type: "object",
      properties: {
        column_name: {
          title: "Column Name",
          type: "string",
        },
        method_name: {
          title: "Method Name",
          default: "split_on_year_and_month",
          enum: ["split_on_year_and_month"],
          type: "string",
        },
      },
      required: ["column_name"],
      additionalProperties: false,
    },
    SplitterYearAndMonthAndDay: {
      title: "SplitterYearAndMonthAndDay",
      // "description": "Base model for most fluent datasource related pydantic models.\n\nAdds yaml dumping and parsing methods.\n\nExtra fields are not allowed.\n\nSerialization methods default to `exclude_unset = True` to prevent serializing\nconfigs full of mostly unset default values.\nAlso prevents passing along unset kwargs to BatchSpec.\nhttps://docs.pydantic.dev/usage/exporting_models/",
      type: "object",
      properties: {
        column_name: {
          title: "Column Name",
          type: "string",
        },
        method_name: {
          title: "Method Name",
          default: "split_on_year_and_month_and_day",
          enum: ["split_on_year_and_month_and_day"],
          type: "string",
        },
      },
      required: ["column_name"],
      additionalProperties: false,
    },
    SplitterDatetimePart: {
      title: "SplitterDatetimePart",
      // "description": "Base model for most fluent datasource related pydantic models.\n\nAdds yaml dumping and parsing methods.\n\nExtra fields are not allowed.\n\nSerialization methods default to `exclude_unset = True` to prevent serializing\nconfigs full of mostly unset default values.\nAlso prevents passing along unset kwargs to BatchSpec.\nhttps://docs.pydantic.dev/usage/exporting_models/",
      type: "object",
      properties: {
        column_name: {
          title: "Column Name",
          type: "string",
        },
        method_name: {
          title: "Method Name",
          default: "split_on_date_parts",
          enum: ["split_on_date_parts"],
          type: "string",
        },
        datetime_parts: {
          title: "Datetime Parts",
          type: "array",
          items: {
            type: "string",
          },
        },
      },
      required: ["column_name", "datetime_parts"],
      additionalProperties: false,
    },
    TableAsset: {
      title: "TableAsset",
      type: "object",
      properties: {
        name: {
          title: "Name",
          type: "string",
        },
        type: {
          title: "Type",
          default: "table",
          type: "string",
          const: "table",
          enum: ["table"],
        },
        id: {
          title: "Id",
          type: "string",
          format: "uuid",
        },
        order_by: {
          title: "Order By",
          type: "array",
          items: {
            $ref: "#/definitions/Sorter",
          },
        },
        batch_metadata: {
          title: "Batch Metadata",
          type: "object",
        },
        splitter: {
          title: "Splitter",
          anyOf: [
            {
              $ref: "#/definitions/SplitterYear",
            },
            {
              $ref: "#/definitions/SplitterYearAndMonth",
            },
            {
              $ref: "#/definitions/SplitterYearAndMonthAndDay",
            },
          ],
        },
        table_name: {
          title: "Table Name",
          // "default": null, TODO: remove this from the schema and resolve this issue that causes a bug when the default value is included: https://greatexpectations.atlassian.net/browse/LAKITU-858
          // "anyOf": [
          // {
          type: "string",
          // },
          // {
          //   "type": "null"
          // }
          // ]
        },
        schema_name: {
          title: "Schema Name",
          type: "string",
        },
      },
      required: ["name", "splitter"],
      additionalProperties: false,
    },
    QueryAsset: {
      title: "QueryAsset",
      type: "object",
      properties: {
        name: {
          title: "Name",
          type: "string",
        },
        type: {
          title: "Type",
          default: "query",
          type: "string",
          const: "query",
          enum: ["query"],
        },
        id: {
          title: "Id",
          type: "string",
          format: "uuid",
        },
        order_by: {
          title: "Order By",
          type: "array",
          items: {
            $ref: "#/definitions/Sorter",
          },
        },
        batch_metadata: {
          title: "Batch Metadata",
          type: "object",
        },
        splitter: {
          title: "Splitter",
          anyOf: [
            {
              $ref: "#/definitions/SplitterYear",
            },
            {
              $ref: "#/definitions/SplitterYearAndMonth",
            },
            {
              $ref: "#/definitions/SplitterYearAndMonthAndDay",
            },
          ],
        },
        query: {
          title: "Query",
          type: "string",
        },
      },
      required: ["name", "query", "splitter"],
      additionalProperties: false,
    },
    ConnectionDetails: {
      title: "ConnectionDetails",
      type: "object",
      properties: {
        account: {
          title: "Account",
          type: "string",
        },
        user: {
          title: "User",
          type: "string",
        },
        password: {
          title: "Password",
          // "anyOf": [
          //   {
          //     "title": "ConfigStr",
          //     "type": "string",
          //     "description": "Contains config templates to be substituted at runtime. Runtime values will never be serialized.",
          //     "format": "password",
          //     "minLength": 2,
          //     "pattern": ".*(?<!\\\\)\\$\\{(.*?)\\}|(?<!\\\\)\\$([_a-zA-Z][_a-zA-Z0-9]*).*",
          //     "writeonly": true,
          //     "examples": [
          //       "${MY_CONFIG_VAR}",
          //       "$MY_CONFIG_VAR"
          //     ]
          //   },
          // {
          type: "string",
          // }
          // ]
        },
        database: {
          title: "Database",
          type: "string",
        },
        schema: {
          title: "Schema",
          type: "string",
        },
        warehouse: {
          title: "Warehouse",
          type: "string",
        },
        role: {
          title: "Role",
          type: "string",
        },
      },
      required: ["account", "user", "password", "database", "schema", "warehouse", "role"],
      additionalProperties: false,
    },
  },
} as const satisfies JSONSchema

export const SnowflakeNoQueryAssetsDataSourceJsonSchema = {
  ...SnowflakeDataSourceJsonSchema,
  properties: {
    ...SnowflakeDataSourceJsonSchema.properties,
    assets: {
      type: "array",
      items: {
        ...SnowflakeDataSourceJsonSchema.properties.assets.items.oneOf[0],
      },
    },
  },
} as const satisfies JSONSchema

// These type tests didn't seem to be performing much labor outside
// of these files, and conflicted against the stricter type-checking
// of json-schema-to-js ^3.1, so commenting out for now.

// type SnowflakeSansQuery = ConfigData<typeof SnowflakeNoQueryAssetsDataSourceJsonSchema>
// type Matches = SnowflakeSansQuery extends SnowflakeDataSourceConfigData ? true : false

// type test
// eslint-disable-next-line @typescript-eslint/no-unused-vars
// const answer: Matches = true

export type SnowflakeDataSourceFormData = FormData<typeof SnowflakeDataSourceJsonSchema>
export type SnowflakeDataSourceConfigData = ConfigData<typeof SnowflakeDataSourceJsonSchema>
