import styled from "styled-components"
import { Image } from "src/global/ui/Image"
import { copyToClipboard } from "src/global/utils/clipboard.ts"

export type LinkableSectionProps = {
  title: string
  id: string
}

const StyledImage = styled(Image)`
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.2s;
`

const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 10px;

  &:hover {
    ${StyledImage} {
      opacity: 1;
    }
  }
`

const createHashUrl = (id: string): string => {
  const url = new URL(window.location.href)
  url.hash = id

  return url.toString()
}

export const LinkableSection = ({ title, id }: LinkableSectionProps) => {
  const size = { height: "18px", width: "18px" }
  const targetUrl = createHashUrl(id)
  const copyUrlToClipboard = () => copyToClipboard(targetUrl, "Link copied")

  return (
    <StyledContainer id={id}>
      {title}
      <StyledImage className="link" type="link" svgProps={size} style={size} onClick={copyUrlToClipboard} />
    </StyledContainer>
  )
}
