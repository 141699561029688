/* eslint-disable react-refresh/only-export-components */ // FIXME
import { Alert as AlertAntD, AlertProps, Flex } from "antd"
import { Icon } from "src/global/ui/Icon"
import styled, { css, useTheme } from "styled-components"
import { graphql } from "src/api/graphql/gql"
import { useQuery } from "@apollo/client"
import { PRICING_URL } from "src/global/config.ts"
import { pluralize } from "src/global/utils/strings.ts"
import { getFirstDayNextMonthLongFmt } from "src/global/utils/formatTime.ts"
import { useAnalytics } from "src/global/hooks/analytics/useAnalytics.ts"
import { BodyStrong } from "src/global/ui/typography/Text/Text.tsx"
import { Link } from "src/global/ui/typography/Link/Link.tsx"

const Container = styled(AlertAntD)`
  ${({ theme }) => css`
    padding: ${theme.spacing.xs};
    border-radius: 0;
    display: flex;
  `}
`

export const GetOrgPlanInfoDocument = graphql(`
  query getOrgPlanInfo {
    organization {
      id
      shouldPromoteTeamPlan
      monthlyDataAssetsValidated
      monthlyValidatedAssetLimit
    }
  }
`)

type CaptureEventParams = Readonly<Parameters<NonNullable<ReturnType<typeof useAnalytics>>["capture"]>>

interface UpgradeBannerContainerProps {
  text: ReturnType<typeof getInfoText>
  type: AlertProps["type"]
  analyticsEvent: CaptureEventParams
}

interface UseUpgradeBannerResult {
  text: (string | JSX.Element)[]
  type: AlertProps["type"]
  analyticsEvent: CaptureEventParams
}

const getInfoText = (assetCount: number, assetCountLimit: number) => {
  return [
    "You have validated ",
    <BodyStrong key="assets">{`${assetCount} `} out of </BodyStrong>,
    <BodyStrong key="assetsLimit">{`${assetCountLimit} `}</BodyStrong>,
    pluralize("Data Asset", assetCount),
    " on your ",
    <BodyStrong key="plan">Developer Plan</BodyStrong>,
    ".",
    " This will reset on ",
    <BodyStrong key="resetdate">{getFirstDayNextMonthLongFmt()}</BodyStrong>,
  ]
}

const getWarningText = (assetCountLimit: number) => {
  return [
    "You have reached the limit of ",
    <BodyStrong key="assetlimit">{`${assetCountLimit} validated`}</BodyStrong>,
    " Data Assets on your ",
    <BodyStrong key="plan">Developer Plan</BodyStrong>,
    ".",
    " This will reset on ",
    <BodyStrong key="resetdate">{getFirstDayNextMonthLongFmt()}</BodyStrong>,
    ".",
  ]
}

const EXTRA_TEXT = "Upgrade for more coverage"

export function useUpgradeBanner(): UseUpgradeBannerResult | null {
  const query = useQuery(GetOrgPlanInfoDocument)

  const showBanner = Boolean(query.data?.organization?.shouldPromoteTeamPlan)

  if (!showBanner || !query.data?.organization) {
    // short circuit
    return null
  }

  const assetCount: number = query.data.organization.monthlyDataAssetsValidated
  const assetCountLimit: number = query.data.organization.monthlyValidatedAssetLimit
  const type = assetCount >= assetCountLimit ? ("warning" as const) : ("info" as const)
  const text = type === "info" ? getInfoText(assetCount, assetCountLimit) : getWarningText(assetCountLimit)
  const analyticsEvent = ["banner_pricing_link.clicked", { validatedAssetCountForCurrentMonth: assetCount }] as const

  return {
    text,
    type,
    analyticsEvent,
  }
}

// UpgradeBannerContainer is a presentational component that renders the banner
export function UpgradeBannerContainer(props: UpgradeBannerContainerProps) {
  const analytics = useAnalytics()
  const iconName = props.type === "warning" ? "warning" : "rocket"
  const theme = useTheme()

  return (
    <Container
      message={
        <Flex justify="space-between" flex="1" gap={theme.spacing.xs}>
          <span>{props.text}</span>
          <Link to={PRICING_URL} target="_blank" onClick={() => analytics?.capture(...props.analyticsEvent)}>
            {EXTRA_TEXT}
          </Link>
        </Flex>
      }
      type={props.type}
      showIcon
      icon={<Icon small name={iconName} />}
      banner
    />
  )
}

// UpgradeBanner queries data for the banner and decides whether to render it
export function UpgradeBanner() {
  const res = useUpgradeBanner()

  if (!res) {
    return null
  }

  return <UpgradeBannerContainer text={res.text} type={res.type} analyticsEvent={res.analyticsEvent} />
}
