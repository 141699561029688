import { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { SelectTableNames } from "src/pages/DataAssets/drawers/ConnectToDataSource/common/SelectTableNames/SelectTableNames.tsx"
import { getMatchingAssets } from "src/pages/DataAssets/drawers/ConnectToDataSource/common/SelectTableNames/tableNameUtils.ts"
import ConnectToDataSourceContext from "src/pages/DataAssets/drawers/ConnectToDataSource/ConnectToDataSourceContext.ts"
import { ApolloError, useMutation, useQuery } from "@apollo/client"
import { AlertProps, Flex, Spin } from "antd"
import { useAssetCreationJobStatus } from "src/pages/DataAssets/hooks/useAssetCreationJobStatus.tsx"
import { useIsGXAgentEnabled } from "src/global/hooks/useIsGXAgentEnabled.ts"
import { useLazyAgentStatus } from "src/global/hooks/useAgentStatus.ts"
import { AgentNotConnectedModal } from "src/pages/DataAssets/views/AssetDetails/AgentNotConnectedModal.tsx"
import { LoadingOutlined } from "@ant-design/icons"
import { theme } from "src/global/ui/themes/theme.ts"
import { AlertBanner } from "src/global/ui/Alert"
import { AUTOGENERATE_EXPECTATIONS_ASSET_LIMIT, AUTOGENERATE_EXPECTATIONS_LIMIT_ERROR } from "src/global/config.ts"
import type {
  PossibleDataSource,
  StepComponentWithSlot,
} from "src/pages/DataAssets/drawers/ConnectToDataSource/types.ts"
import { StepContainer } from "src/pages/DataAssets/drawers/ConnectToDataSource/common/StepContainer.ts"
import { isEmpty, difference } from "lodash-es"
import { demoDataSourceConfig } from "src/pages/DataAssets/schemas/data-source-schemas.ts"
import { JobWithTableNamesFragment } from "src/api/graphql/graphql.ts"
import { graphql } from "src/api/graphql/gql"
import stringify from "json-stable-stringify"

type ListTableNamesMutationErrorProps = Partial<AlertProps>

export const CreateListTableNamesJobDocument = graphql(`
  mutation CreateListTableNamesJob($datasourceId: UUID!) {
    createListTableNamesJob(datasourceId: $datasourceId) {
      jobId
    }
  }
`)

const QueryDataSourcesDocument = graphql(`
  query DataSources {
    datasourcesV2 {
      id
      name
      type
      assets {
        id
        name
      }
    }
  }
`)

const CreateDemoDataSourceDocument = graphql(`
  mutation CreateDemoDataSource($config: JSONString!) {
    createDatasource(config: $config) {
      datasourceV2 {
        name
        id
        type
      }
    }
  }
`)

export function SelectAssetsStep({ children }: StepComponentWithSlot) {
  // Context state
  const {
    dataSource,
    setDataSource,
    selectedTables,
    setSelectedTables,
    allTableNames,
    setAllTableNames,
    setFilteredTables,
    isDemo,
  } = useContext(ConnectToDataSourceContext)

  // Component state
  const matchingTables = getMatchingAssets(allTableNames ?? [], dataSource?.assets ?? [])
  const [errorBanner, setErrorBanner] = useState<null | AlertProps>(null)
  const [connectionError, setConnectionError] = useState<null | AlertProps>(null)
  const [tableJobPending, setTableJobPending] = useState<boolean>(false)

  // Demo Data
  // If the user is onboarding a demo data source, make sure we either create
  // or attach to an existing demo data source on the account
  const demoData = useMemo(
    () => ({
      type: "snowflake",
      name: demoDataSourceConfig.name,
      connection_string: demoDataSourceConfig.connectionString,
    }),
    [],
  )

  const { data: dataSources, refetch } = useQuery(QueryDataSourcesDocument, { skip: !isDemo })
  const [createDemoDataSource] = useMutation(CreateDemoDataSourceDocument, {
    refetchQueries: [{ query: QueryDataSourcesDocument }],
    onError: (error) => {
      setErrorBanner({ message: "Failed to create Demo Data Source", description: error.message })
      throw new Error("failed to create demo data source")
    },
  })

  useEffect(() => {
    if (isDemo) {
      const demoDataSource = dataSources?.datasourcesV2?.find((ds) => ds.name === demoData.name)
      if (demoDataSource) {
        setDataSource(demoDataSource as PossibleDataSource)
      } else {
        createDemoDataSource({
          variables: {
            config: stringify(demoData),
          },
        })
      }
    }
  }, [createDemoDataSource, dataSources, demoData, isDemo, refetch, setDataSource])

  // Agent/Runner status and modal state
  const isGXAgentEnabled = useIsGXAgentEnabled()
  const { executeFunction: loadAgentStatus } = useLazyAgentStatus()
  const [isAgentErrorModalVisible, setIsAgentErrorModalVisible] = useState(false)

  // Fetch tables
  const [createJob, { data, reset }] = useMutation(CreateListTableNamesJobDocument, {
    onError: (err: ApolloError) => handleListTableNamesError({ description: err.message, message: err.name }),
  })

  // Handle table fetch errors
  const handleListTableNamesError: ({ description, message, type }: ListTableNamesMutationErrorProps) => void =
    useCallback(
      ({ description, message, type }) => {
        setConnectionError({ description, message: message ?? null, type: type ?? "error" })
        setTableJobPending(false)
        reset()
      },
      [reset],
    )

  // Poll & handle table fetch job
  const handleJobComplete = useCallback(
    (job: Pick<JobWithTableNamesFragment, "tableNames">) => {
      if (job?.tableNames?.length && job.tableNames.length > 0) {
        setTimeout(() => {
          setAllTableNames(job.tableNames as string[])
          setTableJobPending(false)
          reset()
        }, 0)
      } else {
        setTimeout(() => {
          setTableJobPending(false)
          setConnectionError({
            message: "No tables were visible in your schema",
            description:
              "Check that the database and schema are spelled correctly, and that your user, role, and warehouse have permissions to view tables.",
            type: "warning",
          })
        }, 0)
      }
    },
    [reset, setAllTableNames],
  )

  useAssetCreationJobStatus({
    jobId: tableJobPending ? data?.createListTableNamesJob?.jobId : undefined,
    onError: handleListTableNamesError,
    onComplete: handleJobComplete,
  })

  // Agent/runner-aware table fetch wrapper
  const getTableNames = useCallback(async () => {
    if (isGXAgentEnabled) {
      const res = await loadAgentStatus()
      const isAgentConnected = res.data?.agentStatus.active
      if (!isAgentConnected) {
        setIsAgentErrorModalVisible(true)
        return
      }
    }

    setTableJobPending(true)
    setConnectionError(null)
    setErrorBanner(null)
    dataSource?.id && (await createJob({ variables: { datasourceId: dataSource.id } }))
  }, [createJob, isGXAgentEnabled, loadAgentStatus, dataSource])

  // Fetch tables on load
  useEffect(() => {
    if (isEmpty(allTableNames)) {
      getTableNames()
    } else {
      setAllTableNames(allTableNames)
    }
  }, [allTableNames, getTableNames, setAllTableNames])

  // Watch for > AUTOGENERATE_EXPECTATIONS_ASSET_LIMIT & set error banner
  useEffect(() => {
    const tooManyTablesSelected = (selectedTables?.length ?? 0) > AUTOGENERATE_EXPECTATIONS_ASSET_LIMIT && !errorBanner
    const deselectedTablesToRemoveError =
      (selectedTables?.length ?? 0) <= AUTOGENERATE_EXPECTATIONS_ASSET_LIMIT && errorBanner

    if (tooManyTablesSelected) {
      setErrorBanner({
        message: "Unable to proceed",
        description: AUTOGENERATE_EXPECTATIONS_LIMIT_ERROR,
      })
    } else if (deselectedTablesToRemoveError) {
      setErrorBanner(null)
    }
  }, [connectionError, errorBanner, matchingTables, selectedTables, setSelectedTables])

  // Component utilities
  const selectedTableDifference = useMemo(
    () => difference(selectedTables, matchingTables),
    [matchingTables, selectedTables],
  )
  const noNewTablesSelected = useMemo(() => isEmpty(selectedTableDifference), [selectedTableDifference])

  // Next handler
  const handleNext = useCallback(async () => {
    return new Promise<boolean>((resolve) => {
      setFilteredTables(selectedTableDifference)
      resolve(true)
    })
  }, [selectedTableDifference, setFilteredTables])

  const nextLabel = useMemo(() => {
    if (!selectedTableDifference.length) {
      return "Add Assets"
    }
    return `Add (${selectedTableDifference.length}) Asset${selectedTableDifference.length !== 1 ? "s" : ""}`
  }, [selectedTableDifference])

  return children(
    {
      loading: tableJobPending,
      disableProgress: noNewTablesSelected,
      next: handleNext,
      nextLabel,
    },
    <StepContainer>
      {tableJobPending && (
        <Flex gap="small" align="center">
          <Spin
            indicator={
              <LoadingOutlined
                spin
                style={{
                  fontSize: theme.typography.fontSize.large,
                  color: theme.colors.primaryColors.gxAccentMedium,
                }}
              />
            }
          />
          <p>Loading tables</p>
        </Flex>
      )}
      {!isEmpty(allTableNames) && !tableJobPending && (
        <SelectTableNames
          tableNames={allTableNames}
          preselectedTableNames={selectedTables}
          setSelectedTableNames={setSelectedTables}
          matchingTableNames={matchingTables}
        />
      )}
      {connectionError && (
        <AlertBanner
          message={connectionError.message ?? "There was an error connecting to the Data Source."}
          description={connectionError.description}
          type={connectionError.type}
        />
      )}
      {errorBanner && (
        <AlertBanner
          message={errorBanner.message ?? "There was an error connecting to the Data Source."}
          description={errorBanner.description}
          type={errorBanner.type}
        />
      )}
      <AgentNotConnectedModal isVisible={isAgentErrorModalVisible} setIsVisible={setIsAgentErrorModalVisible} />
    </StepContainer>,
  )
}
