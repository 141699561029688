import { FilterValue, SortOrder } from "antd/es/table/interface"
import { isBefore, parseISO } from "date-fns"

export type DQIMetric = "schema" | "volume" | "completeness"

export interface DQIFilterState {
  metric: DQIMetric
  covered: boolean
}

export function convertSortParamsToSortValue(value: string): { columnKey: string; order: SortOrder | undefined } {
  /**
   * Convert the sort params to the sort value
   * If the value is undefined, return an empty string
   * If the value is not undefined, return the column key and order
   */
  if (!value) {
    return { columnKey: "", order: undefined }
  }
  const order = value[0] === "-" ? "descend" : "ascend"
  const columnKey = order === "descend" ? value.slice(1) : value
  return { columnKey, order }
}

export function convertSortValueToSortParams(columnKey?: string | number, order?: "ascend" | "descend" | undefined) {
  /**
   * Convert the sort value to the sort params
   * If the order is undefined, return an empty string
   * If the order is ascend, return the column key
   * If the order is descend, return the column key prefixed with a "-"
   */
  if (!order || !columnKey) return ""
  const orderString = order === "ascend" ? "" : "-"
  return `${orderString}${columnKey}`
}

export function convertArrayToCommaSeparatedString(array: string[] | null) {
  if (!array) return undefined
  return array.join(",")
}

export function convertFilterObjectToFilterParams(filters: Record<string, FilterValue | null>) {
  const { name, dataSourceName, lastValidation, success, coverage: coverageArray } = filters
  const coverage = Array.isArray(coverageArray) ? parseDQIFilterValue(coverageArray[0] as string) : null

  const result: {
    dataAssets?: string
    dataSources?: string
    lastValidation?: string
    status?: boolean
    schema?: boolean
    volume?: boolean
    completeness?: boolean
  } = {}

  if (name) result.dataAssets = convertArrayToCommaSeparatedString(name as string[] | null)
  if (dataSourceName) result.dataSources = convertArrayToCommaSeparatedString(dataSourceName as string[] | null)
  if (lastValidation) result.lastValidation = convertArrayToCommaSeparatedString(lastValidation as string[] | null)
  if (success && success?.length === 1) result.status = success[0] === true
  if (coverage) result[coverage.metric] = coverage.covered

  // Return empty string if no properties were set in the result
  return Object.keys(result).length === 0 ? "" : JSON.stringify(result)
}

export function filterByStringField(value: string, field?: string): boolean {
  if (!field) return false
  return field.toLowerCase().includes(value.toLowerCase())
}

export function parseDateRangeString(value: string): [Date | null, Date | null, boolean] {
  const [dateRange, excludeString] = value.split("|")
  const exclude = excludeString === "true"
  const dateRangeArray = dateRange ? dateRange.split(",") : []
  const startDate = dateRangeArray[0] ? parseISO(dateRangeArray[0]) : null
  const endDate = dateRangeArray[1] ? parseISO(dateRangeArray[1]) : null

  return [startDate, endDate, exclude]
}

export function filterByDateRange(timestamp: string | null, value: string): boolean {
  const [startDate, endDate, exclude] = parseDateRangeString(value)
  const recordDate = timestamp ? parseISO(timestamp) : null

  if (!startDate || !endDate) return false

  // If exclude is true and timestamp is null, include the row
  if (exclude && !recordDate) return true

  // If exclude is true and timestamp is in range, exclude the row
  if (exclude && recordDate) {
    return !(recordDate >= startDate && recordDate <= endDate)
  }

  // If exclude is false and timestamp is null, exclude the row
  if (!recordDate) return false

  // Normal date range check
  return recordDate >= startDate && recordDate <= endDate
}

export function sortByStringField(a: string | undefined, b: string | undefined): number {
  if (a === undefined && b === undefined) return 0
  if (a === undefined) return 1
  if (b === undefined) return -1
  return a.localeCompare(b)
}

export function sortByDateField(a: string | null, b: string | null): number {
  if (!a && !b) return 0
  if (!a) return -1
  if (!b) return 1
  return isBefore(parseISO(a), parseISO(b)) ? -1 : 1
}

export function sortByBooleanField(a: boolean | null, b: boolean | null): number {
  if (a === null && b === null) return 0
  if (a === null) return -1
  if (b === null) return 1
  return Number(a) - Number(b)
}

export function processFilterValueParam<T>(value?: T): T[] | null {
  if (value === undefined) return null
  return [value]
}

/**
 * Creates a date range string in the format "YYYY-MM-DD,YYYY-MM-DD|exclude"
 * If no dates are provided but exclude is true, returns "|true"
 * If no dates are provided and exclude is false, returns empty string
 */
export const formatDateRangeString = (startDate: Date | null, endDate: Date | null, exclude: boolean): string => {
  const formatDate = (date: Date) => date.toISOString().split("T")[0]

  if (startDate && endDate) {
    return `${formatDate(startDate)},${formatDate(endDate)}|${exclude}`
  }

  return exclude ? "|true" : ""
}

export function parseDQIFilterValue(filterValue: string | undefined): DQIFilterState | null {
  if (!filterValue) {
    return null
  }

  const [metric, value] = filterValue.split(":")
  if (!metric || !value || !["schema", "volume", "completeness"].includes(metric)) {
    return null
  }

  return {
    metric: metric as DQIMetric,
    covered: value === "true",
  }
}

export function groupCoverageQueryParams(fields: Record<DQIMetric, boolean | undefined>): string[] {
  const withValues = Object.entries(fields).filter(([, value]) => value !== undefined)
  const output = withValues.map(([metric, covered]) => `${metric}:${covered}`)
  return output
}
