import { ApolloError, useQuery } from "@apollo/client"
import { graphql } from "src/api/graphql/gql"
import { GetJobListItemsQuery } from "src/api/graphql/graphql.ts"

export type UseListItemsResult<T> = {
  items: T[]
  loading: boolean
  error?: ApolloError
  noData: boolean
}

export const GetJobListItemsDocument = graphql(`
  query GetJobListItems {
    jobs {
      __typename
      id
      jobType
      ...JobListItemInfo
    }
  }
`)

export function useJobListItems(): UseListItemsResult<GetJobListItemsQuery["jobs"][number]> {
  const response = useQuery(GetJobListItemsDocument)

  const { data, loading, error } = response

  if (loading) {
    return { noData: false, loading: true, items: [], error: undefined }
  }
  if (error) {
    return { noData: false, loading: false, items: [], error: error }
  }
  if (!data || !data?.jobs?.length) {
    return { noData: true, loading: false, items: [], error: undefined }
  }
  return {
    noData: false,
    loading: false,
    items: data.jobs,
    error: undefined,
  }
}
