import { Card, Card as AntdCard, CardProps, ConfigProvider, Flex, Space, Tag } from "antd"
import styled, { useTheme } from "styled-components"
import Meta, { CardMetaProps } from "antd/es/card/Meta"
import * as React from "react"
import { Heading3 } from "src/global/ui/typography/Text/Text.tsx"
import { kebabCase } from "lodash-es"

function SidebarCard({ title, ...props }: CardProps) {
  const theme = useTheme()
  return (
    <ConfigProvider
      theme={{
        components: {
          Card: {
            colorBorderSecondary: theme.colors.neutralColorPalette.backgroundsAndBorders.gxBorder,
            headerBg: theme.colors.neutralColorPalette.backgroundsAndBorders.gxBgSecondary,
          },
        },
      }}
    >
      <AntdCard size="small" title={<Heading3>{title}</Heading3>} {...props} />
    </ConfigProvider>
  )
}

const StyledAntdCard = styled(AntdCard)`
  border: 1px solid ${({ theme }) => theme.colors.neutralColorPalette.backgroundsAndBorders.gxBorder};
  p {
    margin: 0;
  }
`

const ExpectationInfoStyledCard = styled(StyledAntdCard)`
  background-color: ${({ theme }) => theme.colors.neutralColorPalette.backgroundsAndBorders.gxBgPrimary};
  font-weight: 500;
  border-color: ${({ theme }) => theme.colors.neutralColorPalette.backgroundsAndBorders.gxBorder};
`

export type ExpectationInfoCardProps = CardProps & {
  dataQualityIssues?: string[]
}

function ExpectationInfoCard(props: ExpectationInfoCardProps) {
  const theme = useTheme()

  const extra = (props.dataQualityIssues ?? []).map((dqi) => <Tag key={kebabCase(dqi)}>{dqi}</Tag>)
  return <ExpectationInfoStyledCard size="small" extra={extra} style={{ marginBottom: theme.spacing.m }} {...props} />
}

interface AlertCardProps {
  metaProps: CardMetaProps
  actions: React.ReactNode
  description: React.ReactNode
  className?: string
}

function AlertCard(props: AlertCardProps) {
  return (
    <Card className={props.className}>
      <Space direction="vertical" size="middle" style={{ display: "flex" }}>
        <Flex justify="space-between">
          <Meta {...props.metaProps} />
          {props.actions}
        </Flex>
        {props.description}
        <Space />
      </Space>
    </Card>
  )
}

export { ExpectationInfoCard, SidebarCard, AlertCard }
