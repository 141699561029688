import { useState } from "react"
import { DrawerProps, Flex } from "antd"
import { Drawer } from "src/global/ui/Drawer/Drawer.tsx"

import { AlertBanner } from "src/global/ui/Alert/AlertBanner.tsx"
import { NotifyType } from "src/api/graphql/graphql.ts"
import { EmailAlertCard } from "src/global/components/alerts/EmailAlertCard.tsx"
import { IncidentAlertsSection } from "src/global/components/alerts/IncidentAlertSection/IncidentAlertsSection.tsx"
import { useIsFeatureEnabled } from "src/global/hooks/useIsFeatureEnabled.ts"

export type AssetFlowIds = {
  assetId: string
  expectationSuiteId: string
}

export interface SlackAction {
  name: string
  action: {
    class_name: "SlackNotificationAction"
    notify_on: NotifyType
    slack_webhook: string
  }
}

interface AlertsDrawerProps extends DrawerProps {
  id?: string
  assetFlowIds?: AssetFlowIds
  isVisible: boolean
  setIsVisible: (isVisible: boolean) => void
}

export interface Error {
  title: string
  description: string
}

export function AlertsDrawer({ assetFlowIds, isVisible, setIsVisible }: AlertsDrawerProps) {
  const [error, setError] = useState<Error | null>(null)
  const incidentAlertsEnabled = useIsFeatureEnabled("incidentAlertsEnabled")

  const handleReset = () => {
    error && setError(null)
  }

  const footer = (
    <Drawer.Footer gap="8px">
      <Drawer.FooterButton
        type="primary"
        onClick={() => {
          setIsVisible(false)
          handleReset()
        }}
      >
        Done
      </Drawer.FooterButton>
    </Drawer.Footer>
  )

  return (
    <Drawer
      title="Alerts"
      onClose={() => {
        setIsVisible(false)
        handleReset()
      }}
      open={isVisible}
      onClick={(e) => e.stopPropagation()}
      footer={footer}
    >
      <Flex vertical justify="space-between" style={{ paddingBottom: "50px" }}>
        <Flex gap="large" vertical>
          {assetFlowIds?.assetId && <EmailAlertCard assetId={assetFlowIds?.assetId} onEmailAlertError={setError} />}
          {incidentAlertsEnabled && <IncidentAlertsSection />}
        </Flex>
        {error && <AlertBanner message={error.title} description={error.description} />}
      </Flex>
    </Drawer>
  )
}
