import { Col, List as AntdList, ListProps, Row, Skeleton, Space } from "antd"
import styled, { css } from "styled-components"
import { ListItemMetaProps } from "antd/es/list/Item"
import { FC, ReactNode } from "react"
import { SkeletonContainer } from "src/pages/DataAssets/DataAssets.styles.tsx"
import { useHeaderResize } from "src/global/hooks/useHeaderResize.ts"
import { DescriptionNormal, Heading3 } from "src/global/ui/typography/Text/Text.tsx"

const StyledAntdList = styled(AntdList)<{ $headerHeight?: string }>`
  background: ${({ theme }) => theme.colors.neutralColorPalette.whites.white};
  div.ant-list-header {
    position: sticky;
    ${({ $headerHeight }) =>
      $headerHeight &&
      css`
        top: ${$headerHeight}px;
      `};
    z-index: 1;
    border-top-right-radius: ${({ theme }) => theme.spacing.xxs};
    border-top-left-radius: ${({ theme }) => theme.spacing.xxs};
    background: ${({ theme }) => theme.colors.neutralColorPalette.backgroundsAndBorders.gxBgSecondary};
  }
`

export const StyledLogoContainer = styled.div`
  width: ${({ theme }) => theme.spacing.s};
  height: ${({ theme }) => theme.spacing.s};
  & img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
`

interface MainContentListHeaderProps {
  search?: ReactNode
  sort?: ReactNode
  checkbox?: ReactNode
}

type MainContentListProps<T> = Omit<ListProps<T>, "bordered" | "size" | "style"> & {
  customHeaderHeight?: string
}

export function MainContentList<T>({ loading, customHeaderHeight = "", ...props }: MainContentListProps<T>) {
  const [headerHeight] = useHeaderResize(0)
  return (
    <>
      {loading ? (
        <SkeletonLoader />
      ) : (
        <StyledAntdList<FC<ListProps<T>>>
          bordered
          size="default"
          {...props}
          $headerHeight={`${customHeaderHeight || headerHeight}`}
        />
      )}
    </>
  )
}

export function MainContentListItemMeta({ title, description, avatar, ...props }: ListItemMetaProps) {
  return (
    <AntdList.Item.Meta
      title={<Heading3>{title}</Heading3>}
      avatar={avatar && <StyledLogoContainer>{avatar}</StyledLogoContainer>}
      description={<DescriptionNormal>{description}</DescriptionNormal>}
      {...props}
    />
  )
}

export function MainContentListHeader({ search, sort, checkbox }: MainContentListHeaderProps) {
  return (
    <Row justify="start" align="middle" gutter={[24, 16]}>
      {search && (
        <Col xs={24} sm={24} md={12} lg={10} xl={10}>
          {search}
        </Col>
      )}
      {sort && (
        <Col xs={24} sm={18} md={12} lg={10} xl={8}>
          <Space align="center" size="small">
            <span>Sort by:</span>
            {sort}
          </Space>
        </Col>
      )}
      {checkbox && (
        <Col xs={24} sm={6} md={10} lg={4} xl={6}>
          {checkbox}
        </Col>
      )}
    </Row>
  )
}

function SkeletonLoader() {
  return (
    <>
      {Array(5)
        .fill(null)
        .map((_, index) => (
          <SkeletonContainer key={index} $column>
            <Skeleton active title={false} paragraph={{ rows: 1 }} />
          </SkeletonContainer>
        ))}
    </>
  )
}
