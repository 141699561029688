import { useState } from "react"
import { Button } from "src/global/ui/Button/Button.tsx"
import { NewAssetDrawer } from "src/pages/DataAssets/drawers/NewAssetDrawer/NewAssetDrawer.tsx"

export function ConnectToDataButton() {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  return (
    <>
      <Button
        id="connect-to-data-button"
        type="primary"
        onClick={(e) => {
          e.stopPropagation()
          setIsDrawerOpen(true)
        }}
        icon="plus"
      >
        New Data Asset
      </Button>
      <NewAssetDrawer isOpen={isDrawerOpen} setIsOpen={setIsDrawerOpen} />
    </>
  )
}
