/* eslint-disable react-refresh/only-export-components */ // FIXME
import { useQuery, makeVar, useReactiveVar } from "@apollo/client"
import { Tabs } from "antd"
import { useEffect } from "react"
import { useParams } from "react-router-dom"
import { assertNever } from "src/pages/DataAssets/utils.ts"
// eslint-disable-next-line no-restricted-imports -- deprecated file
import { ExpectationSuitesDocument } from "src/api/graphql/graphql-operations.ts"
import { useOrganizationSlug } from "src/pages/OrganizationRoot/hooks/useOrganizationSlug.ts"
import { Heading3, LG } from "src/global/ui/typography/Text/Text.tsx"

export type DataAssetDetailTabKey = "expectations" | "validations" | "overview" | "metrics"

type DataAssetTabsProps = {
  expectationSuiteId?: string
  currentTab: DataAssetDetailTabKey
}

/**
 * As part of the first-time experience creating an expectation on a Data Asset
 * we also create a default expectation suite. This reactive variable
 * allows us to trigger a refetch from a child component not directly in
 * this <Tab>'s rendering tree, thus maintaining consistency with available
 * suites.
 */
export const refetchDataAssetExpectationSuites = makeVar(false)

export function DataAssetTabs(props: DataAssetTabsProps) {
  const { navigateInOrg } = useOrganizationSlug()
  const { assetId: _assetId = "" } = useParams<{
    assetId: string
  }>()

  const assetId = decodeURIComponent(_assetId)

  const shouldRefetch = useReactiveVar(refetchDataAssetExpectationSuites)

  const { data, loading, refetch } = useQuery(ExpectationSuitesDocument, {
    variables: { options: { filterByAssetRefId: assetId } },
    skip: !assetId || !!props.expectationSuiteId,
  })
  const selectedExpectationSuiteId = props.expectationSuiteId ?? data?.expectationSuitesV2[0]?.id

  useEffect(() => {
    if (shouldRefetch) {
      refetch()

      // make sure to reset the reactive var so we don't continually refetch
      refetchDataAssetExpectationSuites(false)
    }
  }, [shouldRefetch, refetch])

  if (loading) {
    return null
  }

  const items = [
    {
      label: props.currentTab === "expectations" ? <Heading3>Expectations</Heading3> : <LG>Expectations</LG>,
      key: "expectations",
      disabled: false,
      show: true,
    },
    {
      label: props.currentTab === "validations" ? <Heading3>Validations</Heading3> : <LG>Validations</LG>,
      key: "validations",
      disabled: loading || !selectedExpectationSuiteId,
      show: true,
    },
    {
      label: props.currentTab === "metrics" ? <Heading3>Metrics</Heading3> : <LG>Metrics</LG>,
      key: "metrics",
      disabled: loading,
      show: true,
    },
  ]

  return (
    <Tabs
      activeKey={props.currentTab}
      onChange={(value: string) => {
        const key: DataAssetDetailTabKey = value as DataAssetDetailTabKey
        switch (key) {
          case "expectations":
            navigateInOrg(`/data-assets/${assetId}/${key}`)
            break
          case "overview":
            navigateInOrg(`/data-assets/${assetId}/overview`)
            break
          case "metrics":
            navigateInOrg(`/data-assets/${assetId}/metrics`)
            break
          case "validations":
            navigateInOrg(`/data-assets/${assetId}/${key}/expectation-suites/${selectedExpectationSuiteId}/results`)
            break
          default:
            try {
              // assert never so that TypeScript will fail to compile should the DataAssetDetailTabKey union expand
              assertNever(key)
            } catch (e) {
              console.error(`Invalid AssetDetailTabKey "${key}"`)
            }
            navigateInOrg(`/data-assets/${assetId}/overview`)
        }
      }}
      tabBarStyle={{ marginBottom: 0 }}
      items={items.filter((item) => item.show)}
    />
  )
}
