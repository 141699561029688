import type { StepProps } from "antd"
import { Steps } from "antd"
import { useTheme } from "styled-components"
import type { ProgressBarProps } from "src/pages/DataAssets/drawers/ConnectToDataSource/types.ts"

export const ProgressBar = ({ steps, currentStep }: ProgressBarProps) => {
  const theme = useTheme()
  const stepsItems = steps.reduce((acc, step) => [...acc, { title: step.title }], [] as StepProps[])
  const style = {
    marginTop: theme.spacing.xs,
    marginLeft: theme.spacing.s,
    marginRight: theme.spacing.s,
  }
  return (
    <div style={style}>
      <Steps items={stepsItems} current={currentStep} size="small" />
    </div>
  )
}
