import {
  ExpectationContainer,
  SpaceBetweenContainer,
  getRenderer,
} from "src/pages/DataAssets/views/Expectations/Expectation/utils.tsx"
// eslint-disable-next-line no-restricted-imports -- deprecated file
import { Expectation_ValidationResultFragment } from "src/api/graphql/graphql-operations.ts"
import { DefaultChart } from "src/pages/DataAssets/views/Expectations/Expectation/Charts/DefaultChart.tsx"
import { NumericBetweenChart } from "src/pages/DataAssets/views/Expectations/Expectation/Charts/NumericBetweenChart.tsx"
import { isStringDate } from "src/pages/DataAssets/views/Expectations/Expectation/Charts/ChartUtils.tsx"
import { useGetSplitterData } from "src/pages/DataAssets/views/AssetDetails/Splitters/useGetSplitterData.ts"
type ValidationResultChartsProps = {
  validationResultCharts: Expectation_ValidationResultFragment[]
}

export function ValidationResultCharts({ validationResultCharts }: ValidationResultChartsProps) {
  let mostRecentExpectationValidationResult = validationResultCharts[0]
  // if we have a placeholder piece of data lets find one that isn't
  if (mostRecentExpectationValidationResult.expectationConfig?.expectationType === "placeholder") {
    mostRecentExpectationValidationResult =
      validationResultCharts.find((expectation) => expectation.expectationConfig?.expectationType !== "placeholder") ??
      mostRecentExpectationValidationResult
  }
  const expectationType = mostRecentExpectationValidationResult.expectationConfig?.expectationType ?? ""

  const observedValue = JSON.parse(mostRecentExpectationValidationResult.result || "")
  const skipChart = isStringDate(observedValue["observed_value"])
  const supportedNumericBetweenExpectationTypesForGraphs = [
    "expect_column_max_to_be_between",
    "expect_column_min_to_be_between",
    "expect_column_mean_to_be_between",
    "expect_column_median_to_be_between",
    "expect_table_row_count_to_be_between",
    "expect_table_row_count_to_equal",
  ]

  let Chart = DefaultChart
  if (supportedNumericBetweenExpectationTypesForGraphs.includes(expectationType) && !skipChart) {
    Chart = NumericBetweenChart
  }
  const splitterData = useGetSplitterData({ isVisible: true })

  return (
    <ExpectationContainer success={mostRecentExpectationValidationResult.success ?? null}>
      <SpaceBetweenContainer>
        {getRenderer({
          renderedValue: mostRecentExpectationValidationResult?.expectationConfig?.renderedContent?.[0],
          fallback: expectationType,
          kwargs: mostRecentExpectationValidationResult?.expectationConfig?.kwargs,
          description: mostRecentExpectationValidationResult?.expectationConfig?.description,
        })}
      </SpaceBetweenContainer>
      {Chart && <Chart validationResultCharts={validationResultCharts} splitter={splitterData} />}
    </ExpectationContainer>
  )
}
