import { VegaLite, VisualizationSpec } from "react-vega"
import { tooltipOptions } from "src/global/ui/Chart/ChartConfig.tsx"

export type ChartProps = {
  spec: VisualizationSpec
}

export const Chart = ({ spec }: ChartProps) => {
  return <VegaLite spec={spec} actions={false} tooltip={tooltipOptions} />
}
