/* eslint-disable react-refresh/only-export-components */ // FIXME
import { ControlProps, JsonFormsRendererRegistryEntry, rankWith, scopeEndsWith } from "@jsonforms/core"
import { withJsonFormsControlProps } from "@jsonforms/react"
import { CodeSnippetEditor } from "src/global/ui/CodeSnippetEditor/CodeSnippetEditor.tsx"
import { Form } from "antd"

export const SQLEditorRegistryEntry: JsonFormsRendererRegistryEntry = {
  tester: rankWith(
    100, //increase rank as needed
    scopeEndsWith("#/properties/unexpected_rows_query"),
  ),
  renderer: withJsonFormsControlProps(SQLEditorControl),
}

function SQLEditorControl({ data, path, required, handleChange }: ControlProps) {
  return (
    <Form.Item
      name={path}
      initialValue={data}
      rules={[{ required, whitespace: true, message: "SQL Query is required" }]}
    >
      <CodeSnippetEditor
        language="sql"
        value={data}
        name={path}
        readOnly={false}
        fontSize={14}
        minLines={9}
        maxLines={15}
        showLineNumbers={false}
        showGutter={false}
        onChange={(val) => handleChange(path, val)}
        borderCSS="1px solid lightgray"
      />
    </Form.Item>
  )
}
