// eslint-disable-next-line no-restricted-imports -- deprecated file
import { DatasourceWithRunsFragment } from "src/api/graphql/graphql-operations.ts"

export function getMatchingAssets(
  introspectedTables: string[],
  existingAssets: DatasourceWithRunsFragment["assets"],
): string[] {
  const matchingAssets: string[] = []

  existingAssets.forEach((asset) => {
    if (asset && asset.config) {
      const tableName = JSON.parse(asset?.config).table_name

      if (introspectedTables.includes(tableName)) {
        matchingAssets.push(tableName)
      }
    }
  })

  return matchingAssets
}
