import { Collapse, Input, List, ListProps, Row, Skeleton } from "antd"
import { Icon } from "src/global/ui/Icon"
import styled, { css } from "styled-components"
import { FC } from "react"
import { removeInvalidHtmlCharacters } from "src/global/utils/removeInvalidHtmlCharacters.ts"
import { BodyNormal, Heading3 } from "src/global/ui/typography/Text/Text.tsx"

const FilterContainer = styled(Row)`
  overflow: hidden;
  width: 100%;
  max-height: 300px;
  border: 1px solid ${({ theme }) => theme.colors.neutralColorPalette.backgroundsAndBorders.gxBorder};
  border-radius: ${({ theme }) => theme.spacing.xxs};
  display: block;
`

const StyledHeadingContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.neutralColorPalette.backgroundsAndBorders.gxBgSecondary};
  padding: ${({ theme }) =>
    `${theme.spacing.xxs} ${theme.spacing.xxs}
      `};
`

const CheckMarkIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.primaryColors.gxAccentMedium};
`

const StyledCollapse = styled(Collapse)`
  background-color: ${({ theme }) => theme.colors.neutralColorPalette.whites.white};

  &.ant-collapse > .ant-collapse-item {
    border-bottom: none;
  }

  &.ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: ${({ theme }) =>
      `5px ${theme.spacing.xxs}
      `};
    background-color: ${({ theme }) => theme.colors.neutralColorPalette.backgroundsAndBorders.gxBgSecondary};
    border-bottom: 1px solid ${({ theme }) => theme.colors.neutralColorPalette.backgroundsAndBorders.gxSurfaceSecondary};
  }
`

const StyledCollapsePanel = styled(Collapse.Panel)`
  &.ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
  }
`

const StyledList = styled(List)`
  &.ant-list {
    height: 200px;
    overflow-y: scroll;
    overflow-x: hidden;
  }
`

const StyledListItem = styled(List.Item)<{ $isSelected?: boolean }>`
  &:hover,
  &:focus {
    ${({ $isSelected, theme }) => !$isSelected && `background-color: ${theme.colors.primaryColors.gxAccentBackground}`};
  }

  ${({ theme, $isSelected }) =>
    $isSelected &&
    css`
      background-color: ${theme.colors.primaryColors.gxAccentBackground};
    `}

  &.ant-list-item {
    border: none;
    padding: ${({ theme }) =>
      `${theme.spacing.xxxs} ${theme.spacing.xs}
      `};
  }

  cursor: pointer;
`

const StyledSkeleton = styled(Skeleton)`
  padding: ${({ theme }) =>
    `${theme.spacing.xxs} ${theme.spacing.xxs}
      `};
`

interface FilterBoxProps {
  list: string[]
  selectedItem: string
  onSearch: (value: string) => void
  onSelect: (value: string) => void
  loading: boolean
  title: string
}

function FilterBox({ onSearch, list, selectedItem, onSelect, loading, title }: FilterBoxProps) {
  const headerTitle = `${title}${loading ? `` : ` (${list.length})`}`

  return (
    <FilterContainer style={{ background: "white" }}>
      <StyledHeadingContainer>
        <Input.Search
          placeholder="Search"
          onChange={(event) => onSearch(event.target.value)}
          allowClear
          type="search"
          name="search"
          aria-label="Search input"
        />
      </StyledHeadingContainer>
      <StyledCollapse defaultActiveKey={["1"]} bordered={false}>
        <StyledCollapsePanel header={<Heading3>{headerTitle}</Heading3>} key="1">
          <StyledSkeleton active paragraph={{ rows: 6 }} title={false} loading={loading}>
            <StyledList<FC<ListProps<string>>> // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/39136#issuecomment-719950054
              dataSource={list}
              renderItem={(item) => {
                const isSelected = selectedItem === removeInvalidHtmlCharacters(item.toLowerCase())
                return (
                  <StyledListItem
                    tabIndex={0}
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        onSelect(item)
                      }
                    }}
                    onClick={() => {
                      onSelect(removeInvalidHtmlCharacters(item))
                    }}
                    key={item}
                    $isSelected={isSelected}
                  >
                    <BodyNormal $pointer>{item}</BodyNormal>
                    {isSelected && <CheckMarkIcon name="check" lineHeight="8px" />}
                  </StyledListItem>
                )
              }}
            />
          </StyledSkeleton>
        </StyledCollapsePanel>
      </StyledCollapse>
    </FilterContainer>
  )
}

export { FilterBox }
