import { capitalize } from "lodash-es"
import { useMemo } from "react"
import { SelectedExpectation } from "src/pages/DataAssets/views/Expectations/Expectation/CreateExpectationDrawer/types.ts"
import { jsonSchemas } from "src/global/schemas/expectation-catalog-schemas.ts"
import { DataQualityIssues, DataQualityIssue } from "src/global/schemas/expectation-metadata-data-quality-issues.ts"
import { SupportedExpectation, SupportedExpectationList } from "src/global/schemas/expectation-metadata-utils.ts"

/** Get the options for the given dataQualityIssue along with a mapping of option values to schemas
 * @param dataQualityIssue - The dataQualityIssue to filter by
 * @returns - The options for the given dataQualityIssue and a mapping of option values to schemas
 */
export function useDataQualityFilteredExpectationOptions(dataQualityIssue?: DataQualityIssue): {
  options: Options
  valueToSchema: typeof CategoricalDropdownOptionsToSchema
} {
  return useMemo(() => {
    const options = dataQualityIssue ? CategoricalDropdownOptions[dataQualityIssue] : GroupedOptions
    return { options, valueToSchema: CategoricalDropdownOptionsToSchema }
  }, [dataQualityIssue])
}

/**  ===+=== Constants ===+=== */

/** List of all supported schemas.  Generated once on module load */
const SCHEMA_LIST = SupportedExpectationList.map((title) => jsonSchemas[title])

export const DataQualityIssueDefaultExpectationMap: Record<
  DataQualityIssue,
  (typeof jsonSchemas)[SupportedExpectation]
> = {
  Volume: jsonSchemas.expectTableRowCountToBeBetween,
  Schema: jsonSchemas.expectColumnToExist,
  Completeness: jsonSchemas.expectColumnValuesToNotBeNull,
  Uniqueness: jsonSchemas.expectColumnValuesToBeUnique,
  Numeric: jsonSchemas.expectColumnValuesToBeBetween,
  Validity: jsonSchemas.expectColumnValuesToBeInSet,
  SQL: jsonSchemas.unexpectedRowsExpectation,
}

/** Default option list of all supported schemas.  Generated once on module load */
const ALL_OPTIONS: Options = SCHEMA_LIST.map(schemaToOption).sort(alphabetically)

const GroupedOptions = DataQualityIssues.map((dataQualityIssue) => {
  return {
    label: capitalize(dataQualityIssue),
    value: dataQualityIssue,
    options: schemasForDataQualityIssue(dataQualityIssue)
      .map(schemaToOption)
      .sort(byPreferredExpectation(dataQualityIssue)),
  }
}).filter((g) => g.options.length > 0)

/** Record of options for each dataQualityIssue.  Generated once on module load */
const CategoricalDropdownOptions = DataQualityIssues.reduce(
  buildDataQualityIssueOptions,
  {} as Record<DataQualityIssue, Options>,
)

/** Record of option values to expectation schema.  Generated once on module load */
const CategoricalDropdownOptionsToSchema = ALL_OPTIONS.reduce(
  (acc, option) => {
    const schema = SCHEMA_LIST.find((schema) => schema.schema.title === option.value)
    if (!schema) {
      throw new Error(`Could not find schema for option ${option.value}`)
    }
    return {
      ...acc,
      [option.value]: schema,
    }
  },
  { [jsonSchemas.unexpectedRowsExpectation.schema.title]: jsonSchemas.unexpectedRowsExpectation } as Record<
    string,
    SCHEMA
  >,
)

/** ===+=== Types ===+=== */
type Option = { label: string; value: string }
type Options = (Option | (Option & { options: { label: string; value: string } }))[]
type SCHEMA = (typeof SCHEMA_LIST)[number]

/** ===+=== Helper Functions ===+=== */

// SORTS  https://greatexpectations.atlassian.net/wiki/spaces/SUP/pages/1355972637/Domain+based+Selection+Design+Discovery
// see above doc, gold stars indicate preferred expectation, should sort to top
type Labeled = { label: string }
type OptionSorter = (a: Labeled, b: Labeled) => number
function alphabetically(a: Labeled, b: Labeled) {
  return a.label.localeCompare(b.label)
}

export function byPreferredExpectation(dataQualityIssue: DataQualityIssue | undefined): OptionSorter {
  if (!dataQualityIssue) return alphabetically
  const label = formatLabel(DataQualityIssueDefaultExpectationMap[dataQualityIssue]?.schema.title ?? "")
  return (a, b) => {
    if (a.label === label) return -1
    if (b.label === label) return 1
    return alphabetically(a, b)
  }
}

/** Remove the "Expect " prefix from the title
 * @param title
 */
export function formatLabel(title: string) {
  return capitalize(title.replace("Expect ", "")).replace("sql", "SQL").replace("expectation", "Expectation")
}
/** Convert any schema to a generic option object
 * @param schema
 * @returns Option
 */
export function schemaToOption(schema: SCHEMA): { label: string; value: string } {
  const value = schema.schema.title
  return { label: formatLabel(value), value }
}
/** Get all schemas for a given dataQualityIssue
 * @param dataQualityIssue
 * @returns SCHEMA[]
 */
export function schemasForDataQualityIssue(dataQualityIssue: DataQualityIssue | undefined) {
  if (!dataQualityIssue) return SCHEMA_LIST
  return SCHEMA_LIST.filter((schema) =>
    (schema.schema.properties.metadata.properties.data_quality_issues.const as readonly DataQualityIssue[]).includes(
      dataQualityIssue,
    ),
  )
}

/** Reducer to build a map of options for each dataQualityIssue=
 * @param acc - The accumulator
 * @param dataQualityIssue - The dataQualityIssue to add options for
 * @returns - The accumulator with the new dataQualityIssue options added
 */
export function buildDataQualityIssueOptions(
  acc: Record<DataQualityIssue, Options>,
  dataQualityIssue: DataQualityIssue,
) {
  return {
    ...acc,
    [dataQualityIssue]: schemasForDataQualityIssue(dataQualityIssue)
      .map(schemaToOption)
      .sort(byPreferredExpectation(dataQualityIssue)),
  }
}

export function selectedExpectationFromSchema(
  schema: (typeof jsonSchemas)[SupportedExpectation],
  dataQualityIssue: DataQualityIssue | undefined,
): SelectedExpectation {
  return {
    dataQualityIssues: dataQualityIssue
      ? [dataQualityIssue]
      : Array.from(schema.schema.properties.metadata.properties.data_quality_issues.const),
    title: schema.schema.title,
    value: schema.schema.properties.metadata.properties.expectation_type.const,
  }
}
