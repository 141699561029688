/* eslint-disable react-refresh/only-export-components */ // FIXME
import React, { useCallback, useReducer, createContext } from "react"
import { isB64 } from "src/global/utils/validators.ts"
import { IconTypes } from "src/global/ui/Icon"
import { AlertTypes } from "src/global/ui/Alert"

interface RoutesTypes {
  path: string
  displayName: string
}

interface headerConfig {
  backIcon?: IconTypes
  noBreadCrumb?: boolean
}

type LayoutContextState = {
  routeInfo: {
    pathname: string | null
    currentNestedRoutes: RoutesTypes[]
  }
  alertConfig: AlertTypes | null
  loading: boolean
}

interface ContextValueTypes extends LayoutContextState {
  setPathname: (pathname: string) => void
  setAlertConfig: (alertConfig: AlertTypes | null) => void
  setLoadingState: (loading: boolean) => void
}

type MainReducerActions =
  | {
      type: "NAVIGATE"
      payload: {
        pathname: string
      }
    }
  | {
      type: "SET_ALERT_CONFIG"
      payload: {
        alertConfig: AlertTypes | null
      }
    }
  | {
      type: "SET_LOADING_STATE"
      payload: {
        loading: boolean
      }
    }
  | {
      type: "SET_ROUTE_INFO"
      payload: {
        routeInfo: {
          pathname: string
          currentNestedRoutes: RoutesTypes[]
        }
      }
    }
  | {
      type: "SET_HEADER_CONFIG"
      payload: headerConfig
    }

const LayoutContext = createContext<ContextValueTypes | null>(null)

export const useLayoutContext = (): ContextValueTypes => {
  const context = React.useContext(LayoutContext)
  if (context === null) throw Error("Context cannot be used outside of a provider")
  return context
}

export default function LayoutContextProvider({ children }: { children: React.ReactNode }) {
  const initialState = {
    routeInfo: {
      pathname: null,
      currentNestedRoutes: [],
    },
    alertConfig: null,
    loading: false,
  }

  const reducer = (prevState: LayoutContextState, action: MainReducerActions): LayoutContextState => {
    switch (action.type) {
      case "NAVIGATE": {
        const { payload } = action
        return {
          ...prevState,
          routeInfo: {
            ...prevState.routeInfo,
            pathname: payload.pathname,
          },
          alertConfig: null,
          loading: false,
        }
      }

      case "SET_ALERT_CONFIG": {
        const { payload } = action
        return {
          ...prevState,
          alertConfig: payload.alertConfig,
        }
      }

      case "SET_LOADING_STATE": {
        return {
          ...prevState,
          loading: action.payload.loading,
        }
      }
      case "SET_ROUTE_INFO": {
        return {
          ...prevState,
          routeInfo: action.payload.routeInfo,
          alertConfig: null,
          loading: false,
        }
      }

      default:
        return prevState
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState)

  const defineCurrentNestedRoutes = useCallback((incomingPathname: string) => {
    if (incomingPathname) {
      const currentLocationSplitRoutes = incomingPathname.split("/").filter((word) => word)
      return currentLocationSplitRoutes.map<RoutesTypes>((encodedroute, index) => {
        const route = decodeURIComponent(encodedroute)
        const isBase64Base = isB64(route)
        let path = "/"
        if (isBase64Base && index === 0 && route) {
          path += encodeURIComponent(route)
        } else {
          path += currentLocationSplitRoutes
            .map((subRoute) => {
              const isBase64SubRoute = isB64(subRoute)
              return isBase64SubRoute ? subRoute : encodeURIComponent(subRoute)
            })
            .slice(0, index + 1)
            .join("/")
        }
        return {
          path: path,
          displayName: route,
        }
      })
    }
  }, [])

  const setPathname = useCallback(
    (pathname: string) => {
      const currentNestedRoutes = defineCurrentNestedRoutes(pathname)
      currentNestedRoutes
        ? dispatch({
            type: "SET_ROUTE_INFO",
            payload: { routeInfo: { currentNestedRoutes, pathname } },
          })
        : dispatch({ type: "NAVIGATE", payload: { pathname } })
    },
    [defineCurrentNestedRoutes],
  )

  const setAlertConfig = useCallback((alertConfig: AlertTypes | null) => {
    dispatch({ type: "SET_ALERT_CONFIG", payload: { alertConfig } })
  }, [])

  const setLoadingState = useCallback((loading: boolean) => {
    dispatch({ type: "SET_LOADING_STATE", payload: { loading } })
  }, [])

  const contextValue: ContextValueTypes = {
    ...state,
    setPathname,
    setAlertConfig,
    setLoadingState,
  }

  return <LayoutContext.Provider value={contextValue}>{children}</LayoutContext.Provider>
}
