/* eslint-disable react-refresh/only-export-components */ // FIXME
import { JsonFormsRendererRegistryEntry, LabelProps, RendererProps, rankWith, uiTypeIs } from "@jsonforms/core"
import { withJsonFormsLabelProps } from "@jsonforms/react"
import ReactMarkdown from "react-markdown"
import { ReactNode } from "react"
import { AlertInfo } from "src/global/ui/Alert"

export const LabelRendererRegistryEntry: JsonFormsRendererRegistryEntry = {
  tester: rankWith(10, uiTypeIs("Label")),
  renderer: withJsonFormsLabelProps(AlertControl),
}

function AlertControl({ text }: LabelProps & RendererProps) {
  return (
    <div style={{ marginBottom: "24px" }}>
      <AlertInfo
        message=""
        description={
          <ReactMarkdown
            components={{
              p: "span",
              a(props: { children: ReactNode; href: string; title?: string }) {
                return (
                  <a
                    style={{ textDecoration: "underline", color: "inherit" }}
                    href={props.href}
                    title={props.title}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {props.children}
                  </a>
                )
              },
            }}
          >
            {text}
          </ReactMarkdown>
        }
      />
    </div>
  )
}
