import { List } from "antd"
// eslint-disable-next-line no-restricted-imports -- deprecated file
import { Button } from "src/global/ui/Button/Button.tsx"
import { getImageLogo } from "src/pages/DataAssets/getImageLogo.ts"
import { StyledLogoContainer } from "src/global/ui/List/MainContentList.tsx"
import { useIsDataSourceEditable } from "src/global/hooks/useIsDataSourceEditable.ts"
import { BodyStrong, DescriptionNormal } from "src/global/ui/typography/Text/Text.tsx"
import type { DataSourcesListDrawerQuery } from "src/api/graphql/graphql"
type DataSource = NonNullable<DataSourcesListDrawerQuery["datasourcesV2"][number]>

interface Props {
  dataSource: DataSource
  onDelete: () => void
  onEdit: () => void
}

export function ManageDatasourceListItem({ dataSource, onDelete, onEdit }: Props) {
  const isEditable = useIsDataSourceEditable(dataSource.type)
  return (
    <List.Item
      actions={[
        <Button type="text" title="Edit Data Source" key="edit" icon="edit" onClick={onEdit} disabled={!isEditable} />,
        <Button title="Delete Data Source" type="text" key="delete" icon="trash" onClick={() => onDelete()} />,
      ]}
    >
      <List.Item.Meta
        title={<BodyStrong>{dataSource.name}</BodyStrong>}
        avatar={
          <StyledLogoContainer>
            <img src={getImageLogo(dataSource.type)} alt={`${dataSource.type} logo`} />
          </StyledLogoContainer>
        }
        description={<DescriptionNormal>{dataSource.assets.length} Assets</DescriptionNormal>}
      />
    </List.Item>
  )
}
