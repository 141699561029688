import { message } from "antd"
import { MESSAGE_DURATION_SECONDS } from "src/global/config.ts"

// copyToClipboard will copy some data to the native clipboard.
// if this is unsupported by the browser, or if there is no data to copy, a failure will be reported
async function copyToClipboard(
  data: string, // data to copy
  successMsg = "Successfully copied to clipboard", // empty string will prevent this
  errorMsg = "Failed to copy to clipboard", // empty string will prevent this
) {
  const renderSuccess = () => (successMsg ? message.success(successMsg, MESSAGE_DURATION_SECONDS) : null)
  const renderError = () => (errorMsg ? message.error(errorMsg, MESSAGE_DURATION_SECONDS) : null)
  if (!data) {
    renderError()
    return
  }

  try {
    await navigator.clipboard.writeText(data)
    renderSuccess()
  } catch (err) {
    console.error(err)
    renderError()
  }
}

export { copyToClipboard }
