import { Tag, Tooltip } from "antd"
import { FragmentType, unmaskFragment } from "src/api/graphql/fragment-masking.ts"
import { useAnalytics } from "src/global/hooks/analytics/useAnalytics"
import { graphql } from "src/api/graphql/gql.ts"

export const ActiveAsset_CoverageStatsDocument = graphql(`
  fragment ActiveAsset_CoverageStats on AssetCoverageStats {
    isActive
  }
`)

type ActiveAssetCoverageStat = FragmentType<typeof ActiveAsset_CoverageStatsDocument>

type ActiveAssetStatusTagProps = {
  assetStats: ActiveAssetCoverageStat
}

const INACTIVE_TOOLTIP = "This Data Asset has not been validated in the last 30 days."
const ACTIVE_TOOLTIP = "This Data Asset has been validated in the last 30 days."

const ActiveAssetStatusTag = ({ assetStats }: ActiveAssetStatusTagProps) => {
  const posthog = useAnalytics()
  const { isActive } = unmaskFragment(ActiveAsset_CoverageStatsDocument, assetStats)
  const onHover = () => {
    posthog?.capture("metric_coverage_stats_active_tag_tooltip.hover", {
      isActive,
    })
  }

  return (
    <Tooltip title={isActive ? ACTIVE_TOOLTIP : INACTIVE_TOOLTIP} onOpenChange={onHover}>
      <Tag color={isActive ? "green" : "default"}>{isActive ? "Active" : "Inactive"}</Tag>
    </Tooltip>
  )
}

export default ActiveAssetStatusTag
