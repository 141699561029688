import { CronExpressionParser } from "cron-parser"

interface IteratorProps {
  start?: number
  freq?: number
}

export const DEFAULT_SCHEDULE_FREQUENCY = 24

/**
 * Generates an array of values for use in a crontab expression's hour property.
 * Given a start & frequency, increment each hour by the frequency step until
 * the current value exceeds 23 (number of 0-indexed hours in a day),
 * at which point loop from 0 and continue until reaching start.
 * Example:
 * given {start: 3, freq: 6}
 * result: 3,9,15,21
 * @param start
 * @param freq
 * @returns {string} Sorted string of the hours, e.g.: "3,9,15,21" or * if no values provided
 */
export function generateCronWithCyclicHours({ start, freq }: IteratorProps): string {
  const range = []
  if (start !== undefined && freq !== undefined) {
    // Create a new date object to get the current UTC hour
    const UTCHour = new Date()
    // Set the current hour to the start value
    UTCHour.setHours(start, 0, 0, 0)
    // Get the current hour in UTC
    const startOffset = UTCHour.getUTCHours()
    // Initialize the current hour to the UTC-converted start value
    let current = startOffset
    do {
      range.push(current)
      current = (current + freq) % 24
    } while (current !== startOffset)
    const hours = range.sort((a, b) => a - b).join(",")
    return `0 ${hours} * * ? *`
  }

  // default to 0, i.e. 0th minute of the 0th hour / midnight
  return "0 0 * * ? *"
}
/**
 * Given a 5-value cron expression, parse out the hours and calculate the smallest interval
 * between them (this accounts for any possible variations, but there shouldn't be any)
 * @param cronExp
 * @returns {number} The integer value of the interval between hours
 */
export function getHourlyIntervalFromCron(cronExp: string): number {
  const cronArr = cronExp.split(" ")
  const hours: number[] = cronArr[1]
    .split(",")
    .map((v) => parseInt(v))
    .sort((a, b) => a - b)

  // Since 24 hours is currently our broadest interval, default to it
  // to account for cron expressions like "0 10 * * *"
  // where the hours[i + 1] - hours[i] results in a NaN or other falsy value
  let interval = 24

  for (let i = 0; i < hours.length - 1; i++) {
    const currInterval = hours[i + 1] - hours[i]
    if (currInterval < interval) {
      interval = currInterval
    }
  }
  return interval
}

/**
 * Given a 5-value cron expression, compute the next time the cron job will run.
 * @param cronExp
 * @returns {Date} The datetime of the next cron run
 */
export function getNextRunFromCron(cronExp: string): Date {
  // AWS EventBridge crons don't follow the standard 6-position expression that cron-parser supports
  // but they're similar enough to the 5-position one that we can just elide the final element.
  const options = {
    tz: "UTC",
  }
  const parsedCronExp = cronExp.split(" ").slice(0, -1).join(" ")
  const interval = CronExpressionParser.parse(parsedCronExp, options)
  return interval.next().toDate()
}
/**
 * Returns the next hour, and 0 if the current
 */
export function getNextHour(hour: number): number {
  return (hour + 1) % 24
}
