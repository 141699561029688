import React, { useCallback, useMemo, useState } from "react"
import { Drawer, ScrollableFlex } from "src/global/ui/Drawer/Drawer.tsx"
import { AddAssetDrawerHeader, LogoAndLinkHeader } from "src/pages/DataAssets/components/DataCRUDDrawerHeaders.tsx"
import ConnectToDataSourceContext from "src/pages/DataAssets/drawers/ConnectToDataSource/ConnectToDataSourceContext.ts"
import { Flex } from "antd"
import type {
  PossibleDataSource,
  ConnectToDataSourceProps,
  MaybeButtonWithLabel,
} from "src/pages/DataAssets/drawers/ConnectToDataSource/types.ts"
import { SupportedDataSource } from "src/pages/DataAssets/schemas/data-source-schemas.ts"
import { getAddressFromDataSource } from "src/pages/DataAssets/drawers/ConnectToDataSource/common/SelectTableNames/get-address-from-data-source.ts"
import { useTheme } from "styled-components"
import { DataQualityIssues } from "src/api/graphql/graphql"
import { ErrorBoundary } from "react-error-boundary"
import { ProgressBar } from "src/pages/DataAssets/drawers/ConnectToDataSource/common/ProgressBar"
import { BaseErrorStep } from "src/pages/DataAssets/drawers/ConnectToDataSource/steps/ErrorStep"
import { useIsFeatureEnabled } from "src/global/hooks/useIsFeatureEnabled"

export function ConnectToDataSourceContainer({ config }: ConnectToDataSourceProps) {
  const { steps, dataSourceType: _dataSourceType, dataSource: _dataSource, progressBar } = config
  const [activeStep, setActiveStep] = useState<number>(0)
  const [previouslyActiveStep, setPreviouslyActiveStep] = useState<number>(activeStep - 1) // used to determine user's current direction through the steps
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [formData, setFormData] = useState<Record<string, unknown>>({})
  const [dataSource, setDataSource] = useState<PossibleDataSource | undefined>(_dataSource)
  const [dataSourceType] = useState<SupportedDataSource | undefined>(_dataSourceType)
  const [allTableNames, setAllTableNames] = useState<string[]>([])
  const [selectedTables, setSelectedTables] = useState<string[]>([])
  const [filteredTables, setFilteredTables] = useState<string[]>([])
  const completenessChangeDetectionEnabled = useIsFeatureEnabled("completenessChangeDetectionEnabled")
  const [selectedDataQualityIssues, setSelectedDataQualityIssues] = useState<DataQualityIssues[]>([
    "SCHEMA",
    "VOLUME",
    ...(completenessChangeDetectionEnabled ? (["COMPLETENESS"] satisfies DataQualityIssues[]) : []),
  ])
  const theme = useTheme()

  // Move backward and forward through steps and perform
  // any registered custom or component-level actions.
  // Also set `previouslyActiveStep` in case there are any
  // EmptyStep components present.
  const prevStep = useCallback(() => {
    setPreviouslyActiveStep(activeStep)
    const prevStepConfig = steps[activeStep].footerButtons.prev
    const prevActiveStep = activeStep - 1
    setActiveStep(Math.max(prevActiveStep, 0))
    if (typeof prevStepConfig !== "string") {
      prevStepConfig?.customAction && prevStepConfig.customAction(prevActiveStep)
    }
  }, [activeStep, steps])

  const nextStep = useCallback(() => {
    setPreviouslyActiveStep(activeStep)
    const nextStepConfig = steps[activeStep].footerButtons.next
    const nextActiveStep = activeStep + 1
    setActiveStep(Math.min(nextActiveStep, steps.length - 1))
    if (typeof nextStepConfig !== "string") {
      nextStepConfig?.customAction && nextStepConfig.customAction(nextActiveStep)
    }
  }, [activeStep, steps])

  // Get datasource address from config
  const dataSourceAddress = useMemo(() => {
    if (dataSource && "config" in dataSource) {
      const config = dataSource.config as string
      return getAddressFromDataSource(JSON.parse(config))
    }
  }, [dataSource])

  const handleFooterButtonClick = (next: () => void, componentActions?: () => Promise<boolean>) => async () => {
    let shouldProceed = true
    if (componentActions) {
      shouldProceed = await componentActions()
    }
    shouldProceed && next()
  }

  const header = useCallback(
    (showHeader?: boolean) => {
      if (showHeader !== false && dataSourceType) {
        if (dataSource?.name) {
          return (
            <div style={{ marginBottom: theme.spacing.s, marginTop: theme.spacing.s }}>
              <AddAssetDrawerHeader
                dataSourceType={dataSourceType}
                dataSourceName={dataSource.name}
                dataSourceAddress={dataSourceAddress}
              />
            </div>
          )
        }
        return (
          <div style={{ marginBottom: theme.spacing.xs, marginTop: theme.spacing.xs }}>
            <LogoAndLinkHeader dataSourceType={dataSourceType} text="Connect" secondaryText="schema as a Data Source" />
          </div>
        )
      }
      return null
    },
    [dataSource, dataSourceAddress, dataSourceType, theme],
  )

  // Coalesce all possible button labels
  const getButtonLabel = (defaultLabel: string, btn?: MaybeButtonWithLabel, label?: string) => {
    if (label) {
      return label
    }
    if (typeof btn === "string") {
      return btn
    }
    return btn?.label ?? defaultLabel
  }

  /**
   * The ConnectToDataSourceContainer component acts as a governing proxy between the
   * broader application and the discrete Step components defined in the ./steps directory.
   *
   * On the application side it exposes a configurable sequence of predefined Steps
   * with limited customizations available to each Step at config time.
   *
   * Within its internal context, it leverages the Render Prop pattern (see markup comment below)
   * to allow the slotted Step components to register their own (optional) actions
   * that run on the "back" and "next" buttons interactions.
   *
   * This covers use cases unique to both "users" and "developers" of these components.
   *
   * For example:
   * - as a "user" of these components, I may have some particular app state that I need to set
   *     when someone clicks a Back button. I can provide this state management via the
   *     footerButtons config for these steps without having to know how any particular
   *     Step is implemented
   *
   * - as a "developer" of these components, I know that my particular Step needs to make
   *     a few API calls when someone clicks the Next button, which in my use-case doubles
   *     as a Submit button. Using the Render Prop pattern allows me to hook my child
   *     component's API call into the parent Container's "next" button click and still adhere to
   *     any async/await needs presented by the API calls
   *
   */
  return (
    <ConnectToDataSourceContext.Provider
      value={{
        activeStep,
        nextStep,
        prevStep,
        previouslyActiveStep,
        isLoading,
        setIsLoading,
        formData,
        setFormData,
        dataSource,
        setDataSource,
        dataSourceType,
        selectedTables,
        setSelectedTables,
        filteredTables,
        setFilteredTables,
        allTableNames,
        setAllTableNames,
        selectedDataQualityIssues,
        setSelectedDataQualityIssues,
        isDemo: config?.isDemo,
      }}
    >
      {progressBar && steps.length > 1 && <ProgressBar steps={steps} currentStep={activeStep} />}

      <Flex
        vertical
        style={{
          flexGrow: 1,
          height: "100%",
          width: "100%",
          overflowY: "hidden",
          boxSizing: "border-box",
        }}
      >
        {steps.map(
          ({ title, bodyComponent: BodyComponent, footerButtons }, i) =>
            activeStep === i && (
              <React.Fragment key={title}>
                <ErrorBoundary FallbackComponent={BaseErrorStep} onReset={prevStep}>
                  <BodyComponent>
                    {/* Leverage Render Prop pattern here to provide an inversion of control for the child components' back & next actions. */}
                    {({ back, next, nextLabel, backLabel, loading, disableProgress, showHeader }, children) => (
                      <>
                        {/* Header */}
                        <ScrollableFlex
                          vertical
                          style={{
                            paddingLeft: theme.spacing.s,
                            paddingRight: theme.spacing.s,
                            paddingTop: theme.spacing.xs,
                          }}
                        >
                          <div>{header(showHeader)}</div>

                          {/* Child Components */}
                          {children}
                        </ScrollableFlex>

                        {/* Footer */}
                        <Drawer.Footer
                          style={{
                            borderTop: ".5px solid",
                            color: theme.colors.neutralColorPalette.backgroundsAndBorders.gxBorder,
                          }}
                        >
                          <Drawer.FooterButton onClick={handleFooterButtonClick(prevStep, back)}>
                            {getButtonLabel("Back", footerButtons?.prev, backLabel)}
                          </Drawer.FooterButton>
                          <Drawer.FooterButton
                            type="primary"
                            loading={loading}
                            disabled={loading || disableProgress}
                            onClick={handleFooterButtonClick(nextStep, next)}
                          >
                            {getButtonLabel("Next", footerButtons?.next, nextLabel)}
                          </Drawer.FooterButton>
                        </Drawer.Footer>
                      </>
                    )}
                  </BodyComponent>
                </ErrorBoundary>
              </React.Fragment>
            ),
        )}
      </Flex>
    </ConnectToDataSourceContext.Provider>
  )
}
