import { REFRESH_AND_TRY_AGAIN_OR_CONTACT_SUPPORT } from "src/global/words.tsx"
import { Link } from "src/global/ui/typography/Link/Link.tsx"

export const VALIDATE_SUCCESS_TEXT = "Data Asset validation complete."
export const VALIDATE_ERROR_TEXT = "Unable to validate Data Asset."
export const VALIDATE_JOB_CREATION_ERROR_DESCRIPTION = REFRESH_AND_TRY_AGAIN_OR_CONTACT_SUPPORT

export const ISSUE_TOKEN_ERROR_TEXT = "Access token retrieval failed."
export const ISSUE_TOKEN_ERROR_DESCRIPTION = REFRESH_AND_TRY_AGAIN_OR_CONTACT_SUPPORT

export const VALIDATE_ERROR_DESCRIPTION = (
  <>
    See{" "}
    <Link to="/logs" strong>
      logs
    </Link>{" "}
    for details.
  </>
)

export const VALIDATE_ERROR_EMPTY_BATCH_TEXT = "No data available for specified batch."

export const FETCH_METRICS_SUCCESS_TEXT = "Data Asset profiling complete."
export const FETCH_METRICS_ERROR_TEXT = "We were not able to profile this Data Asset."
export const FETCH_METRICS_ERROR_DESCRIPTION = (
  <>
    See{" "}
    <Link to="/logs" strong>
      logs
    </Link>{" "}
    for details.
  </>
)
