import { useState, useCallback } from "react"
import { useMutation } from "@apollo/client"
import { Flex, Form, Popover, Switch } from "antd"
import { Button } from "src/global/ui/Button/Button.tsx"
import { Icon } from "src/global/ui/Icon"
import { graphql } from "src/api/graphql/gql.ts"
import { FragmentType, unmaskFragment } from "src/api/graphql/fragment-masking.ts"
import { frequencyOptions, startTimeOptions } from "src/pages/DataAssets/views/Expectations/ExpectationSuiteDrawer.tsx"
import { Select } from "src/global/ui/Select"
import { useTheme } from "styled-components"
import {
  getHourlyIntervalFromCron,
  DEFAULT_SCHEDULE_FREQUENCY,
  generateCronWithCyclicHours,
  getNextRunFromCron,
} from "src/global/utils/cron.ts"
import { Heading3 } from "src/global/ui/typography/Text/Text.tsx"

export const EditScheduleMutationDocument = graphql(`
  mutation EditSchedule($input: UpdateScheduleInput!) {
    updateScheduleV2(input: $input) {
      schedule {
        schedule
        startTime
        id
        isEnabled
      }
    }
  }
`)

export function EditSchedulePopover({
  schedule,
}: {
  schedule: FragmentType<typeof EditScheduleForm_ScheduleFragmentDocument> | undefined | null
}) {
  const [isOpen, setIsOpen] = useState(false)
  const theme = useTheme()
  return (
    <Popover
      overlayInnerStyle={{ padding: theme.spacing.s }}
      placement="bottomLeft"
      arrow={false}
      destroyTooltipOnHide
      content={<EditScheduleForm schedule={schedule} onClose={() => setIsOpen(false)} />}
      title={
        <Flex align="center" justify="space-between">
          <Flex align="center">
            <Icon
              name="schedule"
              height={theme.spacing.s}
              width={theme.spacing.s}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: theme.spacing.xxs,
              }}
            />
            <Heading3>Validation schedule</Heading3>
          </Flex>
          <Button
            icon="close"
            aria-label="Close edit window"
            iconSize="18px"
            type="text"
            onClick={() => setIsOpen(false)}
          />
        </Flex>
      }
      trigger="click"
      open={isOpen}
      onOpenChange={() => setIsOpen(true)}
    >
      <Button aria-label="Edit Schedule" icon="edit" onClick={() => setIsOpen(!isOpen)}>
        Edit schedule
      </Button>
    </Popover>
  )
}

const EditScheduleForm_ScheduleFragmentDocument = graphql(`
  fragment EditScheduleForm_Schedule on Schedule {
    id
    schedule
    isEnabled
    startTime
  }
`)

type ScheduleForm = { scheduledStart: number; scheduledFrequency: number }

function EditScheduleForm({
  schedule: maskedSchedule,
  onClose,
}: {
  schedule: FragmentType<typeof EditScheduleForm_ScheduleFragmentDocument> | undefined | null
  onClose: () => void
}) {
  const theme = useTheme()
  const schedule = unmaskFragment(EditScheduleForm_ScheduleFragmentDocument, maskedSchedule)
  const [editScheduleMutation, { loading, error }] = useMutation(EditScheduleMutationDocument)
  const nextHour = schedule ? getNextRunFromCron(schedule?.schedule).getHours() : 0
  const selectedFrequencyOption = schedule?.schedule
    ? getHourlyIntervalFromCron(schedule.schedule)
    : DEFAULT_SCHEDULE_FREQUENCY
  const [isToggleOn, setIsToggleOn] = useState<boolean>(schedule?.isEnabled ?? false)

  const onFinish = useCallback(
    async (fields: ScheduleForm) => {
      if (!schedule?.id) {
        return // not a possibility; just assuring the compiler here
      }
      await editScheduleMutation({
        variables: {
          input: {
            id: schedule?.id,
            schedule: generateCronWithCyclicHours({ start: fields.scheduledStart, freq: fields.scheduledFrequency }),
            startTime: fields.scheduledStart,
            isEnabled: isToggleOn,
          },
        },
      })
      onClose()
    },
    [editScheduleMutation, onClose, schedule?.id, isToggleOn],
  )

  return (
    <>
      <Switch
        checked={isToggleOn}
        checkedChildren="ON"
        unCheckedChildren="OFF"
        onChange={() => setIsToggleOn(!isToggleOn)}
        aria-label="Schedule On/Off Toggle"
        style={{ marginBottom: theme.spacing.xxs }}
      />
      <Form<ScheduleForm> layout="vertical" style={{ width: "300px" }} onFinish={onFinish} disabled={!isToggleOn}>
        <Form.Item initialValue={selectedFrequencyOption} name="scheduledFrequency" label="Frequency" required>
          <Select options={frequencyOptions} defaultValue={frequencyOptions[frequencyOptions.length - 1]} />
        </Form.Item>
        <Form.Item initialValue={nextHour} name="scheduledStart" label="Start time (local timezone)" required>
          <Select options={startTimeOptions} />
        </Form.Item>
        <Form.Item
          style={{ marginTop: theme.spacing.s }}
          {...(error && { validateStatus: "error", help: error.message })}
        >
          <Flex justify="flex-end">
            <Button type="primary" loading={loading} htmlType="submit">
              Save
            </Button>
          </Flex>
        </Form.Item>
      </Form>
    </>
  )
}
