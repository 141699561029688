import React from "react"
import ReactDOM from "react-dom"
import { App } from "src/pages/App"
import "src/index.css"
import { shouldUseMswMocks } from "src/global/utils/devModeHelpers"
import { initializeSentry } from "src/global/observability/sentry/sentry"
import { PostHogProvider } from "posthog-js/react"
import { POSTHOG_API_HOST } from "src/global/config.ts"
import { REACT_APP_POSTHOG_API_KEY, REACT_APP_LAUNCHDARKLY_CLIENT_SIDE_ID } from "src/global/env.ts"
import { loadHiddenScripts } from "src/scripts"
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk"
import { basicLogger } from "launchdarkly-js-client-sdk"

const ANONYMOUS_ID = "anonymous-id"
initializeSentry()

if (shouldUseMswMocks()) {
  // only load mocking code if needed (especially faker, which is heavy)
  import("./api/mocks/browser").then(({ worker }) =>
    worker.start({
      quiet: true,
      onUnhandledRequest: "bypass",
    }),
  )
}

loadHiddenScripts()

const options = {
  api_host: POSTHOG_API_HOST,
}

// adding ANONYMOUS_ID before we identify the user in ApolloAuthenticatedProvider,
// so that every user is not considered unique and add to MAU count
;(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: REACT_APP_LAUNCHDARKLY_CLIENT_SIDE_ID || "",
    context: { kind: "user", key: ANONYMOUS_ID },
    options: {
      logger: basicLogger({ level: "warn" }),
      bootstrap: "localStorage",
    },
  })

  ReactDOM.render(
    <React.StrictMode>
      <LDProvider>
        <PostHogProvider apiKey={REACT_APP_POSTHOG_API_KEY} options={options}>
          <App />
        </PostHogProvider>
      </LDProvider>
    </React.StrictMode>,
    document.getElementById("root"),
  )
})()
