import { SearchProps } from "antd/es/input/Search"
import { theme } from "src/global/ui/themes/theme.ts"
import { ConfigProvider, Input } from "antd"

export const Search = ({ ...props }: SearchProps) => {
  return (
    <ConfigProvider theme={{ components: { Button: { colorPrimaryHover: theme.colors.primaryColors.gxPrimaryDark } } }}>
      <Input.Search {...props} />
    </ConfigProvider>
  )
}
