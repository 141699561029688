import { Flex, Space } from "antd"
import { useRequireRole } from "src/global/hooks/useRequireRole.ts"
import { Button } from "src/global/ui/Button/Button.tsx"
import { AlertNotificationsButton } from "src/global/components/alerts/AlertNotificationsButton.tsx"
import { ShareButton } from "src/global/ui/ShareButton"
import { ConditionalTooltip } from "src/global/ui/Tooltip/ConditionalTooltip.tsx"
import { NO_EXPECTATIONS_TOOLTIP_TEXT } from "src/pages/DataAssets/views/Expectations/Expectation/words.ts"
import { RunCheckpointJobButton } from "src/pages/DataAssets/views/Expectations/RunCheckpointJobButton.tsx"
import { ValidateSubsetButton } from "src/pages/DataAssets/views/AssetDetails/Splitters/ValidateSubsetButton.tsx"
import { CREATE_EXPECTATION_DRAWER_TITLE } from "src/pages/DataAssets/views/Expectations/Expectation/CreateExpectationDrawer/words.ts"

type DataAssetHeaderActionsProps = {
  assetId: string
  showValidateSubsetButton: boolean
  noExpectations: boolean
  onNewExpectationButtonClick?: () => void
  expectationSuiteId?: string
}

export const DataAssetHeaderActions = ({
  assetId,
  showValidateSubsetButton,
  noExpectations,
  expectationSuiteId = "",
  onNewExpectationButtonClick,
}: DataAssetHeaderActionsProps) => {
  const isEditor = useRequireRole("EDITOR")
  const newExpectationOrShareButton =
    isEditor && onNewExpectationButtonClick ? (
      <Button key="plus" type="default" icon="plus" onClick={onNewExpectationButtonClick}>
        {CREATE_EXPECTATION_DRAWER_TITLE}
      </Button>
    ) : (
      <ShareButton key="share" />
    )

  const editorValidateButton = showValidateSubsetButton ? (
    <ValidateSubsetButton
      key="validateSubset"
      expectationSuiteId={expectationSuiteId}
      assetId={assetId}
      disabled={noExpectations}
    />
  ) : (
    <RunCheckpointJobButton
      key="runCheckpointJob"
      expectationSuiteId={expectationSuiteId}
      assetId={assetId}
      disabled={noExpectations}
    />
  )

  const validateButton = isEditor ? editorValidateButton : null

  return (
    <Flex justify="flex-end" align="center">
      <Space>
        {[
          <AlertNotificationsButton
            key="alerts"
            assetFlowIds={{ assetId: assetId, expectationSuiteId: expectationSuiteId }}
          />,
          newExpectationOrShareButton,
          <ConditionalTooltip
            key="runCheckpointJob"
            showTooltip={noExpectations}
            tooltipText={NO_EXPECTATIONS_TOOLTIP_TEXT}
          >
            {validateButton}
          </ConditionalTooltip>,
        ]}
      </Space>
    </Flex>
  )
}
