/* eslint-disable react-refresh/only-export-components */ // FIXME
import { Auth0Provider, Auth0ProviderOptions } from "@auth0/auth0-react"
import getAuth0Config from "src/global/authentication/getAuth0Config.ts"
import { useEffect } from "react"
import { REACT_APP_API_URL } from "src/global/env.ts"
import { useIsFeatureEnabled } from "src/global/hooks/useIsFeatureEnabled.ts"
interface Auth0ProviderWithHistoryProps {
  children: Auth0ProviderOptions["children"]
}

export const extractOrgSlug = (pathname: string): string | undefined => {
  const [, static_segment, slug] = pathname.split("/")
  if (static_segment === "organizations" && !!slug) {
    return slug
  }
  return undefined
}

const Auth0ProviderWithHistory = ({ children }: Auth0ProviderWithHistoryProps) => {
  const config = getAuth0Config()
  useEffect(() => {
    const currentUrlParams = window.location.search

    const storeUrlParams = (urlParams: string) => {
      // This is a bit brittle but it prevents storing these two params generated by Auth0
      if (urlParams.includes("code=") && urlParams.includes("state=")) return
      localStorage.setItem("queryParams", urlParams)
    }

    storeUrlParams(currentUrlParams)
  }, [])

  const orgSlug = extractOrgSlug(window.location.pathname)
  const extraQueryParams: Record<string, string> = {}
  if (orgSlug) {
    extraQueryParams["inviteUrl"] = `${REACT_APP_API_URL}/accounts/invite_requests`
    extraQueryParams["orgSlug"] = orgSlug
  }

  // add signup=true to the query params in case self-serve-signup is enabled.
  // in WebAuthProvider we'll check if this param is passed and show/hide Signup button accordingly

  // allowing access to self serve account creation via url for internal use/specific customers
  const selfServeAccess = new URL(window.location.href).searchParams.get("ssac")
  const selfServeBE = useIsFeatureEnabled("selfServiceAccountCreationEnabled")
  const selfServeFE = useIsFeatureEnabled("selfServiceSignupButtonEnabled")

  const selfServeSignupEnabled = (selfServeBE && selfServeFE) || selfServeAccess

  if (selfServeSignupEnabled) {
    extraQueryParams["signup"] = "true"
  }

  const urlMessage = new URL(window.location.href).searchParams.get("message")
  // message is generated by auth0 when the email has been verified successfully
  const isVerified = urlMessage?.startsWith("Your email was verified")

  if (isVerified) {
    extraQueryParams["emailVerified"] = "true"
  }

  // Auth0 rule sets this query param when the email is not verified
  const emailUnverified = new URL(window.location.href).searchParams.get("email-unverified")
  if (emailUnverified === "true") {
    extraQueryParams["emailVerified"] = "false"
  }

  return (
    <Auth0Provider
      domain={config.domain}
      clientId={config.clientId}
      authorizationParams={{
        redirect_uri: window.origin,
        audience: config.audience,
        ...extraQueryParams,
      }}
      onRedirectCallback={(appState) => {
        const storedParams = localStorage.getItem("queryParams") ?? ""
        const to = appState?.returnTo ? `${appState.returnTo}${storedParams}` : window.location.href
        window.location.href = to
      }}
      useRefreshTokens
      cacheLocation="localstorage"
    >
      {children}
    </Auth0Provider>
  )
}

export default Auth0ProviderWithHistory
