import { Descriptions, DescriptionsProps } from "antd"
import { Param } from "src/global/ui/Param/Param.tsx"
import { useGetSplitterData } from "src/pages/DataAssets/views/AssetDetails/Splitters/useGetSplitterData.ts"
import { pluralize } from "src/global/utils/strings.ts"

function useDescriptionItems({
  isSplitterSupported,
  splitter,
  batchColumnLabel,
  splitterColumns,
}: {
  isSplitterSupported: boolean
  splitter: string
  batchColumnLabel: string
  splitterColumns: string | undefined
}): DescriptionsProps["items"] {
  return [
    {
      label: "Batch interval",
      children: isSplitterSupported ? splitter : "Unsupported",
    },
    {
      label: batchColumnLabel,
      children: <Param>{splitterColumns}</Param>,
    },
  ]
}

export const BatchDefinitionDescription: React.FC<DescriptionsProps> = ({ style, layout = "vertical", column = 5 }) => {
  const { splitter, splitterColumns, isSplitterSupported } = useGetSplitterData({ isVisible: true })

  const columnText = pluralize("column", splitterColumns?.split(",").length || 1)
  const batchColumnLabel = `Batch ${columnText}`

  const items = useDescriptionItems({ isSplitterSupported, splitter, batchColumnLabel, splitterColumns })

  return <Descriptions style={{ ...style }} column={column} layout={layout} items={items} />
}
